import { useCallback } from 'react';
import { NavigateOptions } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { buildSearchParams, replaceSearchParams } from 'util/navigation/searchParams';
export function buildUrl(
  path: string,
  search?: Record<string, string | number | string[] | undefined> | URLSearchParams,
  hash?: string,
) {
  let url = path;
  if (search) {
    const params = search instanceof URLSearchParams ? search : buildSearchParams(search);
    const searchString = params.toString();
    if (searchString) {
      const connectingCharacter = url.includes('?') ? '&' : '?';
      url += `${connectingCharacter}${searchString}`;
    }
  }
  if (hash) {
    url += `#${hash}`;
  }
  return url;
}

export function useNavigation() {
  const navigate = useNavigate();

  const replace = useCallback(
    (
      path: string,
      search?: Record<string, string | number | string[] | undefined> | URLSearchParams,
      options?: Omit<NavigateOptions, 'replace'>,
    ) => {
      navigate(buildUrl(path, search), { ...options, replace: true });
    },
    [navigate],
  );
  const push = useCallback(
    (
      path: string,
      search?: Record<string, string | number | string[]> | URLSearchParams,
      options?: Omit<NavigateOptions, 'replace'>,
    ) => {
      navigate(buildUrl(path, search), { ...options, replace: false });
    },
    [navigate],
  );

  const addSearchParams = useCallback(
    (search: Record<string, string | number | string[] | undefined>, options?: NavigateOptions) => {
      navigate(buildUrl(location.pathname, replaceSearchParams(new URLSearchParams(location.search), search)), {
        replace: true,
        ...options,
      });
    },
    [navigate],
  );

  return { replace, push, addSearchParams };
}
