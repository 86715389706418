import { TextField } from '@mui/material';
import { FormStack } from 'components/general/Form/FormStack';
import { useFormContext } from 'react-hook-form';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';

export const CommentForm = () => {
  const { t } = useTranslation(['contact']);
  const { register } = useFormContext<ContactDetailsFormData>();

  return (
    <FormStack>
      <TextField
        label={t('contact:generalComment')}
        multiline
        sx={{
          '.MuiInput-input': {
            fontSize: theme.typography.body3,
          },
        }}
        {...register('additionalNote')}
      />
    </FormStack>
  );
};
