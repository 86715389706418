import { FormControlLabel, Switch } from '@mui/material';
import { useGetAllEnums } from 'api/graphql/hooks/useGetAllEnums';
import { useGetPropertyTypesAndSubtypesCommand } from 'api/rest';
import { PropertyTypeMapping } from 'api/rest/models/PropertyTypeMapping';
import { DropDown } from 'components/general/DropDown/DropDown';
import { FormStack } from 'components/general/Form/FormStack';
import { InfoTooltip } from 'components/general/InfoTooltip/InfoTooltip';
import HomeIcon from 'components/icons/home.svg?react';
import { assetSubTypeTranslation, assetTypeTranslation, assetVariationTranslation } from 'const/enumTranslations';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { includeEmptyOption } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';
import { enumToLokalisedOptions } from 'util/mappers/enumToOption';
import { NewLeadWithContactFormData } from 'util/schemas/newLeadSchema';

export const PropertyTypeSection = () => {
  const { t } = useTranslation(['lead', 'enums']);
  const { control, watch, setValue, formState, getValues } = useFormContext<NewLeadWithContactFormData>();
  const { errors } = formState;
  const { getPropertyTypesAndSubtypesCommand } = useGetPropertyTypesAndSubtypesCommand();

  const [propertyTypeMap, setPropertyTypeMap] = useState<PropertyTypeMapping>();

  const watchIntentType = watch('intentType');
  const watchPropertyType = watch('property.propertyType');
  const watchPropertySubtype = watch('property.propertySubtype');
  const watchPropertyVariation = watch('property.propertyVariation');
  const isAddProperty = watch('property.isAddProperty');

  const propertySubtypesValues = useMemo(() => {
    return watchPropertyType && propertyTypeMap?.[watchPropertyType]
      ? Object.keys(propertyTypeMap?.[watchPropertyType])
      : [];
  }, [propertyTypeMap, watchPropertyType]);

  const propertyVariationValues = useMemo(
    () =>
      (watchPropertySubtype && watchPropertyType && propertyTypeMap?.[watchPropertyType]?.[watchPropertySubtype]) ?? [],
    [propertyTypeMap, watchPropertyType, watchPropertySubtype],
  );

  const { allEnums } = useGetAllEnums();
  const isRent = watchIntentType === 'RENT_OUT' || watchIntentType === 'TO_RENT';
  useEffect(() => {
    getPropertyTypesAndSubtypesCommand(undefined, {
      onSuccess: (response) => setPropertyTypeMap(response),
    });
  }, [getPropertyTypesAndSubtypesCommand]);

  useEffect(() => {
    if (!propertyTypeMap) {
      return;
    }

    if (watchPropertySubtype && !propertySubtypesValues.includes(watchPropertySubtype)) {
      setValue('property.propertySubtype', undefined);
    }
    if (watchPropertyVariation && !propertyVariationValues.includes(watchPropertyVariation)) {
      setValue('property.propertyVariation', undefined);
    }
  }, [
    propertyTypeMap,
    propertySubtypesValues,
    propertyVariationValues,
    setValue,
    watchPropertySubtype,
    watchPropertyVariation,
  ]);
  const isEditMode = getValues('_internals.isEditMode');

  return (
    <>
      <FormStack icon={<HomeIcon />}>
        <Controller
          name="property.propertyType"
          control={control}
          render={({ field }) => (
            <DropDown
              required
              label={t('lead:propertyEvaluateForm.details.propertyType')}
              hasError={'property' in errors && !!errors?.property?.propertyType}
              errorMessage={'property' in errors ? errors.property?.propertyType?.message : undefined}
              {...field}
              options={
                allEnums?.enumsAssetType
                  ?.filter((item) => !isRent || item.name !== 'PLOT') //We don't have PLOT type for Rent or Rent-out in Go3
                  .map(enumToLokalisedOptions(t, assetTypeTranslation)) || []
              }
            />
          )}
        />
      </FormStack>
      {watchPropertyType && propertySubtypesValues.length > 0 && (
        <FormStack>
          <Controller
            name="property.propertySubtype"
            control={control}
            render={({ field }) => (
              <DropDown
                {...field}
                label={t('lead:propertyEvaluateForm.details.propertySubType')}
                options={includeEmptyOption(
                  propertySubtypesValues?.map((e) => enumToLokalisedOptions(t, assetSubTypeTranslation)({ name: e })),
                )}
              />
            )}
          />
        </FormStack>
      )}
      {watchPropertyType && !isEditMode && (
        <FormStack sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Controller
            name="property.isAddProperty"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                value={value}
                control={
                  <Switch
                    checked={isAddProperty}
                    color="secondary"
                    onChange={(e) => {
                      onChange(e.target.checked);
                    }}
                  />
                }
                label={t('lead:propertyEvaluateForm.details.addPropertyToggle.label')}
              />
            )}
          />
          <InfoTooltip text={t('lead:propertyEvaluateForm.details.addPropertyToggle.tooltip')} />
        </FormStack>
      )}
      {watchPropertySubtype && propertyVariationValues.length > 0 && (
        <FormStack>
          <Controller
            name="property.propertyVariation"
            control={control}
            render={({ field }) => (
              <DropDown
                {...field}
                label={t('lead:propertyEvaluateForm.details.propertyVariation')}
                options={includeEmptyOption(
                  propertyVariationValues?.map((e) =>
                    enumToLokalisedOptions(t, assetVariationTranslation)({ name: e }),
                  ),
                )}
              />
            )}
          />
        </FormStack>
      )}
    </>
  );
};
