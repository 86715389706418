import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

interface StyledBadgeProps extends BadgeProps {
  dotColor?: string;
}

export const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop: string) => !['dotColor'].includes(prop),
})<StyledBadgeProps>(({ theme, dotColor }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: dotColor,
    color: dotColor,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));
