import ImageIcon from 'components/icons/image.svg?react';
import { CSSProperties } from 'react';
import { theme } from 'theme';

const ICON_SIZE = 48;
const MIN_ICON_MARGIN = 8;

interface PlaceholderImageProps extends React.HTMLAttributes<HTMLElement> {
  width?: number;
  height?: number;
  style?: CSSProperties;
  fill?: boolean | undefined;
}

export function PlaceholderImage({ style, width, height, fill, ...passthroughProps }: PlaceholderImageProps) {
  const showIcon = fill || (height && height >= ICON_SIZE + MIN_ICON_MARGIN);

  return (
    <div
      {...passthroughProps}
      style={{
        ...style,
        width,
        height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.shade.grey4,
        color: theme.palette.shade.grey3,
      }}
    >
      {showIcon && <ImageIcon width={ICON_SIZE} height={ICON_SIZE} />}
    </div>
  );
}
