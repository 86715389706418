import { CallParams, TwilioContext, useSnackBars } from '@ev/eva-container-api';
import { Call, Device } from '@twilio/voice-sdk';
import { BaseActivity, Shop } from 'api/graphql/generated/graphql';
import { useLeadActivities } from 'api/graphql/hooks/useLeadActivities';
import { components } from 'api/rest/generated-rest';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { CallUiState, CallerScreen } from 'components/state/Twilio/CallerScreen';
import { useTwilioSession } from 'components/state/Twilio/useTwilioSession';
import { usePopover } from 'components/state/UIStateProvider';
import { useIsFeatureEnabled } from 'eva-frame/EvaProviders/FeatureFlagProvider';
import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'util/i18next';
import sentry from 'util/sentry';
import { useErrorSnackBar } from 'util/useErrorSnackBar';
import { useInterval } from 'util/useInterval';
import { usePermissions } from 'util/usePermissions';

export type CallOutcome = Exclude<components['schemas']['UpdateCallActivityCommand']['callOutcome'], undefined>;

interface CallState {
  callSid?: string;
  status: Call.State;
  callerNumber: string;
  shopId?: string;
  callContactId?: string;
  isCallMuted?: boolean;
  direction: Call.CallDirection;
}

export interface CallerInfo {
  mailAddress: string;
  suggestedRecipients: string[];
  call: CallState;
  activity?: BaseActivity;
  hasFetchedActivity: boolean;
}

interface TwilioProviderProps {
  children: ReactNode;
}

const InitDevice = (token: string) => {
  return new Device(token, {
    logLevel: 'debug',
    edge: 'ashburn',
  });
};

const RegisterDevice = async (device: Device) => {
  await device.register();
  sentry.addBreadcrumb({ message: 'Registered twilio device' });
};

const DEFAULT_CHECK_CONNECTIVITY_TIME_PERIOD = 300000;

const CallQuality = {
  EXCELLENT: 'excellent',
  GREAT: 'great',
  GOOD: 'good',
  FAIR: 'fair',
  DEGRADED: 'degraded',
};

export function TwilioProvider({ children }: TwilioProviderProps) {
  const { openSnackBar, closeSnackBar } = useSnackBars();
  const { openErrorSnackBar } = useErrorSnackBar();
  const { t } = useTranslation(['communication']);
  const { canCall } = usePermissions();
  const twilioPreflightCheckEnabled = useIsFeatureEnabled('TWILIO_PREFLIGHT_CHECK');
  const { allShops, activeShop } = useActiveShop();

  const [device, setDevice] = useState<Device>();
  const [hasConnectivity, setHasConnectivity] = useState(true);
  const snackBarIdRef = useRef<string | undefined>();

  const hasCallingEnabled = useCallback(
    (shop: Shop) => !!(shop.onboardingLevel && shop.twilioConfigurations[0]?.twilioNumber),
    [],
  );
  const canCallInAnyShop = allShops.some((shop) => hasCallingEnabled(shop) && canCall(shop));
  const { twilioSession, updateTwilioSessionStatus, destroyTwilioSession } = useTwilioSession(canCallInAnyShop);

  useEffect(() => {
    if (canCallInAnyShop) {
      if (!hasConnectivity && twilioSession?.sessionStatus !== 'OFFLINE' && !snackBarIdRef.current) {
        updateTwilioSessionStatus({ status: 'OFFLINE', preflightCheck: true });
        snackBarIdRef.current = openSnackBar(t('communication:globalNotification.badConnection'), 'warning', {
          asBanner: true,
        });
      }

      if (hasConnectivity && snackBarIdRef.current) {
        updateTwilioSessionStatus({ status: 'ACTIVE' });
        closeSnackBar(snackBarIdRef.current);
        snackBarIdRef.current = undefined;
      }
    }

    return () => {
      if (snackBarIdRef.current) {
        closeSnackBar(snackBarIdRef.current);
        snackBarIdRef.current = undefined;
      }
    };
  }, [
    canCallInAnyShop,
    twilioSession?.sessionStatus,
    updateTwilioSessionStatus,
    closeSnackBar,
    hasConnectivity,
    openSnackBar,
    t,
  ]);

  const [mailAddress, setMailAddress] = useState<string>('');
  const [suggestedRecipients, setSuggestedRecipients] = useState<string[]>([]);

  const [call, setCall] = useState<Call>();
  const [callState, setCallState] = useState<CallState>();

  const syncCallStates = useCallback(
    (callInstance?: Call) => {
      // The call object is mutable, so we need to copy its properties into the state
      if (!callInstance) {
        setCallState(undefined);
      } else {
        setCallState((state) => ({
          ...state,
          callSid: callInstance?.customParameters.get('parentCallSid') ?? callInstance?.parameters?.CallSid,
          status: callInstance.status(),
          callerNumber: callInstance?.parameters.From ?? callInstance?.customParameters.get('phoneNumber') ?? '',
          shopId: callInstance?.customParameters.get('shopId'),
          callContactId: callInstance?.customParameters.get('contactId'),
          direction: callInstance.direction,
          instance: callInstance,
        }));
      }
    },
    [setCallState],
  );

  const [uiState, setUiStateCall] = useState<CallUiState>();
  const { setOpen } = usePopover('callPopover');

  const setUiState = useCallback(
    (callState: CallUiState | undefined) => {
      setUiStateCall(callState);
      setOpen(!!callState, { minimizedIfOtherPopoverIsOpen: callState === 'incoming' });
    },
    [setOpen],
  );

  const closeCallPopover = useRef<() => void>();
  closeCallPopover.current = () => {
    setCall(undefined);
    setUiState(undefined);
    syncCallStates(undefined);
    if (twilioSession?.sessionStatus !== 'OFFLINE') {
      updateTwilioSessionStatus({ status: 'ACTIVE', force: true });
    }
  };

  const toggleMuteCall = (call: Call) => {
    if (callState) {
      call.mute(!callState?.isCallMuted);
      setCallState({
        ...callState,
        isCallMuted: !callState?.isCallMuted,
      });
    }
  };

  function cleanup(device: Device) {
    try {
      device.disconnectAll();
      device.destroy();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Unable to cleanup twilio', error);
    }
  }

  const hasCall = !!call;
  const outgoingCall = useCallback(
    async ({ phoneNumber, contactId, shopId, isCallToShop, mailAddress, suggestedRecipients }: CallParams) => {
      if (hasCall) {
        return;
      }
      sentry.addBreadcrumb({ message: 'Start outgoing call', data: { contactId, shopId } });
      const formattedNumber = phoneNumber.replace(/\s/g, '');

      const params: Record<string, string> = {
        To: formattedNumber,
        phoneNumber,
        isCallToShop: isCallToShop ? String(isCallToShop) : String(false),
        ...(contactId ? { contactId } : undefined),
        ...(shopId ? { shopId } : undefined),
      };

      const outgoingCall = await device?.connect({ params });

      setCall(outgoingCall);
      syncCallStates(outgoingCall);
      setUiState('outgoing');

      if (mailAddress) {
        setMailAddress(mailAddress);
      }
      if (suggestedRecipients) {
        setSuggestedRecipients(suggestedRecipients);
      }

      outgoingCall?.on('accept', (call: Call) => {
        sentry.addBreadcrumb({ message: 'Outgoing call accepted' });
        syncCallStates(call);
        setUiState('active');
      });

      outgoingCall?.on('reject', () => {
        sentry.addBreadcrumb({ message: 'Outgoing rejected' });
        syncCallStates(outgoingCall);
        setCall(undefined);
        setUiState('active');
      });

      outgoingCall?.on('cancel', () => {
        sentry.addBreadcrumb({ message: 'Outgoing canceled' });
        syncCallStates(outgoingCall);
        setCall(undefined);
        setUiState('active');
      });

      outgoingCall?.on('disconnect', (call: Call) => {
        sentry.addBreadcrumb({ message: 'Outgoing call disconnected' });
        syncCallStates(call);
        setCall(undefined);
        setUiState('active');
      });

      outgoingCall?.on('error', (error) => {
        if (error.code === 31005) {
          openErrorSnackBar(t('communication:call.device.noPermissionOrInvalidNumberError'), 'error');
        }
        sentry.addBreadcrumb({ message: 'Outgoing call failed', data: { error } });
        syncCallStates(outgoingCall);
        setCall(undefined);
        setUiState('active');
      });
    },
    [hasCall, device, syncCallStates, openErrorSnackBar, t, setUiState],
  );

  useEffect(() => {
    if (twilioSession?.twilioToken) {
      const device = InitDevice(twilioSession.twilioToken);
      RegisterDevice(device).then(() => {
        setDevice(device);

        device.on('error', (error) => {
          if (twilioPreflightCheckEnabled) {
            setHasConnectivity(false);
          } else {
            openSnackBar(t('communication:call.device.unknownError'), 'error');
            sentry.addBreadcrumb({ message: 'Twilio device error', data: { error } });
          }
        });

        device.on('incoming', (incomingCall: Call) => {
          sentry.addBreadcrumb({
            message: 'Received incoming call',
            data: { callSid: incomingCall?.customParameters.get('parentCallSid') ?? incomingCall?.parameters?.CallSid },
          });

          setCall(incomingCall);
          syncCallStates(incomingCall);
          setUiState('incoming');

          incomingCall.on('accept', (call: Call) => {
            sentry.addBreadcrumb({ message: 'Incoming call accepted' });
            syncCallStates(call);
            setUiState('active');
          });
          incomingCall.on('disconnect', (call: Call) => {
            sentry.addBreadcrumb({ message: 'Incoming call disconnected' });
            syncCallStates(call);
            setCall(undefined);
            setUiState('active');
          });
          incomingCall.on('reject', () => {
            sentry.addBreadcrumb({ message: 'Incoming call rejected' });
            closeCallPopover.current?.();
          });
          incomingCall.on('cancel', () => {
            sentry.addBreadcrumb({ message: 'Incoming call canceled' });
            closeCallPopover.current?.();
          });
          incomingCall?.on('error', (error) => {
            sentry.addBreadcrumb({ message: 'Incoming call failed', data: { error } });
            syncCallStates(incomingCall);
            setCall(undefined);
            setUiState('active');
          });
        });
      });
      return () => cleanup(device);
    } else {
      setDevice(undefined);
    }
  }, [t, openSnackBar, syncCallStates, twilioSession?.twilioToken, twilioPreflightCheckEnabled, setUiState]);

  const checkConnectivity = useCallback((token?: string | null) => {
    if (!token) {
      setHasConnectivity(false);
      return;
    }
    const preflightTest = Device.runPreflight(token, { fakeMicInput: true });

    preflightTest.on('completed', (report) => {
      if (report.callQuality === CallQuality.DEGRADED) {
        setHasConnectivity(false);
      } else {
        setHasConnectivity(true);
      }
    });

    preflightTest.on('failed', () => {
      setHasConnectivity(false);
    });
  }, []);

  const offlineStatusWasManuallySet = twilioSession?.sessionStatus === 'OFFLINE' && !twilioSession?.preflightCheck;
  useInterval(
    () =>
      canCallInAnyShop &&
      twilioSession?.sessionStatus !== 'BUSY' &&
      !offlineStatusWasManuallySet &&
      twilioPreflightCheckEnabled &&
      checkConnectivity(twilioSession?.twilioToken),
    DEFAULT_CHECK_CONNECTIVITY_TIME_PERIOD,
  );

  const hasActiveShopCallingEnabled = !!activeShop && hasCallingEnabled(activeShop);
  const contextValue = useMemo(
    () => ({
      twilioSession,
      canCallInAnyShop,
      hasActiveShopCallingEnabled,
      outgoingCall,
      openEmptyCallPopover: () => setUiState('empty'),
      updateTwilioSessionStatus,
      destroyTwilioSession,
    }),
    [
      twilioSession,
      canCallInAnyShop,
      hasActiveShopCallingEnabled,
      outgoingCall,
      setUiState,
      updateTwilioSessionStatus,
      destroyTwilioSession,
    ],
  );

  const callSid = callState?.callSid;
  const { activities, isFetched } = useLeadActivities(
    {
      where: { callActivity: { call: { callSid: { _eq: callSid } } } },
    },
    { enabled: !!callSid, refetchInterval: (activities) => (activities?.length ? 60_000 : 10_000) },
  );

  const callerInfo: CallerInfo | undefined = callState && {
    mailAddress: mailAddress,
    suggestedRecipients: suggestedRecipients,
    call: callState,
    activity: activities?.[0],
    hasFetchedActivity: isFetched,
  };

  return (
    <TwilioContext.Provider value={contextValue}>
      <CallerScreen
        callInstance={call}
        callerInfo={callerInfo}
        toggleMuteCall={toggleMuteCall}
        onClose={() => closeCallPopover.current?.()}
        uiState={uiState}
        setUiState={setUiState}
        hasCallingEnabled={hasCallingEnabled}
      />
      {children}
    </TwilioContext.Provider>
  );
}

export function useTwilio() {
  return useContext(TwilioContext);
}
