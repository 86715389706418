import { MODAL_Z_INDEX } from '@ev/eva-container-api';
import { Button, IconButton, useMediaQuery } from '@mui/material';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { ButtonsBox, ModalBox } from 'components/general/ActionsModal/style';
import CrossNaked from 'components/icons/cross_naked.svg?react';
import { FC, ReactNode } from 'react';
import { theme } from 'theme';

export interface Action {
  message: string;
  variant: 'text' | 'outlined' | 'contained' | undefined;
  color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
  handleClick: () => void;
  disabled?: boolean;
}

interface Props {
  open: boolean;
  handleClose: () => void;
  title: string;
  description?: string | ReactNode;
  actions: Action[];
  sx?: SxProps;
  children?: ReactNode;
  modalTextAlign?: string;
}

export const ActionsModal: FC<Props> = ({
  open,
  handleClose,
  title,
  description,
  actions,
  sx,
  children,
  modalTextAlign = 'center',
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  return (
    <Modal
      sx={{ zIndex: MODAL_Z_INDEX, ...sx }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalBox
        sx={{ backgroundColor: 'background.paper', ...(isMobile ? { px: 5 } : {}), maxWidth: '90vw' }}
        data-testid="actions-modal"
      >
        <Typography
          variant="h6"
          component="h1"
          sx={{
            fontSize: theme.typography.h2,
            textAlign: modalTextAlign,
            color: theme.palette.text.primary,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontSize: theme.typography.body3,
            textAlign: modalTextAlign,
            color: theme.palette.text.secondary,
          }}
        >
          {description}
        </Typography>
        {children}
        <ButtonsBox className="dialog-buttons">
          {actions.map((action, i) => {
            return (
              <Button
                key={i}
                color={action.color}
                variant={action.variant}
                onClick={action.handleClick}
                disabled={action.disabled}
                sx={{ width: '228px', height: '36px' }}
              >
                {action.message}
              </Button>
            );
          })}
        </ButtonsBox>
        <IconButton onClick={() => handleClose()} sx={{ position: 'absolute', top: 0, right: 0 }}>
          <CrossNaked height="1em" width="1em" />
        </IconButton>
      </ModalBox>
    </Modal>
  );
};
