import { SvgIcon } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { SxProps } from '@mui/material/styles';
import InfoIcon from 'components/icons/info.svg?react';
import { ReactNode } from 'react';

export function InfoTooltip({ text, sx }: { text: ReactNode; sx?: SxProps }) {
  return (
    <Tooltip title={text}>
      <div>
        <SvgIcon component={InfoIcon} sx={{ display: 'block', ...sx }} />
      </div>
    </Tooltip>
  );
}
