import { FeatureToggle, getFeatureToggles, toggleFeature } from 'util/useFeatureToggles';

declare global {
  interface Window {
    ev: {
      toggleFeature: (feature: FeatureToggle) => void;
      getFeatureToggles: () => void;
    };
  }
}

window.ev = window.ev || {
  toggleFeature,
  getFeatureToggles,
};
