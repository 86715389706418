import { Divider, ListItemIcon, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { DropdownMenu } from 'components/general/DropDown/DropdownMenu';
import CaretIcon from 'components/icons/arrow_1_down.svg?react';
import Book from 'components/icons/book.svg?react';
import Page from 'components/icons/document_naked.svg?react';
import LinkIcon from 'components/icons/link_external.svg?react';
import QuestionMarkIcon from 'components/icons/question.svg?react';
import Star from 'components/icons/star_outlined.svg?react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

export function SupportMenuButton({ asMenuItem }: { asMenuItem?: boolean }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const onCloseMenu = () => setAnchorEl(null);
  const onOpenMenu = (event: React.MouseEvent) => setAnchorEl(event.currentTarget);

  // Enable entries as soon as they should be accessible in production
  const menuListItems = [
    {
      href: 'https://help.engelvoelkers.com/support/solutions/53000022544',
      disabled: false,
      label: t('helperMenu.knowledgeBase'),
      icon: <Book />,
    },
    {
      href: 'https://engelvoelkers.freshservice.com/support/tickets/new',
      disabled: false,
      label: t('helperMenu.submitRequest'),
      icon: <Page />,
    },
    {
      href: 'https://upvoty.engelvoelkers.com/b/leadhub-ev-advance-new-advisor-platform/',
      disabled: false,
      label: t('helperMenu.featureRequest'),
      icon: <Star />,
    },
  ] as const;

  const bottomMenuListItems = [
    {
      href: 'https://www.engelvoelkers.com/en-de/ev-tech-platform-products/imprint/',
      disabled: false,
      sx: {
        textAlign: 'center',
        width: '98px',
      },
      label: t('helperMenu.imprint'),
    },
    {
      href: '',
      disabled: true,
      sx: {
        textAlign: 'center',
        width: '98px',
      },
      label: t('helperMenu.privacy'),
    },
  ] as const;

  return (
    <>
      {asMenuItem ? (
        <MenuItem onClick={onOpenMenu}>
          <ListItemIcon>
            <QuestionMarkIcon />
          </ListItemIcon>
          <ListItemText>{t('helperMenu.buttonLabel')}</ListItemText>
          <CaretIcon />
        </MenuItem>
      ) : (
        <IconButton color="secondary" onClick={onOpenMenu} disableTouchRipple data-testid="helper-menu">
          <QuestionMarkIcon style={{ color: theme.palette.shade.grey2 }} />
        </IconButton>
      )}
      <DropdownMenu
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        id="account-menu"
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        showDrawerOnMobile={{ title: t('helperMenu.buttonLabel') }}
      >
        <MenuList component="div">
          {menuListItems.map(({ href, disabled, icon, label }) => (
            <MenuItem component={Link} to={href} key={label} disabled={disabled} onClick={onCloseMenu} target="_blank">
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText>{label}</ListItemText>
              <LinkIcon />
            </MenuItem>
          ))}
          <Divider variant="middle" />
          {bottomMenuListItems.map(({ href, disabled, label }) => (
            <MenuItem component={Link} to={href} key={label} disabled={disabled} onClick={onCloseMenu} target="_blank">
              <ListItemText>{label}</ListItemText>
              <LinkIcon />
            </MenuItem>
          ))}
        </MenuList>
      </DropdownMenu>
    </>
  );
}
