import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

type ModalContextData = {
  currentTab?: string;
  setCurrentTab: (x: string) => void;
  isMenuExpanded: boolean;
  toggleMenuExpanded: () => void;
  isInvalid?: boolean;
};

export const ModalContext = createContext<ModalContextData>({
  setCurrentTab: () => null,
  isMenuExpanded: false,
  toggleMenuExpanded: () => null,
});

export const ModalContextProvider = ({ children, isInvalid }: { children?: ReactNode; isInvalid?: boolean }) => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [currentTab, setCurrentTab] = useState<string | undefined>(undefined);
  const contextValue = useMemo(
    () => ({
      currentTab,
      setCurrentTab,
      isInvalid,
      isMenuExpanded,
      toggleMenuExpanded: () => setIsMenuExpanded(!isMenuExpanded),
    }),
    [isMenuExpanded, currentTab, isInvalid],
  );

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
};

export const useModalContext = () => useContext(ModalContext);
