import {
  ActivityTypeEnum,
  AppointmentTypeEnum,
  BaseActivityCategoryEnum,
  DbActivityTypeGrouping,
  GetAllEnumsQuery,
  TaskTypeEnum,
} from 'api/graphql/generated/graphql';
import { useActivityWithCategory } from 'api/graphql/hooks/useActivityDetails';
import { useGetAllEnums } from 'api/graphql/hooks/useGetAllEnums';
import { useLeadExtendingActivities } from 'api/graphql/hooks/useShops';
import { useActiveShopId } from 'components/state/ActiveShopProvider';
import { activityTypeTranslationKeys } from 'const/enumTranslations';
import { useTranslation } from 'util/i18next';

export const HIDDEN_CATEGORIES: BaseActivityCategoryEnum[] = ['CONTACT', 'LEAD', 'CONSENT', 'TASK', 'APPOINTMENT'];

export function useActivities() {
  const { activityWithCategory } = useActivityWithCategory();
  const { allEnums } = useGetAllEnums();
  const { activeShopId } = useActiveShopId();

  const { leadExtendingActivities, leadExtendingAppointmentTypes, leadExtendingTaskTypes } =
    useLeadExtendingActivities(activeShopId);
  const { t } = useTranslation(['enums', 'user']);

  const activityWithCategoryFiltered =
    activityWithCategory?.filter((activity) => !HIDDEN_CATEGORIES.includes(activity.activityCategory)) ?? [];
  const activitiesGroupedByCategory = mergeCategories(activityWithCategoryFiltered);

  function mergeCategories(array: DbActivityTypeGrouping[]) {
    return array.reduce((acc, item) => {
      const { activityCategory, activityType } = item;
      if (activityCategory) {
        if (!acc?.[activityCategory]) {
          acc[activityCategory] = [];
        }
        acc?.[activityCategory]?.push({ activityType, label: t(activityTypeTranslationKeys[activityType]) });
      }
      return acc;
    }, {} as { [x: string]: { activityType: ActivityTypeEnum; label: string }[] });
  }

  const areAllItemsSelected = <T extends TaskTypeEnum | AppointmentTypeEnum | ActivityTypeEnum>(
    activities: T[],
    category: BaseActivityCategoryEnum,
    allEnums?: GetAllEnumsQuery,
  ) => {
    const taskOptions = allEnums?.enumsTaskType?.map(({ name }) => name as TaskTypeEnum) || [];
    const appointmentOptions = allEnums?.enumsAppointmentType?.map(({ name }) => name as AppointmentTypeEnum) || [];

    const options = (() => {
      if (category === 'TASK') return taskOptions;
      if (category === 'APPOINTMENT') return appointmentOptions;
      const activitiesFromCategory = activitiesGroupedByCategory[category];
      return activitiesFromCategory?.map((item) => item.activityType);
    })();
    const isSelected = (options as T[]).every((option) => activities?.includes(option));
    return isSelected;
  };

  const categories: BaseActivityCategoryEnum[] = ['APPOINTMENT', 'TASK'];
  activityWithCategoryFiltered?.forEach((activity) => {
    if (!categories.includes(activity.activityCategory)) {
      categories.push(activity.activityCategory);
    }
  });

  const getSelectedCategories = () => {
    return categories
      .map((category) => {
        switch (category) {
          case 'TASK':
            return areAllItemsSelected(leadExtendingTaskTypes ?? [], category, allEnums) ? category : undefined;
          case 'APPOINTMENT':
            return areAllItemsSelected(leadExtendingAppointmentTypes ?? [], category, allEnums) ? category : undefined;
          default:
            return areAllItemsSelected(leadExtendingActivities ?? [], category, allEnums) ? category : undefined;
        }
      })
      .filter(Boolean) as BaseActivityCategoryEnum[];
  };

  return { getSelectedCategories, areAllItemsSelected, activitiesGroupedByCategory };
}
