import { EmailTemplate } from 'api/graphql/generated/graphql';
import { useEmailTemplates } from 'api/graphql/hooks/useEmailTemplates';
import { OptionsList, VirtualElement } from 'components/general/OptionsList/OptionsList';
import { useActiveShopId } from 'components/state/ActiveShopProvider';
import { EXPOSE_TEMPLATE_TYPES } from 'util/email';

export function ExposeTemplatesList({
  selectedTemplateId,
  onTemplateSelected,
  isOpen,
  onClose,
  anchorRef,
}: {
  selectedTemplateId?: string;
  onTemplateSelected: (template: EmailTemplate) => void;
  isOpen: boolean;
  onClose: () => void;
  anchorRef: { current: VirtualElement | null };
}) {
  const { activeShopId } = useActiveShopId();

  const { emailTemplates = [], isLoading } = useEmailTemplates({
    where: {
      type: { _in: [...EXPOSE_TEMPLATE_TYPES] },
      shopId: { _eq: activeShopId! },
    },
  });

  return (
    <OptionsList
      open={isOpen}
      onClose={onClose}
      anchorRef={anchorRef}
      loading={isLoading}
      selectedOption={selectedTemplateId}
      onChange={(id) => onTemplateSelected(emailTemplates.find((t) => t.id === id)!)}
      options={emailTemplates.map((template) => ({
        value: template.id,
        label: template.name,
      }))}
    />
  );
}
