import { IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AddButton from 'components/contact/ContactDetailsForm/AddButton';
import { useCountryOptions } from 'components/contact/useCountryOptions';
import { AddressSearch } from 'components/general/AddressSearch/AddressSearch';
import { FormSection, FormSectionHeader } from 'components/general/Form/FormSection';
import { FormStack } from 'components/general/Form/FormStack';
import { SingleAutocomplete } from 'components/general/SingleAutocomplete/SingleAutocomplete';
import LocationIcon from 'components/icons/location_2.svg?react';
import MinusCircleIcon from 'components/icons/minus_circle.svg?react';
import { SearchDemandMap } from 'components/leads/SearchDemandMap';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';

const AddressSection = () => {
  const { t } = useTranslation(['lead', 'enums']);
  const { countries } = useCountryOptions();
  const {
    control,
    watch,
    formState: { errors },
    setValue,
    register,
  } = useFormContext<PropertyDetailsFormData>();
  const {
    fields: placeIdFields,
    append,
    remove,
    update,
  } = useFieldArray({
    name: 'search.placeIds',
  });
  const watchPlaceFieldIds = watch('search.placeIds');
  const watchGeojson = watch('search.geojson');
  const controlledPlaceFieldIds = placeIdFields.map((field, index) => {
    return {
      ...field,
      ...watchPlaceFieldIds?.[index],
    };
  });
  const showAddMorePlaceIds = controlledPlaceFieldIds[0]?.placeId;

  return (
    <FormSection>
      <FormSectionHeader>{t('lead:form.searchDemand.area.header')}</FormSectionHeader>
      {controlledPlaceFieldIds.map((item, index) => (
        <Stack key={item.id} gap={2}>
          <FormStack
            icon={
              <IconButton
                sx={{ m: -1 }}
                onClick={() => {
                  if (controlledPlaceFieldIds.length > 1) {
                    remove(index);
                  } else {
                    update(index, '');
                  }
                }}
              >
                {index > 0 ? <MinusCircleIcon /> : <LocationIcon />}
              </IconButton>
            }
          >
            <Controller
              name={`search.placeIds.${index}`}
              control={control}
              render={({ field }) => (
                <AddressSearch
                  sx={{ flex: 1 }}
                  showWarningOnBlur={false}
                  label={t('lead:propertyEvaluateForm.details.searchArea')}
                  suggestionOptions={{
                    types: ['geocode'],
                  }}
                  onChange={(result) => field.onChange({ placeId: result?.placeId })}
                  hasError={!!errors.search?.placeId}
                  errorMessage={errors.search?.placeId?.message}
                  textFieldProps={{
                    'data-loggingid': 'address-search',
                  }}
                  defaultAddress={field.value}
                />
              )}
            />
          </FormStack>
        </Stack>
      ))}
      {showAddMorePlaceIds && (
        <AddButton
          onClick={() => {
            append({ placeId: '' });
          }}
          disabled={controlledPlaceFieldIds.length >= 1000}
          text={t('lead:propertyEvaluateForm.details.addArea')}
        />
      )}
      <FormStack>
        <SearchDemandMap
          placeIds={controlledPlaceFieldIds.map(({ placeId }) => placeId || '')}
          geojson={watchGeojson}
          setGeojson={(featureCollection) => {
            setValue('search.geojson', featureCollection);
          }}
        />
      </FormStack>
      {!controlledPlaceFieldIds.length && (
        <>
          {watch('search.countryCode') && (
            <FormStack>
              <Controller
                name="search.countryCode"
                control={control}
                render={({ field }) => (
                  <SingleAutocomplete
                    hasError={!!errors.search?.countryCode}
                    errorMessage={errors.search?.countryCode?.message}
                    noOptionsPlaceholder={t('lead:propertyEvaluateForm.details.country.noOptionsPlaceholder')}
                    label={t('lead:propertyEvaluateForm.details.country')}
                    options={countries}
                    disabled
                    {...field}
                  />
                )}
              />
            </FormStack>
          )}
          {watch('search.area') && (
            <FormStack>
              <TextField
                error={!!errors.search?.area}
                helperText={errors.search?.area?.message}
                label={t('lead:propertyEvaluateForm.details.area')}
                disabled
                {...register('search.area')}
              />
            </FormStack>
          )}
          {watch('search.city') && (
            <FormStack>
              <TextField
                error={!!errors.search?.city}
                helperText={errors.search?.city?.message}
                label={t('lead:propertyEvaluateForm.details.city')}
                disabled
                {...register('search.city')}
              />
            </FormStack>
          )}
          {watch('search.district') && (
            <FormStack>
              <TextField
                error={!!errors.search?.district}
                helperText={errors.search?.district?.message}
                label={t('lead:propertyEvaluateForm.details.district')}
                disabled
                {...register('search.district')}
              />
            </FormStack>
          )}
        </>
      )}
    </FormSection>
  );
};

export default AddressSection;
