import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

export const shopSettingsQuery = graphql(/* GraphQL */ `
  query ShopSettings($where: DbShopSettingBoolExp) {
    dbShopSetting(where: $where) {
      ...ShopSettings
    }
  }
`);

export const useShopSettings = (
  variables: VariablesOf<typeof shopSettingsQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled'>,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['shop', 'useShopSettings', variables],
    queryFn: async () => (await graphqlClient.request(shopSettingsQuery, variables)).dbShopSetting,
    ...options,
  });

  return { shopSettings: data?.[0], ...rest };
};
