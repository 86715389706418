import { Box, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useLeadAndContact } from 'api/graphql/hooks/useLead';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { fullName, leadString } from 'util/stringUtils';
import { useCreateShortLeadSummary } from 'util/summaries/leadSummary';

export function SharedLead({ leadId }: { leadId: string }) {
  const { t } = useTranslation(['user']);

  const { lead } = useLeadAndContact({ id: leadId });
  const summary = useCreateShortLeadSummary(lead);

  if (!lead) {
    return null;
  }

  return (
    <Paper elevation={0} variant="outlined" sx={{ padding: 2 }} square>
      <Stack direction="row" gap={1}>
        <EVAvatar person={lead.contact} size="s" />
        <Box>
          <Typography variant="body4" component="div" lineHeight="24px">
            {fullName(lead.contact)}
          </Typography>
          <Typography variant="body4" component="div">
            {leadString(lead, t)}
          </Typography>
          <Typography variant="body4" color={theme.palette.text.secondary} component="div">
            {summary}
          </Typography>
        </Box>
      </Stack>
    </Paper>
  );
}
