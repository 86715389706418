import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { Agent, AgentWithTeamAssignments, LeadsResponsibleAgentsQuery } from 'api/graphql/generated/graphql';
import { graphqlClient } from 'api/graphql/graphql-client';

export const agentQuery = graphql(/* GraphQL */ `
  query AgentQuery(
    $where: DbAgentBoolExp
    $orderBy: [DbAgentOrderBy!]
    $limit: Int
    $offset: Int
    $distinctOn: [DbAgentSelectColumn!]
  ) {
    dbAgent(where: $where, orderBy: $orderBy, distinctOn: $distinctOn, limit: $limit, offset: $offset) {
      ...Agent
    }
  }
`);

export const useAgents = (
  variables: VariablesOf<typeof agentQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'onSuccess' | 'staleTime'>,
) => {
  const { data: agents, ...rest } = useQuery<Agent[]>({
    queryKey: ['agent', 'useAgents', variables],
    queryFn: async () => (await graphqlClient.request(agentQuery, variables)).dbAgent,
    ...options,
  });
  return { agents, ...rest };
};

export function useAgentsInShopGroup(
  variables: VariablesOf<typeof agentQuery> & {
    shopIds: string[];
  },
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) {
  const { shopIds, where, ...queryVariables } = variables;

  const { data: agentsInShopGroup, ...rest } = useQuery({
    queryKey: ['agent', 'useAgentsInShopGroup', variables],
    queryFn: async () =>
      (
        await graphqlClient.request(agentQuery, {
          ...queryVariables,
          where: {
            _and: [
              where || {},
              {
                _or: [
                  { userShopAssignments: { shopId: { _in: shopIds } } },
                  { userTeamAssignments: { team: { shopId: { _in: shopIds } } } },
                ],
              },
            ],
          },
        })
      ).dbAgent,
    ...options,
  });

  return { agentsInShopGroup, ...rest };
}

export const agentWithTeamAssignmentsQuery = graphql(/* GraphQL */ `
  query AgentWithTeamAssignments($where: DbAgentBoolExp!) {
    dbAgent(where: $where) {
      ...AgentWithTeamAssignments
    }
  }
`);

export const useAgentsWithTeamAssignments = (
  variables: VariablesOf<typeof agentWithTeamAssignmentsQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'onSuccess' | 'staleTime'>,
) => {
  const { data: agents, ...rest } = useQuery<AgentWithTeamAssignments[]>({
    queryKey: ['agent', 'useAgentsWithTeamAssignments', variables],
    queryFn: async () => (await graphqlClient.request(agentWithTeamAssignmentsQuery, variables)).dbAgent,
    ...options,
  });

  return { agents, ...rest };
};

export const leadsResponsibleAgentsQuery = graphql(/* GraphQL */ `
  query LeadsResponsibleAgents($contactId: String) {
    dbContact(where: { id: { _eq: $contactId } }) {
      agentsAssignedToLeadsOfContact {
        intentType
        agent {
          ...AgentInfo
        }
      }
    }
  }
`);

export const useLeadsResponsibleAgents = (
  variables: VariablesOf<typeof leadsResponsibleAgentsQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: contactWithLeads, ...rest } = useQuery({
    queryKey: ['contact', 'useLeadsResponsibleAgents', variables],
    queryFn: async () => (await graphqlClient.request(leadsResponsibleAgentsQuery, variables)).dbContact,
    ...options,
  });

  const responsibleAgents: LeadsResponsibleAgentsQuery['dbContact'][0]['agentsAssignedToLeadsOfContact'] =
    contactWithLeads?.[0]?.agentsAssignedToLeadsOfContact || [];

  return { responsibleAgents, ...rest };
};
