import { ExposeTemplateType, PreSelectableTemplateType } from '@ev/eva-container-api';
import { graphqlClient } from 'api/graphql';
import { agentQuery } from 'api/graphql/hooks/useAgents';
import { contactsAndLeadsQuery, contactsQuery } from 'api/graphql/hooks/useContact';
import { dashboardActivitiesQuery } from 'api/graphql/hooks/useDashboardActivities';
import { emailTemplatesQuery } from 'api/graphql/hooks/useEmailTemplates';
import { leadInfoListQuery } from 'api/graphql/hooks/useLeadList';
import { listingQuery } from 'api/graphql/hooks/useListing';
import { consentManagementShopSettingsQuery } from 'api/graphql/hooks/useShopSettingsCards';
import { httpService } from 'api/rest';
import { ResolveEmailTemplateRequest } from 'api/rest/hooks/useEmail';
import { ResolveBulkEmailTemplateResponse, ResolveEmailTemplateResponse } from 'api/rest/models/EmailDataTypes';
import { ResolveBulkEmailTemplateRequest } from 'api/rest/models/rest/Command';
import { ListingWithLegacyFields } from 'util/go3';

export async function loadContacts(contactIds?: string[]) {
  if (!contactIds?.length) {
    return undefined;
  }
  const data = await graphqlClient.request(contactsQuery, { where: { id: { _in: contactIds } } });
  return data?.dbContact;
}

export async function loadContactsAndLeads(contactIds?: string[]) {
  if (!contactIds?.length) {
    return undefined;
  }
  const data = await graphqlClient.request(contactsAndLeadsQuery, { where: { id: { _in: contactIds } } });
  return data?.dbContact;
}

export async function loadLeads(leadIds?: string[]) {
  if (!leadIds?.length) {
    return undefined;
  }
  const data = await graphqlClient.request(leadInfoListQuery, { where: { id: { _in: leadIds } } });
  return data?.dbLead;
}

export async function loadAgents(agentsIds?: string[]) {
  if (!agentsIds?.length) {
    return undefined;
  }
  const data = await graphqlClient.request(agentQuery, { where: { id: { _in: agentsIds } } });
  return data?.dbAgent;
}

export async function loadAppointment(appointmentId?: string) {
  if (!appointmentId) {
    return undefined;
  }

  const data = await graphqlClient.request(dashboardActivitiesQuery, {
    where: { appointmentActivity: { appointment: { id: { _eq: appointmentId } } } },
  });
  return data.dbBaseActivity[0];
}

export async function loadTask(taskId?: string) {
  if (!taskId) {
    return undefined;
  }

  const data = await graphqlClient.request(dashboardActivitiesQuery, {
    where: { taskActivity: { task: { id: { _eq: taskId } } } },
  });
  return data.dbBaseActivity[0];
}

export async function loadResolvedSignature(activeShopId: string, emailSignatureId?: string) {
  return (
    await httpService.executeLeadEngineCommand({
      requestBody: { shopId: activeShopId, emailSignatureId },
      className: 'ResolveEmailSignatureCommand',
    })
  ).data?.data;
}

export async function loadTemplate(
  activeShopId: string,
  templateType: PreSelectableTemplateType | ExposeTemplateType | undefined,
) {
  if (!templateType) {
    return undefined;
  }
  return (
    await graphqlClient.request(emailTemplatesQuery, {
      where: { type: { _eq: templateType }, shopId: { _eq: activeShopId } },
    })
  ).dbEmailTemplate?.[0];
}

export async function loadConsentManagementShopSettings(activeShopId: string) {
  return (await graphqlClient.request(consentManagementShopSettingsQuery, { shopId: activeShopId }))
    .dbShopManagedConsent;
}

export async function loadResolvedTemplate(requestBody: ResolveEmailTemplateRequest) {
  return (await httpService.executeLeadEngineCommand({ requestBody, className: 'ResolveEmailTemplateCommand' })).data
    .data as ResolveEmailTemplateResponse;
}

export async function loadResolvedBulkTemplates(requestBody: ResolveBulkEmailTemplateRequest) {
  return (await httpService.executeLeadEngineCommand({ requestBody, className: 'ResolveBulkTemplateCommand' })).data
    .data as ResolveBulkEmailTemplateResponse[];
}

export function loadListingsFromPe(utags: string[] | undefined) {
  if (!utags?.length) {
    return [];
  }

  return Promise.all(
    utags.map(
      async (utag) =>
        (await graphqlClient.request(listingQuery, { utag })).propertyEngine
          ?.getListingByUtag as ListingWithLegacyFields,
    ),
  );
}
