import { ContactAndLeads, LeadInfo } from 'api/graphql/generated/graphql';
import { getContactLastActivity, getPreferredEmail, getPreferredPhoneNumber } from 'util/contactUtils';
import { isSet } from 'util/filters';

export type LeadAndContactSearchOption = ContactSearchOption | LeadSearchOption;

export type ContactSearchOption = {
  type: 'CONTACT';
  email: string;
  phoneNumber?: string;
  isServiceContact?: boolean;
  lastActivity?: Date | null;
  disabled?: boolean;
} & Omit<ContactAndLeads, 'type'>;

export interface LeadSearchOption extends LeadInfo {
  type: 'LEAD';
  contact: Omit<ContactSearchOption, 'type'>;
}

export function getContactId(recipient: LeadAndContactSearchOption) {
  return recipient.type === 'CONTACT' ? recipient.id : recipient.contactId;
}

export function getContact(recipient: LeadAndContactSearchOption) {
  return recipient.type === 'CONTACT' ? recipient : recipient.contact;
}

function mapContact(contact: ContactAndLeads): Omit<ContactSearchOption, 'type'> {
  const { type, ...contactData } = contact;
  return {
    ...contactData,
    email: getPreferredEmail(contact) || '',
    phoneNumber: getPreferredPhoneNumber(contact),
    isServiceContact: !!contact.type && contact.type !== 'UNDEFINED',
    lastActivity: getContactLastActivity(contact),
  };
}

export function mapContactToLeadAndContactOptions(contacts: ContactAndLeads[]): LeadAndContactSearchOption[] {
  return contacts.flatMap((contact) => [
    {
      type: 'CONTACT' as const,
      ...mapContact(contact),
    },
    ...(contact?.leads.map((lead) => ({
      type: 'LEAD' as const,
      ...lead,
      contact: mapContact(contact),
    })) ?? []),
  ]);
}

export function mapContactsToContactOptions(contacts: ContactAndLeads[]): ContactSearchOption[] {
  return contacts.map((contact) => ({ type: 'CONTACT' as const, ...mapContact(contact) }));
}

export function mapLeadInfosToLeadAndContactOptions(
  leads: LeadInfo[],
  contacts: ContactAndLeads[],
): LeadAndContactSearchOption[] {
  const leadOptions = leads
    .map((lead) => {
      const contact = contacts.find((c) => c.id === lead.contactId);
      if (contact) {
        return { type: 'LEAD' as const, ...lead, contact: mapContact(contact) };
      }
    })
    .filter(isSet);

  const contactsWithoutLeads = contacts.filter((c) => !leadOptions.find((leadOption) => leadOption.contactId === c.id));
  const contactOptions = mapContactsToContactOptions(contactsWithoutLeads);
  return [...contactOptions, ...leadOptions];
}
