import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { useGetAllEnums } from 'api/graphql/hooks/useGetAllEnums';
import { DropDown } from 'components/general/DropDown/DropDown';
import { FormSection, FormSectionHeader } from 'components/general/Form/FormSection';
import { FormStack } from 'components/general/Form/FormStack';
import { Option } from 'components/general/OptionsList/OptionsList';
import UserIcon from 'components/icons/user_single.svg?react';
import { DisplayPersonDropdown } from 'components/leads/forms/LeadForm/DisplayPersonDropdown/DisplayPersonDropdown';
import {
  propertyOccupationStatusTranslationKeys,
  propertyOwnershipStatusTranslationKeys,
} from 'const/enumTranslations';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { includeEmptyOption } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';
import { enumToLokalisedOptions } from 'util/mappers/enumToOption';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';

export const Ownership = () => {
  const { t } = useTranslation(['lead', 'enums']);
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<PropertyDetailsFormData>();
  const { allEnums } = useGetAllEnums();

  const propertyOwnershipStatusSelectOptions: Option[] = useMemo(() => {
    return includeEmptyOption(
      allEnums?.enumsPropertyOwnershipStatus?.map(enumToLokalisedOptions(t, propertyOwnershipStatusTranslationKeys)) ??
        [],
    );
  }, [allEnums?.enumsPropertyOwnershipStatus, t]);

  const propertyOccupationStatusSelectOptions: Option[] = useMemo(() => {
    return includeEmptyOption(
      allEnums?.enumsPropertyOccupationStatus?.map(
        enumToLokalisedOptions(t, propertyOccupationStatusTranslationKeys),
      ) ?? [],
    );
  }, [allEnums?.enumsPropertyOccupationStatus, t]);

  const watchOwnershipStatus = watch('property.ownershipStatus');

  return (
    <FormSection>
      <FormSectionHeader>{t('lead:propertyDetails.propertyOwner')}</FormSectionHeader>
      <FormStack icon={<UserIcon />}>
        <Controller
          name="property.ownershipStatus"
          control={control}
          render={({ field }) => (
            <DropDown
              {...field}
              label={t('lead:propertyDetails.ownershipStatus')}
              options={propertyOwnershipStatusSelectOptions}
            />
          )}
        />
      </FormStack>
      {watchOwnershipStatus === 'LH_CONTACT' && (
        <FormStack>
          <FormControl error={!!errors.property?.owner}>
            <FormLabel id="owner-label" sx={{ fontSize: '12px' }}>
              {t('lead:propertyDetails.owner')}:
            </FormLabel>
            <Box>
              <Controller
                name="property.owner"
                control={control}
                render={({ field }) => {
                  return (
                    <DisplayPersonDropdown selectedPerson={field.value} onChange={(person) => field.onChange(person)} />
                  );
                }}
              />
            </Box>
            <FormHelperText>{errors.property?.owner?.message}</FormHelperText>
          </FormControl>
        </FormStack>
      )}
      {watchOwnershipStatus === 'OTHER' && (
        <FormStack>
          <FormControl error={!!errors.property?.ownershipDescription}>
            <Controller
              name="property.ownershipDescription"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    label={t('lead:propertyDetails.ownershipDescription')}
                    {...field}
                    error={'property' in errors && !!errors.property?.ownershipDescription}
                  />
                );
              }}
            />
            <FormHelperText>{errors.property?.ownershipDescription?.message}</FormHelperText>
          </FormControl>
        </FormStack>
      )}
      <FormStack>
        <Controller
          name="property.occupationStatus"
          control={control}
          render={({ field }) => (
            <DropDown
              {...field}
              label={t('lead:propertyDetails.occupationStatus')}
              options={propertyOccupationStatusSelectOptions}
            />
          )}
        />
      </FormStack>
    </FormSection>
  );
};
