import { AppointmentTypeEnum, DashboardActivity, TaskAppointmentStatusEnum } from 'api/graphql/generated/graphql';
import { useMemo } from 'react';
import { ListingWithLegacyFields } from 'util/go3';
import { useTranslation } from 'util/i18next';
import { useLeadAndContactSearchOptionSchema } from 'util/schemas/contactAndLeadSchema';
import { useSchemaPrimitives } from 'util/schemas/schemaPrimitives';
import { z } from 'zod';

export function useCreateNewAppointmentSchema() {
  const { t } = useTranslation(['user']);
  const { optionalString, requiredString, optionalNumber } = useSchemaPrimitives();
  const contactAndLead = useLeadAndContactSearchOptionSchema();

  return useMemo(() => {
    return z
      .object({
        agentId: requiredString,
        teamId: requiredString,
        shopId: requiredString,
        actionType: z.custom<AppointmentTypeEnum>((val) => typeof val === 'string' && !!val.trim()),
        status: z
          .enum(['OPEN', 'COMPLETED', 'CANCELLED'] as const satisfies readonly TaskAppointmentStatusEnum[])
          .optional(),
        subject: requiredString,
        date: z.date({ invalid_type_error: t('formValidation.invalidDate') }),
        startTime: z.date({ invalid_type_error: t('formValidation.invalidDate') }),
        endTime: z.date({ invalid_type_error: t('formValidation.invalidDate') }),
        comment: optionalString,
        feedback: optionalString,
        contactsAndLeads: contactAndLead.array().min(1, t('user:formValidation.activity.atLeastOneContact')),
        properties: z.custom<ListingWithLegacyFields>().array().optional(),
        go3AppointmentKey: optionalNumber,
        additionalAgents: z
          .object({
            type: z.literal('AGENT'),
            id: requiredString,
            firstName: optionalString,
            lastName: optionalString,
            email: optionalString,
          })
          .array()
          .optional(),
        _editAppointmentActivity: z.custom<DashboardActivity>().optional(),
      })
      .refine((values) => values.startTime < values.endTime, {
        message: t('formValidation.startBeforeEnd'),
        path: ['startTime'],
      })
      .refine(
        (values) => {
          return values.actionType === 'MISCELLANEOUS' || values.properties?.length;
        },
        {
          message: t('formValidation.propertyRequired'),
          path: ['properties'],
        },
      )
      .refine((values) => !values.additionalAgents?.find(({ id }) => id === values.agentId), {
        message: t('formValidation.duplicateAgentAssignment'),
        path: ['additionalAgents'],
      });
  }, [optionalNumber, optionalString, requiredString, contactAndLead, t]);
}

export type CreateNewAppointmentFormData = z.infer<ReturnType<typeof useCreateNewAppointmentSchema>>;
