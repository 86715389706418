import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { DEFAULT_SHOP_IMAGE_SRC, EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { EmailLink } from 'components/general/Link/EmailLink';
import { PhoneLink } from 'components/general/Link/PhoneLink';
import GlobeIcon from 'components/icons/globe.svg?react';
import LocationIcon from 'components/icons/location_2.svg?react';
import MailIcon from 'components/icons/mail.svg?react';
import PhoneIcon from 'components/icons/phone_naked.svg?react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { MarkerShopData } from 'util/places/mapMarkers';
import { addressToSingleLineString } from 'util/summaries/addressSummary';

export function ShopInfo({ shop }: { shop: MarkerShopData }) {
  const { t } = useTranslation(['enums']);

  return (
    <Stack gap={1}>
      <Stack gap={2} direction="row" alignItems="center">
        <EVAvatar size="l" floating pictureSrc={DEFAULT_SHOP_IMAGE_SRC} />
        <Stack gap={1}>
          <Typography variant="body3" color={theme.palette.text.primary} fontWeight={theme.typography.fontWeightBold}>
            {shop.shopName}
          </Typography>
          {shop.licensePartner && (
            <Typography variant="body4" color={theme.palette.text.secondary}>
              {shop.licensePartner}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Divider />
      <Box sx={{ display: 'grid', gridTemplateColumns: 'min-content minmax(0, 1fr)', gap: 1, alignItems: 'center' }}>
        {shop.websiteUrl && (
          <>
            <GlobeIcon />
            <Link
              href={shop.websiteUrl.startsWith('http') ? shop.websiteUrl : `http://${shop.websiteUrl}`}
              target="_blank"
              sx={{ wordBreak: 'break-all', textDecoration: 'none' }}
            >
              <Typography variant="body4">{shop.websiteUrl}</Typography>
            </Link>
          </>
        )}
        {shop.phone && (
          <>
            <PhoneIcon />
            <PhoneLink number={shop.phone} />
          </>
        )}
        {shop.email && (
          <>
            <MailIcon />
            <EmailLink email={shop.email} to={[{ email: shop.email }]} />
          </>
        )}
        {shop.address && (
          <>
            <LocationIcon />
            <Typography variant="body4" color={theme.palette.text.primary}>
              {addressToSingleLineString(t)(shop.address)}
            </Typography>
          </>
        )}
      </Box>
    </Stack>
  );
}
