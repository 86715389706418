import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { EVAlert } from 'components/general/EVAlert/EVAlert';
import { theme } from 'theme';

export function DialogAlertMessage({
  warningMessageHead,
  warningMessage,
  isError,
  sx,
  sxBody,
}: {
  warningMessageHead: string;
  warningMessage: string;
  isError?: boolean;
  sx?: SxProps;
  sxBody?: SxProps;
}) {
  return (
    <EVAlert severity={isError ? 'error' : 'warning'} sx={sx}>
      <Box sx={sxBody}>
        <Typography
          variant="body3"
          sx={{
            color: isError ? theme.palette.text.secondary : theme.palette.text.primary,
          }}
        >
          {warningMessageHead}
        </Typography>
        <Typography
          variant="body3"
          sx={{
            color: isError ? theme.palette.text.primary : theme.palette.text.secondary,
          }}
        >
          {warningMessage}
        </Typography>
      </Box>
    </EVAlert>
  );
}
