import { useJwtToken } from '@ev/eva-container-api';
import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';
import { parseRolesFromJwtToken } from 'util/keycloak/roles';

const callListQuery = graphql(/* GraphQL */ `
  query callListQuery($limit: Int, $offset: Int, $order_by: [DbCallOrderBy!], $where: DbCallBoolExp) {
    dbCall(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {
      ...Call
    }
  }
`);

export const useCallsList = (
  variables: VariablesOf<typeof callListQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: calls, ...rest } = useQuery({
    queryKey: ['call', 'useCallList', variables],
    queryFn: async () => (await graphqlClient.request(callListQuery, variables)).dbCall,
    refetchInterval: 30_000,
    ...options,
  });

  return { calls: calls, ...rest };
};

export const callQueueQuery = graphql(/* GraphQL */ `
  query CallQueue($shops: [String!]) {
    dbCallAggregate(where: { status: { _eq: QUEUED }, shopId: { _in: $shops } }) {
      aggregate {
        count
      }
    }
  }
`);

export const useCallQueueSize = (options?: Pick<UseQueryOptions, 'enabled' | 'onError'>) => {
  const { jwtToken } = useJwtToken();
  const { shopRoles } = parseRolesFromJwtToken(jwtToken);

  const shopsWithCallCenterAgentRole = Object.entries(shopRoles)
    .filter(([, shopRole]) => shopRole.includes('CALL_CENTER_AGENT'))
    .map(([shopId]) => shopId);

  const { data: queueSize, ...rest } = useQuery({
    queryKey: ['call', 'useCallQueueSize', shopsWithCallCenterAgentRole],
    queryFn: async () =>
      (await graphqlClient.request(callQueueQuery, { shops: shopsWithCallCenterAgentRole })).dbCallAggregate.aggregate
        ?.count,
    refetchInterval: 10_000,
    ...options,
  });
  return { queueSize, ...rest };
};
