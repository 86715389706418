import { Box, MenuItem, Popover, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { EmailTemplate } from 'api/graphql/generated/graphql';
import { useCurrentAgentId } from 'api/graphql/hooks/useCurrentAgent';
import { useEmailTemplates } from 'api/graphql/hooks/useEmailTemplates';
import { SearchTextField } from 'components/general/SearchTextField/SearchTextField';
import SearchResultIcon from 'components/icons/file_text.svg?react';
import LinkIcon from 'components/icons/link_external.svg?react';
import ShopIcon from 'components/icons/shop_naked.svg?react';
import RecentlyUsedIcon from 'components/icons/time_rewind.svg?react';
import TeamIcon from 'components/icons/user_multiple.svg?react';
import PersonalIcon from 'components/icons/user_single.svg?react';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { useDestination } from 'util/navigation/useDestination';
import { createTemplateQuery } from 'util/queries/createTemplateQuery';

const TAB_TITLE = {
  searchResult: 'communication:templates.list.tab.searchResult',
  recentlyUsed: 'communication:templates.list.tab.recentlyUsed',
  personal: 'communication:templates.list.tab.personal',
  team: 'communication:templates.list.tab.team',
  shop: 'communication:templates.list.tab.shop',
} as const satisfies Record<string, string>;

type TabTitle = keyof typeof TAB_TITLE;

const TemplatesContainer = styled('div')({
  overflowY: 'auto',
  maxHeight: '288px',
});

export const ManualTemplatesList = ({
  onTemplateSelected,
  isOpen,
  onClose,
  anchorRef,
}: {
  onTemplateSelected: (template: EmailTemplate) => void;
  isOpen: boolean;
  onClose: () => void;
  anchorRef: { current: Element | null };
}) => {
  const { t } = useTranslation(['user', 'communication']);

  const currentAgentId = useCurrentAgentId();
  const { activeShop, teamsWhereUserIsMember } = useActiveShop();
  const activeShopId = activeShop?.id;
  const teamsIdsWhereUserIsMember = teamsWhereUserIsMember.map((team) => team.id);
  const { emailTemplates = [] } = useEmailTemplates({
    where: {
      name: { _neq: 'Neuer Seller Lead' },
      type: { _eq: 'MANUAL' },
      ...createTemplateQuery(currentAgentId ?? '', teamsIdsWhereUserIsMember, activeShopId ?? ''),
    },
  });

  const [selectedTab, setSelectedTab] = useState<TabTitle>('recentlyUsed');
  const [searchText, setSearchText] = useState<string>('');

  const recentlyUsedTemplatesIds: string[] = JSON.parse(localStorage.getItem('recentlyUsedTemplates') ?? '[]');

  const itemOnClick = (item: EmailTemplate) => {
    localStorage.setItem(
      'recentlyUsedTemplates',
      JSON.stringify([item.id, ...recentlyUsedTemplatesIds.filter((id) => id != item.id).slice(0, 2)]),
    );
    onTemplateSelected(item);
  };

  const filterTemplates = (templates: EmailTemplate[], searchText: string, selectedTab: TabTitle) => {
    const filteredTemplates = templates?.filter((template) =>
      template.name.toLowerCase().includes(searchText.toLowerCase()),
    );

    const recentlyVisitedTemplates = () =>
      filteredTemplates
        .filter((template) => recentlyUsedTemplatesIds.includes(template.id))
        .sort((a, b) => b.scope.localeCompare(a.scope));

    switch (selectedTab) {
      case 'searchResult': {
        const otherTemplates = filteredTemplates.filter((template) => !recentlyUsedTemplatesIds.includes(template.id));
        return {
          recentlyUsed: recentlyVisitedTemplates(),
          otherTemplates,
        };
      }
      case 'recentlyUsed':
        return {
          recentlyUsed: recentlyVisitedTemplates(),
        };

      case 'personal':
      case 'team':
      case 'shop':
        return {
          otherTemplates: filteredTemplates.filter((template) => template.scope === selectedTab.toUpperCase()),
        };
    }
  };

  const shownTemplates = filterTemplates(emailTemplates, searchText, selectedTab) ?? [];
  const shownTitle =
    selectedTab === 'searchResult' && shownTemplates.recentlyUsed
      ? t('communication:templates.list.tab.recentlyUsed')
      : t(TAB_TITLE[selectedTab]);

  const { toEmailTemplates } = useDestination();

  return (
    <Popover
      id="templates-menu"
      anchorEl={() => anchorRef.current!}
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: { width: '100%', maxWidth: 430 },
      }}
      sx={{ top: '5px' }}
    >
      <SearchTextField
        onClick={(event) => event.stopPropagation()}
        fullWidth={true}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (event.target.value === '') {
            setSelectedTab('recentlyUsed');
          } else {
            setSelectedTab('searchResult');
          }
          setSearchText(event.target.value);
        }}
        size="small"
        value={searchText}
        placeholder={t('communication:newEmail.templates.search')}
        sx={{ padding: 2 }}
      />

      <Box
        sx={{
          backgroundColor: theme.palette.shade.grey5,
          borderTop: 1,
          borderTopColor: theme.palette.shade.grey3,
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={(_: unknown, value: TabTitle) => {
            setSelectedTab(value);
          }}
          sx={{
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            borderBottom: 1,
            borderBottomColor: theme.palette.shade.grey3,
          }}
        >
          {selectedTab === 'searchResult' ? (
            <Tab
              value="searchResult"
              icon={<SearchResultIcon />}
              sx={{
                fontSize: theme.typography.body3.fontSize,
                textTransform: 'none',
              }}
              aria-label={t(TAB_TITLE['recentlyUsed'])}
            />
          ) : (
            <Tab
              value="recentlyUsed"
              icon={<RecentlyUsedIcon />}
              sx={{
                fontSize: theme.typography.body3.fontSize,
                textTransform: 'none',
              }}
              aria-label={t(TAB_TITLE['recentlyUsed'])}
            />
          )}
          <Tab
            value="personal"
            icon={<PersonalIcon />}
            sx={{
              fontSize: theme.typography.body3.fontSize,
              textTransform: 'none',
            }}
            aria-label={t(TAB_TITLE['personal'])}
          />
          <Tab
            value="team"
            icon={<TeamIcon />}
            sx={{
              fontSize: theme.typography.body3.fontSize,
              textTransform: 'none',
            }}
            aria-label={t(TAB_TITLE['team'])}
          />
          <Tab
            value="shop"
            icon={<ShopIcon />}
            sx={{
              fontSize: theme.typography.body3.fontSize,
              textTransform: 'none',
            }}
            aria-label={t(TAB_TITLE['shop'])}
          />
        </Tabs>
      </Box>

      <TemplatesContainer>
        <Typography
          variant="body3"
          sx={{
            color: theme.palette.text.secondary,
            paddingLeft: theme.spacing(2),
          }}
        >
          {shownTitle}
        </Typography>

        {shownTemplates.recentlyUsed?.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => {
              itemOnClick(item);
            }}
          >
            {item.scope === 'PERSONAL' && <PersonalIcon style={{ marginRight: theme.spacing(1) }} />}
            {item.scope === 'TEAM' && <TeamIcon style={{ marginRight: theme.spacing(1) }} />}
            {item.scope === 'SHOP' && <ShopIcon style={{ marginRight: theme.spacing(1) }} />}
            {item.name}
          </MenuItem>
        ))}

        {selectedTab === 'searchResult' && shownTemplates.otherTemplates && (
          <Typography
            variant="body3"
            display="block"
            sx={{
              color: theme.palette.text.secondary,
              paddingLeft: theme.spacing(2),
            }}
          >
            {t('communication:newEmail.templates.all')}
          </Typography>
        )}
        {shownTemplates.otherTemplates?.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => {
              itemOnClick(item);
            }}
          >
            {item.scope === 'TEAM' && <TeamIcon style={{ marginRight: theme.spacing(1) }} />}
            {item.scope === 'SHOP' && <ShopIcon style={{ marginRight: theme.spacing(1) }} />}
            {item.scope === 'PERSONAL' && <PersonalIcon style={{ marginRight: theme.spacing(1) }} />}
            {item.name}
          </MenuItem>
        ))}
      </TemplatesContainer>

      <Link to={toEmailTemplates()} target="_blank" style={{ color: theme.palette.text.primary }}>
        <MenuItem sx={{ backgroundColor: theme.palette.shade.grey5 }}>
          <LinkIcon style={{ marginRight: theme.spacing(1) }} />
          {t('communication:newEmail.manage.templates.search')}
        </MenuItem>
      </Link>
    </Popover>
  );
};
