import { ParsedJwtToken } from '@ev/eva-container-api';
import { Shop, Team, UserRoleEnum } from 'api/graphql/generated/graphql';
import sentry from 'util/sentry';

export type ShopRoleEnum =
  | 'SHOP_MANAGER'
  | 'LEAD_MANAGER'
  | 'CALL_CENTER_AGENT'
  | 'COMPLIANCE_MANAGER'
  | 'CONTENT_MANAGER';
export type TeamRoleEnum =
  | 'TEAM_LEAD'
  | 'TEAM_LEAD_LIGHT'
  | 'TEAM_ASSISTANT'
  | 'TEAM_ASSISTANT_LIGHT'
  | 'AGENT'
  | 'AGENT_LIGHT';
export type AdminRoleEnum = 'ADMIN' | 'READONLY_ADMIN';

export const SHOP_ROLES: ShopRoleEnum[] & UserRoleEnum[] = [
  'SHOP_MANAGER',
  'LEAD_MANAGER',
  'CALL_CENTER_AGENT',
  'COMPLIANCE_MANAGER',
  'CONTENT_MANAGER',
];
export const TEAM_ROLES: TeamRoleEnum[] & UserRoleEnum[] = [
  'TEAM_LEAD',
  'TEAM_LEAD_LIGHT',
  'TEAM_ASSISTANT',
  'TEAM_ASSISTANT_LIGHT',
  'AGENT',
  'AGENT_LIGHT',
];
export const GLOBAL_ROLES: AdminRoleEnum[] & UserRoleEnum[] = ['ADMIN', 'READONLY_ADMIN'];

export function parseGlobalRolesFromJwtToken(token: ParsedJwtToken | undefined) {
  return token?.['lead-engine']?.global_roles || [];
}

export function getTeamsWhereUserIsMember(shop: Shop, token: ParsedJwtToken | undefined): Team[] {
  const roles = parseRolesFromJwtToken(token);
  const isAdmin = parseGlobalRolesFromJwtToken(token).includes('ADMIN');
  if (
    isAdmin ||
    roles.shopRoles[shop.id]?.includes('SHOP_MANAGER') ||
    roles.shopRoles[shop.id]?.includes('LEAD_MANAGER')
  ) {
    return shop.teams;
  }
  return shop.teams.filter((t) => t.id in roles.teamRoles);
}

export type ShopRoles = Record<string, UserRoleEnum[]>;
export type TeamRoles = Record<string, { shopId: string; role: UserRoleEnum }>;

export const getShopRoles = (token: ParsedJwtToken | undefined) =>
  (token?.['lead-engine']?.shop_roles || []).reduce((shopRoles, shopRole) => {
    const [shopId, role] = shopRole.split('/');
    if (!shopId || !role) {
      sentry.logError('ERROR: Unknown shop_roles format in jwtToken: ' + shopRole);
      return shopRoles;
    }

    if (!shopRoles[shopId]) {
      shopRoles[shopId] = [];
    }

    shopRoles[shopId]!.push(role as UserRoleEnum);

    return shopRoles;
  }, {} as ShopRoles);

export const getTeamRoles = (token: ParsedJwtToken | undefined): TeamRoles =>
  (token?.['lead-engine']?.team_roles || []).reduce((teamRoles, teamRole) => {
    const [shopId, teamId, role] = teamRole.split('/');
    if (!shopId || !teamId || !role) {
      sentry.logError('ERROR: Unknown team_roles format in jwtToken: ' + teamRole);
      return teamRoles;
    }
    teamRoles[teamId] = { shopId, role: role as UserRoleEnum };

    return teamRoles;
  }, {} as TeamRoles);

export function parseRolesFromJwtToken(token: ParsedJwtToken | undefined): {
  shopIds: string[];
  shopRoles: ShopRoles;
  teamIds: string[];
  teamRoles: TeamRoles;
} {
  if (!token) {
    return { shopIds: [], shopRoles: {}, teamIds: [], teamRoles: {} };
  }

  const teamRoles = getTeamRoles(token);
  const shopRoles = getShopRoles(token);

  const shopIds = Array.from(
    new Set([
      ...Object.keys(shopRoles),
      ...(teamRoles
        ? Object.values(teamRoles).reduce((result, { shopId }) => {
            result.push(shopId);
            return result;
          }, [] as Array<string>)
        : []),
    ]),
  );

  return {
    shopIds,
    shopRoles,
    teamIds: Object.keys(teamRoles),
    teamRoles,
  };
}
