import { AxiosError, AxiosResponse } from 'axios';
import { AddressWithTypeFormData } from 'util/schemas/addressSchema';

export const EXPECTED_ERROR_STATUS_CODE = 422;

export function isExpectedError(error: unknown): error is AxiosError & { response: AxiosResponse } {
  return error instanceof AxiosError && !!error.response && error.response?.status === EXPECTED_ERROR_STATUS_CODE;
}

/** Log when the stored address doesn't capture all the details from the user entered address */
export function logSentryEntryIfAddressIsTruncated(
  inputFieldName: string,
  addresses: Array<Pick<AddressWithTypeFormData, 'address'>>,
) {
  const addressFieldValues: Array<string> = [];

  document.querySelectorAll(`[data-loggingid="${inputFieldName}"]`).forEach((addressInput) => {
    addressFieldValues.push(
      addressInput.firstChild ? (addressInput.firstChild as HTMLInputElement).value : 'Input field inaccessible',
    );
  });
  (addresses || []).forEach((addressObj, index) => {
    if (!addressObj.address) {
      return;
    }
    const { city, countryCode, postalCode, streetNumber, streetName } = addressObj.address;
    if (city && streetName) {
      return;
    }
    const storedAddressLength: number = [city, countryCode, postalCode, streetNumber, streetName].filter(
      Boolean,
    ).length;
    const userInputAddressLength = (addressFieldValues[index] || '').split(',').length;
    if (userInputAddressLength > storedAddressLength) {
      // TODO: LE-3662
      // sentry.logError('TruncatedAddressDetailsAlert', {
      //   extra: {
      //     enteredAddress: addressFieldValues[index],
      //     storedAddress: addressObj.address,
      //     field: inputFieldName,
      //   },
      // });
    }
  });
}

type ErrorResponse = AxiosError<{
  message?: string;
  errorCode?: string;
  errorDataMap: { [key: string]: object };
}>;
type FetchErrorResponse = {
  body?: {
    message?: string;
    errorCode?: string;
    errorDataMap: { [key: string]: object };
  };
};

export type ConditionalSetting = 'LEAD_QUALIFICATION' | 'CONSENT_MANAGEMENT';

export function isMissingSettingError(error: unknown) {
  const errorResponse =
    (error as { response?: ErrorResponse })?.response?.response?.data ||
    (error as { response?: FetchErrorResponse })?.response?.body;
  return (
    errorResponse?.errorCode ===
    'PRE_CONDITIONAL_SETTINGS_ARE_MISSING_WITH_SHOP_ID_AND_WITH_MISSING_PRECONDITION_PER_CONDITION'
  );
}
export function extractMissingSettings(error: unknown, settingKey: ConditionalSetting): string[] | undefined {
  const missingSettingsPerFeature =
    (error as { response?: ErrorResponse })?.response?.response?.data?.errorDataMap?.missingPreConditionPerSetting ||
    (error as { response?: FetchErrorResponse })?.response?.body?.errorDataMap?.missingPreConditionPerSetting;
  return (missingSettingsPerFeature as { [key: string]: string[] })[settingKey];
}
