import { Box, Collapse, SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { theme } from 'theme';

interface DrawerCollapsibleProps {
  children: ReactNode;
  title: string;
  collapsedValues?: string;
  icon?: ReactNode;
  sx?: SxProps;
  isInitiallyOpen?: boolean;
  'data-testid'?: string;
}

export const EVDrawerCollapsible = ({
  children,
  icon,
  title,
  collapsedValues,
  sx,
  isInitiallyOpen,
  ...otherProps
}: DrawerCollapsibleProps) => {
  const [isNotCollapsed, setIsNotCollapsed] = useState(isInitiallyOpen);
  const collapsibleRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (isNotCollapsed && collapsibleRef?.current) {
      collapsibleRef?.current?.scrollIntoView(true);
    }
  }, [isNotCollapsed, collapsibleRef]);

  return (
    <Stack
      sx={{
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.text.secondary,
        },
        py: '10px',
      }}
      data-testid={otherProps['data-testid']}
      onClick={() => setIsNotCollapsed(!isNotCollapsed)}
    >
      <Stack direction={'row'} gap={2} alignItems={'center'} ml={icon ? 0 : 5} sx={sx} tabIndex={0}>
        {icon}
        <Typography variant="h3" ref={collapsibleRef}>
          {title}
        </Typography>
      </Stack>
      <Box>
        <Collapse in={!isNotCollapsed}>
          <Box
            ml={icon ? 5 : 0}
            color={theme.palette.text.secondary}
            sx={{ fontSize: theme.typography.body3.fontSize }}
          >
            {collapsedValues}
          </Box>
        </Collapse>
        <Collapse in={isNotCollapsed}>
          <Stack sx={{ mt: 3 }} gap={3} onClick={(e) => e.stopPropagation()}>
            {children}
          </Stack>
        </Collapse>
      </Box>
    </Stack>
  );
};
