import { Box, Divider, Stack, SxProps, Typography } from '@mui/material';
import Paper, { PaperProps } from '@mui/material/Paper';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import React, { PropsWithChildren, ReactNode } from 'react';
import { theme } from 'theme';

const StyledPaper = React.forwardRef<HTMLDivElement>(function Test({ children }: PaperProps, ref) {
  return (
    <Paper elevation={5} ref={ref} sx={{ mx: 2, width: 300, display: 'flex', p: 2 }}>
      {children}
    </Paper>
  );
});

export function EntityTooltip({
  children,
  content,
  buttons,
  header,
  isLoading,
  placement,
  tooltipProps,
  sx,
}: PropsWithChildren<{
  isLoading?: boolean;
  placement?: TooltipProps['placement'];
  tooltipProps?: Partial<TooltipProps>;
  sx?: SxProps;
  content?: ReactNode;
  buttons?: ReactNode;
  header?: ReactNode;
}>) {
  if (isLoading) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      placement={placement ? placement : 'top'}
      title={
        <Stack gap={1} sx={{ width: '100%', overflow: 'hidden' }}>
          {header}
          <Typography variant="body4" color={theme.palette.text.secondary}>
            {content}
          </Typography>
          {!!buttons && (
            <>
              <Divider sx={{ mx: -2 }} />
              <Stack>{buttons}</Stack>
            </>
          )}
        </Stack>
      }
      components={{
        Tooltip: StyledPaper,
      }}
      {...tooltipProps}
    >
      <Box sx={{ minWidth: 0, ...sx }}>{children}</Box>
    </Tooltip>
  );
}
