import { EmailTemplate } from 'api/graphql/generated/graphql';
import { SendBulkEmailRequest } from 'api/rest/hooks/useBulkEmail';
import { isSet } from 'util/filters';
import { recipientMapper } from 'util/mappers/sendEmailMapper';
import { PreviewBulkEmailFormData } from 'util/schemas/sendBulkEmailSchema';

export function sendBulkEmailMapper(
  data: PreviewBulkEmailFormData,
  composeData: {
    shopId: string;
    senderEmail: string;
    template: EmailTemplate;
    createFollowUpTask: boolean;
    hidePrice: boolean;
  },
): SendBulkEmailRequest {
  return {
    data: {
      shopId: composeData.shopId,
      emails: data.recipients.map((email) => ({
        cc: email.cc.map((r) => recipientMapper(r)).filter(isSet),
        bcc: email.bcc.map((r) => recipientMapper(r)).filter(isSet),
        body: email.body,
        subject: email.subject,
        from: composeData.senderEmail,
        propertyIds: email.properties.map((property) => property.utag).filter(isSet),
        language: email.language,
        recipient:
          email.recipient.type === 'CONTACT'
            ? {
                contactId: email.recipient.id,
                leadId: undefined,
              }
            : {
                contactId: email.recipient.contact.id,
                leadId: email.recipient.id,
              },
      })),
      emailTemplateId: composeData.template.id,
      createFollowUp: composeData.createFollowUpTask,
      hidePrice: composeData.hidePrice,
    },
    files: data.attachments,
  };
}
