import { Button, CircularProgress, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PrimaryAction } from 'components/general/EVDrawer/EVDrawerContent';
import { theme } from 'theme';

type SendEmailFooterProps = {
  primaryAction?: PrimaryAction;
  isLoading: boolean;
  children?: React.ReactNode;
};

export const SendEmailFooter = ({ primaryAction, isLoading, children }: SendEmailFooterProps) => {
  return (
    <Footer sx={{ backgroundColor: theme.palette.special.noon, padding: 2 }}>
      {primaryAction && (
        <>
          {primaryAction.primaryButtonLabel && (
            <Tooltip title={primaryAction.primaryButtonDisabledTooltip} arrow>
              <span>
                <Button
                  disabled={primaryAction.disabled || isLoading}
                  color="primary"
                  variant="contained"
                  type="submit"
                  onClick={primaryAction.callback}
                >
                  {primaryAction.primaryButtonLabel}
                </Button>
              </span>
            </Tooltip>
          )}
        </>
      )}
      {isLoading && <CircularProgress color="primary" size={35} />}
      {children}
    </Footer>
  );
};

const Footer = styled('footer')({
  display: 'flex',
  width: '100%',
  position: 'sticky',
  bottom: 0,
  alignItems: 'center',
});
