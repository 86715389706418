import { LeadAndContactSearchOption } from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import { isSet } from 'util/filters';

export function separateContactsAndLeads(contactsAndLeads: LeadAndContactSearchOption[]) {
  const uniqueContacts: string[] = [];

  const contactIds =
    contactsAndLeads
      ?.map((contactOrLead) => {
        if (contactOrLead.type === 'LEAD') {
          if (!uniqueContacts.includes(contactOrLead.contactId)) {
            uniqueContacts.push(contactOrLead.contactId);
            return contactOrLead.contactId;
          }
        } else if (contactOrLead.id && !uniqueContacts.includes(contactOrLead.id)) {
          uniqueContacts.push(contactOrLead.id);
          return contactOrLead.id;
        }
        return null;
      })
      .filter(isSet) ?? [];

  const leadIds =
    contactsAndLeads
      ?.map((contactOrLead) => {
        if (contactOrLead.type === 'LEAD') {
          return contactOrLead.id ?? '';
        }
        return null;
      })
      .filter(isSet) ?? [];

  return { contactIds, leadIds };
}
