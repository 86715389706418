import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import { ContactDetails } from 'components/contact/ContactDetailsForm/contact';
import { EVAlert } from 'components/general/EVAlert/EVAlert';
import { ContactEditSection } from 'page-components/contact/LayoutContact/EditContact/EditContact';

import { useState } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

export const ContactDetailsForm = ({
  activeSection,
  withoutRelationships,
}: {
  activeSection?: ContactEditSection;
  withoutRelationships?: boolean;
}) => {
  const { t } = useTranslation(['contact']);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box p={3}>
      <Snackbar open={open} onClose={handleClose} sx={{ position: 'inherit', marginBottom: theme.spacing(3) }}>
        <EVAlert onClose={handleClose} severity="error" fullWidth>
          {t('contact:fillMissing')}
        </EVAlert>
      </Snackbar>
      <Stack gap={4}>
        <ContactDetails activeSection={activeSection} withoutRelationships={withoutRelationships} />
      </Stack>
    </Box>
  );
};
