import { boundingBoxToLatLngBounds } from 'components/map/LeafletMap/LeafletMapUtils';
import { useMemo } from 'react';
import { Rectangle, RectangleProps } from 'react-leaflet';
import { theme } from 'theme';
import { BoundingBox } from 'util/places/types';

export const LeafletRectangle: React.FC<Omit<RectangleProps, 'bounds'> & { bounds: BoundingBox }> = ({
  bounds,
  ...props
}) => {
  const memoizedBounds = useMemo(() => {
    return boundingBoxToLatLngBounds(bounds);
  }, [bounds]);

  return (
    <Rectangle
      pathOptions={{
        fillColor: theme.palette.text.primary,
        color: theme.palette.text.primary,
      }}
      bounds={memoizedBounds}
      {...props}
    />
  );
};
