import { CountryEnum } from 'api/graphql/generated/graphql';
import { useGetAllEnums } from 'api/graphql/hooks/useGetAllEnums';
import { Option } from 'components/general/OptionsList/OptionsList';
import { useMemo } from 'react';
import { translateCountryEnum } from 'util/i18n/translateCountryEnum';
import { useTranslation } from 'util/i18next';
import { enumToOptions } from 'util/mappers/enumToOption';
import { sortByDescriptionUndefinedFirst } from 'util/sorting';

export const useCountryOptions = () => {
  const { allEnums } = useGetAllEnums();
  const { t } = useTranslation();

  const countries = useMemo(
    () =>
      allEnums?.enumsCountry
        ?.map(enumToOptions)
        .sort(sortByDescriptionUndefinedFirst)
        .map((opt: Option) => ({
          ...opt,
          label: translateCountryEnum(t, opt?.value as CountryEnum),
        })) || [],
    [allEnums?.enumsCountry, t],
  );

  return { countries };
};
