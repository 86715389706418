import { FormGroup } from '@mui/material';
import { useShopTags } from 'api/graphql/hooks/useShopTags';
import { FormStack } from 'components/general/Form/FormStack';
import { Multiselect, MultiselectOption } from 'components/general/Multiselect/Multiselect';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';

export const TagsForm = () => {
  const { t } = useTranslation(['contact']);
  const {
    control,
    formState: { errors },
  } = useFormContext<ContactDetailsFormData>();
  const { shopIdsInActiveGroup } = useActiveShop();
  const { tags } = useShopTags({ shopIds: shopIdsInActiveGroup });

  const toSearchOption = ({ id, name }: { id: string; name?: string | null }): MultiselectOption => ({
    value: id,
    displayValue: name || '',
  });

  const tagOptions = (tags?.sort((a, b) => (a.name || '').localeCompare(b.name || '')) || []).map((tag) =>
    toSearchOption(tag),
  );

  return (
    <FormStack>
      <FormGroup sx={{ flex: 1 }}>
        <Controller
          name="tags"
          control={control}
          render={({ field: { ref, ...field } }) => {
            return (
              <Multiselect
                options={tagOptions}
                {...field}
                value={field.value || []}
                onChange={(_, items) => {
                  field.onChange(items);
                }}
                label={t('contact:tags')}
                noOptionsText={t('contact:tags.noOptions')}
                errorMessage={errors?.tags?.message}
                limitTags={3}
              />
            );
          }}
        />
      </FormGroup>
    </FormStack>
  );
};
