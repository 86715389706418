import { Button } from '@mui/material';
import PencilIcon from 'components/icons/pencil.svg?react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

const EditMapButton = ({ setIsPinMovable }: { setIsPinMovable: (e: boolean) => void }) => {
  const { t } = useTranslation(['lead']);
  return (
    <Button
      startIcon={<PencilIcon />}
      sx={{
        position: 'absolute',
        bottom: 24,
        left: 12,
        px: 2,
        py: 1,
        zIndex: 400,
        background: theme.palette.backgroundExtension.white,
        color: theme.palette.text.primary,
        '&:hover': { background: theme.palette.backgroundExtension.white },
      }}
      variant="contained"
      color="primary"
      onClick={() => {
        setIsPinMovable(true);
      }}
    >
      {t('lead:map.editPinCTA')}
    </Button>
  );
};

export default EditMapButton;
