import { Button, CircularProgress, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PrimaryAction } from 'components/general/EVDrawer/EVDrawerContent';
import { useTranslation } from 'util/i18next';

type DrawerFooterProps = {
  primaryAction?: PrimaryAction;
  isLoading: boolean;
  children?: React.ReactNode;
  onClose: () => void;
};

export const EVDrawerFooter = ({ primaryAction, isLoading, children, onClose }: DrawerFooterProps) => {
  const { t } = useTranslation();

  return (
    <Footer>
      {primaryAction && (
        <>
          {primaryAction.primaryButtonLabel && (
            <Tooltip title={primaryAction.primaryButtonDisabledTooltip} arrow>
              <span>
                <Button
                  disabled={primaryAction.disabled || isLoading}
                  color="primary"
                  variant="contained"
                  type="submit"
                  onClick={primaryAction.callback}
                  data-testid="createContactButton"
                >
                  {primaryAction.primaryButtonLabel}
                </Button>
              </span>
            </Tooltip>
          )}
          {!primaryAction.hideCancelButton && (
            <Button disabled={isLoading} color="secondary" variant="outlined" onClick={onClose}>
              {primaryAction.cancelButtonLabel ?? t('cancel')}
            </Button>
          )}
        </>
      )}
      {isLoading && <CircularProgress color="primary" size={35} />}
      {children}
    </Footer>
  );
};

const Footer = styled('footer')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  width: '100%',
  backgroundColor: theme.palette.shade.grey4,
  padding: theme.spacing(1, 2),
  position: 'sticky',
  bottom: 0,
  alignItems: 'center',
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing(2, 3),
  },
}));
