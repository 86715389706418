import { Option } from 'components/general/OptionsList/OptionsList';
import { stringFormatToUpperCaseStart } from 'util/stringUtils';

export function enumToOptionsWithUndefinedAs(undefinedLabel: string) {
  // TODO i18n: description is not translated (and even CANNOT be translated)
  return function ({ name, description }: { name: string; description?: string | null }): Option {
    return {
      value: name,
      label: name === 'UNDEFINED' ? undefinedLabel : description || stringFormatToUpperCaseStart(name),
    };
  };
}

/**
 * @deprecated use enumToLokalisedOptions instead
 */
export function enumToOptions(enumEntry: { name: string; description?: string | null }): Option {
  return enumToOptionsWithUndefinedAs('')(enumEntry);
}

export const enumToLokalisedOptions = <TKeys, T extends (k: TKeys) => string>(
  t: T,
  translationKeyMapping: Record<string, TKeys>,
): (({ name }: { name: string }) => Option) => {
  return ({ name: enumValue }: { name: string }) => {
    return enumValueToLokalisedOptions(t, translationKeyMapping)(enumValue);
  };
};

export const enumValueToLokalisedOptions = <TKeys, T extends (k: TKeys) => string>(
  t: T,
  translationKeyMapping: Record<string, TKeys>,
) => {
  return (enumValue: string): Option => {
    const labelValue = translationKeyMapping[enumValue];

    return {
      value: enumValue,
      label: enumValue === 'UNDEFINED' ? '' : labelValue ? t(labelValue) || enumValue : enumValue,
    };
  };
};
