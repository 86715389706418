import { DEFAULT_LANG, useLocale } from '@ev/eva-container-api';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { LinkButton } from 'components/general/LinkButton';
import { VisitWebsite } from 'components/general/VisitWebsite/VisitWebsite';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { env } from 'env';
import { useIsFeatureEnabled } from 'eva-frame/EvaProviders/FeatureFlagProvider';
import { HTMLAttributeAnchorTarget, PropsWithChildren, memo } from 'react';
import { getGOmeLocale } from 'util/navigation/useDestination';

function EVIdWithLinkComponent({
  label,
  goPath,
  legacy,
  target,
  children,
  typographyProps,
  hideIcon = true,
}: {
  label?: string;
  goPath: string;
  legacy?: boolean;
  target?: HTMLAttributeAnchorTarget;
  typographyProps?: TypographyProps;
  hideIcon?: boolean;
} & PropsWithChildren) {
  const { activeShop } = useActiveShop();
  const locale = useLocale();
  const isGomeIntegrationEnabled = useIsFeatureEnabled('GOME_MODULE_FEDERATION');

  const isEvaPhase1Link = activeShop.onboardingLevel && activeShop.onboardingLevel > 1;

  if (legacy && isEvaPhase1Link) {
    return (
      <Typography data-testid="go3-evId-link-label" variant="body3" {...typographyProps}>
        {label}
      </Typography>
    );
  }

  const isEvaPhase2Link = isGomeIntegrationEnabled && isEvaPhase1Link;
  const linkTarget = target || (isEvaPhase1Link ? '_self' : '_blank');

  if (isEvaPhase2Link) {
    const link = locale === DEFAULT_LANG ? `/${activeShop.id}/${goPath}` : `/${locale}/${activeShop.id}/${goPath}`;

    return (
      <LinkButton
        to={link}
        target={linkTarget}
        hideIcon={hideIcon}
        sx={{
          '&:hover': {
            color: 'unset',
          },
        }}
      >
        {children ?? (
          <Typography data-testid="go3-evId-link-label" variant="body3" {...typographyProps}>
            {label}
          </Typography>
        )}
      </LinkButton>
    );
  }

  const gomeLang = getGOmeLocale(locale);
  const langPrefix = gomeLang ? `/${gomeLang}` : '';

  const link = isEvaPhase1Link
    ? `${env.VITE_GO_AGENT_BASE_URL}${langPrefix}/${goPath}?shopId=${activeShop.id}`
    : `${env.VITE_GO_AGENT_BASE_URL}/${goPath}`;

  return (
    <VisitWebsite url={link} label={label} target={linkTarget} typographyProps={typographyProps}>
      {children}
    </VisitWebsite>
  );
}

export const GoEVIdLink = memo(EVIdWithLinkComponent);
