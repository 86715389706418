import { EmailRecipient, useEmailSending } from '@ev/eva-container-api';
import { Button, Stack, SxProps, Typography } from '@mui/material';
import { Shop } from 'api/graphql/generated/graphql';

import WarningIcon from 'components/icons/warning.svg?react';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { EMPTY_DATA_STRING } from 'util/emptyDataUtils';
import { usePermissions } from 'util/usePermissions';

export const EmailLink = ({
  email,
  isInvalidLink = false,
  emptyDataString = EMPTY_DATA_STRING,
  disabled: isDisabled,
  sx,
  to,
  agentId,
}: {
  email?: string | null;
  isInvalidLink?: boolean;
  emptyDataString?: string;
  disabled?: boolean;
  sx?: SxProps;
  to: EmailRecipient[];
  agentId?: string;
}) => {
  // shop can be undefined when use in network page
  const { activeShop } = useActiveShop() as { activeShop: Shop | undefined };
  const { canSendEmail } = usePermissions();
  const { openSendEmail } = useEmailSending();

  const disabled = isDisabled || !activeShop || !canSendEmail(activeShop);

  if (!email || email === EMPTY_DATA_STRING) {
    return <Typography sx={{ pl: 1.5 }}>{emptyDataString}</Typography>;
  }

  return (
    <Stack direction="row" alignItems="center">
      <Button
        component={Link}
        to={`mailto:${email}`}
        variant="text"
        color="secondary"
        title={email}
        sx={{
          textDecoration: 'underline',
          color: isInvalidLink ? theme.palette.warning.main : theme.palette.text.primary,
          p: 0.5,
          ml: 0.5,
          '&:hover': {
            textDecoration: 'underline',
          },
          ...sx,
        }}
        onClick={(evt) => {
          if (!disabled) {
            evt.preventDefault();
            openSendEmail({
              to: agentId ? [{ agentId }] : to ? to : [],
            });
          }
        }}
      >
        <Typography variant="body3" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {email}
        </Typography>
        {isInvalidLink && <WarningIcon />}
      </Button>
    </Stack>
  );
};
