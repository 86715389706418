import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery } from '@tanstack/react-query';

import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

const shopTagsQuery = graphql(/* GraphQL */ `
  query ShopTags($shopIds: [String!]!) {
    dbTag(where: { shopId: { _in: $shopIds }, deleted: { _eq: false } }) {
      id
      name
    }
  }
`);

export const useShopTags = (variables: VariablesOf<typeof shopTagsQuery>) => {
  const { data: tags, ...rest } = useQuery({
    queryKey: ['shop', 'useShopTags', variables],
    queryFn: async () => (await graphqlClient.request(shopTagsQuery, variables)).dbTag,
  });
  return { tags, ...rest };
};
