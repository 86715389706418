import { useCallback, useRef } from 'react';

export function useThrottle(timeoutMS: number) {
  const shouldWait = useRef(false);
  const callQueue = useRef<(() => void) | null>(null);

  const timeoutFunc = useCallback(() => {
    if (callQueue.current === null) {
      shouldWait.current = false;
    } else {
      callQueue.current();
      callQueue.current = null;
      setTimeout(timeoutFunc, timeoutMS);
    }
  }, [timeoutMS]);

  const throttle = useCallback(
    (thisFN: () => void) => {
      if (shouldWait.current) {
        callQueue.current = thisFN;
        return;
      }

      thisFN();
      shouldWait.current = true;
      setTimeout(timeoutFunc, timeoutMS);
    },
    [timeoutFunc, timeoutMS],
  );

  return throttle;
}
