import { useMemo } from 'react';
import { useSchemaPrimitives } from 'util/schemas/schemaPrimitives';
import { z } from 'zod';

export function useAddressSchema() {
  const { optionalString, optionalNumber } = useSchemaPrimitives();
  return useMemo(() => {
    return z.object({
      city: optionalString,
      countryCode: optionalString,
      postalCode: optionalString,
      streetName: optionalString,
      streetNumber: optionalString,
      coordinate: z
        .object({
          latitude: optionalNumber,
          longitude: optionalNumber,
        })
        .optional(),
      placeId: optionalString,
    });
  }, [optionalNumber, optionalString]);
}

export function useAddressWithTypeSchema() {
  const { optionalString, requiredBoolean } = useSchemaPrimitives();
  const addressSchema = useAddressSchema();
  return useMemo(() => {
    return z
      .object({
        addressType: optionalString,
        preferred: requiredBoolean,
        address: addressSchema.optional(),
      })
      .passthrough(); // don't remove extra keys for diffing;
  }, [addressSchema, optionalString, requiredBoolean]);
}

export type AddressWithTypeFormData = z.infer<ReturnType<typeof useAddressWithTypeSchema>>;
export type AddressFormData = z.infer<ReturnType<typeof useAddressSchema>>;
