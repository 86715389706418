import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

export const teamsAssignedToAgentInShopQuery = graphql(/* GraphQL */ `
  query TeamsAssignedToAgentInShop($where: DbAgentBoolExp!, $shopId: String!) {
    dbAgent(where: $where) {
      userTeamAssignments(where: { team: { shopId: { _eq: $shopId } } }) {
        team {
          name
          id
        }
      }
    }
    dbTeam(where: { shopId: { _eq: $shopId } }) {
      id
      name
    }
  }
`);

export const useTeamsAssignedToAgentInShop = (
  variables: VariablesOf<typeof teamsAssignedToAgentInShopQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['agent', 'useTeamsAssignedToAgentInShopQuery', variables],
    queryFn: async () => await graphqlClient.request(teamsAssignedToAgentInShopQuery, variables),
    ...options,
  });
  return {
    agentTeamAssignments: data?.dbAgent[0]?.userTeamAssignments?.map(({ team }) => team) || [],
    shopTeams: data?.dbTeam || [],
    ...rest,
  };
};
