import { Box, Button } from '@mui/material';
import { Call } from '@twilio/voice-sdk';
import { ActiveCallPage } from 'components/calls/ActiveCallPopover/ActiveCallPopover';
import { CallActionButton, CallActions } from 'components/calls/CallActions';
import { CallFrame } from 'components/calls/CallFrame';
import { CallInfo } from 'components/calls/CallInfo';
import { CallTile, CallTiles } from 'components/calls/CallTiles';
import BellIcon from 'components/icons/bell_naked.svg?react';
import ChatIcon from 'components/icons/chat_1_naked.svg?react';
import ForwardIcon from 'components/icons/direction.svg?react';
import EndCallIcon from 'components/icons/end_call.svg?react';
import LeadsIcon from 'components/icons/lead_hub_logo.svg?react';
import MicOffIcon from 'components/icons/microphone_crossed.svg?react';
import MicOnIcon from 'components/icons/microphone_naked.svg?react';
import MoreIcon from 'components/icons/more.svg?react';
import PauseIcon from 'components/icons/pause.svg?react';
import SalesIcon from 'components/icons/tag_single.svg?react';
import { CallerInfo } from 'components/state/Twilio';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

export interface CallPopoverOverviewProps {
  callerInfo: CallerInfo;
  onEndCall: () => void;
  onMuteCall: () => void;
  onClose: () => void;
  onPageChange: (page: ActiveCallPage) => void;
  callDuration: number;
  headerTitle: string;
  headerColor: string;
}

export function CallPopoverOverviewPage({
  onClose,
  callerInfo,
  onEndCall,
  onMuteCall,
  callDuration,
  headerTitle,
  headerColor,
  onPageChange,
}: CallPopoverOverviewProps) {
  const { t } = useTranslation(['contact', 'communication']);

  const isActive = callerInfo.call.status === Call.State.Open;
  const hasActivity = !!callerInfo.activity;
  const { hasFetchedActivity } = callerInfo;

  return (
    <CallFrame
      onClose={onClose}
      title={headerTitle}
      headerColor={headerColor}
      variant="light"
      actions={
        isActive ? (
          <ActiveCallActions onEndCall={onEndCall} onMuteCall={onMuteCall} callerInfo={callerInfo} />
        ) : (
          <EndedCallActions onClose={onClose} />
        )
      }
    >
      <CallInfo
        sx={{ marginY: 'auto' }}
        callerInfo={callerInfo}
        isActive={isActive}
        callDuration={callDuration}
        showAddContact={!callerInfo.call.callContactId && !hasActivity && hasFetchedActivity}
        onAddContact={() => onPageChange('contact')}
      />
      <CallTiles>
        <CallTile
          icon={<LeadsIcon />}
          disabled={!hasActivity}
          onClick={() => onPageChange('lead')}
          tooltipText={t('communication:call.callerPopoverTile.disableTooltip')}
        >
          {t('communication:call.callerPopoverTile.leads')}
        </CallTile>
        <CallTile
          icon={<SalesIcon />}
          disabled={true}
          tooltipText={t('communication:call.callerPopoverTile.comingSoonTooltip')}
          onClick={() => {
            // Coming soon
          }}
        >
          {t('communication:call.callerPopoverTile.tags')}
        </CallTile>
        <CallTile
          icon={<ForwardIcon />}
          disabled={true}
          tooltipText={t('communication:call.callerPopoverTile.comingSoonTooltip')}
          onClick={() => {
            // Coming soon
          }}
        >
          {t('communication:call.callerPopoverTile.transfer')}
        </CallTile>
        <CallTile
          icon={<ChatIcon />}
          disabled={!hasActivity}
          onClick={() => {
            onPageChange('outcome');
          }}
          tooltipText={t('communication:call.callerPopoverTile.disableTooltip')}
        >
          {t('communication:call.callerPopoverTile.outcome')}
        </CallTile>
        <CallTile
          icon={<BellIcon />}
          disabled={true}
          tooltipText={t('communication:call.callerPopoverTile.comingSoonTooltip')}
          onClick={() => {
            // Coming soon
          }}
        >
          {t('communication:call.callerPopoverTile.reminder')}
        </CallTile>
        <CallTile
          icon={<MoreIcon />}
          disabled={true}
          tooltipText={t('communication:call.callerPopoverTile.comingSoonTooltip')}
          onClick={() => {
            // Coming soon
          }}
        >
          {t('communication:call.callerPopoverTile.more')}
        </CallTile>
      </CallTiles>
    </CallFrame>
  );
}

const ActiveCallActions = ({
  onEndCall,
  onMuteCall,
  callerInfo,
}: {
  onEndCall: () => void;
  onMuteCall: () => void;
  callerInfo: CallerInfo;
}) => {
  const { t } = useTranslation(['contact', 'communication']);
  return (
    <CallActions>
      <CallActionButton disabled color={theme.palette.primary.main} icon={<PauseIcon />}>
        {t('communication:call.actionButton.pause')}
      </CallActionButton>
      <CallActionButton color={theme.palette.primary.main} icon={<EndCallIcon />} onClick={onEndCall}>
        {t('contact:callActions.endCall')}
      </CallActionButton>
      {callerInfo.call.isCallMuted ? (
        <CallActionButton color={theme.palette.primary.main} icon={<MicOnIcon />} onClick={onMuteCall}>
          {t('contact:callActions.unmute')}
        </CallActionButton>
      ) : (
        <CallActionButton color={theme.palette.primary.main} icon={<MicOffIcon />} onClick={onMuteCall}>
          {t('contact:callActions.mute')}
        </CallActionButton>
      )}
    </CallActions>
  );
};

const EndedCallActions = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation(['communication']);
  return (
    <Box sx={{ width: '100%', paddingTop: '97px' }}>
      <Button color="primary" sx={{ marginLeft: 'auto', width: '100%' }} variant="contained" onClick={onClose}>
        {t('communication:callActions.saveAndEndCall')}
      </Button>
    </Box>
  );
};
