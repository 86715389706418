import { MenuItem, MenuItemProps } from '@mui/material';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

interface MenuItemWithIntervalProps extends MenuItemProps {
  value: string;
  onClick: () => void;
  startDate?: Date;
  showInterval: boolean;
}

export const CustomMenuItem = ({
  value,
  onClick,
  startDate,
  showInterval = true,
  ...props
}: MenuItemWithIntervalProps) => {
  const { t } = useTranslation(['user']);

  if (!startDate || startDate === new Date(value) || !showInterval) {
    return (
      <MenuItem sx={{ fontSize: theme.typography.body3.fontSize }} onClick={onClick} {...props}>
        {value}
      </MenuItem>
    );
  }

  const startHours = startDate.getHours();
  const startMinutes = startDate.getMinutes();

  const [valueHours, valueMinutes] = value.split(':').map(Number);

  const startTimeInMinutes = startHours * 60 + startMinutes;

  const valueTimeInMinutes = valueHours! * 60 + valueMinutes!;

  const timeDifferenceInMinutes = valueTimeInMinutes - startTimeInMinutes;

  let timeDifference;

  if (timeDifferenceInMinutes % 60 !== 0 && timeDifferenceInMinutes % 60 !== 30) {
    return (
      <MenuItem sx={{ fontSize: theme.typography.body3.fontSize }} onClick={onClick} {...props}>
        {value}
      </MenuItem>
    );
  }

  if (timeDifferenceInMinutes > 60) {
    timeDifference = `${(timeDifferenceInMinutes / 60).toFixed(1)} ${t('user:fields.timePicker.hours')}`;
  } else {
    timeDifference = `${timeDifferenceInMinutes} ${t('user:fields.timePicker.mins')}`;
  }

  if (timeDifferenceInMinutes === 0) {
    return null;
  }

  return (
    <MenuItem
      sx={{ fontSize: theme.typography.body3.fontSize }}
      onClick={onClick}
      {...props}
    >{`${value} ( ${timeDifference} )`}</MenuItem>
  );
};
