import { Divider, SxProps, Typography, useMediaQuery } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ReactNode } from 'react';
import { theme } from 'theme';

export const FormSectionHeader = ({ children, sx }: { children: string | ReactNode; sx?: SxProps }) => (
  <Stack gap={1}>
    <Typography
      variant="subtitle1"
      sx={{ letterSpacing: '0.04rem', fontWeight: 700, textTransform: 'uppercase', ...sx }}
    >
      {children}
    </Typography>
    <Divider sx={{ marginTop: 1 }} />
  </Stack>
);

export const FormSection = ({
  children,
  sx,
  hidden,
}: {
  children: string | ReactNode;
  sx?: SxProps;
  hidden?: boolean;
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const marginBottom = isMobile ? 4 : 6;

  if (hidden) {
    return null;
  }

  return (
    <Stack gap={3} sx={sx} mb={marginBottom}>
      {children}
    </Stack>
  );
};
