import { EmailData, SendBulkEmailData } from '@ev/eva-container-api';
import { useDrawerParams } from 'util/drawerUrlParams/useDrawerParams';
import { EXPOSE_TEMPLATE_TYPES } from 'util/email';
import { z } from 'zod';

const emailUrlParamsSchema = z.object({
  preSelectTemplate: z.enum(['VIEWING_REPORT']).optional(),
  contactIds: z.string().optional(),
  propertyUtags: z.string().optional(),
  appointmentId: z.string().startsWith('APN').optional(),
  redirectUrl: z.string().optional(),
});

export function useEmailUrlParams({
  onEmailParams,
  enabled,
}: {
  onEmailParams: (params: EmailData) => void;
  enabled: boolean;
}) {
  useDrawerParams({
    paramName: 'openEmail',
    schema: emailUrlParamsSchema,
    enabled,
    onParams: ({ preSelectTemplate, contactIds, redirectUrl, propertyUtags, appointmentId }) => {
      const contacts = contactIds?.split(',');
      onEmailParams({
        template: preSelectTemplate,
        to: contacts?.map((contactId) => ({ contactId })) || [],
        redirectUrl,
        appointmentId,
        propertyUtags: propertyUtags?.split(','),
      });
    },
  });
}

const sendBulkEmailUrlParamsSchema = z.object({
  template: z.enum(EXPOSE_TEMPLATE_TYPES),
  contactIds: z.string().optional(),
  propertyUtags: z.string().optional(),
  leadIds: z.string().optional(),
  redirectUrl: z.string().optional(),
});

export function useSendBulkEmailUrlParams({
  onSendBulkEmailParams,
  enabled,
}: {
  onSendBulkEmailParams: (params: SendBulkEmailData) => void;
  enabled: boolean;
}) {
  useDrawerParams({
    paramName: 'sendBulkEmail',
    schema: sendBulkEmailUrlParamsSchema,
    enabled,
    onParams: ({ template, contactIds, propertyUtags, leadIds, redirectUrl }) =>
      onSendBulkEmailParams({
        template,
        to:
          (leadIds
            ? leadIds?.split(',').map((leadId) => ({ leadId }))
            : contactIds?.split(',').map((contactId) => ({ contactId }))) || [],
        redirectUrl,
        propertyUtags: propertyUtags?.split(','),
      }),
  });
}
