import { Keycloak, KeycloakContext, ParsedJwtToken } from '@ev/eva-container-api';
import LinearProgress from '@mui/material/LinearProgress';
import { ErrorPage } from 'eva-frame/ErrorPage';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'util/i18next';
import sentry from 'util/sentry';

export function KeycloakProvider({ keycloak, children }: React.PropsWithChildren<{ keycloak: Keycloak }>) {
  const { t } = useTranslation(['user']);

  const authenticating = useRef(false);
  const [hasError, setHasError] = useState(false);
  const [parsedToken, setParsedToken] = useState<ParsedJwtToken>();

  useEffect(() => {
    if (!authenticating.current) {
      authenticating.current = true;
      keycloak
        .init({ onLoad: 'login-required', checkLoginIframe: false })
        .then(() => {
          setParsedToken(keycloak.tokenParsed as ParsedJwtToken);
        })
        .catch((error) => {
          setHasError(true);
          sentry.logError(error as Error);
        });

      keycloak.onAuthError = (error) => {
        setHasError(true);
        if (error) {
          sentry.logError(error.error, { extra: { description: error.error_description } });
        } else {
          // The type is lie onAuthError can be called without an error
          sentry.logError('Unknown keycloak error');
        }
      };
      keycloak.onAuthRefreshSuccess = () => {
        setParsedToken(keycloak.tokenParsed as ParsedJwtToken);
      };
    }
  }, [keycloak]);

  if (hasError) {
    return (
      <ErrorPage
        title={t('user:error.fetchUserError.title')}
        message={t('user:error.fetchUserError.message')}
        buttonText={t('user:error.fetchUserError.button')}
        onClick={() => location.reload()}
      />
    );
  }

  if (!parsedToken) {
    return <LinearProgress />;
  }

  return <KeycloakContext.Provider value={parsedToken}>{children}</KeycloakContext.Provider>;
}
