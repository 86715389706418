import i18nInstance from 'i18next';
import common from '../../../public/locales/en/common.json';

/**
 * We need some extra languages for the formatted salutation because it is used in emails.
 * KEEP THIS IN SYNC WITH THE BACKEND!
 */
const LOCAL_TRANSLATIONS = {
  ca: {
    common: {
      salutationMr: 'Benvolgut senyor {{name}}',
      salutationMrWithTitle: 'Benvolgut {{title}} {{name}}',
      salutationMrs: 'Benvolguda senyora {{name}}',
      salutationMrsWithTitle: 'Benvolgut {{title}} {{name}}',
      salutationNeutral: 'Benvolgut {{name}}',
      salutationNeutralWithTitle: 'Benvolgut {{title}} {{name}}',
    },
  },
  hr: {
    common: {
      salutationMr: 'Poštovani gospodine {{name}}',
      salutationMrWithTitle: 'Poštovani {{title}} {{name}}',
      salutationMrs: 'Poštovana gospođo {{name}}',
      salutationMrsWithTitle: 'Poštovani {{title}} {{name}}',
      salutationNeutral: 'Dragi {{name}}',
      salutationNeutralWithTitle: 'Dragi {{title}} {{name}}',
    },
  },
  hu: {
    common: {
      salutationMr: 'Tisztelt {{name}} úr',
      salutationMrWithTitle: 'Tisztelt {{name}} {{title}} úr',
      salutationMrs: 'Kedves {{name}} asszony',
      salutationMrsWithTitle: 'Tisztelt {{name}} {{title}}',
      salutationNeutral: 'Kedves {{name}}',
      salutationNeutralWithTitle: 'Tisztelt {{name}} {{title}}',
    },
  },
  nl: {
    common: {
      salutationMr: 'Geachte heer {{name}}',
      salutationMrWithTitle: 'Beste {{title}} {{name}}',
      salutationMrs: 'Geachte mevrouw {{name}}',
      salutationMrsWithTitle: 'Beste {{title}} {{name}}',
      salutationNeutral: 'Beste {{name}}',
      salutationNeutralWithTitle: 'Beste {{title}} {{name}}',
    },
  },
} satisfies Record<string, { common: Partial<typeof common> }>;

export const LOCAL_LANGUAGES = Object.keys(LOCAL_TRANSLATIONS);

export function addLocalTranslations(i18n: typeof i18nInstance) {
  for (const [lang, translations] of Object.entries(LOCAL_TRANSLATIONS)) {
    for (const [namespace, data] of Object.entries(translations)) {
      i18n.addResources(lang, namespace, data);
    }
  }
}
