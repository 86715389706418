import { ErrorBoundaryProps } from 'components/error/ErrorBoundary';
import { Component, Dispatch, ReactNode, SetStateAction } from 'react';
import sentry from 'util/sentry';

interface ErrorBoundaryInnerProps extends ErrorBoundaryProps {
  hasError: boolean;
  setHasError: Dispatch<SetStateAction<boolean>>;
  fallback: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundaryInner extends Component<ErrorBoundaryInnerProps, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidUpdate(prevProps: ErrorBoundaryInnerProps) {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }

  public componentDidCatch(error: Error) {
    this.props.setHasError(true);
    sentry.logError(error);
  }

  public render() {
    return this.state.hasError ? this.props.fallback : this.props.children;
  }
}
