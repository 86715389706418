import { ListItemIcon, ListItemText } from '@mui/material';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { ActiveShopSelector } from 'eva-frame/EvaLayout/ActiveShopSelector';
import { Main } from 'eva-frame/EvaLayout/EvaLayout';
import { LanguageSelector } from 'eva-frame/EvaLayout/LanguageSelector';
import { AgentMenuItem } from 'eva-frame/EvaLayout/ProfileMenuButton/AgentMenuItem';
import { LogoutMenuItem } from 'eva-frame/EvaLayout/ProfileMenuButton/LogoutMenuItem';
import { SupportMenuButton } from 'eva-frame/EvaLayout/SupportMenuButton';
import { useProfileNavigationItems } from 'eva-frame/EvaLayout/useProfileNavigationItems';
import { Link } from 'react-router-dom';
import { theme } from 'theme';

export function MobileProfileNavigation({ onClose }: { onClose: () => void }) {
  const items = useProfileNavigationItems();

  return (
    <Main sx={{ backgroundColor: theme.palette.backgroundExtension.grey }}>
      <Stack gap={1} sx={{ margin: 2 }}>
        <Paper elevation={0}>
          <MenuList component="div">
            <ActiveShopSelector asMenuItem={true} />
            <Divider variant="middle" />
            <LanguageSelector asMenuItem={true} />
            <Divider variant="middle" />
            <SupportMenuButton asMenuItem={true} />
          </MenuList>
        </Paper>
        <Paper elevation={0}>
          <MenuList component="div">
            <AgentMenuItem />
            <Divider variant="middle" />
            {items.map((item) => (
              <MenuItem component={Link} to={item.href} key={item.label} onClick={onClose}>
                <ListItemIcon sx={{ color: item.isActive ? theme.palette.primary.main : theme.palette.shade.grey2 }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText sx={{ color: item.isActive ? theme.palette.primary.main : theme.palette.text.primary }}>
                  {item.label}
                </ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
        <Paper elevation={0}>
          <MenuList component="div">
            <LogoutMenuItem />
          </MenuList>
        </Paper>
      </Stack>
    </Main>
  );
}
