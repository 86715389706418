import { Shop } from 'api/graphql/generated/graphql';
import { CallFrame } from 'components/calls/CallFrame';
import { NoActiveCallsPlaceholder } from 'components/calls/EmptyCallPopover/NoActiveCallsPlaceholder';
import { SearchContactToCall } from 'components/calls/EmptyCallPopover/SearchContactToCall';
import { Label } from 'components/general/Label/Label';
import { ShopSelector } from 'components/general/ShopSelector/ShopSelector';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useState } from 'react';
import { useTranslation } from 'util/i18next';
import { usePermissions } from 'util/usePermissions';

export function EmptyCallPopover({
  onClose,
  hasCallingEnabled,
}: {
  onClose: () => void;
  hasCallingEnabled: (shop: Shop) => boolean;
}) {
  const { t } = useTranslation(['communication']);

  const { activeShop, allShops } = useActiveShop();
  const { canCall } = usePermissions();

  const allowedShops = allShops.filter((shop) => canCall(shop) && hasCallingEnabled(shop));
  const defaultShop = canCall(activeShop) && hasCallingEnabled(activeShop) ? activeShop : allowedShops[0];

  const [selectedShop, setSelectedShop] = useState(defaultShop);

  if (!selectedShop) {
    // should not happen
    return null;
  }

  return (
    <CallFrame onClose={onClose} title={t('communication:call.noActiveCallsTitle')} variant="light">
      <NoActiveCallsPlaceholder sx={{ marginTop: 4, marginBottom: 6 }} />
      <Label>{t('communication:call.noActiveCalls.shopSelector')}</Label>
      <ShopSelector
        sx={{ width: '100%', marginBottom: 2 }}
        showCaret
        selectedShop={selectedShop}
        onShopSelected={setSelectedShop}
        availableShops={allowedShops}
      />

      <SearchContactToCall shop={selectedShop} />
    </CallFrame>
  );
}
