import { BreadcrumbState } from '@ev/eva-container-api';
import IconButton from '@mui/material/IconButton';
import Arrow1LeftIcon from 'components/icons/arrow_1_left.svg?react';
import { Link } from 'react-router-dom';

export function Breadcrumbs({ breadcrumbs }: BreadcrumbState) {
  if (!breadcrumbs.length) {
    return null;
  }

  const href = breadcrumbs[breadcrumbs.length - 1]!.href;

  return (
    <IconButton component={Link} to={href}>
      <Arrow1LeftIcon />
    </IconButton>
  );
}
