import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { NylasIntegratedEmails } from 'components/emails/utils';
import { NoGoogleIntegrationWarning } from 'components/general/NoGoogleIntegrationWarning';
import { OptionsButton } from 'components/general/OptionsButton/OptionsButton';
import ArrowDownIcon from 'components/icons/triangle_down_small.svg?react';
import { theme } from 'theme';

export function SendEmailPopoverHeader({
  from,
  onChangeFrom,
  noEmailIntegration,
  possibleFromEmails,
}: {
  noEmailIntegration: boolean;
  from: string;
  onChangeFrom: (from: string) => void;
  possibleFromEmails: NylasIntegratedEmails[];
}) {
  if (noEmailIntegration) {
    return <NoGoogleIntegrationWarning />;
  }

  return (
    <Box
      sx={{
        minHeight: '36px',
        backgroundColor: theme.palette.special.noon,
        [theme.breakpoints.up('desktop')]: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
      }}
    >
      {possibleFromEmails.length === 1 ? (
        <Typography variant="body4" marginLeft={2}>
          {from}
        </Typography>
      ) : (
        <OptionsButton
          button={
            <Stack direction="row" alignItems="center" gap={1} sx={{ color: theme.palette.text.secondary }}>
              {from}
              <ArrowDownIcon />
            </Stack>
          }
          onChange={onChangeFrom}
          options={possibleFromEmails.map((email) => ({ value: email.email, label: email.email }))}
          selectedOption={from}
        />
      )}
    </Box>
  );
}
