import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { EVAlert } from 'components/general/EVAlert/EVAlert';
import LinkIcon from 'components/icons/link_external.svg?react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'util/i18next';
import { useDestination } from 'util/navigation/useDestination';

export const NoGoogleIntegrationWarning = () => {
  const { t } = useTranslation(['communication']);
  const { toGoogleIntegrations } = useDestination();

  return (
    <EVAlert
      severity="warning"
      customButton={
        <IconButton component={RouterLink} to={toGoogleIntegrations()} target="_blank">
          <LinkIcon height={24} />
        </IconButton>
      }
    >
      <Stack direction="column" spacing={1}>
        <Typography variant="body3" fontWeight="bold">
          {t('communication:emailForm.fromMissingTitle')}
        </Typography>
        <Typography variant="body3">{t('communication:emailForm.fromMissingDescription')}</Typography>
      </Stack>
    </EVAlert>
  );
};
