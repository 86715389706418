import { FormSection, FormSectionHeader } from 'components/general/Form/FormSection';
import { FormStack } from 'components/general/Form/FormStack';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';
import { KnownProperty } from './KnownProperty/KnownProperty';
import { KnownPropertyToggle } from './KnownProperty/KnownPropertyToggle';
import { SearchDemand } from './SearchDemand/SearchDemand';

export function BuyDetails() {
  const { t } = useTranslation(['lead']);
  const { watch } = useFormContext<PropertyDetailsFormData>();

  const isPropertyKnown = watch('_internals.isKnownProperty');
  const intentType = watch('intentType');

  return (
    <>
      <FormSection>
        <FormSectionHeader>{t('lead:form.section.basicDetails.header')}</FormSectionHeader>
        <FormStack>
          <KnownPropertyToggle />
        </FormStack>
        {isPropertyKnown && <KnownProperty />}
      </FormSection>
      {!isPropertyKnown && <SearchDemand intentType={intentType} />}
    </>
  );
}
