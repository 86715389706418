import { ErrorPage } from 'eva-frame/ErrorPage';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'util/i18next';
import { decodeBase64Url } from 'util/queryParams/encoding';

const Page404 = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const fallbackUrl = searchParams.get('fallback');

  useEffect(() => {
    if (fallbackUrl) {
      window.location.href = decodeBase64Url(fallbackUrl);
    }
  }, [fallbackUrl]);

  if (fallbackUrl) {
    return null;
  }

  return <ErrorPage title={t('404.title')} message={t('404.subtitle')} />;
};

export default Page404;
