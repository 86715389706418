import { Box, Button } from '@mui/material';
import { Call } from '@twilio/voice-sdk';
import { ActiveCallPage } from 'components/calls/ActiveCallPopover/ActiveCallPopover';
import { CallDuration } from 'components/calls/CallDuration/CallDuration';
import LeftArrow from 'components/icons/arrow_1_left.svg?react';
import { useTranslation } from 'util/i18next';

export interface CallOutcomePageProps {
  callStatus?: Call.State;
  onPageChange: (page: ActiveCallPage) => void;
  callDuration: number;
}

export function CallSubpageHeader({ onPageChange, callStatus, callDuration }: CallOutcomePageProps) {
  const { t } = useTranslation(['contact', 'communication']);

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          justifyItems: 'center',
          alignItems: 'center',
        }}
      >
        <Button startIcon={<LeftArrow />} sx={{ justifySelf: 'flex-start' }} onClick={() => onPageChange('overview')}>
          {t('communication:call.activeCall.backButton')}
        </Button>
        <CallDuration isActive={callStatus === Call.State.Open} callDuration={callDuration} />
      </Box>
    </>
  );
}
