import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PostRequestBody, fetchClient, toMultiPartFormData } from 'api/rest/fetch-client';
import { nanoid } from 'nanoid';

type RawSendBulkEmailRequest = PostRequestBody<'/api/v1/email-bulk'>;
type SendBulkEmailRequestData = RawSendBulkEmailRequest['data'];

export type SendBulkEmailRequest = {
  data: SendBulkEmailRequestData;
  files: File[];
};

export const useSendBulkEmail = () => {
  const queryClient = useQueryClient();
  const { mutate: sendBulkEmail, ...rest } = useMutation(
    (request: SendBulkEmailRequest) => {
      const requestId = nanoid();

      datadogRum.addAction('send bulk email', {
        recipients: request.data.emails.map((email) => email.recipient),
        templateId: request.data.emailTemplateId,
        requestId,
      });

      return fetchClient
        .POST('/api/v1/email-bulk', {
          body: toMultiPartFormData({ ...request, data: { ...request.data, requestId } }),
        })
        .then((resp) => resp.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { sendBulkEmail, ...rest };
};
