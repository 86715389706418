import { Control } from 'leaflet';
import 'leaflet-fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import { useEffect, useMemo, useState } from 'react';
import { useMap } from 'react-leaflet';

const useFullScreenControls = () => {
  const map = useMap();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const fullscreenControl = useMemo(
    () =>
      /** @ts-expect-error: Fullscreen does exist but isn't typed */
      new Control.Fullscreen({
        pseudoFullscreen: true,
        position: 'topright',
      }),
    [],
  );
  useEffect(() => {
    map.addControl(fullscreenControl);
    return () => {
      map.removeControl(fullscreenControl);
    };
  }, [map, fullscreenControl]);
  useEffect(() => {
    map.on('fullscreenchange', function () {
      setIsFullscreen(map.isFullscreen());
    });
    return () => {
      map.off('fullscreenchange');
    };
  }, [map]);
  return {
    isFullscreen,
    toggleFullscreen: () => map.toggleFullscreen({ pseudoFullscreen: true }),
    removeFullscreenButton: () => map.removeControl(fullscreenControl),
    addFullscreenButton: () => map.addControl(fullscreenControl),
  };
};

export default useFullScreenControls;
