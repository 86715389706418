import Paper, { PaperProps } from '@mui/material/Paper';
import { AddressInfo } from 'components/map/MarkerInfoBox/AddressInfo';
import { ShopInfo } from 'components/map/MarkerInfoBox/ShopInfo';
import { Marker } from 'util/places/mapMarkers';

export function MarkerInfoBox({ marker, paperSx }: { marker: Marker; paperSx?: PaperProps['sx'] }) {
  return (
    <Paper
      variant="outlined"
      elevation={0}
      key={marker.id}
      sx={{
        padding: 1,
        ...(paperSx ? { ...paperSx } : {}),
      }}
    >
      {marker.type === 'SHOP' ? <ShopInfo shop={marker} /> : <AddressInfo addressMarker={marker} />}
    </Paper>
  );
}
