import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  BulkEmailRecipientTabPanels,
  BulkEmailRecipientTabs,
  RecipientTab,
} from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/BulkEmailRecipientTabs';
import { InternalComposeBulkEmailStepProps } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/ComposeBulkEmailStep';
import { ComposeBulkEmailTemplateForm } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/ComposeBulkEmailTemplateForm';
import { CreateFollowUpTaskToggle } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/CreateFollowUpTaskToggle';
import { ShowPricesToggle } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/ShowPricesToggle';
import { SendBulkEmailDialogHeader } from 'components/emails/SendBulkEmailDialog/SendBulkEmailDialogHeader';
import { SendBulkEmailDesktopGrid } from 'components/emails/SendBulkEmailDialog/SendBulkEmailDialogLayout';
import {
  ModalContainer,
  ModalFooter,
  ModalFooterButton,
  ModalHeader,
  ModalTitle,
  ModalTitleAction,
} from 'components/general/Modal';
import CrossIcon from 'components/icons/cross_naked.svg?react';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { ComposeBulkEmailFormData } from 'util/schemas/sendBulkEmailSchema';

export function ComposeBulkEmailStepDesktop({
  possibleFromEmails,
  onClose,
  onPreview,
  noEmailIntegration,
}: InternalComposeBulkEmailStepProps) {
  const { t } = useTranslation(['communication']);
  const [activeTab, setActiveTab] = useState<RecipientTab>('to');
  const { control, watch } = useFormContext<ComposeBulkEmailFormData>();

  const title = watch('isExposeSending')
    ? t('communication:sendBulkEmailDialog.titleExpose')
    : t('communication:sendBulkEmailDialog.title');

  return (
    <>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <ModalTitleAction onClick={onClose}>
          <CrossIcon />
        </ModalTitleAction>
      </ModalHeader>
      <Controller
        name="from"
        control={control}
        render={({ field }) => (
          <SendBulkEmailDialogHeader
            from={field.value}
            onChangeFrom={field.onChange}
            noEmailIntegration={noEmailIntegration}
            possibleFromEmails={possibleFromEmails}
          />
        )}
      />
      <ModalContainer singleColumn sx={{ opacity: noEmailIntegration ? 0.2 : undefined }}>
        <SendBulkEmailDesktopGrid>
          <BulkEmailRecipientTabs activeTab={activeTab} setActiveTab={setActiveTab} disabled={noEmailIntegration} />
          <Stack direction="row" justifyContent="space-between" sx={{ alignSelf: 'center' }} alignItems="center">
            <Typography variant="h2" fontWeight="bold">
              {t('communication:sendBulkEmailDialog.templateTitle')}
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <CreateFollowUpTaskToggle disabled={noEmailIntegration} />
              <ShowPricesToggle disabled={noEmailIntegration} />
            </Stack>
          </Stack>
          <BulkEmailRecipientTabPanels activeTab={activeTab} disabled={noEmailIntegration} />
          <ComposeBulkEmailTemplateForm disabled={noEmailIntegration} />
        </SendBulkEmailDesktopGrid>
      </ModalContainer>
      <ModalFooter>
        <ModalFooterButton variant="contained" onClick={onPreview} disabled={noEmailIntegration}>
          {t('communication:sendBulkEmailDialog.previewButton', { number: watch('to').length })}
        </ModalFooterButton>
      </ModalFooter>
    </>
  );
}
