import { createEnv } from '@t3-oss/env-core';
import { clientSchema, serverSchema } from 'env-schema';

export const env = createEnv({
  clientPrefix: 'VITE_',
  server: serverSchema,
  client: clientSchema,

  // @ts-ignore
  runtimeEnv: {
    ...import.meta.env,
    ...(import.meta.env.VITE_LEADHUB_ENV === 'migration-prod'
      ? { VITE_GO_AGENT_BASE_URL: 'https://goagent-migration.engelvoelkers.com' }
      : undefined),
  },
});
