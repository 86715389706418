import { datadogRum } from '@datadog/browser-rum';
import { useJwtToken } from '@ev/eva-container-api';
import { useEffect, useRef } from 'react';

export function useDatadog() {
  const { jwtToken } = useJwtToken();

  const datadogInitRef = useRef(false);
  useEffect(() => {
    if (datadogInitRef.current || !jwtToken) {
      return;
    }

    datadogRum.setUserProperty('name', jwtToken?.name);
    datadogRum.setUserProperty('email', jwtToken?.email);
    datadogRum.setUserProperty('preferred_username', jwtToken?.preferred_username);
    datadogRum.startSessionReplayRecording();
    datadogInitRef.current = true;
  }, [jwtToken]);
}
