import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useCreateContactUrlParams({ onContactParams }: { onContactParams: () => void }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const hasCreateContactParam = searchParams.has('createContact');
  useEffect(() => {
    if (hasCreateContactParam) {
      searchParams.delete('createContact');
      setSearchParams(searchParams, { replace: true });
      onContactParams();
    }
  }, [hasCreateContactParam, searchParams, onContactParams, setSearchParams]);
}
