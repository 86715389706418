import { FormSection, FormSectionHeader } from 'components/general/Form/FormSection';
import { FormStack } from 'components/general/Form/FormStack';
import { PropertyTypeSection } from 'components/leads/forms/LeadForm/SellIntent/PropertyTypeSection';
import { OptionalSections } from 'components/leads/forms/LeadForm/SpecificAddressAndOptionalDetails/OptionalSections';
import { SpecificAddressSection } from 'components/leads/forms/LeadForm/SpecificAddressAndOptionalDetails/SpecificAddressSection';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';
import { KnownProperty } from './KnownProperty/KnownProperty';
import { KnownPropertyToggle } from './KnownProperty/KnownPropertyToggle';
import { SearchDemand } from './SearchDemand/SearchDemand';

export function RentDetails({ isCreateNewLead }: { isCreateNewLead: boolean }) {
  const { t } = useTranslation(['lead']);
  const { watch } = useFormContext<PropertyDetailsFormData>();

  const intentType = watch('intentType');
  const isPropertyKnown = watch('_internals.isKnownProperty');

  const isToRent = intentType === 'TO_RENT';

  if (isToRent) {
    return (
      <>
        <FormSection>
          <FormSectionHeader>{t('lead:form.section.basicDetails.header')}</FormSectionHeader>
          <FormStack>
            <KnownPropertyToggle />
          </FormStack>
          {isPropertyKnown && <KnownProperty />}
        </FormSection>
        {!isPropertyKnown && <SearchDemand intentType={intentType} />}
      </>
    );
  }

  return (
    <>
      <FormSection>
        <FormSectionHeader>{t('lead:form.section.basicDetails.header')}</FormSectionHeader>
        <PropertyTypeSection />
      </FormSection>
      <SpecificAddressSection isCreateNewLead={isCreateNewLead} />
      <OptionalSections />
    </>
  );
}
