import TextField from '@mui/material/TextField';
import { FormStack } from 'components/general/Form/FormStack';
import HomeIcon from 'components/icons/home.svg?react';
import { LinkPropertySearchField } from 'page-components/contact/lead/LeadPage/LeadOverview/LinkPropertyToLeadDialog/LinkPropertySearchField';
import { useFormContext } from 'react-hook-form';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';

export function KnownProperty() {
  const { t } = useTranslation(['lead']);
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<PropertyDetailsFormData>();

  const watchBuyOrRent = watch('intentType');

  return (
    <>
      <FormStack icon={<HomeIcon />}>
        <LinkPropertySearchField
          label={t('lead:knownProperty')}
          required
          name="knownProperty.linkedPropertyArray"
          isError={!!errors.knownProperty?.linkedPropertyArray}
          helperText={errors.knownProperty?.linkedPropertyArray?.message}
          listingType={watchBuyOrRent === 'BUY' ? 'SALE' : 'RENTAL'}
          sx={{ flex: 1 }}
        />
      </FormStack>
      <FormStack>
        <TextField
          label={t('lead:personalNote')}
          multiline
          error={!!errors.knownProperty?.personalNote}
          helperText={errors.knownProperty?.personalNote?.message}
          sx={{
            '.MuiInput-input': {
              fontSize: theme.typography.body3,
            },
          }}
          {...register('knownProperty.personalNote')}
        />
      </FormStack>
    </>
  );
}
