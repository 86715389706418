import { Box, SxProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { theme } from 'theme';

export function MapPlaceholder(props: PropsWithChildren & { sx?: SxProps }) {
  return (
    <Box
      sx={{
        background: theme.palette.grey[200],
        height: '200px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 1,
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  );
}
