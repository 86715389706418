import { useMemo } from 'react';
import { useSchemaPrimitives } from 'util/schemas/schemaPrimitives';
import { z } from 'zod';

export function useEmailSchema() {
  const { optionalString, requiredBoolean } = useSchemaPrimitives();

  return useMemo(() => {
    const validEmail = z.string().trim().email();
    const isValidEmail = (email: string) => {
      return validEmail.safeParse(email).success;
    };
    const optionalEmail =
      // For some reason this preprocess breaks the schema and transform creates the same issue
      // thus allowing empty string here
      // z.preprocess((a) => a === '' ? undefined : a,
      validEmail.optional().or(z.literal(''));

    return {
      validEmail,
      optionalEmail,
      isValidEmail,
      emailSchema: z
        .object({
          email: optionalEmail,
          emailType: optionalString,
          preferred: requiredBoolean,
        })
        .passthrough(), // don't remove extra keys for diffing;
    };
  }, [optionalString, requiredBoolean]);
}

export type EmailDetailsFormData = z.infer<ReturnType<typeof useEmailSchema>['emailSchema']>;
