import { CloseActivityStatus } from '@ev/eva-container-api';
import { useDrawerParams } from 'util/drawerUrlParams/useDrawerParams';
import { z } from 'zod';

const appointmentUrlParamsSchema = z.object({
  contactIds: z.string().array().optional(),
  type: z
    .enum([
      'HANDOVER',
      'LISTING_PRESENTATION',
      'MISCELLANEOUS',
      'NOTARY',
      'PRICE_MODIFICATION',
      'PRICE_REDUCTION',
      'SEATRIAL',
      'VALUATION',
      'VIEWING',
      'OPEN_HOUSE',
    ])
    .optional(),
  propertyUtags: z.string().array().optional(),
  editId: z.string().optional(),
  status: z.enum(['COMPLETED', 'CANCELLED'] as const satisfies readonly CloseActivityStatus[]).optional(),
  redirectUrl: z.string().optional(),
});

export type AppointmentUrlParams = z.infer<typeof appointmentUrlParamsSchema>;

export function useAppointmentDrawerUrlParams({
  onAppointmentParams,
}: {
  onAppointmentParams: (params: AppointmentUrlParams) => void;
}) {
  useDrawerParams({
    paramName: 'appointmentDrawer',
    schema: appointmentUrlParamsSchema,
    onParams: onAppointmentParams,
  });
}
