import { useMediaQuery } from '@mui/material';
import { ComposeBulkEmailStepDesktop } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/ComposeBulkEmailStepDesktop';
import { ComposeBulkEmailStepMobile } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/ComposeBulkEmailStepMobile';
import { NylasIntegratedEmails } from 'components/emails/utils';
import { theme } from 'theme';

export interface ComposeBulkEmailStepProps {
  onClose: () => void;
  onPreview: () => void;
  possibleFromEmails: NylasIntegratedEmails[];
}

export interface InternalComposeBulkEmailStepProps extends ComposeBulkEmailStepProps {
  noEmailIntegration: boolean;
}

export function ComposeBulkEmailStep(props: ComposeBulkEmailStepProps) {
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  const noEmailIntegration = !props.possibleFromEmails.length;

  const internalProps = {
    ...props,
    noEmailIntegration,
  };

  return isDesktop ? (
    <ComposeBulkEmailStepDesktop {...internalProps} />
  ) : (
    <ComposeBulkEmailStepMobile {...internalProps} />
  );
}
