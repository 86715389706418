import { CALLER_SCREEN_Z_INDEX } from '@ev/eva-container-api';
import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { EVDrawer } from 'components/general/EVDrawer/EVDrawer';
import { EVDrawerContent } from 'components/general/EVDrawer/EVDrawerContent';
import { HEADER_HEIGHT, PopoverHeader } from 'components/general/PopoverFrame/PopoverHeader';
import { useIsAnyDrawerOpen, usePopover } from 'components/state/UIStateProvider';
import { PropsWithChildren, ReactNode } from 'react';
import { theme } from 'theme';

const CALL_SCREEN_WIDTH = 448;
const MAIL_SCREEN_WIDTH = 600;

interface PopoverProps {
  popoverId: string;
  title: string;
  secondaryTitle?: string;
  onClose: () => void;
  actions?: ReactNode;
  headerColor?: string;
  isLoading?: boolean;
  height?: number;
  sx?: SxProps;
}

export function PopoverFrame({
  popoverId,
  children,
  onClose,
  title,
  secondaryTitle,
  actions,
  headerColor,
  isLoading,
  height,
  sx,
}: PropsWithChildren<PopoverProps>) {
  const isAnyDrawerOpen = useIsAnyDrawerOpen();

  const renderAsDrawerAlone = useMediaQuery(theme.breakpoints.down(750));
  const renderAsDrawerWithOtherDrawer = useMediaQuery(theme.breakpoints.down(1200));
  const renderAsDrawer = renderAsDrawerAlone || (isAnyDrawerOpen && renderAsDrawerWithOtherDrawer);

  const { isMinimized, setIsMinimized, minimizedOrder, numMinimized, isEmailPopoverMaximized } = usePopover(popoverId);
  const resultWidth = isEmailPopoverMaximized ? MAIL_SCREEN_WIDTH : CALL_SCREEN_WIDTH;
  if (renderAsDrawer) {
    return (
      <EVDrawer isOpen={true} onClose={onClose}>
        <EVDrawerContent
          title={secondaryTitle ? `${title} - ${secondaryTitle}` : title}
          onClose={onClose}
          isLoading={false}
        >
          {isLoading && <LinearProgress sx={{ marginBottom: '-4px' }} />}
          <Stack
            sx={{
              minHeight: '100%',
              ...sx,
            }}
          >
            {children}

            <Box sx={{ marginTop: 'auto' }}>{actions}</Box>
          </Stack>
        </EVDrawerContent>
      </EVDrawer>
    );
  }

  return (
    <Stack
      sx={{
        zIndex: isMinimized ? CALLER_SCREEN_Z_INDEX + 1 : CALLER_SCREEN_Z_INDEX,
        width: resultWidth,
        maxHeight: '100vh',
        ...(!isMinimized ? { height } : {}),
        position: 'fixed',
        transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        right: 0,
        transform: isAnyDrawerOpen ? 'translateX(-500px)' : 'none',
        bottom: isMinimized ? minimizedOrder * HEADER_HEIGHT : numMinimized * HEADER_HEIGHT,
        boxShadow: '0px 32px 64px 0px #0000003D',
      }}
    >
      <PopoverHeader
        title={title}
        secondaryTitle={secondaryTitle ?? ''}
        isMinimized={isMinimized}
        setIsMinimized={setIsMinimized}
        onClose={onClose}
        backgroundColor={headerColor}
      />
      {isLoading && <LinearProgress sx={{ marginBottom: '-4px' }} />}
      <Stack
        sx={{
          flex: 1,
          overflow: 'auto',
          ...sx,
          display: isMinimized ? 'none' : 'flex',
        }}
      >
        {children}
        {actions}
      </Stack>
    </Stack>
  );
}
