import Typography from '@mui/material/Typography';
import { memo } from 'react';
import { theme } from 'theme';
import { EMPTY_DATA_STRING } from 'util/emptyDataUtils';
import { useFormatTime } from 'util/useFormatTime';

function DateLabelComponent({
  dateString,
  type,
  emptyValueDisplayString,
}: {
  dateString?: string | null;
  type:
    | 'basicDate'
    | 'basicTime'
    | 'minimalDate'
    | 'fullDate'
    | 'relativeTimeFullDate'
    | 'dateWithYear'
    | 'dateWithYearAndTime';
  emptyValueDisplayString?: string;
}) {
  const { formatDateTime, formatRelativeTime, formatDateWithoutTime, formatTime } = useFormatTime();

  if (!dateString) {
    return <strong>{emptyValueDisplayString ?? EMPTY_DATA_STRING}</strong>;
  }

  const date: Date = new Date(dateString);
  const isCurrentYear = date.getFullYear() === new Date().getFullYear();

  switch (type) {
    case 'minimalDate': {
      const minimalFormattedDate = formatDateTime(date, {
        month: 'short',
        year: 'numeric',
      });
      return <div> {minimalFormattedDate} </div>;
    }
    case 'fullDate': {
      const fullFormattedDate = formatDateTime(date, {
        month: 'short',
        day: 'numeric',
        year: !isCurrentYear ? 'numeric' : undefined,
        hour: 'numeric',
        minute: 'numeric',
      });
      return (
        <Typography variant="body3" color={theme.palette.text.secondary}>
          {fullFormattedDate}
        </Typography>
      );
    }
    case 'dateWithYear': {
      const fullFormattedDate = formatDateTime(date, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
      return <div>{fullFormattedDate}</div>;
    }
    case 'dateWithYearAndTime': {
      const fullFormattedDate = formatDateTime(date, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
      return <div>{fullFormattedDate}</div>;
    }
    case 'basicDate': {
      const onlyDate = formatDateWithoutTime(dateString);
      return (
        <Typography variant="body3" color={theme.palette.text.secondary}>
          {onlyDate}
        </Typography>
      );
    }
    case 'basicTime': {
      const onlyTime = formatTime(dateString);
      return (
        <Typography variant="h3" color={theme.palette.text.secondary}>
          {onlyTime}
        </Typography>
      );
    }
    case 'relativeTimeFullDate': {
      const fullFormattedDate = formatDateTime(date, {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
      return (
        <div data-testid={date}>
          <strong>{formatRelativeTime(date, { numeric: 'always', style: 'long' })}</strong>
          <div>
            <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }} component="span">
              {fullFormattedDate}
            </Typography>
          </div>
        </div>
      );
    }
  }
}

export const DateLabel = memo(DateLabelComponent);
