import Logo from 'components/icons/ev_logo.svg?react';
import LogoCompact from 'components/icons/ev_logo_compact.svg?react';
import { Link } from 'react-router-dom';

export function EVLogoCompact({ href }: { href: string }) {
  return (
    <Link style={{ display: 'block', height: 44 }} to={href}>
      <LogoCompact width="44" height="44" />
    </Link>
  );
}

export function EVLogo({ href }: { href: string }) {
  return (
    <Link style={{ display: 'block' }} to={href}>
      <Logo />
    </Link>
  );
}
