import { FormControlLabel, Switch } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { ComposeBulkEmailFormData } from 'util/schemas/sendBulkEmailSchema';

export function ShowPricesToggle({ disabled }: { disabled: boolean }) {
  const { control } = useFormContext<ComposeBulkEmailFormData>();
  const { t } = useTranslation(['communication']);

  return (
    <Controller
      name="hidePrice"
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          sx={{ marginLeft: 1 }}
          value={value}
          control={
            <Switch
              color="secondary"
              disabled={disabled}
              checked={value}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
            />
          }
          label={t('communication:emailForm.showPricesToggle')}
        />
      )}
    />
  );
}
