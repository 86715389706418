import { DRAWER_Z_INDEX } from '@ev/eva-container-api';
import { Button, FormControlLabel, Stack, Switch, TextField } from '@mui/material';
import { ContactRelationTypeEnum } from 'api/graphql/generated/graphql';
import AddButton from 'components/contact/ContactDetailsForm/AddButton';
import { RelationshipTypeSelect } from 'components/contact/RelationshipForm/RelationshipTypeSelect';
import { ContactPicker } from 'components/forms/ContactPicker/ContactPicker';
import { EVDrawer } from 'components/general/EVDrawer/EVDrawer';
import { FormStack } from 'components/general/Form/FormStack';
import AddUserIcon from 'components/icons/user_added.svg?react';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { CreateNewContact } from 'page-components/contacts/ContactListPage/CreateNewContact';
import { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';
import { fullName } from 'util/stringUtils';
import { usePermissions } from 'util/usePermissions';

export const RelationshipForm = () => {
  const { t } = useTranslation(['enums', 'contact']);
  const { control, watch } = useFormContext<ContactDetailsFormData>();
  const { activeShop } = useActiveShop();
  const { canCreateContact } = usePermissions();
  const [isCreateContactDrawerOpen, setCreateContactDrawerOpen] = useState(false);
  const [isTurnedIntoContact, setIsTurnedIntoContact] = useState(false);
  const [migratedRelationshipType, setMigratedRelationshipType] =
    useState<ContactRelationTypeEnum>('PARTNER_OR_SPOUSE');

  const { fields: contactRelationships, append } = useFieldArray({
    name: 'contactRelationships',
    control,
  });

  const watchedMigratedContact = watch('_internals.contact.migratedPartnerContact');
  const migratedPartnerContact = fullName(watchedMigratedContact, '');

  const addRelationship = () => {
    append({
      type: undefined,
      visibleContacts: [],
      hiddenContacts: [],
      autoCc: false,
    });
  };

  return (
    <>
      {contactRelationships.map((relationship, index) => {
        if (!relationship?.visibleContacts.length && relationship?.hiddenContacts.length) {
          return null;
        }
        return (
          <Stack gap={2} key={index}>
            <FormStack>
              <Controller
                name={`contactRelationships.${index}.type`}
                control={control}
                render={({ field }) => <RelationshipTypeSelect {...field} />}
              />
            </FormStack>
            <FormStack>
              <Controller
                control={control}
                name={`contactRelationships.${index}.visibleContacts`}
                render={({ field: { value, onChange }, fieldState }) => (
                  <ContactPicker
                    label={t('contact:addRelationship.contactPicker.label')}
                    sx={{ flex: 1 }}
                    value={value}
                    onChange={onChange}
                    fieldState={fieldState}
                  />
                )}
              />
            </FormStack>
            <FormStack>
              <Controller
                name={`contactRelationships.${index}.autoCc`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    value={value}
                    control={<Switch color="secondary" onChange={onChange} defaultChecked={value} />}
                    label={t('contact:addRelationship.ccAutomatically.label')}
                  />
                )}
              />
            </FormStack>
          </Stack>
        );
      })}
      {!!migratedPartnerContact && !isTurnedIntoContact && (
        <Stack gap={2}>
          <FormStack>
            <RelationshipTypeSelect
              value={migratedRelationshipType}
              onChange={(value) => setMigratedRelationshipType(value as ContactRelationTypeEnum)}
            />
          </FormStack>
          <FormStack>
            <TextField
              label={t('contact:addRelationship.migratedPartnerContact.title')}
              value={fullName(watchedMigratedContact)}
              helperText={t('contact:addRelationship.migratedPartnerContact.helperText')}
              disabled
            />
          </FormStack>
          <EVDrawer
            zIndex={DRAWER_Z_INDEX + 1}
            isOpen={isCreateContactDrawerOpen}
            onClose={() => setCreateContactDrawerOpen(false)}
            hideBackdrop={true}
          >
            <CreateNewContact
              defaultValues={{
                firstName: watchedMigratedContact?.firstName || undefined,
                lastName: watchedMigratedContact?.lastName || undefined,
              }}
              onSuccess={(id, contactDetails) => {
                append({
                  type: migratedRelationshipType,
                  autoCc: contactDetails?.autoCc || false,
                  visibleContacts: [
                    {
                      id: id,
                      firstName: contactDetails?.firstName ?? '',
                      lastName: contactDetails?.lastName ?? '',
                    },
                  ],
                  hiddenContacts: [],
                });
                setIsTurnedIntoContact(true);
              }}
              onClose={() => setCreateContactDrawerOpen(false)}
              redirectToContactOnSuccess={false}
              withoutRelationships
            />
          </EVDrawer>
          <FormStack>
            <Button
              startIcon={<AddUserIcon />}
              color="secondary"
              variant="contained"
              onClick={() => setCreateContactDrawerOpen(true)}
              disabled={!canCreateContact(activeShop)}
            >
              {t('contact:addRelationship.migratedPartnerContact.convertToContactCTA')}
            </Button>
          </FormStack>
        </Stack>
      )}
      <AddButton text={t('contact:addRelationship.cta')} onClick={addRelationship} />
    </>
  );
};
