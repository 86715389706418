import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { EVDrawerFooter } from 'components/general/EVDrawer/EVDrawerFooter';
import { EVDrawerHeader } from 'components/general/EVDrawer/EVDrawerHeader';
import React, { PropsWithChildren, ReactNode } from 'react';
import { theme } from 'theme';

export interface PrimaryAction {
  primaryButtonLabel?: React.ReactNode;
  cancelButtonLabel?: React.ReactNode;
  callback?: () => void;
  disabled?: boolean;
  hideCancelButton?: boolean;
  primaryButtonDisabledTooltip?: string;
}

interface EVDrawerContentProps {
  onClose: () => void;
  title: string | ReactNode;
  primaryAction?: PrimaryAction;
  isLoading: boolean;
  extraFooterItems?: React.ReactNode;
  sx?: SxProps;
  tooltip?: string;
  hasBackButton?: boolean;
}

export function EVDrawerContent({
  onClose,
  title,
  primaryAction,
  isLoading,
  extraFooterItems,
  children,
  hasBackButton,
  sx,
}: PropsWithChildren<EVDrawerContentProps>) {
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const hasFooter = (primaryAction && !isMobile) || extraFooterItems;

  return (
    <>
      <EVDrawerHeader
        onClose={onClose}
        title={title}
        isLoading={isLoading}
        primaryAction={primaryAction}
        hasBackButton={hasBackButton}
      />
      <Box
        data-testid="drawer"
        sx={{
          overflow: 'auto',
          width: '100%',
          flex: 1,
          maxWidth: '100vw',
          backgroundColor: theme.palette.backgroundExtension.grey1,
          ...sx,
        }}
      >
        {children}
      </Box>
      {hasFooter && (
        <EVDrawerFooter primaryAction={isMobile ? undefined : primaryAction} onClose={onClose} isLoading={isLoading}>
          {extraFooterItems}
        </EVDrawerFooter>
      )}
    </>
  );
}
