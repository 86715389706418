import { useDrawerParams } from 'util/drawerUrlParams/useDrawerParams';
import { z } from 'zod';

const shareExposeUrlParamsSchema = z.object({
  contactIds: z.string().array().optional(),
  leadIds: z.string().array().optional(),
});

export type ShareExposeUrlParams = z.infer<typeof shareExposeUrlParamsSchema>;

export function useShareExposeDrawerUrlParams({
  onShareExposeParams,
}: {
  onShareExposeParams: (params: ShareExposeUrlParams) => void;
}) {
  useDrawerParams({
    paramName: 'shareExposeDrawer',
    schema: shareExposeUrlParamsSchema,
    onParams: onShareExposeParams,
  });
}
