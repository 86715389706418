import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useAgents } from 'api/graphql/hooks/useAgents';
import { useLeadActivities } from 'api/graphql/hooks/useLeadActivities';
import { AppointmentTaskSummary } from 'components/activity/ActivityCard/AppointmentTaskActivityCard/AppointmentTaskSummary';
import { isActivityClosed } from 'util/activity/activityUtils';
import { useTranslation } from 'util/i18next';
import { fullName } from 'util/stringUtils';
import { useShowError } from 'util/useShowError';

export function AppointmentForEmail({ appointmentId }: { appointmentId: string }) {
  const { t } = useTranslation(['activities', 'communication']);

  const { activities, isLoading } = useLeadActivities({
    where: { appointmentActivity: { appointmentId: { _eq: appointmentId } } },
  });
  const activity = activities?.[0];

  const { agents } = useAgents(
    { where: { id: { _eq: activity?.createdBy || '' } } },
    { enabled: !!activity?.createdBy },
  );

  const agentName = fullName(agents?.[0], t('activities:status.leadCreatedByAutomatedService'));
  const { appointment } = activity?.appointmentActivity || {};

  useShowError(t('communication:emailPopover.loadAppointmentForEmailError'), !isLoading && !appointment);

  if (isLoading || !appointment) {
    return null;
  }

  const isClosed = isActivityClosed(activity!);

  return (
    <Paper elevation={0} sx={{ padding: 2 }} variant="outlined" square>
      <Stack direction="row" gap={1} alignItems="flex-start">
        <Box flex="none">{isClosed ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />}</Box>
        <Stack gap={1}>
          <Box>
            <Typography variant="body3" fontWeight={'bold'}>
              {t(`activities:appointmentTaskActivity.title.APPOINTMENT`)}
            </Typography>
            <Typography variant="body3" fontWeight={'normal'} sx={{ ml: 0.5 }}>
              {t(`activities:appointmentTaskActivity.title.by`, { agentName })}
            </Typography>
          </Box>
          <AppointmentTaskSummary appointment={appointment} />
        </Stack>
      </Stack>
    </Paper>
  );
}
