import {
  GlobalEditorStateContextProvider,
  useSetupGlobalEditorState,
} from 'components/forms/RichTextEditor/blots/GlobalEditorState';
import { ExposeListWidget } from 'components/forms/RichTextEditor/blots/expose/ExposeListWidget';
import { PremiumExposeWidget } from 'components/forms/RichTextEditor/blots/expose/PremiumExposeWidget';
import ReactDOM from 'react-dom/client';
import { Quill } from 'react-quill';

let setup = false;

/**
 * Setup custom blots see https://github.com/slab/parchment
 */
export function useCustomBlots() {
  useSetupGlobalEditorState();

  if (setup) {
    return;
  }
  setup = true;

  const Embed = Quill?.import('blots/embed');
  const Inline = Quill?.import('blots/inline');
  const Block = Quill?.import('blots/block');

  Block.tagName = 'DIV';
  Quill?.register(Block, true);

  class Signature extends Embed {
    static create(value: string) {
      const node = super.create(value);
      node.innerHTML = value;
      return node;
    }
    static value(node: { childNodes: { textContent: unknown }[] }) {
      return node.childNodes[0]?.textContent;
    }
  }
  Signature.blotName = 'signature';
  Signature.tagName = 'signature';
  Quill.register(Signature, true);

  class ExposeListBlot extends Embed {
    static create() {
      const node = super.create();
      node.setAttribute('contenteditable', 'false');
      return node;
    }

    constructor(node: HTMLElement) {
      super(node);

      ReactDOM.createRoot(node).render(
        <GlobalEditorStateContextProvider>
          <ExposeListWidget />
        </GlobalEditorStateContextProvider>,
      );
    }
  }
  ExposeListBlot.blotName = 'expose-list';
  ExposeListBlot.tagName = 'expose-list';
  Quill.register(ExposeListBlot, true);

  class PremiumExposeListBlot extends Embed {
    static create() {
      const node = super.create();
      node.setAttribute('contenteditable', 'false');
      return node;
    }

    constructor(node: HTMLElement) {
      super(node);

      ReactDOM.createRoot(node).render(
        <GlobalEditorStateContextProvider>
          <PremiumExposeWidget />
        </GlobalEditorStateContextProvider>,
      );
    }
  }
  PremiumExposeListBlot.blotName = 'premium-expose';
  PremiumExposeListBlot.tagName = 'premium-expose';
  Quill.register(PremiumExposeListBlot, true);

  class UnresolvedPlaceHolder extends Inline {
    static create(value: { qlid: string; placeholder: string }) {
      const node = super.create(value);
      node.dataset.qlId = value.qlid;
      node.dataset.placeholder = value.placeholder;
      return node;
    }

    static formats(node: HTMLElement) {
      return {
        qlid: node.dataset.qlId,
        placeholder: node.dataset.placeholder,
      };
    }

    optimize(context: unknown) {
      super.optimize(context);
      const placeholder = this.domNode.dataset.placeholder;
      const text = this.domNode.textContent;
      const resolved = `${text !== placeholder}`;

      if (this.domNode.dataset.resolved !== resolved) {
        this.domNode.dataset.resolved = resolved;
      }
    }
  }
  UnresolvedPlaceHolder.blotName = 'unresolved';
  UnresolvedPlaceHolder.className = 'ql-placeholder';
  Quill.register(UnresolvedPlaceHolder, true);
}
