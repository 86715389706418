import { styled } from '@mui/material/styles';
import { theme } from 'theme';

export const UncheckedIcon = styled('span')({
  boxShadow: `0 0 0 1px ${theme.palette.shade.grey3}`,
  backgroundColor: theme.palette.backgroundExtension.white,
  borderRadius: '4px',
  margin: '3px',
  width: '18px',
  height: '18px',
});
