import { useMemo } from 'react';
import { useSchemaPrimitives } from 'util/schemas/schemaPrimitives';
import { z } from 'zod';

export function useNewLeadTeamAssignmentSchema() {
  const { requiredString } = useSchemaPrimitives();
  return useMemo(
    () =>
      z.object({
        teamId: requiredString,
      }),
    [requiredString],
  );
}

export type NewLeadTeamAssignmentFormData = z.infer<ReturnType<typeof useNewLeadTeamAssignmentSchema>>;
