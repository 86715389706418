import { ContactWithLastInteraction } from 'api/graphql/hooks/useContact';
import { createContext, useContext } from 'react';
import { useGo3DuplicateContacts } from 'util/useGo3DuplicateContacts';

type ContactContextData = {
  contact: ContactWithLastInteraction | undefined;
  go3DuplicateContacts: ReturnType<typeof useGo3DuplicateContacts>;
};
export const ContactContext = createContext<ContactContextData>({} as ContactContextData);
export function useContactContext() {
  return useContext(ContactContext);
}
