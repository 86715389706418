export type SearchQuery = Record<string, string | number | (string | number)[] | undefined>;

function appendParams(searchParams: URLSearchParams, search: SearchQuery) {
  for (const [key, value] of Object.entries(search)) {
    if (Array.isArray(value)) {
      for (const arrayValue of value) {
        searchParams.append(key, `${arrayValue}`);
      }
    } else if (value !== undefined) {
      searchParams.append(key, `${value}`);
    }
  }
  return searchParams;
}

export function buildSearchParams(search: SearchQuery) {
  const searchParams = new URLSearchParams();
  appendParams(searchParams, search);
  return searchParams;
}

export function omitSearchParams(searchParams: URLSearchParams, keysToOmit: string[]) {
  return new URLSearchParams(Array.from(searchParams.entries()).filter(([key]) => !keysToOmit.includes(key)));
}

export function replaceSearchParams(searchParams: URLSearchParams, search: SearchQuery): URLSearchParams {
  return appendParams(omitSearchParams(searchParams, Object.keys(search)), search);
}

export function searchParamsToObject(searchParams: URLSearchParams) {
  return Array.from(searchParams.entries()).reduce((paramsObj, [key, value]) => {
    if (key in paramsObj) {
      if (!Array.isArray(paramsObj[key])) {
        paramsObj[key] = [paramsObj[key] as string];
      }
      (paramsObj[key] as string[]).push(value);
    } else {
      paramsObj[key] = value;
    }
    return paramsObj;
  }, {} as Record<string, string | string[]>);
}
