import {
  ContactRelationTypeEnum,
  ContactTypeEnum,
  CountryEnum,
  CurrencyEnum,
  HouseholdTypeEnum,
  ProfessionTypeEnum,
  SalutationEnum,
} from 'api/graphql/generated/graphql';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useMemo } from 'react';
import { useTranslation } from 'util/i18next';
import { useSchemaPrimitives } from 'util/schemas/schemaPrimitives';
import { z } from 'zod';
import { useAddressWithTypeSchema } from './addressSchema';
import { useEmailSchema } from './emailSchema';
import { usePhoneSchema } from './phoneSchema';

export function useContactDetailsSchema() {
  const { t } = useTranslation(['contact']);
  const { requiredNumber, requiredBoolean, optionalBoolean, optionalString, optionalStringEmptyAsUndefined } =
    useSchemaPrimitives();
  const { emailSchema, validEmail } = useEmailSchema();
  const { phoneNumber, phoneSchema } = usePhoneSchema();
  const addressSchema = useAddressWithTypeSchema();

  return useMemo(() => {
    const phoneUtil = new PhoneNumberUtil();
    return z
      .object({
        _internals: z
          .object({
            isEditMode: optionalBoolean.nullable(),
            isContactLeadToggleDisabled: optionalBoolean.nullable(),
            redirectToContactOnSuccess: optionalBoolean.nullable(),
            contact: z.object({
              contactQualified: requiredBoolean,
              preferredAddress: requiredNumber,
              preferredEmail: requiredNumber,
              preferredPhone: requiredNumber,
              isServiceContact: requiredBoolean,
              isOnlyContact: requiredBoolean,
              id: optionalString,
              migratedPartnerContact: z.object({
                firstName: optionalString.nullable(),
                lastName: optionalString.nullable(),
              }),
            }),
          })
          .deepPartial(),
        additionalNote: optionalStringEmptyAsUndefined,
        tags: z
          .object({
            value: z.string(),
            displayValue: z.string().optional(),
          })
          .array()
          .optional(),
        renterDetails: z
          .object({
            householdType: z.custom<HouseholdTypeEnum>().optional(),
            professionType: z.custom<ProfessionTypeEnum>().optional(),
            petOwner: optionalBoolean.nullable(),
            incomeRange: z
              .object({
                minimum: z.number().optional(),
                maximum: z.number().optional(),
                _currency: z.custom<CurrencyEnum>(),
              })
              .optional(),
          })
          .optional(),
        addresses: addressSchema.array(),
        birthDate: z
          .date({ invalid_type_error: t('formValidation.invalidDate') })
          .nullable()
          .optional(),
        placeOfBirth: optionalString,
        nationality: z.custom<CountryEnum>().optional(),
        idNumber: optionalString,
        autoCc: requiredBoolean,
        contactRelationships: z
          .object({
            type: z.custom<ContactRelationTypeEnum>().optional(),
            autoCc: requiredBoolean,
            visibleContacts: z
              .object({
                id: z.string(),
                firstName: z.string().nullable().optional(),
                lastName: z.string().nullable().optional(),
              })
              .array()
              .optional()
              .default([]),
            hiddenContacts: z
              .object({
                id: z.string(),
              })
              .array()
              .optional()
              .default([]),
          })
          .array()
          .optional()
          .default([]),
        company: optionalString,
        division: optionalStringEmptyAsUndefined,
        emailAddresses: emailSchema.array().superRefine((values, ctx) => {
          if (values) {
            const duplicateIndex = findDuplicateIndex(values.map((x) => x.email));
            if (duplicateIndex > -1) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: t('contact:formValidation.uniqueEmail'),
                path: [duplicateIndex, 'email'],
              });
            }
          }
        }),
        salutation: z.custom<SalutationEnum>().optional(),
        title: optionalString,
        firstName: optionalString,
        lastName: optionalString,
        formattedSalutation: optionalString,
        isCompany: requiredBoolean,
        phoneNumbers: phoneSchema.array().superRefine((values, ctx) => {
          if (values) {
            const duplicateIndex = findDuplicateIndex(values.map((x) => x.number));
            if (duplicateIndex > -1) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: t('contact:formValidation.uniquePhoneNumber'),
                path: [duplicateIndex, 'number'],
              });
            }
            const invalidPhoneNumberIndex = values.findIndex((x) => {
              if (!x?.number) {
                return false;
              }
              try {
                const parsedNumber = phoneUtil.parse(x?.number);
                return !phoneUtil.isValidNumber(parsedNumber);
              } catch {
                return false;
              }
            });
            if (invalidPhoneNumberIndex > -1) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: t('contact:formValidation.invalidPhoneNumber'),
                path: [invalidPhoneNumberIndex, 'number'],
              });
            }
          }
        }),
        preferredLanguage: optionalString,
        preferredTimes: z.string().array(),
        contactType: z.custom<ContactTypeEnum>().optional(),
      })
      .superRefine((val, ctx) => {
        if (!val.firstName && !val.lastName) {
          ['firstName', 'lastName'].forEach((field) =>
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [field],
              message: t('contact:formValidation.firstNameOrLastRequired'),
            }),
          );
        }
      })
      .superRefine((val, ctx) => {
        if (
          !validEmail.safeParse(val.emailAddresses?.[0]?.email).success &&
          !phoneNumber.safeParse(val.phoneNumbers?.[0]?.number).success
        ) {
          [
            { field: 'emailAddresses', prop: 'email' },
            { field: 'phoneNumbers', prop: 'number' },
          ].forEach((target) =>
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [target.field, 0, target.prop],
              message: t('contact:formValidation.emailOrPhoneRequired'),
            }),
          );
        }
      })
      .refine((val) => !val.isCompany || (val.isCompany && !!val.company), {
        message: t('formValidation.requiredField'),
        path: ['company'],
      })
      .refine(
        (val) => !val._internals.contact?.isServiceContact || (val.contactType && val.contactType !== 'UNDEFINED'),
        {
          message: t('formValidation.requiredField'),
          path: ['contactType'],
        },
      );
  }, [
    requiredBoolean,
    optionalBoolean,
    requiredNumber,
    optionalStringEmptyAsUndefined,
    addressSchema,
    t,
    optionalString,
    emailSchema,
    phoneSchema,
    validEmail,
    phoneNumber,
  ]);
}

function findDuplicateIndex<T>(array: Array<T>) {
  const asSet = new Set<T>();
  let index = 0;
  for (const entry of array) {
    if (asSet.has(entry)) {
      return index;
    }
    index++;
    asSet.add(entry);
  }
  return -1;
}

export type ContactDetailsFormData = z.infer<ReturnType<typeof useContactDetailsSchema>>;
