import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import { ReactNode } from 'react';
import { useCollapsibleSection } from './SectionContext';

export function SectionContent({ children, sx }: { children: ReactNode; sx?: SxProps }) {
  const { expanded } = useCollapsibleSection();

  return <Box sx={{ paddingTop: 3, paddingBottom: 3, display: expanded ? 'block' : 'none', ...sx }}>{children}</Box>;
}
