import { Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { Address } from 'api/graphql/generated/graphql';
import { AddressSearch } from 'components/general/AddressSearch/AddressSearch';
import { FormSection, FormSectionHeader } from 'components/general/Form/FormSection';
import { FormStack } from 'components/general/Form/FormStack';
import LocationIcon from 'components/icons/location_2.svg?react';
import { PropertyMap } from 'components/leads/PropertyMap';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { NewLeadWithContactFormData } from 'util/schemas/newLeadSchema';

const isNonEmpty = (field?: string | null): boolean => !!field && field.replace(/\s/g, '').length > 0;

const addressIsComplete = (address?: Address): boolean =>
  isNonEmpty(address?.countryCode) &&
  address?.countryCode !== 'UNDEFINED' &&
  isNonEmpty(address?.city) &&
  isNonEmpty(address?.postalCode) &&
  isNonEmpty(address?.streetName) &&
  isNonEmpty(address?.streetNumber);

export const SpecificAddressSection = ({ isCreateNewLead }: { isCreateNewLead: boolean }) => {
  const { t } = useTranslation(['lead', 'enums']);
  const { control, watch, setValue, formState } = useFormContext<NewLeadWithContactFormData>();
  const { errors } = formState;

  const watchPropertyAddress = watch('property.propertyAddress');
  const hasSameAddress = watch('property.isClientAddress');
  const firstAddress = watch('addresses')?.[0];

  const contactAddress = useMemo(() => {
    if (isCreateNewLead) {
      return firstAddress;
    } else if ('property' in control._defaultValues) {
      return control._defaultValues.property?._internals?.contactAddress;
    }

    return undefined;
  }, [isCreateNewLead, control._defaultValues, firstAddress]);

  // TODO: Proper typing of address requires changes in schema.
  const isContactAddressComplete = addressIsComplete(contactAddress as Address);

  const resetManualCoordinates = () => {
    setValue('property._internals.latitude', undefined);
    setValue('property._internals.longitude', undefined);
  };

  return (
    <FormSection>
      <FormSectionHeader>{t('lead:propertyEvaluateForm.details.address')}</FormSectionHeader>
      {
        <Controller
          name="property.propertyAddress"
          control={control}
          render={({ field }) => (
            <>
              <FormStack sx={{ flexWrap: 'wrap' }} icon={<LocationIcon />}>
                <AddressSearch
                  sx={{ flex: 1 }}
                  label={t('lead:propertyEvaluateForm.details.address')}
                  suggestionOptions={{
                    types: ['geocode'],
                  }}
                  onChange={(address) => {
                    field.onChange(address);
                    resetManualCoordinates();
                  }}
                  disabled={hasSameAddress}
                  defaultAddress={field.value}
                  hasError={'property' in errors && !!errors.property?.propertyAddress}
                  errorMessage={'property' in errors ? errors.property?.propertyAddress?.message : undefined}
                  textFieldProps={{
                    'data-loggingid': 'propertySelect',
                  }}
                />
              </FormStack>
              <PropertyMap
                sx={{ ml: 5, width: 'auto' }}
                propertyAddress={hasSameAddress ? contactAddress : watchPropertyAddress ?? undefined}
                placeId={
                  hasSameAddress
                    ? !!contactAddress && 'placeId' in contactAddress && typeof contactAddress.placeId === 'string'
                      ? contactAddress?.placeId
                      : undefined
                    : watchPropertyAddress?.placeId
                }
                editable={!!field.value && !hasSameAddress}
              />
            </>
          )}
        />
      }
      <FormStack>
        <Tooltip
          arrow
          title={isContactAddressComplete ? '' : t('lead:propertyEvaluateForm.details.isClientAddress.tooltip')}
        >
          <Controller
            name="property.isClientAddress"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                value={value}
                control={
                  <Switch
                    disabled={!isContactAddressComplete}
                    checked={isContactAddressComplete && hasSameAddress}
                    color="secondary"
                    onChange={(e) => {
                      onChange(e.target.checked);
                    }}
                  />
                }
                label={t('lead:propertyEvaluateForm.details.isClientAddress')}
              />
            )}
          />
        </Tooltip>
      </FormStack>
    </FormSection>
  );
};
