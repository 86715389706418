import {
  DashboardActivity,
  PriorityEnum,
  TaskAppointmentStatusEnum,
  TaskTypeEnum,
} from 'api/graphql/generated/graphql';
import { useMemo } from 'react';
import { ListingWithLegacyFields } from 'util/go3';
import { useTranslation } from 'util/i18next';
import { useLeadAndContactSearchOptionSchema } from 'util/schemas/contactAndLeadSchema';
import { useSchemaPrimitives } from 'util/schemas/schemaPrimitives';
import { z } from 'zod';

export function useCreateNewTaskSchema() {
  const { t } = useTranslation(['user']);
  const { optionalString, requiredString, optionalNumber } = useSchemaPrimitives();
  const contactAndLead = useLeadAndContactSearchOptionSchema();

  return useMemo(() => {
    return z
      .object({
        agentId: requiredString,
        teamId: requiredString,
        shopId: requiredString,
        actionType: z.custom<TaskTypeEnum>((value) => !!value, t('formValidation.requiredField')),
        status: z.enum(['OPEN', 'COMPLETED', 'CANCELLED'] as const satisfies readonly TaskAppointmentStatusEnum[]),
        priority: z.enum(['LOW', 'MEDIUM', 'HIGH'] as const satisfies readonly PriorityEnum[]),
        title: requiredString,
        date: z.date({ invalid_type_error: t('formValidation.invalidDate') }),
        time: z.date({ invalid_type_error: t('formValidation.invalidDate') }),
        comment: optionalString,
        feedback: optionalString,
        contactsAndLeads: contactAndLead.array().min(1, t('user:formValidation.activity.atLeastOneContact')),
        properties: z.custom<ListingWithLegacyFields>().array().optional(),
        go3TaskKey: optionalNumber,
        additionalAgents: z
          .object({
            type: z.literal('AGENT'),
            id: requiredString,
            firstName: optionalString,
            lastName: optionalString,
            email: optionalString,
          })
          .array()
          .optional(),
        _editTaskActivity: z.custom<DashboardActivity>().optional(),
      })
      .refine((values) => !values.additionalAgents?.find(({ id }) => id === values.agentId), {
        message: t('formValidation.duplicateAgentAssignment'),
        path: ['additionalAgents'],
      });
  }, [optionalNumber, optionalString, requiredString, contactAndLead, t]);
}

export type CreateNewTaskFormData = z.infer<ReturnType<typeof useCreateNewTaskSchema>>;
