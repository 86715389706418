import { useMemo } from 'react';
import { useSchemaPrimitives } from 'util/schemas/schemaPrimitives';
import { z } from 'zod';

export function usePhoneSchema() {
  const { optionalString, requiredBoolean } = useSchemaPrimitives();
  return useMemo(() => {
    const phoneNumber = z.string().trim().min(5);
    const phoneSchema = z
      .object({
        number: z.union([z.literal(''), phoneNumber]).optional(),
        phoneType: optionalString,
        preferred: requiredBoolean,
      })
      .passthrough(); // don't remove extra keys for diffing;

    return { phoneNumber, phoneSchema };
  }, [optionalString, requiredBoolean]);
}

export type PhoneDetailsFormData = z.infer<ReturnType<typeof usePhoneSchema>['phoneSchema']>;
