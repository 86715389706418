import { Contact, LanguageEnum } from 'api/graphql/generated/graphql';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import i18n, { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { isSet } from 'util/filters';
import { useTranslation } from 'util/i18next';

export const PREFERRED_LANGUAGE_MAP = new Map<string, string>([
  ['EN', 'en'],
  ['EN_AU', 'en'],
  ['EN_HK', 'en'],
  ['DE', 'de'],
  ['DE_AT', 'de-at'],
  ['DE_CH', 'de-ch'],
  ['EL', 'el'],
  ['ES', 'es'],
  ['ES_AR', 'es'],
  ['FR', 'fr'],
  ['FR_BE', 'fr'],
  ['IT', 'it'],
  ['PT', 'pt'],
  ['HU', 'hu'],
  ['CA', 'ca'],
  ['HR', 'hr'],
  ['NL', 'nl'],
]);

type ContactLike = Pick<
  Contact,
  'formattedSalutation' | 'salutation' | 'title' | 'firstName' | 'lastName' | 'preferredLanguage'
>;

export function useFormattedSalutation({
  salutation,
  title,
  firstName,
  lastName,
  formattedSalutation,
  preferredLanguage,
}: ContactLike) {
  const { t } = useTranslation(['contact']);
  const [contactFormattedSalutation, setContactFormattedSalutation] = useState('');
  const { activeShopSettings } = useActiveShop();
  const shopPreferredLanguage = activeShopSettings?.defaultPreferredLanguage;

  useEffect(() => {
    getFormattedSalutation(
      { salutation, title, firstName, lastName, formattedSalutation, preferredLanguage },
      t,
      shopPreferredLanguage,
    ).then(setContactFormattedSalutation);
  }, [t, shopPreferredLanguage, formattedSalutation, salutation, title, firstName, lastName, preferredLanguage]);
  return contactFormattedSalutation;
}

export async function getFormattedSalutation(
  { salutation, title, firstName, lastName, formattedSalutation, preferredLanguage: contactLanguage }: ContactLike,
  t: TFunction<['common']>,
  shopLanguage: LanguageEnum | undefined | null,
) {
  if (formattedSalutation) {
    return formattedSalutation;
  }

  const lokaliseLang =
    PREFERRED_LANGUAGE_MAP.get(contactLanguage || shopLanguage || 'EN') ||
    PREFERRED_LANGUAGE_MAP.get(shopLanguage || 'EN') ||
    'en';
  await i18n.loadLanguages(lokaliseLang);

  const withGender = salutation === 'MR' || salutation === 'MRS';
  const name = (withGender ? lastName : [firstName, lastName].filter(isSet).join(' ')) || '';

  if (salutation === 'MR') {
    return title
      ? t('salutationMrWithTitle', { name, title, lng: lokaliseLang })
      : t('salutationMr', { name, lng: lokaliseLang });
  }
  if (salutation === 'MRS') {
    return title
      ? t('salutationMrsWithTitle', { name, title, lng: lokaliseLang })
      : t('salutationMrs', { name, lng: lokaliseLang });
  }

  return title
    ? t('salutationNeutralWithTitle', { name, title, lng: lokaliseLang })
    : t('salutationNeutral', { name, lng: lokaliseLang });
}
