export class FetchError extends Error {
  constructor(
    message: string,
    public response: {
      body?: Record<string, unknown>;
      text?: string;
      status: number;
      statusText: string;
      headers: Headers;
    },
    public request: {
      url: string;
      method: string;
      body?: Record<string, unknown>;
    },
  ) {
    super(message);
  }
}

export async function createFetchError(message: string, response: Response, request: Request) {
  let responseBody;
  let responseText;
  try {
    if (response.headers.get('content-type')?.includes('json')) {
      responseBody = await response.json();
    } else {
      responseText = await response.text();
    }
  } catch (e: unknown) {
    // eslint-disable-next-line no-console
    console.error('Parsing error response failed', e);
  }

  let requestBody;
  try {
    if (request.method !== 'GET' && request.method !== 'HEAD') {
      requestBody = await request.json();
    }
  } catch (e: unknown) {
    // eslint-disable-next-line no-console
    console.error('Parsing error request failed', e);
  }

  return new FetchError(
    message,
    {
      body: responseBody,
      text: responseText,
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
    },
    {
      url: request.url,
      method: request.method,
      body: requestBody,
    },
  );
}
