import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import { useCustomDemandFields } from 'api/graphql/hooks/useCustomDemandFields';
import { EVCheckbox } from 'components/general/EVCheckbox/EVCheckbox';
import { EVDrawerCollapsible } from 'components/general/EVDrawer/EVDrawerCollapsible';
import { FormStack } from 'components/general/Form/FormStack';
import StarIcon from 'components/icons/star_outlined.svg?react';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { NewLeadWithContactFormData } from 'util/schemas/newLeadSchema';
import { useLeadDetailUtils } from 'util/useLeadDetailUtils';
import { CdfPicker } from '../CdfPicker/CdfPicker';

export const FeaturesSection = () => {
  const { t } = useTranslation(['lead', 'enums']);
  const { activeShop, shopIdsInActiveGroup } = useActiveShop();
  const { collapseFeatureValues } = useLeadDetailUtils();
  const { mapCustomDemandLabels } = useCustomDemandFields({
    where: { shopId: { _in: shopIdsInActiveGroup } },
  });
  const { control, watch } = useFormContext<NewLeadWithContactFormData>();

  const [balcony, garden, parking, swimmingPool, guestToilet, elevator, garage, cdfs] = watch([
    'property.balcony',
    'property.garden',
    'property.parking',
    'property.swimmingPool',
    'property.guestToilet',
    'property.elevator',
    'property.garage',
    'property.cdfs',
  ]);

  const featuresCollapsedValues = collapseFeatureValues({
    balcony,
    garden,
    parking,
    swimmingPool,
    guestToilet,
    elevator,
    garage,
    cdfs: mapCustomDemandLabels(cdfs),
  });

  return (
    <EVDrawerCollapsible
      icon={<StarIcon />}
      title={t('lead:form.optionalSection.features.header')}
      collapsedValues={featuresCollapsedValues}
    >
      <FormStack>
        <Stack gap={2}>
          <Controller
            name={'property.balcony'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<EVCheckbox checked={value} onChange={onChange} />}
                label={t('lead:leadSummary.propertyDetails.features.balcony')}
              />
            )}
          />
          <Controller
            name={'property.garden'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<EVCheckbox checked={value} onChange={onChange} />}
                label={t('lead:leadSummary.propertyDetails.features.garden')}
              />
            )}
          />
          <Controller
            name={'property.parking'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<EVCheckbox checked={value} onChange={onChange} />}
                label={t('lead:leadSummary.propertyDetails.features.parking')}
              />
            )}
          />
          <Controller
            name={'property.swimmingPool'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<EVCheckbox checked={value} onChange={onChange} />}
                label={t('lead:leadSummary.propertyDetails.features.swimmingPool')}
              />
            )}
          />
          <Controller
            name={'property.guestToilet'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<EVCheckbox checked={value} onChange={onChange} />}
                label={t('lead:leadSummary.propertyDetails.features.guestToilet')}
              />
            )}
          />
          <Controller
            name={'property.elevator'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<EVCheckbox checked={value} onChange={onChange} />}
                label={t('lead:leadSummary.propertyDetails.features.elevator')}
              />
            )}
          />
          <Controller
            name={'property.garage'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<EVCheckbox checked={value} onChange={onChange} />}
                label={t('lead:leadSummary.propertyDetails.features.garage')}
              />
            )}
          />
        </Stack>
      </FormStack>
      {activeShop?.onboardingLevel === 2 && (
        <FormStack>
          <Controller
            name={'property.cdfs'}
            control={control}
            render={({ field: { ref, ...field } }) => <CdfPicker {...field} />}
          />
        </FormStack>
      )}
    </EVDrawerCollapsible>
  );
};
