import { useSnackBars } from '@ev/eva-container-api';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Lead } from 'api/graphql/generated/graphql';
import { useDeLinkEmailToLead, useLinkEmailToLead } from 'api/rest';
import { Action, ActionsModal } from 'components/general/ActionsModal/ActionsModal';
import { IntentTag } from 'components/general/IntentTag/IntentTag';
import DeLinkChainIcon from 'components/icons/delink_chain.svg?react';
import LinkChainIcon from 'components/icons/link.svg?react';
import LinkIcon from 'components/icons/link_external.svg?react';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { intentTypeToIntentTabs } from 'util/intentType';
import { useDestination } from 'util/navigation/useDestination';
import { leadString } from 'util/stringUtils';
import { useCreateShortLeadSummary } from 'util/summaries/leadSummary';
import { useErrorSnackBar } from 'util/useErrorSnackBar';

export function LinkingLeadCard({ lead, messageId, threadId }: { lead: Lead; messageId: string; threadId: string }) {
  const { t } = useTranslation(['communication', 'enums', 'errors']);
  const { openSnackBar } = useSnackBars();
  const { openErrorSnackBar } = useErrorSnackBar();
  const [showDeLinkLeadConfirmationModal, setShowDeLinkLeadConfirmationModal] = useState(false);

  const leadSummary = useCreateShortLeadSummary(lead);
  const selectedThreadId = threadId;
  const isLeadLinked = !!lead.emailLinks.find(({ threadId }) => threadId === selectedThreadId);

  const { linkEmailToLead, isLoading: isLinkingLead } = useLinkEmailToLead();
  const { deLinkEmailToLead } = useDeLinkEmailToLead();

  const handleOnDeLinkEmailToLeadClick = () => {
    deLinkEmailToLead(
      {
        leadId: lead.id,
        threadId: selectedThreadId,
      },
      {
        onSuccess: () => {
          setShowDeLinkLeadConfirmationModal(false);
          openSnackBar(t('communication:mails.leadDeLinkedSuccessfully'), 'success');
        },
        onError: (error) => {
          openErrorSnackBar(t('communication:mails.leadDeLinkingFailed'), error);
        },
      },
    );
  };

  const handleLinkEmailToLeadClick = () => {
    linkEmailToLead(
      {
        messageId: messageId,
        leadId: lead.id,
      },
      {
        onSuccess: () => {
          openSnackBar(t('communication:mails.leadLinkedSuccessfully'), 'success');
        },
        onError: (error) => {
          openErrorSnackBar(t('communication:mails.leadLinkingFailed'), error);
        },
      },
    );
  };

  const { toLeadDetails } = useDestination();

  return (
    <>
      <Card variant="outlined" sx={{ margin: theme.spacing(1.5) }}>
        <CardHeader
          avatar={
            <IntentTag
              intent={intentTypeToIntentTabs(lead.intentType ?? 'UNDEFINED')}
              compact={true}
              sx={{ margin: 0, fontSize: '14px', width: '28px' }}
            />
          }
          title={
            <Stack direction="row" alignItems="center">
              <Typography variant="h3" component={'span'}>
                {leadString(lead, t)}
              </Typography>
              <Link
                to={toLeadDetails({
                  contactId: lead.contactId,
                  leadId: lead.id,
                })}
                target="_blank"
              >
                <IconButton color="secondary">
                  <LinkIcon />
                </IconButton>
              </Link>
            </Stack>
          }
        />
        <CardContent sx={{ paddingTop: '0px' }}>
          <Typography variant="body3" color="text.secondary">
            {leadSummary}
          </Typography>
        </CardContent>
        <Divider variant="middle" sx={{ marginTop: theme.spacing(1) }} />
        <CardActions sx={{ justifyContent: 'center' }}>
          {!isLeadLinked && (
            <Button
              disabled={isLinkingLead}
              variant="contained"
              color="secondary"
              startIcon={<LinkChainIcon />}
              onClick={handleLinkEmailToLeadClick}
            >
              {t('communication:mails.linkLead')}
            </Button>
          )}
          {isLeadLinked && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<DeLinkChainIcon />}
              onClick={() => setShowDeLinkLeadConfirmationModal(true)}
            >
              {t('communication:mails.deLinkLead')}
            </Button>
          )}
        </CardActions>
      </Card>
      <DeLinkLeadConfirmationModal
        open={showDeLinkLeadConfirmationModal}
        onSubmit={handleOnDeLinkEmailToLeadClick}
        onClose={() => setShowDeLinkLeadConfirmationModal(false)}
        lead={lead}
      />
    </>
  );
}

const DeLinkLeadConfirmationModal = ({
  open,
  onSubmit,
  onClose,
  lead,
}: {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  lead: Lead;
}) => {
  const { t } = useTranslation(['communication']);
  const deleteAction = {
    message: t('proceed'),
    variant: 'contained',
    color: 'primary',
    handleClick: onSubmit,
  } as Action;
  const cancelAction = {
    message: t('cancel'),
    variant: 'outlined',
    color: 'secondary',
    handleClick: onClose,
  } as Action;
  return (
    <ActionsModal
      open={open}
      handleClose={onClose}
      title={t('communication:mails.deLinkLeadModalTitle')}
      description={
        <Trans t={t} i18nKey="communication:mails.deLinkLeadModalContent" values={{ leadLabel: leadString(lead, t) }} />
      }
      actions={[cancelAction, deleteAction]}
    />
  );
};
