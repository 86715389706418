import { useCallback, useEffect, useMemo, useState } from 'react';

declare global {
  interface WindowEventMap {
    'local-storage': CustomEvent;
  }
}

export function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    return undefined;
  }
}

export function readValue<T>(key: string): T | undefined {
  const item = window.localStorage.getItem(key);
  return item ? (parseJSON(item) as T) : undefined;
}

function useLocalStorage<T>(key: string, defaultValue: T): [T, (value: T) => void] {
  // Note: This trigger is used to force the useMemo to re-run when the value is updated
  const [readerTrigger, setReaderTrigger] = useState(0);
  // Note: useMemo is used to prevent the function from being recreated on every render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const storedValue = useMemo(() => readValue<T>(key), [key, readerTrigger]);

  const setValue = useCallback(
    (value: T) => {
      window.localStorage.setItem(key, JSON.stringify(value));
      window.dispatchEvent(new Event('local-storage'));
    },
    [key],
  );

  const handleStorageChange = useCallback(
    (event: StorageEvent | CustomEvent) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return;
      }
      // Note: this is triggering the value change that will be read by the useMemo
      setReaderTrigger((prev) => prev + 1);
    },
    [key],
  );

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [handleStorageChange]);

  useEffect(() => {
    window.addEventListener('local-storage', handleStorageChange);
    return () => window.removeEventListener('local-storage', handleStorageChange);
  }, [handleStorageChange]);

  return [storedValue ?? defaultValue, setValue];
}

export default useLocalStorage;
