import { setupKeycloak } from '@ev/eva-container-api';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'api/queryClient';
import { KeycloakProvider } from 'components/state/KeycloakProvider';
import { SnackBarProvider } from 'components/state/SnackbarProvider';
import { UIStateProvider } from 'components/state/UIStateProvider';
import { env } from 'env';
import { FeatureFlagProvider } from 'eva-frame/EvaProviders/FeatureFlagProvider';
import { LokalizationProvider } from 'eva-frame/EvaProviders/LokalizationProvider';
import { RootErrorBoundary } from 'eva-frame/EvaProviders/RootErrorBoundary';
import { EvaRouter } from 'eva-frame/EvaRouter';
import { HelmetProvider } from 'react-helmet-async';
import { theme } from 'theme';

const keycloak = setupKeycloak({
  url: env.VITE_KEYCLOAK_URL,
  realm: env.VITE_KEYCLOAK_REALM,
  clientId: env.VITE_KEYCLOAK_CLIENT_ID,
});

export function EvaFrame() {
  return (
    <LokalizationProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RootErrorBoundary>
          <KeycloakProvider keycloak={keycloak}>
            <SnackBarProvider>
              <QueryClientProvider client={queryClient}>
                <FeatureFlagProvider>
                  <HelmetProvider>
                    <UIStateProvider>
                      <EvaRouter />
                    </UIStateProvider>
                  </HelmetProvider>
                </FeatureFlagProvider>
              </QueryClientProvider>
            </SnackBarProvider>
          </KeycloakProvider>
        </RootErrorBoundary>
      </ThemeProvider>
    </LokalizationProvider>
  );
}
