import { HomePageRedirect } from 'page-components/HomePageRedirect';
import { Page, useEnabledPages } from 'util/useEnabledPages';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withEnabledPage<T extends Record<string, any> = Record<string, never>>(
  page: Page,
  PageComponent: React.ComponentType<T>,
) {
  return function WrappedPage(props: T) {
    const isPageEnabled = useEnabledPages();

    if (!isPageEnabled(page)) {
      return <HomePageRedirect />;
    }

    return <PageComponent {...props} />;
  };
}
