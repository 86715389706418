import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { useContact } from 'api/graphql/hooks/useContact';
import { CallDuration } from 'components/calls/CallDuration/CallDuration';
import { useCallUiVariant } from 'components/calls/CallFrame';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import genericAvatarSrc from 'components/icons/avatar.svg?url';
import LinkIcon from 'components/icons/link_external.svg?react';
import AddUserIcon from 'components/icons/user_added.svg?react';
import { CallerInfo } from 'components/state/Twilio';
import { phoneNumberTypeTranslation } from 'const/enumTranslations';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { useDestination } from 'util/navigation/useDestination';
import { fullName } from 'util/stringUtils';

export function CallInfo({
  title,
  sx,
  callerInfo,
  isActive,
  callDuration,
  showAddContact,
  onAddContact,
}: {
  title?: string;
  sx?: SxProps;
  isActive: boolean;
  callDuration?: number;
  callerInfo: CallerInfo;
  showAddContact?: boolean;
  onAddContact?: () => void;
}) {
  const variant = useCallUiVariant();
  const primaryColor = variant === 'dark' ? theme.palette.textExtension.white : theme.palette.text.primary;
  const secondaryColor = variant === 'dark' ? theme.palette.shade.grey4 : theme.palette.text.secondary;
  const { t } = useTranslation(['communication', 'enums']);

  const contactId = callerInfo.activity ? callerInfo.activity.contactId : callerInfo.call.callContactId;

  const { contact } = useContact({ id: contactId }, { enabled: !!contactId });
  const numberType = contact?.phoneNumbers.find(
    (phoneNumber) => phoneNumber.internationalNumber === callerInfo.call.callerNumber,
  )?.type;

  const displayedNumber =
    numberType && numberType !== 'UNDEFINED'
      ? `${t(phoneNumberTypeTranslation[numberType])} ${callerInfo.call.callerNumber}`
      : callerInfo.call.callerNumber;

  const { toContactDetails } = useDestination();

  return (
    <Stack alignItems="center" sx={sx} gap={1}>
      {title && (
        <Typography variant="body3" color={secondaryColor}>
          {title}
        </Typography>
      )}
      {contact?.firstName || contact?.lastName ? (
        <EVAvatar size="l" person={contact} />
      ) : (
        <EVAvatar size="l" pictureSrc={genericAvatarSrc} />
      )}
      <Stack alignItems="center" direction="row">
        <Typography variant="body1" color={primaryColor}>
          {fullName(contact || undefined, t('communication:call.activeCall.unknownUser'))}
        </Typography>
        {showAddContact && (
          <IconButton onClick={onAddContact} edge="end">
            <AddUserIcon color={primaryColor} />
          </IconButton>
        )}
        {contact && (
          <Link
            to={toContactDetails({
              shopId: contact.shopId,
              contactId: contact.id,
            })}
          >
            <IconButton edge="end">
              <LinkIcon color={primaryColor} />
            </IconButton>
          </Link>
        )}
      </Stack>
      <Typography
        variant="body4"
        color={secondaryColor}
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {displayedNumber}
      </Typography>
      {callDuration ? <CallDuration isActive={isActive} callDuration={callDuration} /> : null}
    </Stack>
  );
}
