import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import { useEffect, useState } from 'react';
import { EditControl } from 'react-leaflet-draw';

import EditButtons from 'components/map/LeafletMap/Editor/EditButtons';
import { DrawOptions, PathOptions, drawLocal } from 'leaflet';
import { useTranslation } from 'util/i18next';
import './leaflet-polygon.css';

interface PolygonEditPanelProps {
  handleSave: () => void;
  handleCreate: () => void;
  handlePopupClose: () => void;
  resetState: () => void;
  turnOffEditMode: () => void;
  shapeOptions?: PathOptions;
  isDirty: boolean;
  setIsDirty: (isChanged: boolean) => void;
}

const PolygonEditPanel = ({
  handleSave,
  resetState,
  handleCreate,
  handlePopupClose,
  shapeOptions,
  isDirty,
  setIsDirty,
}: PolygonEditPanelProps) => {
  const { t } = useTranslation();
  const [isDrawing, setIsDrawing] = useState(false);
  const handleChange = () => {
    setIsDirty(true);
  };

  useEffect(() => {
    drawLocal.draw.handlers.polygon.tooltip = {
      start: t('map.help.draw.start'),
      cont: t('map.help.draw.continue'),
      end: t('map.help.draw.end'),
    };
  }, [t]);

  const drawOptions = {
    rectangle: false,
    circle: false,
    polyline: false,
    polygon: {
      allowIntersection: false,
      pathOptions: shapeOptions,
    } as DrawOptions.PolygonOptions,
    marker: false,
    circlemarker: false,
  };

  return (
    <>
      <EditControl
        position="bottomleft"
        onEdited={handleChange}
        onCreated={handleChange}
        onDeleted={handleChange}
        onDrawStart={() => setIsDrawing(true)}
        onDrawStop={() => {
          setIsDrawing(false);
        }}
        onEditVertex={() => {
          setIsDirty(true);
          handlePopupClose();
        }}
        onDrawVertex={() => setIsDirty(true)}
        onEditStop={() => {
          handlePopupClose();
        }}
        draw={drawOptions}
      />
      <EditButtons
        buttons={{
          back: {
            disabled: isDrawing || isDirty,
            onClick: () => {
              resetState();
            },
          },
          add: {
            disabled: isDrawing,
            onClick: handleCreate,
          },
          save: {
            disabled: isDrawing || !isDirty,
            onClick: () => {
              handleSave();
              setIsDirty(false);
            },
          },
        }}
      />
    </>
  );
};

export default PolygonEditPanel;
