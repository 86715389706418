import { MODAL_Z_INDEX } from '@ev/eva-container-api';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { Shop } from 'api/graphql/generated/graphql';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import CaretIcon from 'components/icons/arrow_1_down.svg?react';
import ShopIcon from 'components/icons/shop_naked.svg?react';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import React, { useState } from 'react';

function ShopItem({ shop }: { shop: Shop }) {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <EVAvatar shop={shop} size="s" />
      <Typography variant="body3" color="secondary">
        {shop.shopName}
      </Typography>
    </Stack>
  );
}

export function ShopSelector({
  showCaret,
  selectedShop,
  onShopSelected,
  sx,
  availableShops,
}: {
  showCaret?: boolean;
  selectedShop: Shop | undefined;
  onShopSelected: (shop: Shop) => void;
  sx?: SxProps;
  availableShops?: Shop[];
}) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { allShops } = useActiveShop();

  const openModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeModal = () => {
    setAnchorEl(null);
  };

  const shops = availableShops ? availableShops : allShops;

  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <Button
        startIcon={<ShopIcon />}
        onClick={openModal}
        color="secondary"
        sx={sx}
        endIcon={showCaret ? <CaretIcon /> : undefined}
      >
        <Typography
          variant="body4"
          sx={{
            width: '100%',
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          component="div"
        >
          {selectedShop?.shopName ?? ''}
        </Typography>
      </Button>
      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        disablePortal
        sx={{
          zIndex: MODAL_Z_INDEX,
          transformOrigin: 'center bottom',
          minWidth: '100%',
        }}
      >
        <ClickAwayListener onClickAway={closeModal}>
          <Paper elevation={3} sx={{ maxHeight: '300px', overflow: 'auto' }}>
            <MenuList>
              {shops.map((shop) => (
                <MenuItem
                  key={shop.id}
                  onClick={() => {
                    onShopSelected(shop);
                    closeModal();
                  }}
                >
                  <ShopItem shop={shop} />
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}
