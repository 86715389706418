import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { SxProps } from '@mui/material/styles';
import { AsyncAutocomplete } from 'components/general/AsyncAutocomplete';
import { Option } from 'components/general/OptionsList/OptionsList';
import { HTMLAttributes, ReactNode, forwardRef, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'util/useDebounce';

interface SingleAutocompleteProps<T> {
  label: ReactNode;
  disabled?: boolean;
  onChange: (value?: T) => void;
  onBlur?: () => void;
  noOptionsPlaceholder: string;
  options: Option[];
  value?: T;
  errorMessage?: string;
  hasError?: boolean;
  required?: boolean;
  onSearchStringChanged?: (text?: string) => void;
  renderOption?: (props: HTMLAttributes<HTMLLIElement>, option: Option) => ReactNode;
  sx?: SxProps;
}

export const SingleAutocomplete = forwardRef<unknown, SingleAutocompleteProps<string>>(function SingleAutocomplete(
  {
    sx,
    disabled,
    label,
    onChange,
    onBlur: propsOnBlur,
    renderOption: propsRenderOption,
    errorMessage,
    hasError,
    value,
    options,
    required,
    noOptionsPlaceholder,
    onSearchStringChanged,
  },
  ref,
) {
  const [selectedOption, setSelectedOption] = useState<Option | undefined>();

  useEffect(() => {
    setSelectedOption(options.find((option) => option.value == value));
  }, [options, value]);
  const [searchString, setSearchString] = useState<string>('');
  const [displayedSearchString, setDisplayedSearchString] = useState<string>('');
  const debounce = useDebounce();

  const displayedOptions = useMemo(
    () =>
      options.filter(
        (opt) =>
          String(opt.label).toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) || opt.value === 'UNDEFINED',
      ),
    [options, searchString],
  );

  const _onSearchStringChanged = (search: string) => {
    setDisplayedSearchString(search);
    debounce(() => {
      setSearchString(search);
      onSearchStringChanged?.(search);
    });
  };
  const onSelectedOptionChanged = (selected: Option | undefined) => {
    setSelectedOption(selected);
    onChange(selected && selected.value);
  };

  const onBlur = () => {
    propsOnBlur?.();
  };

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: Option) => {
    if (propsRenderOption) {
      return propsRenderOption(props, option);
    } else {
      return (() => (
        <MenuItem {...props} key={option.value}>
          <Typography variant="body4">{option.label}</Typography>
        </MenuItem>
      ))();
    }
  };

  return (
    <AsyncAutocomplete
      sx={sx}
      label={label}
      disabled={disabled}
      selectedOption={selectedOption}
      options={displayedOptions}
      onSelectedOptionChanged={onSelectedOptionChanged}
      searchString={displayedSearchString}
      onSearchStringChanged={_onSearchStringChanged}
      getSearchTextForOption={(option) => (option ? (option.label as string) : '')}
      noOptionsPlaceholder={noOptionsPlaceholder}
      loading={false}
      hasError={hasError}
      errorMessage={errorMessage}
      onBlur={onBlur}
      textFieldRef={ref}
      filterSelectedOptions={false}
      disableClearable={false}
      preserveDefaultValue
      renderOption={renderOption}
      required={required}
      textFieldProps={{
        title: String((selectedOption && (selectedOption.label as string)) || ''),
      }}
    />
  );
});
