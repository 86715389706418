import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

type CollapsibleSectionContextData = {
  collapsible: boolean;
  expanded: boolean;
  toggleExpanded: () => void;
};

export const CollapsibleSectionContext = createContext<CollapsibleSectionContextData>(
  {} as CollapsibleSectionContextData,
);

export function CollapsibleSectionContextProvider({
  collapsible,
  children,
}: {
  collapsible: boolean;
  children?: ReactNode;
}) {
  const [expanded, setExpanded] = useState(true);
  const contextValue = useMemo(
    () => ({ collapsible, expanded, toggleExpanded: () => setExpanded((e) => !e) }),
    [collapsible, expanded],
  );

  return <CollapsibleSectionContext.Provider value={contextValue}>{children}</CollapsibleSectionContext.Provider>;
}

export function useCollapsibleSection() {
  return useContext(CollapsibleSectionContext);
}
