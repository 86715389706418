import { getKeycloakToken } from '@ev/eva-container-api';
import { env } from 'env';
import { GraphQLClient } from 'graphql-request';
import sentry from 'util/sentry';

type RequestInit = ConstructorParameters<typeof GraphQLClient>[1];

const graphqlClientConfig: RequestInit = {
  requestMiddleware: async (request) => {
    const accessToken = await getKeycloakToken();
    return {
      ...request,
      headers: { ...request.headers, Authorization: accessToken ? `Bearer ${accessToken}` : '' },
    };
  },
  responseMiddleware: (response) => {
    if (response instanceof Error) {
      sentry.logError(response);
    }
  },
};

export const graphqlClient = new GraphQLClient(env.VITE_HASURA_URL, graphqlClientConfig);

export const secondaryGraphqlClient = new GraphQLClient(
  env.VITE_SECONDARY_HASURA_URL || env.VITE_HASURA_URL,
  graphqlClientConfig,
);
