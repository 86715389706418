import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps, styled } from '@mui/material/styles';
import { Label } from 'components/general/Label/Label';
import { ReactNode } from 'react';
import { theme } from 'theme';
import { EMPTY_DATA_STRING } from 'util/emptyDataUtils';

export function FieldListItem({
  label,
  text,
  startAlignLabel = false,
  children,
  sx,
  sxLabel,
  allowLabelWrap = false,
}: {
  label: string;
  text?: ReactNode;
  startAlignLabel?: boolean;
  children?: ReactNode;
  sx?: SxProps;
  sxLabel?: SxProps;
  allowLabelWrap?: boolean;
}) {
  return (
    <>
      <Label
        sx={{
          alignSelf: startAlignLabel ? 'start' : undefined,
          whiteSpace: allowLabelWrap ? 'normal' : 'nowrap',
          ...sxLabel,
        }}
      >
        {label}
      </Label>

      {text ? (
        <Typography variant="body3" sx={{ ...sx, paddingTop: '1px', paddingBottom: '1px', wordBreak: 'break-word' }}>
          {text}
        </Typography>
      ) : children ? (
        <Box sx={{ width: '100%', overflow: 'hidden', ...sx }}>{children}</Box>
      ) : (
        <Typography variant="body3" sx={{ ...sx, paddingTop: '1px', paddingBottom: '1px', wordBreak: 'break-word' }}>
          {EMPTY_DATA_STRING}
        </Typography>
      )}
    </>
  );
}

export const FieldList = styled('div')({
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '4fr 6fr',
  gap: theme.spacing(1),
});
