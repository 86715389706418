import { ButtonBase } from '@mui/material';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DropdownMenu } from 'components/general/DropDown/DropdownMenu';
import MoreIcon from 'components/icons/more.svg?react';
import { NavItem } from 'eva-frame/EvaLayout/useMainNavigationItems';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

export function MoreSidePanelItems({ menuItems, showLabel }: { menuItems: NavItem[]; showLabel?: boolean }) {
  const { t } = useTranslation();
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);

  const onCloseMenu = () => setMenuAnchorEl(null);
  const onOpenMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const isActive = menuItems.some((item) => item.isActive);

  return (
    <>
      <Tooltip title={showLabel ? undefined : t('mainNavigation.moreButton')} placement="right">
        <ButtonBase sx={{ paddingX: 3, paddingY: 2, justifyContent: 'flex-start', width: '100%' }} onClick={onOpenMenu}>
          <MoreIcon style={{ color: isActive ? theme.palette.primary.main : theme.palette.shade.grey2 }} />
          {showLabel && (
            <Typography
              variant="body3"
              color={isActive ? theme.palette.primary.main : theme.palette.text.secondary}
              sx={{ marginLeft: 1 }}
            >
              {t('mainNavigation.moreButton')}
            </Typography>
          )}
          {isActive && (
            <Box
              sx={{
                position: 'absolute',
                right: '-1px',
                top: 0,
                bottom: 0,
                width: '1px',
                backgroundColor: theme.palette.primary.main,
              }}
            />
          )}
        </ButtonBase>
      </Tooltip>

      <DropdownMenu
        anchorEl={menuAnchorEl}
        onClose={onCloseMenu}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        {menuItems.map(({ icon: Icon, ...item }) => (
          <MenuItem
            selected={item.isActive}
            key={item.label}
            style={{ color: theme.palette.text.primary }}
            to={item.href}
            disabled={item.disabled}
            component={Link}
            onClick={onCloseMenu}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </DropdownMenu>
    </>
  );
}
