import { AddBuyerRentalLeadRequest, AddSellerRenterLeadRequest } from 'api/rest/models/rest/Command';
import { AssignmentProps, newLeadAssignmentMapper } from 'util/mappers/leadAssigmentMapper';
import { propertyMapper } from 'util/mappers/propertyMapper';
import { requirementsMapper } from 'util/mappers/requirementsMapper';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';

export function addNewSellerRenterLeadMapper(
  data: PropertyDetailsFormData & AssignmentProps,
  contactId: string,
): AddSellerRenterLeadRequest {
  const { property } = data;

  return {
    contactId: contactId,
    ...newLeadAssignmentMapper(data),
    leadSource: data.leadSource,
    property: {
      intentType: data.intentType,
      ...propertyMapper(property),
    },
  };
}

export function addNewBuyerRentalLeadMapper(
  data: PropertyDetailsFormData & AssignmentProps,
  contactId: string,
): AddBuyerRentalLeadRequest {
  const { search } = data;

  return {
    contactId: contactId,
    ...newLeadAssignmentMapper(data),
    intentType: data.intentType,
    leadSource: data.leadSource,
    propertyId: data._internals.isKnownProperty ? data.knownProperty.linkedPropertyArray?.[0]?.utag : undefined,
    personalOrLeadNote:
      (data._internals.isKnownProperty ? data.knownProperty.personalNote : search.personalNote) || undefined,
    requirements: search && !data._internals.isKnownProperty ? requirementsMapper(search) : undefined,
  };
}
