import { styled } from '@mui/material/styles';
import { Popup } from 'react-leaflet';

export const LeafletPopup = styled(Popup)`
  .leaflet-popup-content-wrapper {
    border-radius: 5px;
    padding: 8px;
  }

  .leaflet-popup-content {
    border: hidden;
    margin: 0;
  }
  .leaflet-popup-content p {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .leaflet-popup-content a {
    color: unset;
  }

  .leaflet-popup-close-button.leaflet-popup-close-button {
    font-size: 24px;
    top: 8px;
    right: 8px;
  }

  .leaflet-popup-tip-container {
    visibility: hidden;
  }
`;
