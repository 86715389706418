import { addHours, getMinutes, setHours, setMinutes, setSeconds } from 'date-fns';

export function getNextClosestQuarter(day: Date, offsetHours = 0) {
  const minutes = getMinutes(day);
  const nextQuarter = (Math.ceil(minutes / 15) * 15) % 60;

  /**
   * To get next available timeslot (minutes)
   * If time is 10:42 then it should return 10:45
   * If offset is set to 1, then 11:45 should be returned
   */
  return setMinutes(addHours(day, minutes > 45 ? offsetHours + 1 : offsetHours), nextQuarter);
}

export function getPreviousClosestQuarter(day: Date) {
  const minutes = getMinutes(day);
  const previousQuarter = Math.floor(minutes / 15) * 15;

  /**
   * To get next available timeslot (minutes)
   * If time is 10:42 then it should return 10:30
   */
  return setMinutes(day, previousQuarter);
}

export function setTimeFromDifferentDate(day: Date, sourceDay: Date) {
  const hours = sourceDay.getHours();
  const minutes = sourceDay.getMinutes();
  const seconds = sourceDay.getSeconds();

  return setHours(setMinutes(setSeconds(day, seconds), minutes), hours);
}
