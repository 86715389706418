import { Button, DialogActions, Stack } from '@mui/material';
import { PickersActionBarProps } from '@mui/x-date-pickers';
import { useTranslation } from 'util/i18next';

//Makes use of built in props for picker action bars
//For more information see the documentation for customizing date and time pickers
//https://mui.com/x/react-date-pickers/custom-components/

export const CustomPickerActionBar = ({ onCancel, onAccept, className, actions }: PickersActionBarProps) => {
  const { t } = useTranslation([]);

  //On mobile, the accept and cancel actions are passed to the component by default
  const isMobileView = actions?.length == 2;

  if (!isMobileView) {
    return null;
  }

  return (
    <DialogActions className={className}>
      <Stack direction="row" gap={1.5} sx={{ mx: 2 }}>
        <Button variant="outlined" color="secondary" onClick={onCancel} sx={{ minWidth: 100 }}>
          {t('cancel')}
        </Button>
        <Button variant="contained" onClick={onAccept} sx={{ minWidth: 100 }}>
          {t('confirm')}
        </Button>
      </Stack>
    </DialogActions>
  );
};
