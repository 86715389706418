import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { theme } from 'theme';

export type TooltipHeaderProps = {
  label: ReactNode;
  subLabel?: ReactNode;
  avatar?: ReactNode;
};

export const EntityTooltipHeader = ({ label, subLabel, avatar }: TooltipHeaderProps) => (
  <Stack gap={2} direction="row" alignItems="center" sx={{ maxWidth: '100%', overflow: 'hidden' }}>
    {avatar}
    <Stack>
      <Typography variant="body3" color={theme.palette.text.primary} fontWeight={theme.typography.fontWeightBold}>
        {label}
      </Typography>
      {subLabel && (
        <Typography variant="body3" color={theme.palette.text.secondary}>
          {subLabel}
        </Typography>
      )}
    </Stack>
  </Stack>
);
