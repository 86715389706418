import { usePlaceDetails } from 'api/searchBff/hooks/usePlaceDetails';
import { env } from 'env';
import { useCallback } from 'react';
import sentry from 'util/sentry';

const useGetPlaceIdFromCoordinates = () =>
  useCallback(async (latitude?: number, longitude?: number) => {
    if (latitude === undefined || longitude === undefined) {
      return null;
    }

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${env.VITE_GOOGLE_MAPS_API_KEY}`,
      );
      if (!response.ok) {
        sentry.logError('Error: an error occurred while getting details');
        return null;
      }
      const data = await response.json();
      return data?.results?.length ? data.results[0].place_id : null;
    } catch (error) {
      sentry.logError(error as Error);
      return null;
    }
  }, []);

export const useGetAddressFromCoordinates = () => {
  const getPlaceIdFromCoordinates = useGetPlaceIdFromCoordinates();
  const placeDetails = usePlaceDetails();

  return useCallback(
    async (latitude?: number, longitude?: number) => {
      const placeId = await getPlaceIdFromCoordinates(latitude, longitude);
      return await placeDetails({ placeId });
    },
    [getPlaceIdFromCoordinates, placeDetails],
  );
};
