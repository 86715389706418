import { Person } from 'api/rest/models/Common';
import { Option } from 'components/general/OptionsList/OptionsList';
import { fullName } from 'util/stringUtils';

export function sortByDescriptionUndefinedFirst(a: Option, b: Option): number {
  if ((a.value === 'UNDEFINED') != (b.value === 'UNDEFINED')) {
    return a.value === 'UNDEFINED' ? -1 : 1;
  }
  if (typeof a.label === 'string' && typeof b.label === 'string') {
    return a.label.localeCompare(b.label);
  }
  return a.value.localeCompare(b.value);
}

export function sortByFullName(a: Person, b: Person) {
  return fullName(a).localeCompare(fullName(b));
}
