import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useAgentsInShopGroup } from 'api/graphql/hooks/useAgents';
import { useContactInfos, useContactRelationships } from 'api/graphql/hooks/useContact';
import { ContactItem } from 'components/contact/LeadAndContactPicker/LeadAndContactOptionItems';
import {
  LeadAndContactSearchOption,
  getContactId,
} from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import { EVChip } from 'components/general/Chips/EVChip/EVChip';
import { AgentTooltipChip } from 'components/general/Chips/TooltipChip/AgentTooltipChip';
import { ContactTooltipChip } from 'components/general/Chips/TooltipChip/ContactTooltipChip';
import { PersonMenuItem } from 'components/general/person/PersonItem';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { contactRelationshipTypeTranslation } from 'const/enumTranslations';
import React, { useRef, useState } from 'react';
import { theme } from 'theme';
import { getPreferredEmail } from 'util/contactUtils';
import { agentSearchFilter, contactSearchNameFilter } from 'util/hasura/filters';
import { useTranslation } from 'util/i18next';
import { PreviewCcRecipient } from 'util/schemas/sendBulkEmailSchema';
import { useDebouncedValue } from 'util/useDebounce';

function getEmail(ccRecipient: PreviewCcRecipient) {
  return ccRecipient.type === 'AGENT' || ccRecipient.type === 'EMAIL'
    ? ccRecipient.email
    : getPreferredEmail(ccRecipient);
}

function getId(ccRecipient: PreviewCcRecipient) {
  return ccRecipient.type === 'EMAIL' ? ccRecipient.email : ccRecipient.id;
}

export function CcBccAutocomplete({
  value,
  onChange,
  recipient,
  label,
  disabled,
  errorMessage,
}: {
  value: PreviewCcRecipient[];
  onChange: (value: PreviewCcRecipient[]) => void;
  recipient: LeadAndContactSearchOption;
  label: React.ReactNode;
  disabled?: boolean;
  errorMessage?: string;
}) {
  const { t } = useTranslation(['communication', 'enums']);
  const { shopIdsInActiveGroup } = useActiveShop();

  const [hasFocus, setHasFocus] = useState(false);
  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useDebouncedValue(searchString, 300);

  const { agentsInShopGroup = [] } = useAgentsInShopGroup({
    shopIds: shopIdsInActiveGroup,
    where: agentSearchFilter(debouncedSearchString),
  });

  const { contactRelationships = [] } = useContactRelationships({
    contactId: getContactId(recipient),
    where: {
      emailAddresses: { address: { _isNull: false } },
      contactStatus: { _neq: 'FLAGGED' },
      deleted: { _neq: true },
      ...contactSearchNameFilter(debouncedSearchString),
    },
  });
  const { contacts = [] } = useContactInfos(
    {
      where: {
        shopId: { _in: shopIdsInActiveGroup },
        emailAddresses: { address: { _isNull: false } },
        contactStatus: { _neq: 'FLAGGED' },
        deleted: { _neq: true },
        _or: [
          contactSearchNameFilter(debouncedSearchString),
          { emailAddresses: { address: { _like: `${debouncedSearchString}%` } } },
          { phoneNumbers: { number: { _like: `${debouncedSearchString}%` } } },
          { go3Utag: { _eq: debouncedSearchString?.toUpperCase() } },
          { id: { _eq: debouncedSearchString?.toUpperCase() } },
        ],
      },
      limit: 20,
      offset: 0,
    },
    { enabled: !!debouncedSearchString },
  );

  const showAgentOptions = !!searchString.length;
  const isOpen = hasFocus || !!searchString.length;

  const excludedContactIds = new Set([
    getContactId(recipient),
    ...(contactRelationships?.map((c) => c.relatedContact.id) || []),
  ]);

  const ccRecipients: PreviewCcRecipient[] = [
    ...contactRelationships.map((contactRelationship) => ({
      type: 'RELATED_CONTACT' as const,
      relationship: contactRelationship.type,
      ...contactRelationship.relatedContact,
    })),
    ...contacts
      .filter((c) => !excludedContactIds.has(c.id))
      .map((contact) => ({ type: 'CONTACT' as const, ...contact })),
    ...(showAgentOptions
      ? agentsInShopGroup.map((agent) => ({
          type: 'AGENT' as const,
          ...agent,
        }))
      : []),
  ];

  const lastRenderedOptionType = useRef<PreviewCcRecipient['type']>();

  return (
    <>
      <Autocomplete
        open={isOpen}
        onBlur={() => setHasFocus(false)}
        onFocus={() => setHasFocus(true)}
        disabled={disabled}
        value={value}
        disableClearable
        multiple
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          switch (option.type) {
            case 'AGENT':
            case 'CONTACT':
            case 'RELATED_CONTACT':
              return [option.firstName, option.lastName, getEmail(option)].filter(Boolean).join();
            case 'EMAIL':
              return option.email;
          }
        }}
        onChange={(_, items) => {
          onChange(items.map((item) => (typeof item === 'string' ? { type: 'EMAIL', email: item } : item)));
        }}
        isOptionEqualToValue={(option: string | PreviewCcRecipient, value: PreviewCcRecipient) =>
          typeof option === 'string' ? option === getEmail(value) : getId(option) === getId(value)
        }
        onInputChange={(_, newInputValue) => setSearchString(newInputValue)}
        options={ccRecipients}
        getOptionDisabled={(option: PreviewCcRecipient) =>
          (option.type === 'RELATED_CONTACT' || option.type === 'CONTACT') && !getPreferredEmail(option)
        }
        filterOptions={(options) => options} // Needed to disable the internal filtering
        renderOption={(props, option, state) => {
          if (state.index === 0) {
            lastRenderedOptionType.current = undefined;
          }
          const isFirstOfType = lastRenderedOptionType.current !== option.type;
          lastRenderedOptionType.current = option.type;

          // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
          switch (option.type) {
            case 'AGENT':
              return (
                <React.Fragment key={option.id}>
                  {isFirstOfType && <TypeLabel>{t('communication:sendBulkEmailDialog.ccType.agent')}</TypeLabel>}
                  <PersonMenuItem person={option} {...props} />
                </React.Fragment>
              );
            case 'CONTACT':
              return (
                <React.Fragment key={option.id}>
                  {isFirstOfType && <TypeLabel>{t('communication:sendBulkEmailDialog.ccType.contact')}</TypeLabel>}
                  <ContactItem {...props} option={{ ...option, email: getPreferredEmail(option) || '' }} />
                </React.Fragment>
              );
            case 'RELATED_CONTACT':
              return (
                <React.Fragment key={option.id}>
                  {isFirstOfType && <TypeLabel>{t('communication:sendBulkEmailDialog.ccType.relationship')}</TypeLabel>}
                  <ContactItem {...props} option={{ ...option, email: getPreferredEmail(option) || '' }}>
                    <Typography variant="body4" sx={{ color: theme.palette.text.secondary }}>
                      {t(contactRelationshipTypeTranslation[option.relationship])}
                    </Typography>
                  </ContactItem>
                </React.Fragment>
              );
          }
        }}
        freeSolo={true}
        filterSelectedOptions
        renderTags={(value, getTagProps) =>
          value.map((option, index: number) => {
            switch (option.type) {
              case 'AGENT':
                return (
                  <AgentTooltipChip
                    key={index}
                    handleDelete={() => getTagProps({ index }).onDelete(index)}
                    agentId={option.id}
                  />
                );
              case 'RELATED_CONTACT':
              case 'CONTACT':
                return (
                  <ContactTooltipChip
                    key={index}
                    handleDelete={() => getTagProps({ index }).onDelete(index)}
                    contactId={option.id}
                    asLink={false}
                  />
                );
              case 'EMAIL':
                return <EVChip label={option.email} {...getTagProps({ index })} key={index} selected={false} />;
            }
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={!!errorMessage}
            helperText={errorMessage}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    </>
  );
}

function TypeLabel({ children }: React.PropsWithChildren) {
  return (
    <Typography
      variant="subtitle1"
      sx={{ color: theme.palette.text.secondary, paddingX: 2, paddingY: 1 }}
      fontWeight="bold"
      component="p"
    >
      {children}
    </Typography>
  );
}
