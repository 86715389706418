import { useMutation } from '@tanstack/react-query';
import { fetchClient, GetRequestQuery, GetResponseData } from 'api/rest/fetch-client';

export type GetGeoRequest = GetRequestQuery<'/api/v1/geo'>;
export type GetGeoResponse = GetResponseData<'/api/v1/geo'>;

export const useGetGeoByAddress = () => {
  const {
    mutate: getGeoByAddress,
    mutateAsync: getGeoByAddressAsync,
    ...rest
  } = useMutation((query: GetGeoRequest) => {
    return fetchClient
      .GET('/api/v1/geo', {
        params: {
          query,
        },
      })
      .then((resp) => resp.data!);
  });
  return { getGeoByAddress, getGeoByAddressAsync, ...rest };
};
