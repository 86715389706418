import { useQuery } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';
import { env } from 'env';

const getEnableFeaturesQuery = graphql(/* GraphQL */ `
  query GetEnableFeatures {
    dbEnabledFeatureFlags {
      enabledFeature
    }
  }
`);

export const useGetEnableFeatures = () => {
  const { data: enabledFeatures, ...rest } = useQuery({
    queryKey: ['features', 'useGetEnableFeatures'],
    queryFn: async () => {
      const data = await graphqlClient.request(getEnableFeaturesQuery);
      const dbEnabledFeatures = data.dbEnabledFeatureFlags.map(({ enabledFeature }) => enabledFeature);
      const disabledFeaturesOverwrite = (env.VITE_DISABLED_FEATURES || '').split(',');

      return dbEnabledFeatures.filter((feature) => !disabledFeaturesOverwrite.includes(feature));
    },
    staleTime: 600_000,
    refetchInterval: 600_000,
    retry: false,
  });
  return { enabledFeatures, ...rest };
};
