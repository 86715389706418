import { createContext, ReactNode, useContext, useEffect, useMemo, useRef } from 'react';

export type Confirmation = {
  title: string;
  active: boolean;
};

type ConfirmationContextState = {
  setConfirmation: (confirmation: Confirmation | undefined) => void;
};

type ConfirmationContextProps = {
  children: ReactNode;
  setConfirmation: (confirmation: Confirmation | undefined) => void;
};

export const ConfirmationContext = createContext<ConfirmationContextState>({} as ConfirmationContextState);

export function ConfirmationContextProvider({ children, setConfirmation }: ConfirmationContextProps) {
  const setConfirmationCallback = useRef(setConfirmation);
  setConfirmationCallback.current = setConfirmation;
  const contextValue = useMemo(
    () => ({
      setConfirmation: (confirmation: Confirmation | undefined) => {
        setConfirmationCallback.current(confirmation);
      },
    }),
    [],
  );

  return <ConfirmationContext.Provider value={contextValue}>{children}</ConfirmationContext.Provider>;
}

export function useConfirmation(confirmation: Confirmation) {
  const { setConfirmation } = useContext(ConfirmationContext);
  useEffect(() => {
    setConfirmation(confirmation);
    return () => {
      setConfirmation(undefined);
    };
  }, [confirmation, setConfirmation]);
}
