import { CurrencyEnum } from 'api/graphql/generated/graphql';
import { useGetAllEnums } from 'api/graphql/hooks/useGetAllEnums';
import { DropDown } from 'components/general/DropDown/DropDown';
import { FormStack } from 'components/general/Form/FormStack';
import { householdTypeTranslation, professionTypeTranslation } from 'const/enumTranslations';
import { Controller, useFormContext } from 'react-hook-form';
import { includeEmptyOption } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';
import { enumToLokalisedOptions } from 'util/mappers/enumToOption';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';
import { useFormatNumber } from 'util/useFormatNumber';

export const HouseholdForm = () => {
  const { t } = useTranslation(['contact']);
  const { control, watch } = useFormContext<ContactDetailsFormData>();
  const { allEnums } = useGetAllEnums();
  const { formatPriceRange } = useFormatNumber();

  const currency = watch('renterDetails.incomeRange._currency');
  const options = { currency };

  const netIncomeOptions = [
    { label: `${formatPriceRange(null, 500, options)}`, value: '< 500' },
    { label: `${formatPriceRange(500, 1000, options)}`, value: '500 - 1000' },
    { label: `${formatPriceRange(1000, 1500, options)}`, value: '1000 - 1500' },
    { label: `${formatPriceRange(1500, 2000, options)}`, value: '1500 - 2000' },
    { label: `${formatPriceRange(2000, 3000, options)}`, value: '2000 - 3000' },
    { label: `${formatPriceRange(3000, 4000, options)}`, value: '3000 - 4000' },
    { label: `${formatPriceRange(4000, 5000, options)}`, value: '4000 - 5000' },
    { label: `${formatPriceRange(5000, null, options)}`, value: '> 5000' },
  ] as const;

  return (
    <>
      <Controller
        name="renterDetails.householdType"
        control={control}
        render={({ field }) => (
          <FormStack>
            <DropDown
              {...field}
              label={t('contact:renterDetails.householdSize')}
              options={includeEmptyOption(
                allEnums?.enumsHouseholdType?.map(enumToLokalisedOptions(t, householdTypeTranslation)) ?? [],
              )}
            />
          </FormStack>
        )}
      />
      <Controller
        name="renterDetails.incomeRange"
        control={control}
        render={({ field }) => (
          <FormStack>
            <DropDown
              {...field}
              label={t('contact:renterDetails.netHouseholdIncome')}
              value={getIncomeRangeString(field.value)}
              onChange={(value) => field.onChange(getIncomeRangeNumbers(value, currency))}
              options={includeEmptyOption(
                netIncomeOptions.map((netIncomeOption) => ({
                  label: netIncomeOption.label,
                  value: netIncomeOption.value,
                })),
              )}
            />
          </FormStack>
        )}
      />
      <Controller
        name="renterDetails.professionType"
        control={control}
        render={({ field }) => (
          <FormStack>
            <DropDown
              {...field}
              label={t('contact:renterDetails.typeOfEmployment')}
              options={includeEmptyOption(
                allEnums?.enumsProfessionType?.map(enumToLokalisedOptions(t, professionTypeTranslation)) ?? [],
              )}
            />
          </FormStack>
        )}
      />
      <Controller
        name="renterDetails.petOwner"
        control={control}
        render={({ field }) => (
          <FormStack>
            <DropDown
              label={t('contact:renterDetails.pets')}
              value={field.value?.toString()}
              onChange={(value) => field.onChange(value ? value === 'true' : null)}
              options={includeEmptyOption([
                {
                  label: t('contact:renterDetails.pets.yes'),
                  value: 'true',
                },
                {
                  label: t('contact:renterDetails.pets.no'),
                  value: 'false',
                },
              ])}
            />
          </FormStack>
        )}
      />
    </>
  );
};

const getIncomeRangeNumbers = (incomeRange: string | string[], currency: CurrencyEnum) => {
  switch (incomeRange) {
    case '< 500':
      return {
        minimum: undefined,
        maximum: 500,
        _currency: currency,
      };
    case '500 - 1000':
      return {
        minimum: 500,
        maximum: 1000,
        _currency: currency,
      };
    case '1000 - 1500':
      return {
        minimum: 1000,
        maximum: 1500,
        _currency: currency,
      };
    case '1500 - 2000':
      return {
        minimum: 1500,
        maximum: 2000,
        _currency: currency,
      };
    case '2000 - 3000':
      return {
        minimum: 2000,
        maximum: 3000,
        _currency: currency,
      };
    case '3000 - 4000':
      return {
        minimum: 3000,
        maximum: 4000,
        _currency: currency,
      };
    case '4000 - 5000':
      return {
        minimum: 4000,
        maximum: 5000,
        _currency: currency,
      };
    case '> 5000':
      return {
        minimum: 5000,
        maximum: undefined,
        _currency: currency,
      };
    default:
      return {};
  }
};

export const getIncomeRangeString = (range?: { minimum?: number; maximum?: number }) => {
  if (!range) {
    return undefined;
  }

  const { minimum, maximum } = range;
  if (maximum === 500) {
    return '< 500';
  }
  if (minimum === 500 && maximum === 1000) {
    return '500 - 1000';
  }
  if (minimum === 1000 && maximum === 1500) {
    return '1000 - 1500';
  }
  if (minimum === 1500 && maximum === 2000) {
    return '1500 - 2000';
  }
  if (minimum === 2000 && maximum === 3000) {
    return '2000 - 3000';
  }
  if (minimum === 3000 && maximum === 4000) {
    return '3000 - 4000';
  }
  if (minimum === 4000 && maximum === 5000) {
    return '4000 - 5000';
  }
  if (minimum === 5000) {
    return '> 5000';
  }
  return undefined;
};
