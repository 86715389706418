import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  activeTab: number | string;
  value: number | string;
  sx?: SxProps;
}

export function TabPanel({ children, value, activeTab, sx }: TabPanelProps) {
  return (
    <Box
      role="tabpanel"
      hidden={value !== activeTab}
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
      sx={{ width: '100%', ...sx }}
    >
      {value === activeTab && children}
    </Box>
  );
}
