import { IntentCategoryEnum, IntentTypeEnum } from 'api/graphql/generated/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';

export function isIntentTypeWithProperty(intentType: IntentTypeEnum) {
  return ['UNDEFINED', 'SELL', 'RENT_OUT', 'VALUATION'].includes(intentType);
}

export const intentTypeToIntentCategory = (intentType: Maybe<IntentTypeEnum>): IntentCategoryEnum | undefined => {
  switch (intentType) {
    case 'VALUATION':
    case 'SELL':
    case 'UNDEFINED': // As Erno said, undefined is a seller lead for the MVP release
      return 'SELLER';
    case 'BUY':
      return 'BUYER';
    case 'RENT_OUT':
    case 'TO_RENT':
      return 'RENTAL';
    default:
      return undefined;
  }
};

export const intentTabs = ['BUYER', 'RENT_OUT', 'TO_RENT', 'SELLER'] as const;
export type IntentTabEnum = (typeof intentTabs)[number];

export const intentTypeToIntentTabs = (intentType: Maybe<IntentTypeEnum>): IntentTabEnum | undefined => {
  switch (intentType) {
    case 'VALUATION':
    case 'SELL':
    case 'UNDEFINED':
      return 'SELLER';
    case 'BUY':
      return 'BUYER';
    case 'RENT_OUT':
      return 'RENT_OUT';
    case 'TO_RENT':
      return 'TO_RENT';
    default:
      return undefined;
  }
};

export const intentCategoryToIntentTypes = (intentCategory: IntentCategoryEnum): IntentTypeEnum[] => {
  switch (intentCategory) {
    case 'SELLER':
      return ['VALUATION', 'SELL', 'UNDEFINED'];
    case 'BUYER':
      return ['BUY'];
    case 'RENTAL':
      return ['RENT_OUT', 'TO_RENT'];
  }
};

export const intentTabToIntentTypes = (intentCategory: IntentTabEnum): IntentTypeEnum[] => {
  switch (intentCategory) {
    case 'SELLER':
      return ['VALUATION', 'SELL', 'UNDEFINED'];
    case 'BUYER':
      return ['BUY'];
    case 'RENT_OUT':
      return ['RENT_OUT'];
    case 'TO_RENT':
      return ['TO_RENT'];
  }
};
