import { AssetSubTypeEnum, AssetTypeEnum, AssetVariationEnum } from 'api/graphql/generated/graphql';
import { CreateBuyerRentalLeadRequest } from 'api/rest/models/rest/Command';
import { convertFeaturesToRequestFormat } from 'components/map/MapUtils';
import { SearchFormData } from 'util/schemas/propertyDetailsSchema';

export function requirementsMapper(search: SearchFormData): NonNullable<CreateBuyerRentalLeadRequest['requirements']> {
  return {
    area: search.area,
    bedroomsMax: search.bedroomsMax ?? undefined,
    bedroomsMin: search.bedroomsMin ?? undefined,
    city: search.city || undefined,
    countryCode: search.countryCode || undefined,
    currency: search.currency,
    district: search.district || undefined,
    livingAreaMax: search.livingAreaMax ?? undefined,
    livingAreaMin: search.livingAreaMin ?? undefined,
    monthlyNetRentMax: search.monthlyNetRentMax ?? undefined,
    monthlyNetRentMin: search.monthlyNetRentMin ?? undefined,
    monthlyTotalRentMax: search.monthlyTotalRentMax ?? undefined,
    monthlyTotalRentMin: search.monthlyTotalRentMin ?? undefined,
    plotSurfaceMax: search.plotSurfaceMax ?? undefined,
    plotSurfaceMin: search.plotSurfaceMin ?? undefined,
    priceMax: search.priceMax ?? undefined,
    priceMin: search.priceMin ?? undefined,
    roomsMax: search.roomsMax ?? undefined,
    roomsMin: search.roomsMin ?? undefined,
    subTypes: search.subTypes as AssetSubTypeEnum[],
    variations: search.variations as AssetVariationEnum[],
    totalSurfaceMax: search.totalSurfaceMax ?? undefined,
    totalSurfaceMin: search.totalSurfaceMin ?? undefined,
    type: search.propertyType as AssetTypeEnum,
    placeIds: (search.placeIds || []).map((placeIdObj) => placeIdObj.placeId || ''),
    cdfs: search.cdfs ?? undefined,
    polygons: convertFeaturesToRequestFormat(search.geojson?.features),
  };
}
