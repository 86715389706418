import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallUiVariant } from 'components/calls/CallFrame';
import { PropsWithChildren, ReactNode } from 'react';
import { theme } from 'theme';

export function CallTile({
  icon,
  children,
  disabled,
  onClick,
  tooltipText,
}: PropsWithChildren<{ icon: ReactNode; disabled: boolean; onClick: () => void; tooltipText?: string }>) {
  const variant = useCallUiVariant();

  return (
    <Tooltip
      PopperProps={{ sx: { zIndex: 9002 } }}
      disableHoverListener={!disabled}
      title={tooltipText}
      placement={'top'}
    >
      <ButtonBase
        onClick={!disabled ? onClick : undefined}
        sx={{
          border: `1px solid ${theme.palette.shade.grey2}`,
          paddingY: 2,
          color: variant === 'dark' ? theme.palette.textExtension.white : theme.palette.text.primary,
          opacity: disabled ? 0.7 : 1,
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        <Stack alignItems="center" gap={1}>
          {icon}
          <Typography variant="body3">{children}</Typography>
        </Stack>
      </ButtonBase>
    </Tooltip>
  );
}

export function CallTiles({ children }: PropsWithChildren) {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }} gap={2}>
      {children}
    </Box>
  );
}
