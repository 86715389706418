import Button from '@mui/material/Button';
import { FormStack } from 'components/general/Form/FormStack';
import PlusCircle from 'components/icons/plus.svg?react';
import { MouseEventHandler } from 'react';

const AddButton = ({ onClick, text, disabled }: { onClick: MouseEventHandler; text: string; disabled?: boolean }) => (
  <FormStack>
    <Button
      variant="text"
      color="secondary"
      startIcon={<PlusCircle />}
      sx={{ alignSelf: 'flex-start' }}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  </FormStack>
);

export default AddButton;
