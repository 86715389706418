import { Box, Typography } from '@mui/material';
import { theme } from 'theme';

export function CallDuration({ callDuration, isActive }: { callDuration: number; isActive: boolean }) {
  return callDuration > 0 ? (
    <Box
      sx={{
        backgroundColor: isActive ? theme.palette.transparent.green16 : theme.palette.transparent.black16,
        color: isActive ? theme.palette.special.green1 : theme.palette.text.primary,
        borderRadius: '16px',
        height: '32px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingX: '12px',
      }}
    >
      <Typography variant="body3">{secondsToHMS(callDuration)}</Typography>
    </Box>
  ) : null;
}

export function secondsToHMS(secs: number) {
  function z(n: number) {
    return (n < 10 ? '0' : '') + n;
  }

  const sign = secs < 0 ? '-' : '';
  secs = Math.abs(secs);
  return sign + z((secs / 3600) | 0) + ':' + z(((secs % 3600) / 60) | 0) + ':' + z(secs % 60);
}
