import React from 'react';

export function splitRef<T>(...refs: Array<React.MutableRefObject<T> | React.RefCallback<T> | null | undefined>) {
  return (current: T) => {
    for (const ref of refs) {
      if (typeof ref === 'function') {
        ref(current);
      } else if (ref) {
        ref.current = current;
      }
    }
  };
}
