import { latLngToPosition } from 'components/map/LeafletMap/LeafletMapUtils';
import { LeafletPopup } from 'components/map/LeafletMap/LeafletPopup';
import { MarkerInfoBox } from 'components/map/MarkerInfoBox';
import { MARKER_ICON_OFFSETS } from 'const/MarkerAnchorPointOffsets';
import { DragEndEvent, Icon, Marker as MarkerApi, Point } from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { Marker as LeafletInternalMarker } from 'react-leaflet';
import { LatLng, Marker } from 'util/places/mapMarkers';

type LeafletMarkerProps = { marker: Marker; draggable?: boolean; handleDrag?: (latlng: LatLng) => void };

export const LeafletMarker: React.FC<LeafletMarkerProps> = ({ marker, draggable, handleDrag }) => {
  const [position, setPosition] = useState<LatLng>();

  const markerRef = useRef<MarkerApi>(null);

  useEffect(() => {
    if (marker.infoShownInitially) {
      window.setTimeout(() => markerRef.current?.openPopup(), 100);
    }
  }, [marker.infoShownInitially]);

  useEffect(() => {
    setPosition(marker.position);
  }, [marker, setPosition]);

  return (
    <LeafletInternalMarker
      ref={markerRef}
      key={marker.id}
      icon={
        new Icon({
          iconUrl: marker.icon,
          iconAnchor: new Point(MARKER_ICON_OFFSETS.x, MARKER_ICON_OFFSETS.y),
          popupAnchor: [0, -24],
        })
      }
      draggable={draggable}
      eventHandlers={{
        dragend: (event: DragEndEvent) => {
          const marker = event.target;
          const newPosition = marker.getLatLng();
          handleDrag?.(newPosition);
          setPosition(newPosition);
        },
      }}
      position={latLngToPosition(position || { lat: 0, lng: 0 })}
    >
      {!draggable && (
        <LeafletPopup maxWidth={500} minWidth={320}>
          <MarkerInfoBox
            marker={marker}
            paperSx={{
              border: 'hidden',
            }}
          />
        </LeafletPopup>
      )}
    </LeafletInternalMarker>
  );
};
