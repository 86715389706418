import { ListItemIcon, ListItemText } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import { Main } from 'eva-frame/EvaLayout/EvaLayout';
import { NavItem } from 'eva-frame/EvaLayout/useMainNavigationItems';
import { Link } from 'react-router-dom';
import { theme } from 'theme';

export function MobileMainNavigation({ items, onClose }: { items: readonly NavItem[]; onClose: () => void }) {
  return (
    <Main sx={{ backgroundColor: theme.palette.backgroundExtension.grey }}>
      <Paper elevation={0} sx={{ margin: 2 }}>
        <MenuList component="div">
          {items.map((item) => {
            const Icon = item.icon;
            return (
              <MenuItem component={Link} to={item.href} key={item.label} onClick={onClose}>
                <ListItemIcon sx={{ color: item.isActive ? theme.palette.primary.main : theme.palette.shade.grey2 }}>
                  <Icon />
                </ListItemIcon>
                <ListItemText sx={{ color: item.isActive ? theme.palette.primary.main : theme.palette.secondary.main }}>
                  {item.label}
                </ListItemText>
              </MenuItem>
            );
          })}
        </MenuList>
      </Paper>
    </Main>
  );
}
