import { Box, FormHelperText, SxProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useAgents } from 'api/graphql/hooks/useAgents';
import { AgentPopover } from 'components/forms/AgentsPicker/AgentPopover';
import { EVChip } from 'components/general/Chips/EVChip/EVChip';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { PersonMenuItem } from 'components/general/person/PersonItem';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { SyntheticEvent, useRef, useState } from 'react';
import { ControllerFieldState } from 'react-hook-form';
import { agentSearchFilter } from 'util/hasura/filters';
import { useTranslation } from 'util/i18next';
import { fullName } from 'util/stringUtils';
import { useSearchText } from 'util/useDebounce';

export interface AgentOption {
  type?: 'AGENT';
  id?: string;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  email?: Maybe<string>;
  name?: Maybe<string>;
  disabled?: boolean;
  displayValue?: Maybe<string>;
}

export const AgentsPicker = ({
  label,
  value,
  onChange,
  fieldState,
  children,
  errorMessage,
  sx,
  noOptionsText,
  loadingText,
  required,
  disabled,
}: {
  label: React.ReactNode;
  value?: (string | AgentOption)[];
  onChange: (e: (string | AgentOption)[]) => void;
  fieldState?: ControllerFieldState;
  children?: React.ReactNode;
  errorMessage?: string;
  sx?: SxProps;
  noOptionsText?: string;
  loadingText?: string;
  required?: boolean;
  disabled?: boolean;
}) => {
  const autocompleteRef = useRef(null);
  const { searchText, setSearchText } = useSearchText();
  const [selectedOption, setSelectedOption] = useState<AgentOption>();
  const { t } = useTranslation(['contact']);
  const { activeShop } = useActiveShop();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLButtonElement | null>(null);
  const { agents: agentsInShop, isLoading: isAgentsLoading } = useAgents(
    {
      where: {
        _and: [
          {
            _or: [agentSearchFilter(searchText), { email: { _like: `${searchText}%` } }],
          },
          {
            _or: [
              {
                userShopAssignments: { shopId: { _eq: activeShop.id } },
              },
              {
                userTeamAssignments: { team: { shopId: { _eq: activeShop.id } } },
              },
            ],
          },
        ],
      },
      limit: 10,
      offset: 0,
      orderBy: { firstName: 'ASC', lastName: 'ASC' },
    },
    { enabled: !!searchText.length },
  );

  const suggestedAgents =
    agentsInShop?.map((agent) => ({
      type: 'AGENT' as const,
      id: agent.id,
      firstName: agent.firstName,
      lastName: agent.lastName,
      email: agent.email,
    })) ?? [];

  const onSearchStringChanged = (_: SyntheticEvent<Element, Event>, value: string) => {
    setSearchText(value);
  };

  const handleClick = (option: AgentOption) => {
    return (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      setSelectedOption(option);
      setAnchorEl(event.currentTarget);
    };
  };

  return (
    <Box sx={sx}>
      <Autocomplete
        noOptionsText={noOptionsText || t('contact:form.contactPicker.noOptions')}
        loading={!!searchText.length && isAgentsLoading}
        loadingText={loadingText || t('contact:form.contactPicker.loading')}
        value={value}
        disableClearable
        disabled={disabled}
        multiple
        openOnFocus={true}
        ref={autocompleteRef}
        getOptionLabel={(option) =>
          typeof option === 'string'
            ? option
            : [option.email, option.firstName, option.lastName].filter(Boolean).join(' ')
        }
        onChange={(_, items) => {
          const payload = items.map((item) => {
            if (typeof item === 'string') {
              return {
                type: 'AGENT' as const,
                displayValue: item,
                email: item,
              };
            }
            return {
              type: 'AGENT' as const,
              displayValue: item.email,
              id: item.id,
              email: item.email,
              firstName: item.firstName,
              lastName: item.lastName,
            };
          });
          onChange(payload);
        }}
        isOptionEqualToValue={(option: string | AgentOption, value: AgentOption) =>
          typeof option === 'string' ? option === value.email : option.email === value.email
        }
        onInputChange={(event, newInputValue) => onSearchStringChanged(event, newInputValue)}
        options={suggestedAgents}
        renderOption={(props, option) => (
          <MenuItem key={option.id} {...props}>
            <PersonMenuItem person={option} />
          </MenuItem>
        )}
        freeSolo={true}
        getOptionDisabled={(option) => {
          return !!option.disabled;
        }}
        limitTags={2}
        filterSelectedOptions
        renderTags={(value, getTagProps) =>
          value.map((option, index: number) => {
            return (
              <EVChip
                label={
                  option?.firstName && option?.lastName
                    ? fullName({ firstName: option?.firstName, lastName: option?.lastName })
                    : option.email
                }
                avatar={<EVAvatar person={option} size="s" />}
                {...getTagProps({ index })}
                key={option.id}
                disabled={disabled}
                onClick={handleClick(option)}
                selected={false}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            disabled={disabled}
            required={required}
            InputProps={{
              ...params.InputProps,
            }}
            error={!!fieldState?.error}
          />
        )}
      />
      <Stack direction="row" sx={{ marginBottom: children ? -3 : 0 }}>
        <FormHelperText>{errorMessage}</FormHelperText>
        <Box sx={{ marginLeft: 'auto' }}>{children}</Box>
      </Stack>
      <AgentPopover
        anchorEl={anchorEl}
        selectedOption={selectedOption}
        handleClose={() => {
          setSelectedOption(undefined);
          setAnchorEl(null);
        }}
      />
    </Box>
  );
};
