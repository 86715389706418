import { useActiveShopId } from '@ev/eva-container-api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CustomView, SessionStatusEnum } from 'api/graphql/generated/graphql';
import { HttpService } from 'api/rest/HttpService';
import { AcceptReferralResponse } from 'api/rest/models/AcceptReferralReponse';
import { ConsentTextResponse } from 'api/rest/models/ConsentTextResponse';
import { DuplicateContactInfo } from 'api/rest/models/ContactDuplicates';
import {
  EmailMessageProperties,
  GetNylasEmailThreadsResponse,
  PreviewEmailResponse,
} from 'api/rest/models/EmailDataTypes';
import { KycCheckResponse } from 'api/rest/models/KycCheckResponse';
import { LeadResponse } from 'api/rest/models/LeadResponse';
import { MissingAttribute } from 'api/rest/models/Profile';
import { PropertyTypeMapping } from 'api/rest/models/PropertyTypeMapping';
import { TwilioSession } from 'api/rest/models/TwilioSession';
import { Go3ActivityFeatures } from 'api/rest/models/go3/Go3ActivityFeatures';
import { Go3ContactResponse } from 'api/rest/models/go3/Go3ContactResponse';
import { Go3TeamsResponse } from 'api/rest/models/go3/Go3TeamsResponse';
import {
  AcceptReferralRequest,
  ActivateContactsRequest,
  AddAdminRequest,
  AddBuyerRentalLeadRequest,
  AddSellerRenterLeadRequest,
  AddShopGroupRequest,
  AddTeamRequest,
  AssignAgentOnContactRequest,
  AssignLeadToAgentRequest,
  AssignLeadToGo3AgentRequest,
  BulkUpdateLeadStatusRequest,
  BuyMobileNumberRequest,
  CheckContactDuplicatesRequest,
  CompositeCdfCommandRequest,
  ConsentTextRequest,
  CreateAndAssignAgentToShopRequest,
  CreateAppointmentRequest,
  CreateBuyerRentalLeadRequest,
  CreateContactRequest,
  CreateManualConsentRequest,
  CreateTaskRequest,
  DeLinkEmailToContactRequest,
  DeLinkEmailToLeadRequest,
  DeclineTwilioCallRequest,
  DeleteAgentProfileImageRequest,
  DeleteContactsRequest,
  DeleteCustomListViewRequest,
  DeleteDocumentsCommandRequest,
  DeleteEmailTemplateRequest,
  DeleteShopProfileImageRequest,
  DestroyTwilioSessionRequest,
  DownloadDocumentReferenceRequest,
  EditBuyerRentalLeadRequest,
  EditSellerRenterLeadRequest,
  EditTeamRequest,
  GetCallingAvailabilityRequest,
  GetContactDuplicatesRequest,
  GetGo3ContactRequest,
  GetGo3TeamsRequest,
  GetKycCheckCommandRequest,
  GetNylasEmailMessagesForThreadRequest,
  GetNylasEmailThreadsRequest,
  GetNylasOneTimeTokenRequest,
  GetPropertyNewsRequest,
  InactivateTeamRequest,
  LinkCallToContactRequest,
  LinkEmailToContactRequest,
  LinkEmailToLeadRequest,
  LinkGo3ContactRequest,
  LinkLeadsRequest,
  LinkPropertyToSellerLeadRequest,
  MergeContactRequest,
  MergeDuplicateContactsRequest,
  ModifyDocumentRequest,
  OverwriteLinkGo3ContactRequest,
  PingRequest,
  PreviewReferralEmailRequest,
  PublishAgentProfileRequest,
  PublishShopProfileRequest,
  ReassignAgentsOnContactRequest,
  RejectReferralRequest,
  RelinkGo3ContactRequest,
  RemoveAdminRequest,
  RequestConsentRequest,
  RequestKycCheckCommandRequest,
  RerunFailedLeadImportsRequest,
  RerunKycCheckCommandRequest,
  ResolveEmailSignatureRequest,
  ResolvePreviewEmailTemplateRequest,
  RetrieveGo3ActivityRequest,
  RevokeConsentsRequest,
  SearchForContactInReceiverShopRequest,
  ShareExposeRequest,
  UnlinkLeadsRequest,
  UnlinkPropertyToSellerLeadRequest,
  UnpublishAgentProfileCommand,
  UpdateAgentDetailsRequest,
  UpdateAgentEnergyAdvisoryRequest,
  UpdateAgentProfileImageTitleRequest,
  UpdateAppointmentRequest,
  UpdateCallActivityRequest,
  UpdateContactRequest,
  UpdateFailedLeadImportsRequest,
  UpdateLeadAssignmentsRequest,
  UpdateLeadDetailsRequest,
  UpdateLeadExtendingInteractionsRequest,
  UpdateLicensePartnerCommandRequest,
  UpdateLicensePartnerProfileEmailSettingsRequest,
  UpdateLicensePartnerProfileImageTitleCommand,
  UpdateLicensePartnerProfileImageTitleRequest,
  UpdateLicensePartnerProfilePropertyPreviewsRequest,
  UpdateLipaProfileFeaturedAgentsRequest,
  UpdateNylasEmailThreadRequest,
  UpdateShopEnergyAdvisoryRequest,
  UpdateShopGroupRequest,
  UpdateShopProfileNewsletterCommand,
  UpdateTaskRequest,
  UpdateTwilioSessionRequest,
  UpdateUserRoleWithinShopRequest,
  UploadAgentProfileImageRequest,
  UploadDocumentsRequest,
  UploadLicensePartnerProfileImageCommand,
  UploadLicensePartnerProfileImageRequest,
  UploadShopProfileImageRequest,
  UpsertCustomListViewRequest,
  UpsertLicensePartnerProfileCareerCommand,
  UpsertLicensePartnerProfileDescriptionRequest,
  UpsertLicensePartnerProfileFeaturedContentRequest,
  UpsertLicensePartnerProfileHeroTeaserRequest,
  UpsertLicensePartnerProfileImprintRequest,
  UpsertLicensePartnerProfileIntroCommand,
  UpsertLicensePartnerProfilePrivacyPolicyRequest,
  UpsertLicensePartnerProfileSeoTrackingRequest,
  UpsertLicensePartnerProfileShopOverviewCommand,
  UpsertLicensePartnerProfileSocialMediaProfilesRequest,
  UpsertShopProfileEmailFooterRequest,
  UpsertShopProfileFeaturedContentRequest,
  UpsertShopProfileWithdrawalPolicyRequest,
  UpsertTagsCommandRequest,
  ValidateAgentProfileFieldsRequest,
  ValidateLicensePartnerProfileFieldsRequest,
  ValidateShopProfileFieldsRequest,
  VerifyMobileNumberRequest,
  updateShopManagedConsentSettingsRequest,
} from 'api/rest/models/rest/Command';
import { env } from 'env';
import { updatePageToken } from 'page-components/mails/ThreadPage/pageToken';
import { useDestination } from 'util/navigation/useDestination';
import { useNavigation } from 'util/navigation/useNavigation';

export const httpService = new HttpService();

if (['local', 'local-dev', 'feature', 'dev', 'stage'].includes(env.VITE_LEADHUB_ENV)) {
  // expose API for E2E tests
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).leadEngineService = httpService;
}

export const useCreateTwilioSession = ({ onSuccess }: { onSuccess: (response: TwilioSession) => void }) => {
  const { mutate: createTwilioSession, ...rest } = useMutation({
    mutationFn: () =>
      httpService
        .executeLeadEngineCommand({
          requestBody: {},
          className: 'CreateTwilioSessionCommand',
        })
        .then((resp) => resp.data.data as TwilioSession),
    onSuccess,
  });
  return { createTwilioSession, ...rest };
};

export const useDestroyTwilioSession = () => {
  const { mutate: destroyTwilioSession, ...rest } = useMutation((requestBody: DestroyTwilioSessionRequest) =>
    httpService.executeLeadEngineCommand({
      requestBody,
      className: 'DestroyTwilioSessionCommand',
    }),
  );
  return { destroyTwilioSession, ...rest };
};

export const usePing = () => {
  const { mutate: ping, ...rest } = useMutation((requestBody: PingRequest) =>
    httpService.executeLeadEngineCommand({ requestBody, className: 'PingCommand' }).then((resp) => resp.data.data),
  );
  return { ping, ...rest };
};

export const useAssignLeadToAnAgent = () => {
  const queryClient = useQueryClient();
  const { mutate: assignLeadToAnAgent, ...rest } = useMutation(
    (requestBody: AssignLeadToAgentRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'AssignLeadToAgentCommand',
      }),
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['lead'] }),
    },
  );
  return { assignLeadToAnAgent, ...rest };
};

export const useUpdateContact = () => {
  const queryClient = useQueryClient();
  const { mutate: updateContact, ...rest } = useMutation(
    (requestBody: UpdateContactRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateContactCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { updateContact, ...rest };
};

export const useTriggerMigration = () => {
  const { mutate: triggerMigration, ...rest } = useMutation((ShopId: string) => httpService.executeMigration(ShopId));
  return { triggerMigration, ...rest };
};

export const useUpdateLeadDetails = () => {
  const queryClient = useQueryClient();
  const { mutate: updateLeadDetails, ...rest } = useMutation(
    (requestBody: UpdateLeadDetailsRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateLeadDetailsCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['matching'] });
      },
    },
  );
  return { updateLeadDetails, ...rest };
};

export const useUpdateTwilioSession = () => {
  const { mutate: updateTwilioSession, ...rest } = useMutation((requestBody: UpdateTwilioSessionRequest) =>
    httpService
      .executeLeadEngineCommand({ requestBody, className: 'UpdateTwilioSessionCommand' })
      .then((resp) => resp.data.data as { sessionStatus: SessionStatusEnum; preflightCheck: boolean }),
  );
  return { updateTwilioSession, ...rest };
};
export const usePreviewAssignmentEmailTemplate = () => {
  const { mutate: resolvePreviewEmailTemplate, ...rest } = useMutation(
    (requestBody: ResolvePreviewEmailTemplateRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'PreviewAssignmentEmailCommand',
        })
        .then((resp) => resp.data.data as PreviewEmailResponse),
  );
  return { resolvePreviewEmailTemplate, ...rest };
};

export const usePreviewReferralEmailTemplate = () => {
  const { mutate: resolvePreviewReferralEmailTemplate, ...rest } = useMutation(
    (requestBody: PreviewReferralEmailRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'PreviewReferralEmailCommand',
        })
        .then((resp) => resp.data.data as PreviewEmailResponse[]),
  );
  return { resolvePreviewReferralEmailTemplate, ...rest };
};

export const useSearchForContactInReceiverShop = () => {
  const { mutateAsync: searchForContactInReceiverShopAsync, ...rest } = useMutation(
    (requestBody: SearchForContactInReceiverShopRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'SearchForContactInReceiverShopCommand',
        })
        .then((resp) => resp.data.data as DuplicateContactInfo[]),
  );
  return { searchForContactInReceiverShopAsync, ...rest };
};

export const useGetPropertyTypesAndSubtypesCommand = () => {
  const { mutate: getPropertyTypesAndSubtypesCommand, ...rest } = useMutation(() =>
    httpService
      .executeLeadEngineCommand({
        requestBody: {},
        className: 'GetPropertyTypesAndSubtypesCommand',
      })
      .then((resp) => resp.data.data as PropertyTypeMapping),
  );
  return { getPropertyTypesAndSubtypesCommand, ...rest };
};
export const useResolveEmailSignature = () => {
  const {
    mutate: resolveEmailSignature,
    mutateAsync: resolveEmailSignatureAsync,
    ...rest
  } = useMutation((requestBody: ResolveEmailSignatureRequest) =>
    httpService.executeLeadEngineCommand({
      requestBody,
      className: 'ResolveEmailSignatureCommand',
    }),
  );
  return { resolveEmailSignature, resolveEmailSignatureAsync, ...rest };
};

export function useResolvedSignature(params: { shopId: string; emailSignatureId?: string }) {
  const { resolveEmailSignatureAsync } = useResolveEmailSignature();
  const { data, ...rest } = useQuery({
    queryKey: ['email', 'useResolvedSignature', params],
    queryFn: async () => (await resolveEmailSignatureAsync(params)).data.data || '',
  });
  return { signature: data, ...rest };
}

export const useCreateAndAssignAgentToShop = () => {
  const queryClient = useQueryClient();
  const { mutate: createAndAssignAgentToShop, ...rest } = useMutation(
    (requestBody: CreateAndAssignAgentToShopRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'CreateAndAssignAgentToShopCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['agent'] });
      },
    },
  );
  return { createAndAssignAgentToShop, ...rest };
};

export const useDeleteEmailTemplate = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteEmailTemplate, ...rest } = useMutation(
    (requestBody: DeleteEmailTemplateRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'DeleteEmailTemplateCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
      },
    },
  );
  return { deleteEmailTemplate, ...rest };
};

export const useAssignLeadToGo3Agent = () => {
  const queryClient = useQueryClient();
  const { mutate: assignLeadToGo3Agent, ...rest } = useMutation(
    (requestBody: AssignLeadToGo3AgentRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'AssignLeadToGo3AgentCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { assignLeadToGo3Agent, ...rest };
};

export const useGetTeamMembersFromGo3 = (teamId?: string) => {
  const { data, ...rest } = useQuery({
    enabled: !!teamId,
    queryKey: ['go3', 'useGetTeamMembersFromGo3', teamId],
    queryFn: () => {
      if (!teamId) {
        return;
      }

      return httpService
        .executeLeadEngineCommand({
          requestBody: { teamId },
          className: 'GetTeamMembersCommand',
        })
        .then((resp) => resp.data.data);
    },
  });
  return { membersData: data, ...rest };
};

export const useGetNylasOneTimeToken = () => {
  const { mutate: getNylasOneTimeToken, ...rest } = useMutation((requestBody: GetNylasOneTimeTokenRequest) =>
    httpService.executeLeadEngineCommand({
      requestBody,
      className: 'GetNylasOneTimeTokenCommand',
    }),
  );
  return { getNylasOneTimeToken, ...rest };
};

export const getGo3DuplicateContacts = (requestBody: GetContactDuplicatesRequest) =>
  httpService
    .executeLeadEngineCommand({
      requestBody,
      className: 'GetContactDuplicatesCommand',
    })
    .then((resp) => resp.data.data as Go3ContactResponse[]);

export const getGo3Contact = (requestBody: GetGo3ContactRequest) =>
  httpService
    .executeLeadEngineCommand({
      requestBody,
      className: 'GetGo3ContactCommand',
    })
    .then((resp) => resp.data.data as Go3ContactResponse);

export const useLinkGo3Contact = () => {
  const queryClient = useQueryClient();
  const { mutate: linkGo3Contact, ...rest } = useMutation(
    (requestBody: LinkGo3ContactRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'LinkGo3ContactCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { linkGo3Contact, ...rest };
};

export const useRelinkGo3Contact = () => {
  const queryClient = useQueryClient();
  const { mutate: relinkGo3Contact, ...rest } = useMutation(
    (requestBody: RelinkGo3ContactRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'RelinkGo3ContactCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { relinkGo3Contact, ...rest };
};

export const useOverwriteLinkGo3Contact = () => {
  const queryClient = useQueryClient();
  const { mutate: overwritelinkGo3Contact, ...rest } = useMutation(
    (requestBody: OverwriteLinkGo3ContactRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'OverwriteGo3ContactCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { overwritelinkGo3Contact, ...rest };
};

export const useCreateBuyerRentalLead = () => {
  const queryClient = useQueryClient();
  const { mutate: createBuyerRentalLead, ...rest } = useMutation(
    (requestBody: CreateBuyerRentalLeadRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'CreateBuyerRentalLeadCommand',
        })
        .then((resp) => resp.data.data as LeadResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { createBuyerRentalLead, ...rest };
};

export const useAddNewSellerRenterLead = () => {
  const queryClient = useQueryClient();
  const { mutate: addNewSellerRenterLead, ...rest } = useMutation(
    (requestBody: AddSellerRenterLeadRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'AddSellerRenterLeadCommand',
        })
        .then((resp) => resp.data.data as LeadResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { addNewSellerRenterLead, ...rest };
};

export const useAddNewBuyerRentalLead = () => {
  const queryClient = useQueryClient();
  const { mutate: addNewBuyerRentalLead, ...rest } = useMutation(
    (requestBody: AddBuyerRentalLeadRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'AddBuyerRentalLeadCommand',
        })
        .then((resp) => resp.data.data as LeadResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { addNewBuyerRentalLead, ...rest };
};

export const useEditSellerRenterLead = () => {
  const queryClient = useQueryClient();
  const { mutate: editSellerRenterLead, ...rest } = useMutation(
    (requestBody: EditSellerRenterLeadRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'EditSellerRenterLeadCommand',
        })
        .then((resp) => resp.data.data as LeadResponse),
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['lead'] }),
    },
  );
  return { editSellerRenterLead, ...rest };
};

export const useEditBuyerRentalLead = () => {
  const queryClient = useQueryClient();
  const { mutate: editBuyerRentalLead, ...rest } = useMutation(
    (requestBody: EditBuyerRentalLeadRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'EditBuyerRentalLeadCommand',
        })
        .then((resp) => resp.data.data as LeadResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['matching'] });
      },
    },
  );
  return { editBuyerRentalLead, ...rest };
};

export const useRetrieveGo3Activities = (requestBody: RetrieveGo3ActivityRequest, disableQuery?: boolean) =>
  useQuery({
    queryKey: ['retrieveGo3Activities', requestBody],
    enabled: !!requestBody.go3ContactUtag && !disableQuery,
    queryFn: () => {
      return httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'RetrieveGo3ActivityCommand',
        })
        .then((resp) => resp.data.data as Go3ActivityFeatures[]);
    },
  });

export const useUpdateUserRoleWithinShop = () => {
  const queryClient = useQueryClient();
  const { mutate: updateUserRoleWithinShop, ...rest } = useMutation(
    (requestBody: UpdateUserRoleWithinShopRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateUserRoleWithinShopCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['agent'] });
      },
    },
  );
  return { updateUserRoleWithinShop, ...rest };
};

export const useRevokeConsents = () => {
  const queryClient = useQueryClient();
  const { mutate: revokeConsents, ...rest } = useMutation(
    (requestBody: RevokeConsentsRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'RevokeConsentsCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { revokeConsents, ...rest };
};

export const useGetConsentTexts = (requestBody: ConsentTextRequest) =>
  useQuery({
    queryKey: ['consentTexts', 'useGetConsentTexts', requestBody],
    enabled: !!requestBody.consentTypes.length,
    queryFn: () => {
      return httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'GetConsentTextCommand',
        })
        .then((resp) => resp.data.data as ConsentTextResponse[]);
    },
  });

export const useCreateContact = () => {
  const queryClient = useQueryClient();
  const { mutate: createContact, ...rest } = useMutation(
    (requestBody: CreateContactRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'CreateContactCommand',
        })
        .then((resp) => resp.data.data as { id: string }),
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['contact'] }),
    },
  );
  return { createContact, ...rest };
};

export const useCheckContactDuplicates = () => {
  const { mutateAsync: checkContactDuplicatesAsync, ...rest } = useMutation(
    (requestBody: CheckContactDuplicatesRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'CheckContactDuplicatesCommand',
        })
        .then((resp) => resp.data.data as DuplicateContactInfo[]),
  );
  return { checkContactDuplicatesAsync, ...rest };
};

export function buildNylasSearchQuery(searchText: string | undefined) {
  return searchText ? `{from:${searchText} to:${searchText} subject:${searchText} "${searchText}"}` : undefined;
}

export const useGetNylasEmailThreads = (
  parameters: GetNylasEmailThreadsRequest & { page: number; limit: number },
  { enabled }: { enabled: boolean },
) => {
  return useQuery({
    queryKey: ['email', 'useGetNylasEmailThreads', parameters],
    enabled: !!parameters.emailAddress && enabled,
    queryFn: async () => {
      const { page, ...requestBody } = parameters;
      const resp = await httpService.executeLeadEngineCommand({
        requestBody,
        className: 'GetNylasEmailThreadsCommand',
      });
      const threadsResponse = resp.data.data as GetNylasEmailThreadsResponse;

      if (threadsResponse.nextCursor) {
        updatePageToken({
          emailIntegration: parameters.emailAddress,
          page: page + 1,
          pageSize: parameters.limit,
          token: threadsResponse.nextCursor,
        });
      }
      return threadsResponse;
    },
  });
};

export const useGetNylasEmailMessagesForThread = () => {
  const { mutate: getNylasEmailMessagesForThread, ...rest } = useMutation(
    (requestBody: GetNylasEmailMessagesForThreadRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'GetNylasEmailMessagesForThreadCommand',
        })
        .then((resp) => resp.data.data as EmailMessageProperties[]),
  );
  return { getNylasEmailMessagesForThread, ...rest };
};

export const useGetNylasUnreadThreadCount = (enabled: boolean, integratedEmail?: string) => {
  return useQuery({
    enabled: !!integratedEmail && enabled,
    queryKey: ['email', integratedEmail, 'threadCount'],
    queryFn: () =>
      httpService
        .executeLeadEngineCommand({
          requestBody: { emailAddress: integratedEmail ?? '' },
          className: 'GetNylasUnreadThreadCountCommand',
        })
        .then((resp) => resp.data.data),
  });
};

export const useGetGo3Teams = () => {
  const { mutate: getGo3Teams, ...rest } = useMutation((requestBody: GetGo3TeamsRequest) =>
    httpService
      .executeLeadEngineCommand({
        requestBody,
        className: 'GetGo3TeamsCommand',
      })
      .then((resp) => resp.data.data as Go3TeamsResponse),
  );
  return { getGo3Teams, ...rest };
};

export const useUpdateNylasEmailThread = () => {
  const { mutate: updateNylasEmailThread, ...rest } = useMutation((requestBody: UpdateNylasEmailThreadRequest) =>
    httpService.executeLeadEngineCommand({
      requestBody,
      className: 'UpdateNylasEmailThreadCommand',
    }),
  );
  return { updateNylasEmailThread, ...rest };
};

export const useLinkEmailToLead = () => {
  const queryClient = useQueryClient();
  const { mutate: linkEmailToLead, ...rest } = useMutation(
    (requestBody: LinkEmailToLeadRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'LinkEmailToLeadCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { linkEmailToLead, ...rest };
};

export const useDeLinkEmailToLead = () => {
  const queryClient = useQueryClient();
  const { mutate: deLinkEmailToLead, ...rest } = useMutation(
    (requestBody: DeLinkEmailToLeadRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'DeLinkEmailToLeadCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { deLinkEmailToLead, ...rest };
};

export const useLinkEmailToContact = () => {
  const queryClient = useQueryClient();
  const { mutate: linkEmailToContact, ...rest } = useMutation(
    (requestBody: LinkEmailToContactRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'LinkEmailToContactCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { linkEmailToContact, ...rest };
};

export const useDeLinkEmailToContact = () => {
  const queryClient = useQueryClient();
  const { mutate: deLinkEmailToContact, ...rest } = useMutation(
    (requestBody: DeLinkEmailToContactRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'DeLinkEmailToContactCommand',
      }),
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['email'] }),
    },
  );
  return { deLinkEmailToContact, ...rest };
};

export const useRerunFailedLeadImports = () => {
  const { mutate: rerunFailedLeadImports, ...rest } = useMutation((requestBody: RerunFailedLeadImportsRequest) => {
    return httpService.executeLeadEngineCommand({
      requestBody,
      className: 'RerunFailedLeadImportsCommand',
    });
  });

  const { mutate: deleteFailedLeadImports, ...deleteRest } = useMutation(
    (requestBody: UpdateFailedLeadImportsRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateFailedLeadImportsCommand',
      });
    },
  );

  return {
    rerunFailedLeadImports,
    deleteFailedLeadImports,
    ...rest,
    deleteRest,
  };
};

export const useBuyNumber = () => {
  const queryClient = useQueryClient();
  const { mutate: buyMobileNumber, ...rest } = useMutation(
    (requestBody: BuyMobileNumberRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'BuyTwilioNumberCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['verifyNumber'] });
      },
    },
  );
  return { buyMobileNumber, ...rest };
};

export const useAddCallerIdNumber = () => {
  const queryClient = useQueryClient();
  const { mutate: addCallerIdNumber, ...rest } = useMutation(
    (requestBody: VerifyMobileNumberRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'AddTwilioOutgoingCallerIdCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['verifyNumber'] });
      },
    },
  );
  return {
    addCallerIdNumber,
    validationCode: rest?.data?.data?.data?.validationCode,
    ...rest,
  };
};

export const useCheckIsCallingAvailable = (requestBody: GetCallingAvailabilityRequest) =>
  useQuery({
    queryKey: ['verifyNumber', 'useCheckIsCallingAvailable'],
    queryFn: async () => {
      const resp = await httpService.executeLeadEngineCommand({
        requestBody,
        className: 'CheckCallingFunctionalityAvailableCommand',
      });
      return resp.data.data;
    },
    refetchInterval: 5000,
  });

export const useAddAdmin = () => {
  const queryClient = useQueryClient();
  const { mutate: addAdmin, ...rest } = useMutation(
    (requestBody: AddAdminRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'AddAdminCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['agent'] });
      },
    },
  );
  return { addAdmin, ...rest };
};

export const useRemoveAdmin = () => {
  const queryClient = useQueryClient();
  const { mutate: removeAdmin, ...rest } = useMutation(
    (requestBody: RemoveAdminRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'RemoveAdminCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['agent'] });
      },
    },
  );
  return { removeAdmin, ...rest };
};

export const useAddTeam = () => {
  const queryClient = useQueryClient();
  const { mutate: addTeam, ...rest } = useMutation(
    (requestBody: AddTeamRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'AddTeamCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['team'] });
      },
    },
  );
  return { addTeam, ...rest };
};

export const useEditTeam = () => {
  const queryClient = useQueryClient();
  const { mutate: editTeam, ...rest } = useMutation(
    (requestBody: EditTeamRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'EditTeamCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['team'] });
      },
    },
  );
  return { editTeam, ...rest };
};

export const useInactivateTeam = () => {
  const queryClient = useQueryClient();
  const { mutate: inactivateTeam, ...rest } = useMutation(
    (requestBody: InactivateTeamRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'InactivateTeamCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['team'] });
      },
    },
  );
  return { inactivateTeam, ...rest };
};

export const useUpdateShopManagedConsentSettings = () => {
  const queryClient = useQueryClient();
  const { mutate: updateShopManagedConsentSettings, ...rest } = useMutation(
    (requestBody: updateShopManagedConsentSettingsRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertShopManagedConsentCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['shop'] });
      },
    },
  );
  return { updateShopManagedConsentSettings, ...rest };
};

export const useMergeContacts = () => {
  const queryClient = useQueryClient();
  const { replace } = useNavigation();
  const { toContactDetails } = useDestination();

  const { mutate: mergeContacts, ...rest } = useMutation(
    (requestBody: MergeContactRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'MergeContactCommand',
      });
    },
    {
      onSuccess: (_, { targetContactId }) => {
        // The navigation needs to happen before the invalidation to prevent issues with the
        // redirect on the contact details page
        replace(toContactDetails({ contactId: targetContactId }));
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { mergeContacts, ...rest };
};

export const useUploadShopProfileImage = () => {
  const queryClient = useQueryClient();
  const { mutate: uploadShopProfileImage, ...rest } = useMutation(
    ({ requestBody, files }: { requestBody: UploadShopProfileImageRequest; files: File[] }) =>
      httpService.executeLeadEngineCommandWithFileUpload({
        requestBody,
        files,
        className: 'UploadShopProfileImageCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['shopProfile'] });
        queryClient.invalidateQueries({ queryKey: ['shop'] });
      },
    },
  );
  return { uploadShopProfileImage, ...rest };
};

export const useDeleteShopProfileImage = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteShopProfileImage, ...rest } = useMutation(
    (requestBody: DeleteShopProfileImageRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'DeleteShopProfileImageCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['shopProfile'] });
      },
    },
  );
  return { deleteShopProfileImage, ...rest };
};

export const useReassignAgentsCommand = () => {
  const queryClient = useQueryClient();

  const { mutate: reassignAgents, ...rest } = useMutation(
    (requestBody: ReassignAgentsOnContactRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'ReassignAgentsOnContactCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { reassignAgents, ...rest };
};

export const useAssignAgentOnContact = () => {
  const queryClient = useQueryClient();

  const { mutate: addResponsibleAgent, ...rest } = useMutation(
    (requestBody: AssignAgentOnContactRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'AssignAgentOnContactCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { addResponsibleAgent, ...rest };
};

export const useUpsertShopProfileWithdrawalPolicy = () => {
  const queryClient = useQueryClient();
  const { mutate: upsertShopProfileWithdrawalPolicy, ...rest } = useMutation(
    (requestBody: UpsertShopProfileWithdrawalPolicyRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertShopProfileWithdrawalPolicyCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['shopProfile'] });
      },
    },
  );
  return { upsertShopProfileWithdrawalPolicy, ...rest };
};

export const useUpsertShopProfileEmailFooter = () => {
  const queryClient = useQueryClient();
  const { mutate: upsertShopProfileEmailFooter, ...rest } = useMutation(
    (requestBody: UpsertShopProfileEmailFooterRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertShopProfileCustomerMailsCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['shopProfile'] });
      },
    },
  );
  return { upsertShopProfileEmailFooter, ...rest };
};

export const useUpdateAgentDetails = () => {
  const queryClient = useQueryClient();
  const { mutate: updateAgentDetails, ...rest } = useMutation(
    (requestBody: UpdateAgentDetailsRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'UpdateAgentDetailsCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['agent'] });
      },
    },
  );
  return { updateAgentDetails, ...rest };
};

export const useUploadAgentProfileImage = () => {
  const queryClient = useQueryClient();
  const { mutate: uploadAgentProfileImage, ...rest } = useMutation(
    ({ requestBody, files }: { requestBody: UploadAgentProfileImageRequest; files: File[] }) =>
      httpService.executeLeadEngineCommandWithFileUpload({
        requestBody,
        files,
        className: 'UploadAgentProfileImageCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['agentProfile'] });
        queryClient.invalidateQueries({ queryKey: ['agent'] });
      },
    },
  );
  return { uploadAgentProfileImage, ...rest };
};

export const useDeleteAgentProfileImage = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteAgentProfileImage, ...rest } = useMutation(
    (requestBody: DeleteAgentProfileImageRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'DeleteAgentProfileImageCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['agentProfile'] });
      },
    },
  );
  return { deleteAgentProfileImage, ...rest };
};

export const useUpdateAgentProfileImageTitle = () => {
  const queryClient = useQueryClient();
  const { mutate: updateAgentProfileImageTitle, ...rest } = useMutation(
    (requestBody: UpdateAgentProfileImageTitleRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateAgentProfileImageTitleCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['agentProfile'] });
      },
    },
  );
  return { updateAgentProfileImageTitle, ...rest };
};

export const useUpdateCallActivity = () => {
  const queryClient = useQueryClient();
  const { mutate: updateCallActivity, ...rest } = useMutation(
    (requestBody: UpdateCallActivityRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateCallActivityCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { updateCallActivity, ...rest };
};

export const useLinkCallToContact = () => {
  const queryClient = useQueryClient();
  const { mutate: linkCallToContact, ...rest } = useMutation(
    (requestBody: LinkCallToContactRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'LinkCallToContactCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { linkCallToContact, ...rest };
};

export const useCreateTask = () => {
  const queryClient = useQueryClient();
  const { mutate: createTask, ...rest } = useMutation(
    (requestBody: CreateTaskRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'CreateTaskCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['user'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['agent'] });
        queryClient.invalidateQueries({ queryKey: ['activity'] });
      },
    },
  );
  return { createTask, ...rest };
};

export const useCreateAppointment = () => {
  const queryClient = useQueryClient();
  const { mutate: createAppointment, ...rest } = useMutation(
    (requestBody: CreateAppointmentRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'CreateAppointmentCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['user'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['agent'] });
        queryClient.invalidateQueries({ queryKey: ['activity'] });
      },
    },
  );
  return { createAppointment, ...rest };
};

export const useShareExpose = () => {
  const queryClient = useQueryClient();
  const { mutate: shareExpose, ...rest } = useMutation(
    (requestBody: ShareExposeRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'CreateDocumentSharedActivityCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { shareExpose, ...rest };
};

export const useAcceptReferral = () => {
  const queryClient = useQueryClient();
  const { mutate: acceptReferral, ...rest } = useMutation(
    (requestBody: AcceptReferralRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'AcceptReferralCommand',
        })
        .then((resp) => resp.data.data as AcceptReferralResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['referral'] });
      },
    },
  );
  return { acceptReferral, ...rest };
};

export const useRejectReferral = () => {
  const queryClient = useQueryClient();
  const { mutate: rejectReferral, ...rest } = useMutation(
    (requestBody: RejectReferralRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'RejectReferralCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['referral'] });
      },
    },
  );
  return { rejectReferral, ...rest };
};

export const useUpdateTask = () => {
  const queryClient = useQueryClient();
  const {
    mutate: updateTask,
    mutateAsync: updateTaskAsync,
    ...rest
  } = useMutation(
    (requestBody: UpdateTaskRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'UpdateTaskCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['user'] });
        queryClient.invalidateQueries({ queryKey: ['agent'] });
        queryClient.invalidateQueries({ queryKey: ['activity'] });
      },
    },
  );
  return { updateTask, updateTaskAsync, ...rest };
};

export const useUpdateAppointment = () => {
  const queryClient = useQueryClient();
  const {
    mutate: updateAppointment,
    mutateAsync: updateAppointmentAsync,
    ...rest
  } = useMutation(
    (requestBody: UpdateAppointmentRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'UpdateAppointmentCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['user'] });
        queryClient.invalidateQueries({ queryKey: ['agent'] });
        queryClient.invalidateQueries({ queryKey: ['activity'] });
      },
    },
  );
  return { updateAppointment, updateAppointmentAsync, ...rest };
};

export const useUploadDocuments = () => {
  const queryClient = useQueryClient();
  const { mutate: uploadDocuments, ...rest } = useMutation(
    ({ requestBody, files }: { requestBody: UploadDocumentsRequest; files: File[] }) =>
      httpService.executeLeadEngineCommandWithFileUpload({
        requestBody,
        files,
        className: 'UploadDocumentsCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { uploadDocuments, ...rest };
};

export const useModifyDocument = () => {
  const queryClient = useQueryClient();
  const { mutate: modifyDocument, ...rest } = useMutation(
    ({ requestBody, files }: { requestBody: ModifyDocumentRequest; files: File[] }) =>
      httpService.executeLeadEngineCommandWithFileUpload({
        requestBody,
        files,
        className: 'ModifyDocumentCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { modifyDocument, ...rest };
};

export const useDeleteDocuments = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteDocuments, ...rest } = useMutation(
    (requestBody: DeleteDocumentsCommandRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'DeleteDocumentsCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { deleteDocuments, ...rest };
};

export const usePublishShopProfile = () => {
  const queryClient = useQueryClient();
  const { mutate: publishShopProfile, ...rest } = useMutation(
    async (requestBody: PublishShopProfileRequest) => {
      return (await httpService.executeLeadEngineCommand({ requestBody, className: 'PublishShopProfileCommand' })).data
        .data as MissingAttribute[];
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['shopProfile'] });
      },
    },
  );
  return { publishShopProfile, ...rest };
};

export const useValidateShopProfile = () => {
  const queryClient = useQueryClient();
  const { mutate: validateShopProfile, ...rest } = useMutation(
    async (requestBody: ValidateShopProfileFieldsRequest) => {
      return (
        await httpService.executeLeadEngineCommand({ requestBody, className: 'ValidateShopProfileFieldsCommand' })
      ).data.data as MissingAttribute[];
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['shopProfile'] });
      },
    },
  );
  return { validateShopProfile, ...rest };
};

export const usePublishAgentProfile = () => {
  const queryClient = useQueryClient();
  const { mutate: publishAgentProfile, ...rest } = useMutation(
    async (requestBody: PublishAgentProfileRequest) => {
      return (await httpService.executeLeadEngineCommand({ requestBody, className: 'PublishAgentProfileCommand' })).data
        .data as MissingAttribute[];
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['agentProfile'] });
      },
    },
  );
  return { publishAgentProfile, ...rest };
};

export const useUnpublishAgentProfile = () => {
  const queryClient = useQueryClient();
  const { mutate: unpublishAgentProfile, ...rest } = useMutation(
    (requestBody: UnpublishAgentProfileCommand) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'UnpublishAgentProfileCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['agentProfile'] });
      },
    },
  );
  return { unpublishAgentProfile, ...rest };
};

export const useValidateAgentProfile = () => {
  const queryClient = useQueryClient();
  const { mutate: validateAgentProfile, ...rest } = useMutation(
    async (requestBody: ValidateAgentProfileFieldsRequest) => {
      return (
        await httpService.executeLeadEngineCommand({ requestBody, className: 'ValidateAgentProfileFieldsCommand' })
      ).data.data as MissingAttribute[];
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['agentProfile'] });
      },
    },
  );
  return { validateAgentProfile, ...rest };
};

export const useDeleteContacts = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteContacts, ...rest } = useMutation(
    (requestBody: DeleteContactsRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'DeleteContactsCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { deleteContacts, ...rest };
};

export const useActivateContacts = () => {
  const queryClient = useQueryClient();
  const { mutate: activateContacts, ...rest } = useMutation(
    (requestBody: ActivateContactsRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'ActivateContactsCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { activateContacts, ...rest };
};

export const useDownloadDocumentReference = () => {
  const {
    mutate: downloadDocument,
    mutateAsync: downloadDocumentAsync,
    ...rest
  } = useMutation((requestBody: DownloadDocumentReferenceRequest) =>
    httpService.executeLeadEngineDownloadCommand({
      requestBody,
      className: 'DownloadDocumentReferenceCommand',
    }),
  );
  return { downloadDocument, downloadDocumentAsync, ...rest };
};

export const useDeclineTwilioCall = () => {
  const { mutate: declineTwilioCall, ...rest } = useMutation((requestBody: DeclineTwilioCallRequest) => {
    return httpService.executeLeadEngineCommand({ requestBody, className: 'DeclineTwilioCallCommand' });
  });
  return { declineTwilioCall, ...rest };
};

export const useUpdateCustomDemandFields = () => {
  const queryClient = useQueryClient();
  const { mutate: updateCustomDemandFields, ...rest } = useMutation(
    (requestBody: CompositeCdfCommandRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'CompositeCdfCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['cdf'] });
      },
    },
  );
  return { updateCustomDemandFields, ...rest };
};

export const useUpsertTags = () => {
  const queryClient = useQueryClient();
  const { mutate: upsertTags, ...rest } = useMutation(
    (requestBody: UpsertTagsCommandRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertTagsCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['tag'] });
      },
    },
  );
  return { upsertTags, ...rest };
};

export const useLinkPropertyToSellerLead = () => {
  const queryClient = useQueryClient();
  const { mutate: linkPropertyToSellerLead, ...rest } = useMutation(
    (requestBody: LinkPropertyToSellerLeadRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'LinkPropertyToSellerLeadCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { linkPropertyToSellerLead, ...rest };
};

export const useUnlinkPropertyToSellerLead = () => {
  const queryClient = useQueryClient();
  const { mutate: unlinkPropertyToSellerLead, ...rest } = useMutation(
    (requestBody: UnlinkPropertyToSellerLeadRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UnlinkPropertyToSellerLeadCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { unlinkPropertyToSellerLead, ...rest };
};

export const useBulkUpdateLeadStatus = () => {
  const queryClient = useQueryClient();
  const { mutate: bulkUpdateLeadStatus, ...rest } = useMutation(
    (requestBody: BulkUpdateLeadStatusRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'BulkUpdateLeadStatusCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { bulkUpdateLeadStatus, ...rest };
};

export const useUpdateLeadAssignments = () => {
  const queryClient = useQueryClient();
  const { mutate: updateLeadAssignments, ...rest } = useMutation(
    (requestBody: UpdateLeadAssignmentsRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateLeadAssignmentsCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { updateLeadAssignments, ...rest };
};

export const useGetPropertyNews = (requestBody: GetPropertyNewsRequest) => {
  const { data, ...rest } = useQuery({
    queryKey: ['propertyNews', 'useGetPropertyNews'],
    queryFn: () => {
      return httpService
        .executeLeadEngineCommand({
          requestBody: requestBody,
          className: 'GetPropertyNewsCommand',
        })
        .then((resp) => resp.data.data);
    },
    refetchOnWindowFocus: false,
  });
  return { propertyNews: data, ...rest };
};

export const useRequestKycCheck = () => {
  const queryClient = useQueryClient();
  const { mutate: requestKycCheck, ...rest } = useMutation(
    (requestBody: RequestKycCheckCommandRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'RequestKycCheckCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { requestKycCheck, ...rest };
};

export const useRerunKycCheck = () => {
  const queryClient = useQueryClient();
  const { mutate: rerunKycCheck, ...rest } = useMutation(
    (requestBody: RerunKycCheckCommandRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'RerunKycCheckCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['kyc'] });
      },
    },
  );
  return { rerunKycCheck, ...rest };
};

export const useGetKycCheck = (
  inputData: GetKycCheckCommandRequest & { kycSearchId: string | null } & { go3Utag: string | null },
) =>
  useQuery({
    queryKey: ['kyc', 'useGetKycCheck', inputData],
    // kycSearchId is not part of the request. It is only used to enable/disable the query
    enabled: !!inputData.contactId && (!!inputData.kycSearchId || !!inputData.go3Utag),
    queryFn: () => {
      return httpService
        .executeLeadEngineCommand({
          requestBody: { contactId: inputData.contactId } as GetKycCheckCommandRequest,
          className: 'GetKycCheckCommand',
        })
        .then((resp) => (resp.data.data as KycCheckResponse) || null);
    },
  });

export const useMergeDuplicateContacts = () => {
  const queryClient = useQueryClient();
  const { mutate: mergeDuplicateContacts, ...rest } = useMutation(
    (requestBody: MergeDuplicateContactsRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'MergeDuplicateContactsCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { mergeDuplicateContacts, ...rest };
};

export const useUpdateShopGroup = () => {
  const queryClient = useQueryClient();
  const { mutate: updateShopGroup, ...rest } = useMutation(
    (requestBody: UpdateShopGroupRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateShopGroupCommand',
      }),
    {
      onSuccess: () => {
        // If the shops in the group change all queries need to be invalidated
        queryClient.invalidateQueries();
      },
    },
  );
  return { updateShopGroup, ...rest };
};

export const useAddShopGroup = () => {
  const queryClient = useQueryClient();
  const { mutate: addShopGroup, ...rest } = useMutation(
    (requestBody: AddShopGroupRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'CreateShopGroupCommand',
      });
    },
    {
      onSuccess: () => {
        // If the shops in the group change all queries need to be invalidated
        queryClient.invalidateQueries();
      },
    },
  );
  return { addShopGroup, ...rest };
};

export const useUpdateShopEnergyAdvisory = () => {
  const queryClient = useQueryClient();
  const { mutate: updateShopEnergyAdvisory, ...rest } = useMutation(
    (requestBody: UpdateShopEnergyAdvisoryRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateShopEnergyAdvisoryCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['shop'] });
      },
    },
  );
  return { updateShopEnergyAdvisory, ...rest };
};
export const useUpdateAgentEnergyAdvisory = () => {
  const queryClient = useQueryClient();
  const { mutate: updateAgentEnergyAdvisory, ...rest } = useMutation(
    (requestBody: UpdateAgentEnergyAdvisoryRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateAgentEnergyAdvisoryCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['agent'] });
      },
    },
  );
  return { updateAgentEnergyAdvisory, ...rest };
};

export const useValidateLipaProfile = () => {
  const queryClient = useQueryClient();
  const { mutate: validateLipaProfile, ...rest } = useMutation(
    async (requestBody: ValidateLicensePartnerProfileFieldsRequest) => {
      return (
        await httpService.executeLeadEngineCommand({
          requestBody,
          className: 'ValidateLicensePartnerProfileFieldsCommand',
        })
      ).data.data as MissingAttribute[];
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { validateLipaProfile, ...rest };
};

export const useUpdateLicensePartner = () => {
  const queryClient = useQueryClient();
  const { mutate: updateLicensePartner, ...rest } = useMutation(
    (requestBody: UpdateLicensePartnerCommandRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateLicensePartnerCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['shop'] });
      },
    },
  );
  return { updateLicensePartner, ...rest };
};
export const useUpdateLiPaEmailSettings = () => {
  const queryClient = useQueryClient();
  const { mutate: updateLipaEmailSettings, ...rest } = useMutation(
    (requestBody: UpdateLicensePartnerProfileEmailSettingsRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateLicensePartnerProfileEmailSettingsCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { updateLipaEmailSettings, ...rest };
};

export const useUpsertLicensePartnerProfileIntroCommand = () => {
  const queryClient = useQueryClient();
  const { mutate: upsertLicensePartnerProfileIntroCommand, ...rest } = useMutation(
    (requestBody: UpsertLicensePartnerProfileIntroCommand) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertLicensePartnerProfileIntroCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { upsertLicensePartnerProfileIntroCommand, ...rest };
};
export const useUpsertLicensePartnerProfileShopOverviewCommand = () => {
  const queryClient = useQueryClient();
  const { mutate: upsertLicensePartnerProfileShopOverviewCommand, ...rest } = useMutation(
    (requestBody: UpsertLicensePartnerProfileShopOverviewCommand) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertLicensePartnerProfileShopOverviewCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { upsertLicensePartnerProfileShopOverviewCommand, ...rest };
};

export const useUpdateLipaProfileFeaturedAgents = () => {
  const queryClient = useQueryClient();
  const { mutate: updateLipaProfileFeaturedAgents, ...rest } = useMutation(
    (requestBody: UpdateLipaProfileFeaturedAgentsRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateLicensePartnerProfileFeaturedAgentsCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { updateLipaProfileFeaturedAgents, ...rest };
};

export const useUpdateShopProfileNewsletter = () => {
  const queryClient = useQueryClient();
  const { mutate: updateShopProfileNewsletter, ...rest } = useMutation(
    (requestBody: UpdateShopProfileNewsletterCommand) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateShopProfileNewsletterCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['shopProfile'] });
      },
    },
  );
  return { updateShopProfileNewsletter, ...rest };
};

export const useUpsertLipaProfileDescription = () => {
  const queryClient = useQueryClient();
  const { mutate: upsertLipaProfileDescription, ...rest } = useMutation(
    (requestBody: UpsertLicensePartnerProfileDescriptionRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertLicensePartnerProfileDescriptionCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { upsertLipaProfileDescription, ...rest };
};

export const useUploadLiPaProfileImage = () => {
  const queryClient = useQueryClient();
  const { mutate: uploadLipaProfileImage, ...rest } = useMutation(
    ({ requestBody, files }: { requestBody: UploadLicensePartnerProfileImageCommand; files: File[] }) =>
      httpService.executeLeadEngineCommandWithFileUpload({
        requestBody,
        files,
        className: 'UploadLicensePartnerProfileImageCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { uploadLipaProfileImage, ...rest };
};

export const useUpdateLicensePartnerProfileImageTitle = () => {
  const queryClient = useQueryClient();
  const { mutate: updateLicensePartnerProfileImageTitle, ...rest } = useMutation(
    (requestBody: UpdateLicensePartnerProfileImageTitleCommand) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateLicensePartnerProfileImageTitleCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { updateLicensePartnerProfileImageTitle, ...rest };
};
export const useUpsertLicensePartnerProfileCareerCommand = () => {
  const queryClient = useQueryClient();
  const { mutate: upsertLicensePartnerProfileCareer, ...rest } = useMutation(
    (requestBody: UpsertLicensePartnerProfileCareerCommand) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertLicensePartnerProfileCareerCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { upsertLicensePartnerProfileCareer, ...rest };
};

export const useUploadLipaProfileImage = () => {
  const queryClient = useQueryClient();
  const { mutate: uploadLipaProfileImage, ...rest } = useMutation(
    ({ requestBody, files }: { requestBody: UploadLicensePartnerProfileImageRequest; files: File[] }) =>
      httpService.executeLeadEngineCommandWithFileUpload({
        requestBody,
        files,
        className: 'UploadLicensePartnerProfileImageCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { uploadLipaProfileImage, ...rest };
};
export const useUpdateLipaProfileImageTitle = () => {
  const queryClient = useQueryClient();
  const { mutate: updateLipaProfileImageTitle, ...rest } = useMutation(
    (requestBody: UpdateLicensePartnerProfileImageTitleRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateLicensePartnerProfileImageTitleCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { updateLipaProfileImageTitle, ...rest };
};

export const useUpsertLipaProfileHeroTeaser = () => {
  const queryClient = useQueryClient();
  const { mutate: upsertLipaProfileHeroTeaser, ...rest } = useMutation(
    (requestBody: UpsertLicensePartnerProfileHeroTeaserRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertLicensePartnerProfileHeroTeaserCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { upsertLipaProfileHeroTeaser, ...rest };
};

export const useUpsertLipaProfileSocialMediaProfiles = () => {
  const queryClient = useQueryClient();
  const { mutate: upsertLipaProfileSocialMediaProfiles, ...rest } = useMutation(
    (requestBody: UpsertLicensePartnerProfileSocialMediaProfilesRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertLicensePartnerProfileSocialMediaProfilesCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { upsertLipaProfileSocialMediaProfiles, ...rest };
};

export const useUpsertLipaProfileSeoTracking = () => {
  const queryClient = useQueryClient();
  const { mutate: upsertLipaProfileSeoTracking, ...rest } = useMutation(
    (requestBody: UpsertLicensePartnerProfileSeoTrackingRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertLicensePartnerProfileSeoTrackingCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { upsertLipaProfileSeoTracking, ...rest };
};

export const useUpsertLipaProfilePrivacyPolicy = () => {
  const queryClient = useQueryClient();
  const { mutate: upsertLipaProfilePrivacyPolicy, ...rest } = useMutation(
    (requestBody: UpsertLicensePartnerProfilePrivacyPolicyRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertLicensePartnerProfilePrivacyPolicyCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { upsertLipaProfilePrivacyPolicy, ...rest };
};

export const useUpsertLipaProfileImprint = () => {
  const queryClient = useQueryClient();
  const { mutate: upsertLipaProfileImprint, ...rest } = useMutation(
    (requestBody: UpsertLicensePartnerProfileImprintRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertLicensePartnerProfileImprintCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { upsertLipaProfileImprint, ...rest };
};

export const useUpsertLiPaFeaturedContent = () => {
  const queryClient = useQueryClient();
  const { mutate: upsertLipaFeaturedContent, ...rest } = useMutation(
    (requestBody: UpsertLicensePartnerProfileFeaturedContentRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertLicensePartnerProfileFeaturedContentCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { upsertLipaFeaturedContent, ...rest };
};

export const useUpdateLipaProfilePropertyPreviews = () => {
  const queryClient = useQueryClient();
  const { mutate: updateLipaProfilePropertyPreviews, ...rest } = useMutation(
    (requestBody: UpdateLicensePartnerProfilePropertyPreviewsRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateLicensePartnerProfilePropertyPreviewsCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lipaProfile'] });
      },
    },
  );
  return { updateLipaProfilePropertyPreviews, ...rest };
};

export const useCreateManualConsent = () => {
  const queryClient = useQueryClient();
  const { mutate: createManualConsent, ...rest } = useMutation(
    ({ requestBody, files }: { requestBody: CreateManualConsentRequest; files: File[] }) =>
      httpService.executeLeadEngineCommandWithFileUpload({
        requestBody,
        files,
        className: 'CreateManualConsentCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { createManualConsent, ...rest };
};

export const useRequestConsent = () => {
  const queryClient = useQueryClient();
  const { mutate: requestConsent, ...rest } = useMutation(
    ({ requestBody }: { requestBody: RequestConsentRequest }) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'RequestConsentViaMailCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { requestConsent, ...rest };
};

export const useUpdateLeadExtendingInteractions = () => {
  const queryClient = useQueryClient();
  const { mutate: updateLeadExtendingInteractions, ...rest } = useMutation(
    (requestBody: UpdateLeadExtendingInteractionsRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'UpdateLeadExtendingInteractionsCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['shop'] });
      },
    },
  );
  return { updateLeadExtendingInteractions, ...rest };
};

export const useLinkLeads = () => {
  const queryClient = useQueryClient();
  const { mutate: linkLeads, ...rest } = useMutation(
    (requestBody: LinkLeadsRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'LinkLeadsCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { linkLeads, ...rest };
};

export const useUnlinkLeads = () => {
  const queryClient = useQueryClient();
  const { mutate: unlinkLeads, ...rest } = useMutation(
    (requestBody: UnlinkLeadsRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UnlinkLeadsCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { unlinkLeads, ...rest };
};

export const useUpsertCustomView = () => {
  const activeShopId = useActiveShopId();

  const queryClient = useQueryClient();
  const { mutate: upsertCustomView, ...rest } = useMutation(
    async (requestBody: Omit<UpsertCustomListViewRequest, 'shopId'>) => {
      if (!activeShopId) {
        return;
      }

      const result = await httpService.executeLeadEngineCommand({
        requestBody: {
          shopId: activeShopId,
          ...requestBody,
        },
        className: 'UpsertCustomListViewCommand',
      });

      return result.data?.data as CustomView;
    },
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['customView'] }),
    },
  );
  return { upsertCustomView, ...rest };
};

export const useDeleteCustomView = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteCustomView, ...rest } = useMutation(
    (requestBody: DeleteCustomListViewRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'DeleteCustomListViewCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['customView'] });
      },
    },
  );
  return { deleteCustomView, ...rest };
};
export const useUpsertShopFeaturedContent = () => {
  const queryClient = useQueryClient();
  const { mutate: upsertShopFeaturedContent, ...rest } = useMutation(
    (requestBody: UpsertShopProfileFeaturedContentRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertShopProfileFeaturedContentCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['shopProfile'] });
      },
    },
  );
  return { upsertShopFeaturedContent, ...rest };
};
