import { useSnackBars } from '@ev/eva-container-api';
import MuiLink from '@mui/material/Link';
import { DbNylasEmailIntegration } from 'api/graphql/generated/graphql';
import { useCurrentAgent } from 'api/graphql/hooks/useCurrentAgent';
import { useGoogleIntegrations } from 'api/graphql/hooks/useGoogleIntegrations';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import React, { ReactNode, useEffect, useRef } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { useDestination } from 'util/navigation/useDestination';
import { usePermissions } from 'util/usePermissions';
import { useSelectedGoogleIntegration } from 'util/useSelectedGoogleIntegration';

export const GoogleIntegrationCheck = React.memo(function IntegrationCheck() {
  const { t } = useTranslation(['communication']);
  const { activeShop } = useActiveShop();

  const { openSnackBar, closeSnackBar } = useSnackBars();
  const { canCreateOrDeleteShopEmailIntegration, canCreateOrDeletePersonalEmailIntegration } = usePermissions();
  const snackBarIdRef = useRef<string | undefined>();

  const { currentAgent } = useCurrentAgent();

  const { googleIntegrations } = useGoogleIntegrations(
    {
      where: {
        _or: [{ agentId: { _eq: currentAgent?.id ?? '' } }, { shopId: { _eq: activeShop.id ?? '' } }],
      },
      agent_id: currentAgent?.id ?? '',
      shop_id: activeShop.id ?? '',
    },
    { enabled: !!activeShop.id },
  );

  const { hasExpiredIntegration, personalIntegrationExpired, shopIntegrationExpired } =
    hasGoogleIntegrationExpired(googleIntegrations);

  const { hasActiveGoogleAccountIntegration, isLoading } = useSelectedGoogleIntegration();
  const { toGoogleIntegrations } = useDestination();

  useEffect(() => {
    let integrationErrorMessage: string | ReactNode = '';

    const getLink = (type: 'personal' | 'shop') => {
      return (
        // Notifications are rendered outside the router context so we have to use a MuiLink
        <MuiLink href={toGoogleIntegrations()} style={{ color: theme.palette.text.primary }}>
          {type === 'personal'
            ? t('communication:globalNotification.expiredIntegration.owner.personal')
            : t('communication:globalNotification.expiredIntegration.owner.shop')}{' '}
          {t('communication:globalNotification.expiredIntegration.owner.integration')}
        </MuiLink>
      );
    };

    if (hasExpiredIntegration && !snackBarIdRef.current) {
      const canCreateOrDeleteShopIntegration = canCreateOrDeleteShopEmailIntegration(activeShop);
      const canCreateOrDeletePersonalIntegration = canCreateOrDeletePersonalEmailIntegration(activeShop);
      if (canCreateOrDeletePersonalIntegration && personalIntegrationExpired) {
        integrationErrorMessage = getLink('personal');
      }
      if (canCreateOrDeleteShopIntegration && shopIntegrationExpired) {
        integrationErrorMessage = getLink('shop');
      }

      if (!canCreateOrDeleteShopIntegration && shopIntegrationExpired) {
        integrationErrorMessage = t('communication:globalNotification.expiredIntegration.user.personal');
      }
      if (!canCreateOrDeletePersonalIntegration && shopIntegrationExpired) {
        integrationErrorMessage = t('communication:globalNotification.expiredIntegration.user.shop');
      }

      snackBarIdRef.current = openSnackBar(integrationErrorMessage, 'warning', {
        asBanner: true,
      });
    }

    if (!hasActiveGoogleAccountIntegration && !isLoading && !hasExpiredIntegration) {
      snackBarIdRef.current = openSnackBar(t('communication:globalNotification.noIntegration'), 'warning', {
        action: {
          text: t('communication:globalNotification.noIntegration.labelToIntegrationsPage'),
          callback: () => {
            window.location.href = toGoogleIntegrations();
          },
        },
        asBanner: true,
      });
    }
    return () => {
      if (snackBarIdRef.current) {
        closeSnackBar(snackBarIdRef.current);
        snackBarIdRef.current = undefined;
      }
    };
  }, [activeShop, canCreateOrDeletePersonalEmailIntegration, canCreateOrDeleteShopEmailIntegration, closeSnackBar, hasExpiredIntegration, openSnackBar, personalIntegrationExpired, shopIntegrationExpired, t, toGoogleIntegrations, hasActiveGoogleAccountIntegration, isLoading]);

  return null;
});

const hasGoogleIntegrationExpired = (integrations?: Partial<DbNylasEmailIntegration>[]) => {
  const hasExpiredIntegration = integrations?.some((integration) => integration.active === false);
  let personalIntegrationExpired = false;
  let shopIntegrationExpired = false;
  integrations?.forEach((integration) => {
    const hasPersonalIntegration = integration.scope === 'PERSONAL';
    const hasShopIntegration = integration.scope === 'SHOP';
    const isExpired = integration.active === false;
    if (hasPersonalIntegration && isExpired) {
      personalIntegrationExpired = true;
    }
    if (hasShopIntegration && isExpired) {
      shopIntegrationExpired = true;
    }
  });

  return {
    hasExpiredIntegration,
    personalIntegrationExpired,
    shopIntegrationExpired,
  };
};
