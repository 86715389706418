import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AppointmentTypeEnum } from 'api/graphql/generated/graphql';
import { useGetAllEnums } from 'api/graphql/hooks/useGetAllEnums';
import { useTeamsAssignedToAgentInShop } from 'api/graphql/hooks/useTeamsAssignedToAgentInShop';
import { ContactsAndLeadsPicker } from 'components/contact/LeadAndContactPicker/ContactsAndLeadsPicker';
import { AgentPicker } from 'components/dashboard/AgentPicker';
import { AgentsPicker } from 'components/forms/AgentsPicker/AgentsPicker';
import { DropDown } from 'components/general/DropDown/DropDown';
import { EVAlert } from 'components/general/EVAlert/EVAlert';
import { EVDatePicker } from 'components/general/EVDatePicker/EVDatePicker';
import { EVTimePicker } from 'components/general/EVTimePicker/EVTimePicker';
import { FormSection, FormSectionHeader } from 'components/general/Form/FormSection';
import { FormStack } from 'components/general/Form/FormStack';
import { NoGoogleIntegrationWarning } from 'components/general/NoGoogleIntegrationWarning';
import CalendarIcon from 'components/icons/calendar_today.svg?react';
import ChatIcon from 'components/icons/chat_1_naked.svg?react';
import DirectoryIcon from 'components/icons/contact.svg?react';
import PropertiesIcon from 'components/icons/home.svg?react';
import MinusIcon from 'components/icons/minus_naked.svg?react';
import StopWatchIcon from 'components/icons/stopwatch.svg?react';
import UserDoubleIcon from 'components/icons/user_double.svg?react';
import AgentIcon from 'components/icons/user_single_2.svg?react';
import { PropertySearchField } from 'components/property/PropertySearch/PropertySearchField';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { appointmentTypeTranslation, taskAppointmentStatusTranslation } from 'const/enumTranslations';
import { useEffect, useMemo } from 'react';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { UNASSIGNED } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';
import { enumToLokalisedOptions } from 'util/mappers/enumToOption';
import { CreateNewAppointmentFormData } from 'util/schemas/newAppointmentSchema';
import { useCanEditActivity, usePermissions } from 'util/usePermissions';
import { useSelectedGoogleIntegration } from 'util/useSelectedGoogleIntegration';

type FilteredAppointmentTypeEnum = Exclude<AppointmentTypeEnum, 'SEATRIAL' | 'PRICE_REDUCTION'>;

export const MAX_PROPERTIES = 3;

export const CreateOrEditAppointmentForm = () => {
  const { t } = useTranslation(['user', 'enums']);
  const { allEnums } = useGetAllEnums();
  const { hasActiveGoogleAccountIntegration } = useSelectedGoogleIntegration();
  const { activeShop } = useActiveShop();
  const { canChangeResponsibleAgentAndTeam } = usePermissions();
  const {
    control,
    getValues,
    watch,
    setValue,
    formState: { errors, defaultValues },
    register,
    clearErrors,
  } = useFormContext<CreateNewAppointmentFormData>();
  const [watchedActionType, watchedTeamId] = watch(['actionType', 'teamId']);
  const watchedStartTime = watch('startTime');
  const isViewing = watchedActionType === 'VIEWING';
  const isMiscellaneous = watchedActionType === 'MISCELLANEOUS';
  const [comment, feedback, additionalAgents] = watch(['comment', 'feedback', 'additionalAgents']);
  const disabledAppointmentTypes: AppointmentTypeEnum[] = ['SEATRIAL', 'PRICE_REDUCTION'];
  const frequentlyUsedTypeSortOrder = [
    'VIEWING',
    'VALUATION',
    'LISTING_PRESENTATION',
    'MISCELLANEOUS',
    'HANDOVER',
    'PRICE_MODIFICATION',
    'NOTARY',
    'OPEN_HOUSE',
  ] as const satisfies readonly FilteredAppointmentTypeEnum[];

  const {
    field: { value: agentId, onChange: onAgentIdChange },
    fieldState: { error: agentError },
  } = useController({ name: 'agentId', control });

  const { agentTeamAssignments } = useTeamsAssignedToAgentInShop({
    where: { id: { _eq: agentId } },
    shopId: watch('shopId'),
  });
  const teams = useMemo(
    () =>
      agentTeamAssignments.length
        ? agentTeamAssignments.map((team) => ({ label: team.name, value: team.id }))
        : [{ label: t('user:dashboard.appointment.team.notAssigned'), value: UNASSIGNED }],
    [agentTeamAssignments, t],
  );

  useEffect(() => {
    clearErrors();
  }, [watchedActionType, clearErrors]);

  useEffect(() => {
    if (!teams.find(({ value }) => value === watchedTeamId)) {
      setValue('teamId', (agentId && teams?.[0]?.value) || '');
    }
  }, [agentId, teams, setValue, watchedTeamId]);

  useEffect(() => {
    const currentTitleValue = getValues('subject');

    /** Don't reset title if it's edited by the agent */
    if (
      !currentTitleValue ||
      frequentlyUsedTypeSortOrder.find(
        (type) =>
          currentTitleValue ===
          t('user:dashboard.appointment.title.text', { type: t(appointmentTypeTranslation[type]) }),
      )
    ) {
      const subject = t('user:dashboard.appointment.title.text', {
        type: t(appointmentTypeTranslation[watchedActionType]),
      });
      setValue('subject', subject);
    }
  }, [setValue, watchedActionType, getValues, frequentlyUsedTypeSortOrder, t]);

  useEffect(() => {
    setValue('endTime', new Date(watchedStartTime.getTime() + 60 * 60 * 1000), {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [setValue, watchedStartTime]);

  const isEdit = !!watch('_editAppointmentActivity');
  const hasEditPermissions = useCanEditActivity(watch('_editAppointmentActivity'));
  const hasReadOnlyStatus = defaultValues?.status === 'CANCELLED' || defaultValues?.status === 'COMPLETED';
  const readOnly = isEdit && (hasReadOnlyStatus || !hasEditPermissions);
  const disabled = readOnly || !hasActiveGoogleAccountIntegration;
  const appointmentCreatedBeforeMigration = !!defaultValues?.go3AppointmentKey;
  const canViewResponsibilitySection = canChangeResponsibleAgentAndTeam(activeShop);

  function getReadOnlyAlert() {
    if (!hasEditPermissions) {
      return (
        <FormSection>
          <EVAlert severity="info">{t(`user:activity.taskOrAppointment.noEditPermissions`)}</EVAlert>
        </FormSection>
      );
    }
    if (hasReadOnlyStatus) {
      return (
        <FormSection>
          <EVAlert severity="info">{t(`user:dashboard.appointment.statusAlert.${defaultValues?.status}`)}</EVAlert>
        </FormSection>
      );
    }
  }

  return (
    <>
      <Stack p={3}>
        {readOnly && getReadOnlyAlert()}
        {appointmentCreatedBeforeMigration && (
          <Stack>
            <EVAlert severity="warning">{t(`user:dashboard.activityDrawer.migrationAlert`)}</EVAlert>
          </Stack>
        )}
        {!hasActiveGoogleAccountIntegration ? (
          <FormSection>
            <NoGoogleIntegrationWarning />
          </FormSection>
        ) : null}
        <FormSection hidden={!canViewResponsibilitySection}>
          <FormSectionHeader>{t('user:dashboard.appointment.section.responsibility')}</FormSectionHeader>
          <FormStack icon={<AgentIcon />}>
            <Controller
              name="agentId"
              control={control}
              render={() => (
                <AgentPicker
                  required
                  shopId={watch('shopId')}
                  sx={{ width: '100%' }}
                  agentError={agentError?.message}
                  agentId={agentId ?? ''}
                  defaultAgentId={defaultValues?.agentId}
                  onChangeAgentId={onAgentIdChange}
                  disabled={disabled}
                  disableAgentsWithoutEmailIntegration
                />
              )}
            />
          </FormStack>
          <FormStack>
            <Controller
              name="teamId"
              control={control}
              render={({ field }) => (
                <DropDown
                  {...field}
                  required
                  disabled={disabled}
                  hasError={!!errors?.teamId}
                  errorMessage={errors?.teamId?.message}
                  label={t('user:dashboard.appointment.team')}
                  options={teams}
                />
              )}
            />
          </FormStack>
        </FormSection>
        <FormSection>
          <FormSectionHeader>{t('user:dashboard.appointment.section.dueDate')}</FormSectionHeader>
          <FormStack icon={<CalendarIcon />}>
            <Stack direction={'row'} gap={2}>
              <Box sx={{ flexGrow: 1 }}>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <EVDatePicker
                      label={t('user:dashboard.appointment.date')}
                      error={!!errors.date}
                      helperText={errors.date?.message}
                      required
                      disabled={disabled}
                      showWeekDay
                      {...field}
                    />
                  )}
                />
              </Box>
              <Stack direction={'row'} gap={0.25} sx={{ width: '280px' }}>
                <Box>
                  <Controller
                    name="startTime"
                    control={control}
                    render={({ field }) => (
                      <EVTimePicker
                        required
                        label={t('user:dashboard.appointment.startTime')}
                        error={!!errors.startTime}
                        disabled={disabled}
                        helperText={errors.startTime?.message}
                        hideIcon
                        {...field}
                      />
                    )}
                  />
                </Box>
                <MinusIcon style={{ marginTop: '12px' }} />
                <Box>
                  <Controller
                    name="endTime"
                    control={control}
                    render={({ field }) => (
                      <EVTimePicker
                        required
                        label={t('user:dashboard.appointment.endTime')}
                        error={!!errors.endTime}
                        disabled={disabled}
                        helperText={errors.endTime?.message}
                        hideIcon
                        startTime={watchedStartTime}
                        {...field}
                      />
                    )}
                  />
                </Box>
              </Stack>
            </Stack>
          </FormStack>
        </FormSection>
        <FormSection>
          <FormSectionHeader>{t('user:dashboard.appointment.section.typeAndProperties')}</FormSectionHeader>
          <FormStack icon={<StopWatchIcon />}>
            <Controller
              name="actionType"
              control={control}
              render={({ field }) => (
                <DropDown
                  {...field}
                  required
                  disabled={disabled}
                  hasError={!!errors?.actionType}
                  errorMessage={errors?.actionType?.message}
                  label={t('user:dashboard.appointment.actionType')} // Type
                  options={
                    /** Filter on frontend for compatibility reasons */
                    allEnums?.enumsAppointmentType
                      ?.filter(
                        (appointmentType) =>
                          !disabledAppointmentTypes.includes(appointmentType?.name as AppointmentTypeEnum),
                      )
                      .sort(
                        ({ name }, { name: name2 }) =>
                          frequentlyUsedTypeSortOrder.indexOf(name as FilteredAppointmentTypeEnum) -
                          frequentlyUsedTypeSortOrder.indexOf(name2 as FilteredAppointmentTypeEnum),
                      )
                      .map(enumToLokalisedOptions(t, appointmentTypeTranslation)) ?? []
                  }
                />
              )}
            />
          </FormStack>
          <FormStack>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <DropDown
                  {...field}
                  required
                  label={t('user:dashboard.appointment.status')}
                  disabled={disabled}
                  options={
                    allEnums?.enumsTaskAppointmentStatus?.map(
                      enumToLokalisedOptions(t, taskAppointmentStatusTranslation),
                    ) ?? []
                  }
                />
              )}
            />
          </FormStack>
          <FormStack>
            <TextField
              label={t('user:dashboard.appointment.subject')}
              required
              error={!!errors.subject}
              helperText={errors.subject?.message}
              disabled={disabled}
              {...register('subject')}
            />
          </FormStack>
          <FormStack icon={<DirectoryIcon />}>
            <ContactsAndLeadsPicker
              required
              errorMessage={errors.contactsAndLeads?.message || errors.contactsAndLeads?.[0]?.message}
              name="contactsAndLeads"
              disabled={disabled}
              limitTags={2}
              label={t('user:dashboard.appointment.contacts')}
              allowLeads
              sx={{ width: '100%' }}
            />
          </FormStack>
          <FormStack icon={<PropertiesIcon />}>
            <PropertySearchField
              required={!isMiscellaneous}
              maxProperties={MAX_PROPERTIES}
              disabled={disabled}
              sx={{ width: '100%' }}
              label={t('user:dashboard.appointment.properties.label')}
            />
          </FormStack>
          <FormStack
            descriptionText={t('user:dashboard.appointment.internalComments')}
            isOpen={!!comment}
            icon={<ChatIcon />}
          >
            <TextField
              label={t('user:dashboard.appointment.internalComments.label')}
              disabled={disabled}
              multiline
              {...register('comment')}
            />
          </FormStack>
          <FormStack
            descriptionText={t('user:dashboard.appointment.viewingFeedback')}
            isOpen={!!feedback}
            icon={<ChatIcon style={{ transform: 'rotateY(180deg)' }} />}
            hidden={!isViewing}
          >
            <TextField
              label={t('user:dashboard.appointment.viewingFeedback')}
              disabled={disabled}
              multiline
              {...register('feedback')}
            />
          </FormStack>
          <FormStack
            descriptionText={t('user:dashboard.appointment.additionalAgents')}
            isOpen={!!additionalAgents}
            icon={<UserDoubleIcon />}
          >
            <Controller
              name="additionalAgents"
              control={control}
              render={({ field, fieldState }) => (
                <AgentsPicker
                  errorMessage={errors.additionalAgents?.message || errors.additionalAgents?.[0]?.message}
                  label={t('user:dashboard.appointment.additionalAgents.label')}
                  sx={{ width: '100%' }}
                  fieldState={fieldState}
                  disabled={disabled}
                  {...field}
                />
              )}
            />
          </FormStack>
        </FormSection>
      </Stack>
    </>
  );
};
