import { SxProps } from '@mui/material';
import { IntentTypeEnum } from 'api/graphql/generated/graphql';
import { useGetAllEnums } from 'api/graphql/hooks/useGetAllEnums';
import { useGetPropertyTypesAndSubtypesCommand } from 'api/rest';
import { PropertyTypeMapping } from 'api/rest/models/PropertyTypeMapping';
import { DropDown } from 'components/general/DropDown/DropDown';
import { FormSection } from 'components/general/Form/FormSection';
import { FormStack } from 'components/general/Form/FormStack';
import HomeIcon from 'components/icons/home.svg?react';
import { assetSubTypeTranslation, assetTypeTranslation, assetVariationTranslation } from 'const/enumTranslations';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { enumToLokalisedOptions } from 'util/mappers/enumToOption';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';

const PropertySection = ({ intentType, sx }: { intentType?: IntentTypeEnum; sx?: SxProps }) => {
  const { t } = useTranslation(['lead', 'enums']);
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<PropertyDetailsFormData>();
  const { getPropertyTypesAndSubtypesCommand } = useGetPropertyTypesAndSubtypesCommand();

  const watchPropertyType = watch('search.propertyType');
  const watchPropertySubtypes = watch('search.subTypes');
  const [propertyTypeMap, setPropertyTypeMap] = useState<PropertyTypeMapping>();

  const propertySubtypesValues = useMemo(() => {
    return watchPropertyType ? Object.keys(propertyTypeMap?.[watchPropertyType] || {}) : [];
  }, [propertyTypeMap, watchPropertyType]);

  const propertyVariationValues = useMemo(() => {
    if (watchPropertyType && watchPropertySubtypes?.length === 1) {
      return propertyTypeMap?.[watchPropertyType]?.[watchPropertySubtypes[0]!] ?? [];
    }
    return [];
  }, [propertyTypeMap, watchPropertyType, watchPropertySubtypes]);

  const { allEnums } = useGetAllEnums();
  const isRent = intentType === 'RENT_OUT' || intentType === 'TO_RENT';

  useEffect(() => {
    getPropertyTypesAndSubtypesCommand(undefined, {
      onSuccess: (response) => setPropertyTypeMap(response),
    });
  }, [getPropertyTypesAndSubtypesCommand]);

  return (
    <FormSection sx={sx}>
      <FormStack icon={<HomeIcon />}>
        <Controller
          name="search.propertyType"
          control={control}
          render={({ field: { onChange, ...field } }) => (
            <DropDown
              hasError={!!errors.search?.propertyType}
              errorMessage={errors.search?.propertyType?.message}
              {...field}
              onChange={(value) => {
                onChange(value);
                setValue('search.subTypes', []);
                setValue('search.variations', []);
              }}
              required
              label={t('lead:propertyEvaluateForm.details.propertyType')}
              options={
                allEnums?.enumsAssetType
                  ?.filter((item) => !isRent || item.name !== 'PLOT') //We don't have PLOT type for Rent or Rent-out in Go3
                  .map(enumToLokalisedOptions(t, assetTypeTranslation)) || []
              }
            />
          )}
        />
      </FormStack>
      <FormStack>
        <Controller
          name="search.subTypes"
          control={control}
          render={({ field: { onChange, ...field } }) => (
            <DropDown
              {...field}
              onChange={(value) => {
                onChange(value);
                setValue('search.variations', []);
              }}
              disabled={!watchPropertyType || propertySubtypesValues.length <= 0}
              multiple
              label={t('lead:propertyEvaluateForm.details.propertySubType')}
              options={(propertySubtypesValues || []).map((e) =>
                enumToLokalisedOptions(t, assetSubTypeTranslation)({ name: e }),
              )}
            />
          )}
        />
      </FormStack>
      {watchPropertySubtypes?.length === 1 && propertyVariationValues.length > 0 && (
        <FormStack>
          <Controller
            name="search.variations"
            control={control}
            render={({ field }) => (
              <DropDown
                {...field}
                multiple
                label={t('lead:propertyEvaluateForm.details.propertyVariation')}
                options={propertyVariationValues?.map((e) =>
                  enumToLokalisedOptions(t, assetVariationTranslation)({ name: e }),
                )}
              />
            )}
          />
        </FormStack>
      )}
    </FormSection>
  );
};

export default PropertySection;
