import { Menu } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { useCurrentAgentId } from 'api/graphql/hooks/useCurrentAgent';
import { useEmailSignatures } from 'api/graphql/hooks/useEmailSignatures';
import LinkIcon from 'components/icons/link_external.svg?react';
import Signature from 'components/icons/signature.svg?react';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { useDestination } from 'util/navigation/useDestination';
import { createSignatureQuery } from 'util/queries/createSignatureQuery';

export function SignatureMenu({
  onSignatureSelected,
  disabled = false,
}: {
  onSignatureSelected: (signature: { shopId: string; emailSignatureId?: string }) => void;
  disabled?: boolean;
}) {
  const [signaturesAnchorEl, setSignaturesAnchorEl] = useState<null | HTMLElement>(null);

  const { t } = useTranslation(['communication']);
  const currentAgentId = useCurrentAgentId();
  const { activeShop, teamsWhereUserIsMember } = useActiveShop();
  const activeShopId = activeShop?.id;
  const teamsIdsWhereUserIsMember = teamsWhereUserIsMember.map((team) => team.id);

  const isOpen = !!signaturesAnchorEl;

  const { emailSignatures } = useEmailSignatures({
    where: {
      ...createSignatureQuery(currentAgentId ?? '', teamsIdsWhereUserIsMember, activeShopId ?? ''),
    },
    agent_id: currentAgentId ?? '',
    shop_id: activeShopId ?? '', // to be removed once personal signatures contain shopId
  });

  const { toEmailSignatures } = useDestination();

  return (
    <>
      <Menu
        id="signature-menu"
        anchorEl={signaturesAnchorEl}
        open={isOpen}
        onClose={() => setSignaturesAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          style: { paddingBottom: '0px' },
        }}
      >
        {emailSignatures?.map((signature) => (
          <MenuItem
            key={signature.id}
            onClick={() => {
              setSignaturesAnchorEl(null);
              onSignatureSelected({
                shopId: activeShopId ?? '',
                emailSignatureId: signature.id,
              });
            }}
          >
            <Signature style={{ marginRight: '8px' }} />
            {signature.name}
          </MenuItem>
        ))}
        <Link to={toEmailSignatures()} target="_blank" style={{ color: theme.palette.text.primary }}>
          <MenuItem sx={{ backgroundColor: theme.palette.shade.grey5 }}>
            <LinkIcon style={{ marginRight: '8px' }} />
            {t('communication:newEmail.manage.signatures.search')}
          </MenuItem>
        </Link>
      </Menu>
      <IconButton
        aria-label="signature button"
        id="signature-button"
        aria-controls={isOpen ? 'signature-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={(event) => setSignaturesAnchorEl(event.currentTarget)}
        disabled={disabled}
      >
        <Signature />
      </IconButton>
    </>
  );
}
