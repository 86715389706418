import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';

export function contactRelationshipMapper(data: ContactDetailsFormData) {
  return (data.contactRelationships || []).flatMap((relationship) =>
    [...relationship.visibleContacts, ...relationship.hiddenContacts].map((contact) => ({
      relationshipType: relationship.type!,
      autoCc: relationship.autoCc,
      relatedContactId: contact.id,
    })),
  );
}
