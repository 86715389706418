import { TwilioSession } from '@ev/eva-container-api';
import { MutationOptions } from '@tanstack/react-query';
import { SessionStatusEnum } from 'api/graphql/generated/graphql';
import { useCreateTwilioSession, useDestroyTwilioSession, usePing, useUpdateTwilioSession } from 'api/rest';
import { DestroyTwilioSessionRequest, UpdateTwilioSessionRequest } from 'api/rest/models/rest/Command';
import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useInterval } from 'util/useInterval';

export const PING_CONFIG = { interval: 30000 };

export function useTwilioSession(enabled: boolean) {
  const [twilioSession, setTwilioSession] = useState<TwilioSession>();

  const { createTwilioSession } = useCreateTwilioSession({
    onSuccess: setTwilioSession,
  });
  const { ping: updateSession } = usePing();

  const twilioSessionCreated = useRef(false);
  useEffect(() => {
    if (enabled && !twilioSessionCreated.current) {
      twilioSessionCreated.current = true;
      createTwilioSession();
    }
    if (!enabled) {
      twilioSessionCreated.current = false;
    }
  }, [createTwilioSession, enabled]);

  useInterval(
    () => {
      if (twilioSession?.sessionId) {
        updateSession(
          { sessionId: twilioSession?.sessionId },
          {
            onSuccess: (status) =>
              setTwilioSession((session) => (session ? { ...session, sessionStatus: status } : undefined)),
          },
        );
      }
    },
    twilioSession?.sessionId ? PING_CONFIG.interval : null,
  );

  const { updateTwilioSession } = useUpdateTwilioSession();
  const updateTwilioSessionStatus = useCallback(
    (
      request: UpdateTwilioSessionRequest,
      options?: MutationOptions<
        { sessionStatus: SessionStatusEnum; preflightCheck: boolean },
        unknown,
        UpdateTwilioSessionRequest
      >,
    ) => {
      updateTwilioSession(request, {
        ...options,
        onSuccess: (data, variables, context) => {
          setTwilioSession((session) =>
            session
              ? { ...session, sessionStatus: data.sessionStatus, preflightCheck: data.preflightCheck }
              : undefined,
          );
          options?.onSuccess?.(data, variables, context);
        },
      });
    },
    [updateTwilioSession],
  );

  const { destroyTwilioSession: destroySession } = useDestroyTwilioSession();
  const destroyTwilioSession = useCallback(
    (options?: MutationOptions<AxiosResponse, unknown, DestroyTwilioSessionRequest>) => {
      if (twilioSession?.sessionId) {
        destroySession(
          { sessionId: twilioSession?.sessionId },
          {
            ...options,
            onSuccess: (newStatus, variables, context) => {
              setTwilioSession(undefined);
              options?.onSuccess?.(newStatus, variables, context);
            },
          },
        );
      }
    },
    [destroySession, twilioSession?.sessionId],
  );

  return { twilioSession, updateTwilioSessionStatus, destroyTwilioSession };
}
