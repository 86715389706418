import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

export const googleIntegrationsQuery = graphql(/* GraphQL */ `
  query GoogleIntegrations(
    $order_by: [DbNylasEmailIntegrationOrderBy!]
    $where: DbNylasEmailIntegrationBoolExp
    $agent_id: String
    $shop_id: String
  ) {
    dbNylasEmailIntegration(orderBy: $order_by, where: $where) {
      ...NylasEmailIntegration
    }
  }
`);

export const useGoogleIntegrations = (
  parameters: VariablesOf<typeof googleIntegrationsQuery> & {
    selectedIntegration?: string;
  },
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  // The selected integration is part of the queryKey to invalidate the query if the integration changes in another tab
  const { selectedIntegration, ...variables } = parameters;
  const { data: googleIntegrations, ...rest } = useQuery({
    queryKey: ['email', 'useGoogleIntegrations', parameters],
    queryFn: async () => (await graphqlClient.request(googleIntegrationsQuery, variables)).dbNylasEmailIntegration,
    ...options,
  });

  return { googleIntegrations, ...rest };
};
