import { Box, SxProps, Typography, useMediaQuery } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import {
  ContactSearchOption,
  LeadSearchOption,
} from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import { EVChip } from 'components/general/Chips/EVChip/EVChip';
import { EntityTooltip } from 'components/general/Chips/EntityTooltip/EntityTooltip';
import { EntityTooltipHeader } from 'components/general/Chips/EntityTooltip/EntityTooltipHeader';
import { ContactTooltip } from 'components/general/Chips/TooltipChip/ContactTooltipChip';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { IntentTag } from 'components/general/IntentTag/IntentTag';
import { LinkButton } from 'components/general/LinkButton';
import InfoIcon from 'components/icons/info.svg?react';
import { PropsWithChildren, ReactNode } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { intentTypeToIntentTabs } from 'util/intentType';
import { useDestination } from 'util/navigation/useDestination';
import { fullName, fullNameFromContact, joinWithComma, leadString, useLeadChipString } from 'util/stringUtils';
import { sxTruncate } from 'util/styleUtils';
import { useCreateShortLeadSummary } from 'util/summaries/leadSummary';
import { useFormatTime } from 'util/useFormatTime';

export const LeadItem = ({ lead, sx }: { lead: LeadSearchOption; sx?: SxProps }) => {
  const { t } = useTranslation(['user']);
  return (
    <Stack sx={sx}>
      <Stack direction={'row'} gap={1} alignItems={'center'}>
        <IntentTag
          intent={intentTypeToIntentTabs(lead?.intentType)}
          sx={{
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.transparent.gray10,
            width: 20,
            height: 20,
          }}
          compact
        />
        <Typography variant="body3" lineHeight={2}>
          {leadString(lead, t)}
        </Typography>
      </Stack>
      <Typography variant="body4" lineHeight={1.75} color={theme.palette.text.secondary}>
        {useCreateShortLeadSummary(lead)}
      </Typography>
    </Stack>
  );
};

interface ContactItemProps extends React.HTMLAttributes<HTMLElement> {
  option?: Pick<
    ContactSearchOption,
    'firstName' | 'lastName' | 'email' | 'id' | 'isServiceContact' | 'go3Utag' | 'lastActivity'
  >;
  sx?: SxProps;
  children?: ReactNode | undefined;
  type?: string;
  lead?: LeadSearchOption;
}

export const ContactItem = ({ option, children, sx, type, lead, ...props }: ContactItemProps) => {
  const { t } = useTranslation(['user']);
  const isTablet = useMediaQuery(theme.breakpoints.down('desktop'));
  const { formatDateTime } = useFormatTime();

  const getEmailPhoneString = ({ email, phoneNumber }: { email?: string; phoneNumber?: string }) => {
    return joinWithComma([email, phoneNumber]);
  };

  return (
    <Stack sx={{ display: 'flex', ...sx }} pt={1} direction={'row'} gap={1} {...props}>
      <EVAvatar
        person={{ firstName: option?.firstName, lastName: option?.lastName }}
        size="s"
        sx={{ alignSelf: 'flex-start' }}
      />
      <Stack sx={{ minWidth: 0 }}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography variant="body2" component="div" sx={sxTruncate}>
            {fullNameFromContact(option)}
          </Typography>
          {type === 'LEAD'
            ? lead &&
              !isTablet && (
                <LeadOptionTooltip lead={lead}>
                  <InfoIcon style={{ display: 'block', color: theme.palette.secondary.main }} />
                </LeadOptionTooltip>
              )
            : option &&
              !isTablet && (
                <ContactTooltip contactId={option.id}>
                  <InfoIcon style={{ display: 'block', color: theme.palette.secondary.main }} />
                </ContactTooltip>
              )}
        </Stack>

        {option?.isServiceContact && (
          <Box
            sx={{
              backgroundColor: theme.palette.shade.grey4,
              fontSize: theme.typography.body4,
              width: 'fit-content',
              borderRadius: '2px',
              paddingLeft: '4px',
              paddingRight: '4px',
            }}
          >
            {t('user:contactsPicker.serviceContact')}
          </Box>
        )}
        <Typography
          variant="body4"
          sx={{
            color: theme.palette.text.secondary,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {getEmailPhoneString(option || {})}
        </Typography>
        <Typography
          variant="body4"
          sx={{
            color: theme.palette.text.secondary,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {`${option?.go3Utag}, ${t('user:contactsPicker.lastActivity')}: ${formatDateTime(option?.lastActivity)}`}
        </Typography>
        {children}
      </Stack>
    </Stack>
  );
};

function useGetLabel(lead: LeadSearchOption) {
  const leadString = useLeadChipString(lead);
  return `${fullName(lead.contact)}, ${leadString}`;
}

export function LeadOptionTooltip({
  lead,
  sx,
  children,
}: PropsWithChildren<{
  lead: LeadSearchOption;
  index?: number;
  sx?: SxProps;
}>) {
  const { toLeadDetails } = useDestination();
  const label = useGetLabel(lead);

  const linkTo = lead.id && lead.contactId ? toLeadDetails({ leadId: lead.id, contactId: lead.contactId }) : undefined;

  return (
    <EntityTooltip
      header={
        <EntityTooltipHeader
          label={!!linkTo && <LinkButton to={linkTo}>{label}</LinkButton>}
          avatar={<EVAvatar lead={lead} size="l" />}
        />
      }
      sx={sx}
    >
      {children}
    </EntityTooltip>
  );
}

export const LeadOptionChip = ({
  lead,
  index,
  getTagProps,
  sx,
}: {
  lead: LeadSearchOption;
  index?: number;
  getTagProps?: AutocompleteRenderGetTagProps;
  sx?: SxProps;
}) => {
  const label = useGetLabel(lead);
  const tabProps = getTagProps && index !== undefined ? getTagProps({ index }) : undefined;

  return (
    <LeadOptionTooltip lead={lead} sx={sx}>
      <EVChip
        label={label}
        {...tabProps}
        key={lead.id}
        avatar={<EVAvatar person={lead.contact} size="s" />}
        selected={false}
      />
    </LeadOptionTooltip>
  );
};
