import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { setupI18n } from 'util/i18next';

export function LokalizationProvider({ children }: PropsWithChildren) {
  const [i18nReady, setI18nReady] = useState<boolean>();
  const setupI18nCalledRef = useRef(false);

  useEffect(() => {
    if (!setupI18nCalledRef.current) {
      setupI18nCalledRef.current = true;
      setupI18n().then(() => setI18nReady(true));
    }
  }, []);

  if (!i18nReady) {
    return null;
  }

  return <>{children}</>;
}
