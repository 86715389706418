import { useParams, useSearchParams } from 'react-router-dom';
import { useDestination } from 'util/navigation/useDestination';
import { buildUrl, useNavigation } from 'util/navigation/useNavigation';

export function useSelectedLead() {
  const { leadId } = useParams();
  return leadId ?? '';
}

export function useSelectedContact() {
  const { contactId } = useParams();
  return contactId ?? '';
}

export function useSetSelectedLead() {
  const { push } = useNavigation();
  const { contactId } = useParams();
  const { toLeadDetails } = useDestination();
  const [searchParams] = useSearchParams();

  const setSelectedLead = (newLeadId: string) => {
    push(buildUrl(toLeadDetails({ leadId: newLeadId, contactId: contactId! }), searchParams));
  };
  return setSelectedLead;
}

export function useNavigateToLead() {
  const { push } = useNavigation();
  const { toLeadDetails } = useDestination();
  const [searchParams] = useSearchParams();

  const navigateToLead = (leadId: string, contactId: string, isAddProperty?: boolean) => {
    const checkedSearchParams = isAddProperty ? { addProperty: 'PROPERTY', ...searchParams } : searchParams;

    push(buildUrl(toLeadDetails({ leadId, contactId }), { ...checkedSearchParams }));
  };
  return navigateToLead;
}
