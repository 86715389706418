import { useLocale } from '@ev/eva-container-api';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useCallback } from 'react';
import { EMPTY_DATA_STRING } from 'util/emptyDataUtils';

const DIVISIONS = [
  { amount: 60, name: 'seconds' },
  { amount: 60, name: 'minutes' },
  { amount: 24, name: 'hours' },
  { amount: 7, name: 'days' },
  { amount: 4.34524, name: 'weeks' },
  { amount: 12, name: 'months' },
  { amount: Number.POSITIVE_INFINITY, name: 'years' },
];

interface FormatDateTimeOptions {
  emptyValueDisplayString?: string;
  hideCurrentYear?: boolean;
}

//check for an invalid date with isNan
//https://masteringjs.io/tutorials/fundamentals/check-if-date-is-valid
function isInvalidDate(date: Date | string | number) {
  return isNaN(new Date(date).valueOf());
}

export function useFormatTime() {
  const locale = useLocale();

  const formatDateTime = useCallback(
    (
      date: Maybe<Date | string | number>,
      options?: Intl.DateTimeFormatOptions,
      formatOptions?: FormatDateTimeOptions,
    ): string => {
      const { emptyValueDisplayString, hideCurrentYear } = formatOptions || {};
      if (!date || isInvalidDate(date)) return emptyValueDisplayString ?? EMPTY_DATA_STRING;
      const isCurrentYearHidden = hideCurrentYear && new Date(date).getFullYear() === new Date().getFullYear();
      return new Intl.DateTimeFormat(
        locale,
        options || {
          day: '2-digit',
          month: 'short',
          year: !isCurrentYearHidden ? 'numeric' : undefined,
          hour: '2-digit',
          minute: '2-digit',
        },
      ).format(new Date(date));
    },
    [locale],
  );

  const formatDateWithoutTime = (
    date: Maybe<Date | string | number>,
    options?: Intl.DateTimeFormatOptions,
    formatOptions?: FormatDateTimeOptions,
  ): string => formatDateTime(date, options || { day: '2-digit', month: 'short', year: 'numeric' }, formatOptions);

  const formatRelativeTime = useCallback(
    (
      date: Maybe<Date | string | number>,
      options: Intl.RelativeTimeFormatOptions = { numeric: 'auto', style: 'long' },
      emptyValueDisplayString?: string,
    ): string | undefined => {
      const formatter = new Intl.RelativeTimeFormat(locale, options);

      if (!date || isInvalidDate(date)) return emptyValueDisplayString ?? EMPTY_DATA_STRING;

      function formatTimeAgo() {
        if (!date || isInvalidDate(date)) return emptyValueDisplayString ?? EMPTY_DATA_STRING;

        const subtrahendDate = new Date();
        const minuendDate = new Date(date);
        let duration = (minuendDate.getTime() - subtrahendDate.getTime()) / 1000;

        for (const division of DIVISIONS) {
          if (Math.abs(duration) < division.amount) {
            return formatter.format(Math.round(duration), division.name as Intl.RelativeTimeFormatUnit);
          }
          duration /= division.amount;
        }
      }

      return formatTimeAgo();
    },
    [locale],
  );

  const isToday = useCallback((date: Maybe<Date | string | number>): boolean => {
    if (!date || isInvalidDate(date)) return false;

    const inputDate = new Date(date);
    const currentDate = new Date();

    return (
      inputDate.getDate() === currentDate.getDate() &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear()
    );
  }, []);

  const formatTime = useCallback(
    (timestamp: Maybe<Date | string | number>, isHour12?: boolean, emptyValueDisplayString?: string): string => {
      if (!timestamp || isInvalidDate(timestamp)) return emptyValueDisplayString ?? EMPTY_DATA_STRING;

      const ts = new Date(timestamp);
      return new Intl.DateTimeFormat(locale, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: isHour12,
      })
        .format(ts)
        .toUpperCase();
    },
    [locale],
  );

  return { formatDateTime, formatDateWithoutTime, formatRelativeTime, formatTime, isToday };
}
