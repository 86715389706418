import { SxProps, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { Agent } from 'api/graphql/generated/graphql';
import { useAgents } from 'api/graphql/hooks/useAgents';
import { SingleAutocomplete } from 'components/general/SingleAutocomplete/SingleAutocomplete';
import { PersonWithAvatar } from 'components/general/person/PersonWithAvatar';
import { useMemo } from 'react';
import { useTranslation } from 'util/i18next';

interface AgentPickerProps {
  required?: boolean;
  agentId?: string;
  defaultAgentId?: string;
  agentError?: string;
  onChangeAgentId: (agentId: string | undefined) => void;
  disabled?: boolean;
  shopId: string;
  sx?: SxProps;
  disableAgentsWithoutEmailIntegration?: boolean;
}

export function AgentPicker({
  required,
  agentError,
  onChangeAgentId,
  agentId,
  defaultAgentId,
  disabled,
  shopId,
  disableAgentsWithoutEmailIntegration,
  sx,
}: AgentPickerProps) {
  const { agents, isLoading } = useAgents({
    where: {
      _or: [
        ...(defaultAgentId ? [{ id: { _eq: defaultAgentId } }] : []),
        { userShopAssignments: { shopId: { _eq: shopId } } },
        { userTeamAssignments: { team: { shopId: { _eq: shopId } } } },
      ],
    },
    orderBy: { firstName: 'ASC' },
  });

  const { t } = useTranslation(['user']);

  const agentsOptions = useMemo(() => {
    return (
      agents?.map((option) => ({
        value: option.id,
        properties: option,
        label: option.email,
        disabled:
          disableAgentsWithoutEmailIntegration &&
          !option.nylasEmailIntegrations.some((nylasEmailIntegration) => nylasEmailIntegration.active),
      })) ?? []
    );
  }, [agents, disableAgentsWithoutEmailIntegration]);

  return (
    <Stack gap={2} sx={{ ...sx }}>
      {isLoading ? (
        <TextField sx={{ flex: 1 }} label={t('user:dashboard.task.agent')} required={required} disabled />
      ) : (
        <SingleAutocomplete
          sx={{ flex: 1 }}
          noOptionsPlaceholder={t('user:dashboard.task.agent')}
          label={t('user:dashboard.task.agent')}
          required={required}
          options={agentsOptions}
          value={agentId ?? ''}
          onChange={(id) => {
            onChangeAgentId(id as string);
          }}
          disabled={disabled}
          hasError={!!agentError}
          errorMessage={agentError}
          renderOption={(props, option) => (
            <MenuItem {...props} key={option.value}>
              <PersonWithAvatar person={option.properties as Agent} sx={{ whiteSpace: 'break-spaces' }} />
            </MenuItem>
          )}
        />
      )}
    </Stack>
  );
}
