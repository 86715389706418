import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { secondaryGraphqlClient } from 'api/graphql';

import { graphql } from 'api/graphql/generated';
import { DashboardActivity } from 'api/graphql/generated/graphql';

export const dashboardActivitiesQuery = graphql(/* GraphQL */ `
  query AgentDashboardActivity($order_by: [DbBaseActivityOrderBy!], $where: DbBaseActivityBoolExp, $limit: Int) {
    dbBaseActivity(where: $where, orderBy: $order_by, limit: $limit) {
      ...DashboardActivity
    }
  }
`);

export const useDashboardActivities = (
  variables: VariablesOf<typeof dashboardActivitiesQuery> = {},
  options?: Pick<UseQueryOptions<DashboardActivity[]>, 'enabled' | 'onError' | 'refetchInterval'>,
) => {
  const { data: activities, ...rest } = useQuery<DashboardActivity[]>({
    queryKey: ['agent', 'useDashboardActivities', variables],
    queryFn: async () => (await secondaryGraphqlClient.request(dashboardActivitiesQuery, variables)).dbBaseActivity,
    ...options,
  });
  return { activities: activities || [], ...rest };
};
