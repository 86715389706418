import { Box, FormHelperText, ListItemIcon, ListItemText, Popover, SxProps } from '@mui/material';
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { EVChip } from 'components/general/Chips/EVChip/EVChip';
import { EVCheckbox } from 'components/general/EVCheckbox/EVCheckbox';
import React, { HTMLAttributes, ReactNode, SyntheticEvent, useRef, useState } from 'react';
import { useSearchText } from 'util/useDebounce';

export interface MultiselectOption {
  value: string;
  displayValue?: string;
}

const MultiselectItem = <T extends MultiselectOption>({
  option,
  sx,
  value,
}: {
  option?: T;
  sx?: SxProps;
  value?: T[];
}) => {
  if (!option) {
    return null;
  }
  return (
    <>
      <ListItemIcon>
        <EVCheckbox checked={!!value?.find((searchOption) => searchOption.value === option.value)} />
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ variant: 'body3' }} sx={sx}>
        {option.displayValue}
      </ListItemText>
    </>
  );
};

export function Multiselect<T extends MultiselectOption>({
  label,
  children,
  errorMessage,
  disabled,
  required,
  isLoading,
  noOptionsText,
  loadingText,
  options,
  value,
  onChange,
  renderOption,
  getOptionLabel = (option) => (typeof option === 'string' ? option : option.displayValue || ''),
  popoverComponent,
  isOptionEqualToValue = (option: string | T, value: T) =>
    typeof option === 'string' ? option === value.value : option.value === value.value,
  getTagLabel = (option) => option?.displayValue,
  filterSelectedOptions,
  openOnFocus = true,
  limitTags,
}: {
  label: React.ReactNode;
  children?: React.ReactNode;
  errorMessage?: string;
  disabled?: boolean;
  required?: boolean;
  isLoading?: boolean;
  noOptionsText?: string;
  loadingText?: string;
  options: T[];
  renderOption?:
    | ((props: HTMLAttributes<HTMLLIElement>, option: T, state: AutocompleteRenderOptionState) => ReactNode)
    | undefined;
  popover?: ReactNode;
  getOptionLabel?: ((option: T) => string) | undefined;
  isOptionEqualToValue?: ((option: T, value: T) => boolean) | undefined;
  getTagLabel?: (option: T) => string | ReactNode;
  filterSelectedOptions?: boolean;
  value: T[];
  openOnFocus?: boolean;
  onChange?:
    | ((
        event: SyntheticEvent<Element, Event>,
        value: T[],
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<T>,
      ) => void)
    | undefined;
  popoverComponent?: (option?: T) => ReactNode;
  limitTags?: number;
}) {
  const anchorEl = useRef<HTMLDivElement | HTMLButtonElement | null>(null);
  const { searchText, setSearchText } = useSearchText();
  const [selectedOption, setOption] = useState<T>();

  const onSearchStringChanged = (_: SyntheticEvent<Element, Event>, value: string) => {
    setSearchText(value);
  };

  const handleClick = (option: T) => {
    return (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      setOption(option);
      anchorEl.current = event.currentTarget;
    };
  };

  const handleClose = () => {
    setOption(undefined);
    anchorEl.current = null;
  };

  const open = Boolean(anchorEl.current);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Autocomplete
        openOnFocus={openOnFocus}
        noOptionsText={noOptionsText}
        loading={!!searchText.length && isLoading}
        loadingText={loadingText}
        value={value}
        disableClearable
        multiple
        disabled={disabled}
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        isOptionEqualToValue={isOptionEqualToValue}
        onInputChange={(event, newInputValue) => onSearchStringChanged(event, newInputValue)}
        options={options}
        limitTags={limitTags}
        renderOption={(props, option, state) =>
          renderOption ? (
            renderOption(props, option, state)
          ) : (
            <Box component="li" {...props} sx={{ p: 1 }}>
              <MultiselectItem option={option} value={value} />
            </Box>
          )
        }
        filterSelectedOptions={filterSelectedOptions}
        renderTags={(value, getTagProps) =>
          value.map((option, index: number) => {
            return (
              <EVChip
                label={getTagLabel(option)}
                {...getTagProps({ index })}
                key={index}
                onClick={handleClick(option)}
                selected={false}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            required={required}
            error={!!errorMessage}
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
      <Stack direction="row" sx={{ marginBottom: children ? -3 : 0 }}>
        <FormHelperText>{errorMessage}</FormHelperText>
        <Box sx={{ marginLeft: 'auto' }}>{children}</Box>
      </Stack>

      {popoverComponent && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl.current}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {popoverComponent(selectedOption)}
        </Popover>
      )}
    </>
  );
}
