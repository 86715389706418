import { Dialog, SxProps, useMediaQuery } from '@mui/material';
import { ModalContextProvider } from 'components/general/Modal/ModalContext';
import { CSSProperties, ReactNode } from 'react';
import { theme } from 'theme';

export const Modal = ({
  onClose,
  isOpen,
  children,
  isInvalid,
  size = 'large',
  sx,
  sxPaper,
  sxPaperFullWidth,
}: {
  onClose: () => void;
  isOpen: boolean;
  isInvalid?: boolean;
  children: ReactNode;
  sx?: SxProps;
  size?: 'small' | 'medium' | 'large';
  sxPaper?: CSSProperties;
  sxPaperFullWidth?: CSSProperties;
}) => {
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('desktop'));
  const modalWidth = {
    large: '916px',
    medium: '672px',
    small: '600px',
    extraSmall: '600px',
  };
  const modalHeight = {
    large: 'min(770px, 90vh)',
    medium: 'min(770px, 90vh)',
    small: '400px',
    extraSmall: '250px',
  };
  const desktopWidth = modalWidth[size];
  const desktopHeight = modalHeight[size];
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth={true}
      fullScreen={true}
      transitionDuration={{ exit: 0 }}
      sx={{
        '.MuiDialog-paper': {
          px: 0,
          py: 0,
          display: 'flex',
          flexDirection: 'column',
          ...sxPaper,
        },
        '.MuiDialog-paperFullWidth': {
          width: isMobileOrTablet ? '100%' : desktopWidth,
          maxWidth: '1400px',
          maxHeight: isMobileOrTablet ? '100%' : desktopHeight,
          ...sxPaperFullWidth,
        },
        zIndex: theme.zIndex.modal,
        ...sx,
      }}
    >
      <ModalContextProvider isInvalid={isInvalid}>{children}</ModalContextProvider>
    </Dialog>
  );
};
