import { Divider, ListItemIcon, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCurrentAgent } from 'api/graphql/hooks/useCurrentAgent';
import { DropdownMenu } from 'components/general/DropDown/DropdownMenu';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { AgentMenuItem } from 'eva-frame/EvaLayout/ProfileMenuButton/AgentMenuItem';
import { LogoutMenuItem } from 'eva-frame/EvaLayout/ProfileMenuButton/LogoutMenuItem';
import { Container } from 'eva-frame/EvaLayout/ProfileMenuButton/style';
import { useProfileNavigationItems } from 'eva-frame/EvaLayout/useProfileNavigationItems';
import { Dispatch, SetStateAction, useRef } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

export function ProfileMenuButton({
  setIsProfileNavigationOpen,
  isProfileNavigationOpen,
}: {
  isProfileNavigationOpen: boolean;
  setIsProfileNavigationOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation(['user', 'communication', 'errors']);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const { currentAgent } = useCurrentAgent();

  const onCloseMenu = () => setIsProfileNavigationOpen(false);
  const onOpenMenu = () => setIsProfileNavigationOpen(true);

  const menuListItems = useProfileNavigationItems();

  return (
    <Container>
      <IconButton
        ref={anchorRef}
        onClick={onOpenMenu}
        disableTouchRipple
        edge="end"
        aria-label={t('user:profileMenu.profileMenuButton')}
        data-testid="account-menu"
      >
        <EVAvatar person={currentAgent} />
      </IconButton>
      <DropdownMenu
        anchorEl={isProfileNavigationOpen && isDesktop ? anchorRef.current : undefined}
        onClose={onCloseMenu}
        id="account-menu"
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuList>
          <AgentMenuItem />
          <Divider variant="middle" />
          {menuListItems.map(({ href, disabled, icon, label }) => (
            <MenuItem component={Link} to={href} disabled={disabled} key={label} onClick={onCloseMenu}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          ))}
          <Divider variant="middle" />
          <LogoutMenuItem />
        </MenuList>
      </DropdownMenu>
    </Container>
  );
}
