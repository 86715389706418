import { ParsedJwtToken, useJwtToken } from '@ev/eva-container-api';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { env } from 'env';
import { useMemo } from 'react';
import { ProductFruits } from 'react-product-fruits';
import { useISO2LanguageCode } from 'util/i18next';
import { parseGlobalRolesFromJwtToken, parseRolesFromJwtToken } from 'util/keycloak/roles';

const E2E_TEST_USERS = [
  env.VITE_LEAD_HUB_E2E_ADMIN_USERNAME,
  env.VITE_LEAD_HUB_E2E_MANAGER_USERNAME,
  env.VITE_LEAD_HUB_E2E_AGENT_USERNAME,
];

const getRole = (jwtToken: ParsedJwtToken, activeShopId: string) => {
  const globalRoles = parseGlobalRolesFromJwtToken(jwtToken);
  if (globalRoles.length) {
    return globalRoles[0];
  }
  const { shopRoles, teamRoles, teamIds } = parseRolesFromJwtToken(jwtToken);
  /** Return first matching shop role, if not present return first matching team role */
  const shopRole = shopRoles[activeShopId]?.[0];
  if (shopRole) {
    return shopRole;
  }
  const activeShopTeamId = teamIds.find((teamId) => teamRoles[teamId]?.shopId === activeShopId);
  return activeShopTeamId && teamRoles[activeShopTeamId]?.role;
};

export const ProductFruitsIntegration = () => {
  const { jwtToken } = useJwtToken();
  const { activeShop } = useActiveShop();
  const username = jwtToken?.preferred_username;
  const email = jwtToken?.email;
  const firstname = jwtToken?.given_name;
  const lastname = jwtToken?.family_name;
  const role = getRole(jwtToken, activeShop.id);

  const userInfo = useMemo(
    () => ({
      username,
      email,
      firstname,
      lastname,
      role,
      props: {
        masterCountry: activeShop.countryCode,
        masterShopId: activeShop.id,
      },
    }),
    [username, email, firstname, lastname, activeShop, role],
  );

  const language = useISO2LanguageCode();

  if (
    !userInfo ||
    !language ||
    ['local', 'local-dev'].includes(env.VITE_LEADHUB_ENV) ||
    E2E_TEST_USERS.includes(username)
  ) {
    return null;
  }

  return <ProductFruits workspaceCode={env.VITE_PRODUCT_FRUITS_WORKSPACE_ID} language={language} user={userInfo} />;
};
