import { DEFAULT_LANG, languageMatchRegex, useLocale } from '@ev/eva-container-api';
import { env } from 'env';
import i18n, { TFunction as TFunctionI18next } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { useMemo } from 'react';
import { UseTranslationResponse, initReactI18next, useTranslation as useTranslationReactI18next } from 'react-i18next';
import { LOCAL_LANGUAGES, addLocalTranslations } from 'util/i18n/localTranslations';
import { z } from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';

export const SUPPORTED_LANGUAGES = ['en', 'de', 'de-CH', 'de-AT', 'el', 'es', 'fr', 'it', 'pt'];

export const DEFAULT_NAMESPACE = 'common';
const ZOD_NAMESPACE = 'zod';
const ADDITIONAL_NAMESPACES = [
  'activities',
  'admin',
  'communication',
  'contact',
  'enums',
  'errors',
  'lead',
  'user',
  ZOD_NAMESPACE,
] as const;

export async function setupI18n() {
  const languageDetector = new LanguageDetector();
  languageDetector.addDetector({
    name: 'customPathDetector',
    lookup() {
      const language = window.location.pathname.match(languageMatchRegex);

      if (language) {
        return language[1];
      }

      return undefined;
    },
  });

  await i18n
    .use(HttpBackend)
    .use(initReactI18next)
    .use(languageDetector)
    .init(
      {
        backend: {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        fallbackLng: DEFAULT_LANG,
        supportedLngs: [...SUPPORTED_LANGUAGES, ...LOCAL_LANGUAGES],
        ns: [DEFAULT_NAMESPACE, ...ADDITIONAL_NAMESPACES],
        defaultNS: DEFAULT_NAMESPACE,
        // Uncomment to enable debug mode locally
        // debug: env.NODE_ENV === 'development',
        interpolation: {
          escapeValue: false, // not needed for react!!
        },
        react: {
          useSuspense: false,
        },
        detection: {
          // order and from where user language should be detected
          order: ['customPathDetector', 'cookie', 'navigator'],
          lookupCookie: 'NEXT_LOCALE',
        },
      },
      () => {
        if (
          i18n.resolvedLanguage &&
          i18n.resolvedLanguage !== DEFAULT_LANG &&
          !location.pathname.startsWith(`/${i18n.resolvedLanguage}`)
        ) {
          location.pathname = `/${i18n.resolvedLanguage}${location.pathname}`;
        }
        if (env.VITE_LEADHUB_ENV !== 'prod') {
          const search = new URLSearchParams(location.search);
          if (search.has('keys')) {
            i18n.changeLanguage('cimode');
          }
        }
      },
    );

  addLocalTranslations(i18n);

  z.setErrorMap(makeZodI18nMap({ ns: ZOD_NAMESPACE }));
}

export type Namespaces = typeof DEFAULT_NAMESPACE | (typeof ADDITIONAL_NAMESPACES)[number];
type AdditionalNamespaces = Exclude<Namespaces, typeof DEFAULT_NAMESPACE>[];

export type TFunction<N extends AdditionalNamespaces = []> = TFunctionI18next<[typeof DEFAULT_NAMESPACE, ...N]>;

/**
 * Translation for the default namespaces are always included and can be used without a prefix.
 * Other namespaces are loaded on demand and have to requested. Their keys have to be prefixed.
 *
 * @example
 *  const {t} = useTranslation();
 *  t('someKeyFromDefaultNameSpace');
 *
 *  const {t} = useTranslation(['ns1']);
 *  t('someKeyFromDefaultNameSpace');
 *  t('ns1:someKeyFromNs1');
 */
export function useTranslation<N extends AdditionalNamespaces = []>(
  namespaces?: N | Readonly<N>,
): UseTranslationResponse<[typeof DEFAULT_NAMESPACE, ...N]> {
  return useTranslationReactI18next([DEFAULT_NAMESPACE, ...(namespaces || [])]);
}

const convertLanguageToISO2LetterLanguageCode = (language: string) => (language ? language.split('-')[0] : 'en');

export function useISO2LanguageCode() {
  const locale = useLocale();

  return useMemo(() => {
    return convertLanguageToISO2LetterLanguageCode(locale);
  }, [locale]);
}

export function useEnumTranslation() {
  const { t } = useTranslation(['enums']);

  function translateEnumArray<T extends object>(translationEnum: T, keys?: Array<keyof T> | null): string | undefined {
    return keys?.map((key) => translateEnum(translationEnum, key)).join(', ');
  }

  function translateEnum<T extends object>(translationEnum: T, key?: keyof T | null): string | undefined {
    if (!key) {
      return;
    }
    return translationEnum[key] ? t(translationEnum[key] as unknown as TemplateStringsArray) : (key as string);
  }

  return { translateEnum, translateEnumArray };
}
