import { MODAL_Z_INDEX } from '@ev/eva-container-api';
import { Stack, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import MuiMenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { LinearProgressPaperTop } from 'components/general/LinearProgressPaperTop/LinearProgressPaperTop';
import { NESTED_OPTION_SEPARATOR, OptionItem } from 'components/general/OptionItem/OptionItem';
import { SearchTextField } from 'components/general/SearchTextField/SearchTextField';
import * as React from 'react';
import { ChangeEvent, ReactNode } from 'react';

export interface VirtualElement {
  getBoundingClientRect: () => ClientRect | DOMRect;
  contextElement?: Element;
}

export interface Option {
  value: string;
  label: ReactNode;
  properties?: object;
  disabled?: boolean;
  subOptions?: Option[];
  header?: ReactNode;
}

export interface SearchProps {
  onSearchStringChange: (newSearchString: string) => void;
  placeholder?: string;
  searchString: string;
}

export type OptionsListPropsWithoutOptions = {
  options: Option[];
  nonFilteredOptions?: Option[];
  search?: SearchProps;
  onClose?: () => void;
  open?: boolean;
  loading?: boolean;
  anchorRef: { current: VirtualElement | null };
  maxHeight?: string;
  hasStopPropagation?: boolean;
  compact?: boolean;
  disablePortal?: boolean;
  sxMenu?: SxProps;
  'data-testid'?: string;
  placement?: PopperPlacementType;
  handleResetToDefault?: () => void;
  footer?: ReactNode;
};

export type OptionsListPropsOptions =
  | {
      multiple: true;
      onChange?: (options: string[], changedValue?: string) => void;
      selectedOptions: string[];
      optionsTitleComponent?: ReactNode;
      selectedOption?: null; // To keep TypeScript happy
    }
  | {
      multiple?: false;
      onChange?: (options: string, changedValue?: string) => void;
      selectedOption: string | null | undefined;
      optionsTitleComponent?: ReactNode;
      selectedOptions?: undefined; // To keep TypeScript happy
    };

export type OptionsListProps = OptionsListPropsWithoutOptions & OptionsListPropsOptions;

export function OptionsList({
  options,
  nonFilteredOptions,
  search,
  anchorRef,
  selectedOptions,
  selectedOption,
  onClose,
  loading,
  open,
  onChange,
  multiple,
  maxHeight,
  sxMenu,
  hasStopPropagation,
  optionsTitleComponent,
  disablePortal = true,
  compact,
  placement = 'bottom',
  'data-testid': dataTestId,
  footer,
}: OptionsListProps) {
  const onSearchStringChange = (eventArgs: ChangeEvent<HTMLInputElement>) => {
    search?.onSearchStringChange?.(eventArgs.currentTarget.value);
  };
  const onClickAway = (event: MouseEvent | TouchEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onClose?.();
  };

  const selectedOptionList = Array.isArray(selectedOptions)
    ? selectedOptions
    : selectedOption !== undefined && selectedOption !== null
    ? [selectedOption]
    : [];

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      onClose?.();
    }
  };

  const onItemClick = (value: string) => {
    if (onChange) {
      if (Array.isArray(selectedOptions)) {
        const isUnselect = selectedOptionList.includes(value);
        //Checks if the item user clicked is a parent. A children looks like x.y so the result of the split is 2
        const isParent = value.split('.').length === 1;
        const parent = value.split('.')[0];

        const subOptions = (nonFilteredOptions || options).find((option) => option.value === parent)?.subOptions ?? [];
        if (isParent && subOptions.length > 0) {
          const unselectedSubOptions = subOptions.filter((option) => {
            return !selectedOptionList.includes(parent + NESTED_OPTION_SEPARATOR + option.value);
          });

          const values = (isUnselect ? subOptions : unselectedSubOptions)
            .map((item) => value + NESTED_OPTION_SEPARATOR + item.value)
            .concat(value);

          onChange(
            // @ts-ignore
            isUnselect
              ? selectedOptionList.filter((option) => !values?.includes(option))
              : [...selectedOptionList, ...values],
            values,
          );
          return;
        }

        const subOptionsWithoutClicked =
          subOptions.filter((subOption) => parent + NESTED_OPTION_SEPARATOR + subOption.value !== value) ?? [];

        const areAllSubOptionsSelected =
          subOptionsWithoutClicked.filter(
            (subOption) => !selectedOptionList.includes(parent + NESTED_OPTION_SEPARATOR + subOption.value),
          ).length === 0;

        if (subOptions.length > 0 && areAllSubOptionsSelected) {
          const values = [value, parent];
          onChange(
            // @ts-ignore
            isUnselect
              ? selectedOptionList.filter((option) => option !== value && option !== parent)
              : [...selectedOptionList, ...values],
            values,
          );
        } else {
          onChange(
            // @ts-ignore
            isUnselect ? selectedOptionList.filter((option) => option !== value) : [...selectedOptionList, value],
            value,
          );
        }
      } else {
        // @ts-ignore
        onChange(value, value);
      }
    }
    if (!multiple) {
      onClose?.();
    }
  };

  function renderOption(option: Option, parentValuePath = ''): ReactNode[] {
    const valuePath = parentValuePath + option.value;
    const selected = !!selectedOptionList?.includes(valuePath);
    const selectedSubOptions =
      option.subOptions?.filter((item) => selectedOptionList.includes(option.value + '.' + item.value)) ?? [];

    const optionFromNonFilteredOptions = nonFilteredOptions?.find(
      (nonFilteredOption) => nonFilteredOption.value === option.value,
    );

    const indeterminate =
      selectedSubOptions.length > 0 &&
      selectedSubOptions.length !== (optionFromNonFilteredOptions || option).subOptions?.length &&
      !selected;

    return [
      <Stack key={valuePath}>
        {option.header}
        <OptionItem
          selected={selected}
          indeterminate={indeterminate}
          value={valuePath}
          disabled={option.disabled}
          onItemClick={onItemClick}
          multiple={multiple}
          compact={compact}
          label={option.label}
          hasStopPropagation={hasStopPropagation}
        />
      </Stack>,
      ...(option.subOptions?.flatMap((subOption) => renderOption(subOption, valuePath + NESTED_OPTION_SEPARATOR)) ||
        []),
    ];
  }

  return (
    <Popper
      open={!!open}
      anchorEl={() => anchorRef.current!}
      transition={true}
      disablePortal={disablePortal}
      sx={{ zIndex: MODAL_Z_INDEX, maxWidth: '100vw', ...sxMenu }}
      placement={placement}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          onExited={() => {
            TransitionProps?.onExited?.();
            search?.onSearchStringChange?.('');
          }}
          style={{
            transformOrigin: placement.includes('bottom') ? 'center top' : 'center bottom',
          }}
        >
          <Paper elevation={3}>
            <ClickAwayListener onClickAway={onClickAway}>
              <div onKeyDown={handleKeyDown}>
                {!!loading && <LinearProgressPaperTop />}
                {search ? (
                  <>
                    <Box padding={2}>
                      <SearchTextField
                        autoFocus={true}
                        role="combox"
                        aria-autocomplete="list"
                        onClick={(event) => event.stopPropagation()}
                        fullWidth={true}
                        onChange={onSearchStringChange}
                        size="small"
                        value={search.searchString}
                        placeholder={search.placeholder}
                        data-testid={dataTestId}
                      />
                    </Box>
                    <Divider />
                  </>
                ) : null}
                {optionsTitleComponent ?? null}
                <MuiMenuList autoFocusItem={!search} sx={{ overflow: 'auto', ...(maxHeight ? { maxHeight } : {}) }}>
                  {options.flatMap((option) => renderOption(option))}
                </MuiMenuList>
              </div>
            </ClickAwayListener>
            {footer}
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
