import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

export const shopsQuery = graphql(/* GraphQL */ `
  query Shops($limit: Int, $offset: Int, $order_by: [DbShopOrderBy!], $where: DbShopBoolExp) {
    dbShop(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {
      ...Shop
    }
  }
`);

export const useShops = (
  variables: VariablesOf<typeof shopsQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'keepPreviousData'>,
) => {
  const { data: shops, ...rest } = useQuery({
    queryKey: ['shop', 'useShops', variables],
    queryFn: async () => (await graphqlClient.request(shopsQuery, variables)).dbShop,
    ...options,
  });
  return { shops, ...rest };
};

export const shopInfoQuery = graphql(/* GraphQL */ `
  query ShopInfo($limit: Int, $offset: Int, $order_by: [DbShopOrderBy!], $where: DbShopBoolExp) {
    dbShop(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {
      ...ShopInfo
    }
  }
`);

export const useShopInfo = (
  variables: VariablesOf<typeof shopInfoQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'keepPreviousData'>,
) => {
  const { data: shops, ...rest } = useQuery({
    queryKey: ['shop', 'useShopInfo', variables],
    queryFn: async () => (await graphqlClient.request(shopInfoQuery, variables)).dbShop,
    ...options,
  });
  return { shops, ...rest };
};

export const shopsWithMembersQuery = graphql(/* GraphQL */ `
  query ShopsWithMembers($limit: Int, $offset: Int, $order_by: [DbShopOrderBy!], $where: DbShopBoolExp) {
    dbShop(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {
      ...ShopWithMembers
    }
  }
`);

export const useShopsWithMembers = (
  variables: VariablesOf<typeof shopsWithMembersQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: shops, ...rest } = useQuery({
    queryKey: ['shop', 'useShopsWithMembers', variables],
    queryFn: async () => (await graphqlClient.request(shopsWithMembersQuery, variables)).dbShop,
    ...options,
  });
  return { shops, ...rest };
};

export const totalShopCountQuery = graphql(/* GraphQL */ `
  query TotalShopCount($where: DbShopBoolExp) {
    dbShopAggregate(where: $where) {
      aggregate {
        totalShopCount: count
      }
    }
  }
`);

export const useTotalShopCount = (
  variables: VariablesOf<typeof totalShopCountQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: totalShopCount, ...rest } = useQuery({
    queryKey: ['shop', 'useTotalShopCount', variables],
    queryFn: async () =>
      (await graphqlClient.request(totalShopCountQuery, variables)).dbShopAggregate.aggregate?.totalShopCount,
    ...options,
  });
  return { totalShopCount, ...rest };
};

export const shopNameByIDsQuery = graphql(/* GraphQL */ `
  query shopNameByIDs($shopIds: [String!]!) {
    dbShop(where: { id: { _in: $shopIds } }) {
      ...ShopNameByID
    }
  }
`);

export const useShopNameByIDs = (shopIds: string[]) => {
  const { data } = useQuery({
    queryKey: ['shop', 'useShopNameByIDQuery', shopIds],
    queryFn: async () =>
      await graphqlClient.request(shopNameByIDsQuery, {
        shopIds,
      }),
  });
  return {
    shops: data?.dbShop,
  };
};

export const leadExtendingActivitiesQuery = graphql(/* GraphQL */ `
  query LeadExtendingActivities($shopId: String!) {
    dbLeadExtendingActivities(where: { shopId: { _eq: $shopId } }) {
      activityType
    }
    dbLeadExtendingTaskTypes(where: { shopId: { _eq: $shopId } }) {
      taskType
    }
    dbLeadExtendingAppointmentTypes(where: { shopId: { _eq: $shopId } }) {
      appointmentType
    }
  }
`);

export const useLeadExtendingActivities = (shopId?: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['shop', 'useLeadExtendingActivities', shopId],
    queryFn: async () => await graphqlClient.request(leadExtendingActivitiesQuery, { shopId: shopId || '' }),
    enabled: !!shopId,
  });
  return {
    leadExtendingActivities: data?.dbLeadExtendingActivities.map((activity) => activity.activityType),
    leadExtendingTaskTypes: data?.dbLeadExtendingTaskTypes.map((activity) => activity.taskType),
    leadExtendingAppointmentTypes: data?.dbLeadExtendingAppointmentTypes.map((activity) => activity.appointmentType),
    ...rest,
  };
};
