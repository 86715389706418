import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { RecipientPagination } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/RecipientPagination';
import { RecipientsList } from 'components/emails/SendBulkEmailDialog/RecipientsList';
import { SearchTextField } from 'components/general/SearchTextField/SearchTextField';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { countUnresolvedPlaceholders, hasAlreadyProposedProperty } from 'util/email';
import { useTranslation } from 'util/i18next';
import { PreviewBulkEmailFormData } from 'util/schemas/sendBulkEmailSchema';
import { fullName, hasMatchingWords } from 'util/stringUtils';

export function PreviewRecipients({
  selectedRecipient,
  onSelectRecipient,
  onRemoveRecipient,
  disabled,
}: {
  selectedRecipient: string | undefined;
  onRemoveRecipient: (recipient: string) => void;
  onSelectRecipient: (recipient: string) => void;
  disabled?: boolean;
}) {
  const { t } = useTranslation(['communication']);

  const { watch, formState } = useFormContext<PreviewBulkEmailFormData>();
  const [searchText, setSearchText] = useState<string | undefined>();

  let recipients = watch('recipients').map((r, index) => ({
    ...r.recipient,
    hasAlreadyProposedProperty: hasAlreadyProposedProperty(r),
    hasUnresolvedPlaceholder: !!countUnresolvedPlaceholders(r.body),
    showSubjectOverrideWarning: r.showSubjectOverrideWarning,
    errorMessage: formState.errors.recipients?.[index]?.properties?.message,
  }));

  if (searchText) {
    recipients = recipients.filter((recipient) => {
      const contact = recipient.type === 'CONTACT' ? recipient : recipient.contact;
      return (
        (contact.email && hasMatchingWords(contact.email, searchText)) ||
        hasMatchingWords(fullName(contact, ''), searchText)
      );
    });
  }

  return (
    <>
      <Stack sx={{ gridRow: 1, gridColumn: 1 }} direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" fontWeight="bold">
          {t('communication:sendBulkEmailDialog.previewTitle')}
        </Typography>
        <RecipientPagination
          narrow
          disabled={disabled}
          recipients={recipients}
          selectedRecipient={selectedRecipient}
          onSelectRecipient={onSelectRecipient}
        />
      </Stack>
      <Stack sx={{ gridRow: 2, gridColumn: 1 }} gap={2}>
        <SearchTextField
          fullWidth={true}
          disabled={disabled}
          size="small"
          value={searchText}
          placeholder={t('communication:emailForm.bulk.review.searchPlaceholder')}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <RecipientsList
          disabled={disabled}
          selectedRecipient={selectedRecipient}
          onSelectedRecipientChange={onSelectRecipient}
          recipients={recipients}
          onRemoveRecipient={onRemoveRecipient}
        />
      </Stack>
    </>
  );
}
