import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Contact, Shop } from 'api/graphql/generated/graphql';
import { useContacts } from 'api/graphql/hooks/useContact';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { SearchTextField } from 'components/general/SearchTextField/SearchTextField';
import genericAvatarSrc from 'components/icons/avatar.svg?url';
import PhoneCallOutIcon from 'components/icons/phone_call_outgoing.svg?react';
import { useTwilio } from 'components/state/Twilio';
import { ChangeEventHandler, ReactNode, useRef } from 'react';
import { theme } from 'theme';
import { getPreferredEmail } from 'util/contactUtils';
import { contactSearchNameFilter } from 'util/hasura/filters';
import { useTranslation } from 'util/i18next';
import { formatPhoneLabelCallerScreen, fullName } from 'util/stringUtils';
import { useSearchText } from 'util/useDebounce';

/// https://www.twilio.com/docs/voice/twiml/number#:~:text=Phone%20numbers%20should%20be%20formatted,or%20415%2D555%2D1212
const PHONE_NUMBER_REGEX = /^\+[0-9]+$/;

export function CallMenuItem({
  avatar,
  primaryLabel,
  secondaryLabel,
}: {
  avatar: ReactNode;
  primaryLabel: string;
  secondaryLabel: string;
}) {
  return (
    <Stack direction="row" gap={1.5} alignItems="center" sx={{ overflow: 'hidden', width: '100%' }}>
      {avatar}
      <Box>
        <Typography
          variant="body3"
          color={theme.palette.text.primary}
          component="div"
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {primaryLabel}
        </Typography>
        <Typography
          variant="body4"
          color={theme.palette.text.secondary}
          component="div"
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {secondaryLabel}
        </Typography>
      </Box>
      <PhoneCallOutIcon style={{ marginLeft: 'auto' }} />
    </Stack>
  );
}

export function SearchContactToCall({ shop }: { shop: Shop }) {
  const searchFieldRef = useRef<HTMLElement>(null);
  const { searchText, rawSearchText, setSearchText } = useSearchText();
  const { outgoingCall } = useTwilio();

  const { t } = useTranslation(['communication']);

  const shopGroupIds = shop.shopGroup?.shops.map(({ id }) => id) || [shop.id];

  const { contacts, isLoading } = useContacts(
    {
      where: {
        shopId: { _in: shopGroupIds },
        phoneNumbers: { internationalNumber: { _isNull: false } }, // If internationalNumber is missing the number is invalid and can't be used
        _and: [
          {
            _or: [
              {
                deleted: { _eq: false },
              },
              { deleted: { _isNull: true } },
            ],
          },
          contactSearchNameFilter(searchText),
        ],
      },
      limit: 20,
    },
    { enabled: !!searchText },
  );

  const searchTextIsNumber = PHONE_NUMBER_REGEX.test(searchText);

  const mapContactsToNumberEntries = (contact: Contact) => {
    if (searchTextIsNumber) {
      const matchingNumber = contact.phoneNumbers.find((phoneNumber) =>
        phoneNumber.internationalNumber?.startsWith(searchText),
      );
      if (matchingNumber) {
        return [{ contact, contactPhoneNumber: matchingNumber }];
      }
    }

    return contact.phoneNumbers
      .filter((number) => number.internationalNumber)
      .map((number) => ({ contact, contactPhoneNumber: number }));
  };

  const contactMatches = contacts?.flatMap(mapContactsToNumberEntries) || [];

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    const term = event.target.value;
    setSearchText(term);
  };

  const clearSearch = () => {
    setSearchText('');
  };

  const showUnknownNumber =
    searchTextIsNumber &&
    !contactMatches.find((contactResult) => contactResult.contactPhoneNumber.internationalNumber === searchText);

  const showNoResultsPlaceholder = !showUnknownNumber && contactMatches.length === 0 && !isLoading;

  return (
    <Box sx={{ position: 'relative' }}>
      <SearchTextField ref={searchFieldRef} value={rawSearchText} onChange={handleSearch} />
      <Popper
        anchorEl={() => searchFieldRef.current!}
        open={!!searchText.trim().length}
        disablePortal
        sx={{ transformOrigin: 'center bottom', width: '100%' }}
      >
        <Paper elevation={3} sx={{ maxHeight: '220px', overflow: 'auto' }}>
          <MenuList>
            {showNoResultsPlaceholder && (
              <MenuItem>
                <Typography variant="body3" color={theme.palette.text.secondary}>
                  {t('communication:call.noActiveCalls.noMatchingUser')}
                </Typography>
              </MenuItem>
            )}

            {showUnknownNumber && (
              <MenuItem
                key={searchText}
                onClick={() => {
                  clearSearch();
                  outgoingCall({ phoneNumber: searchText, shopId: shop.id });
                }}
              >
                <CallMenuItem
                  avatar={<EVAvatar pictureSrc={genericAvatarSrc} />}
                  primaryLabel={t('communication:call.noActiveCalls.unknownNumberLabel')}
                  secondaryLabel={searchText}
                />
              </MenuItem>
            )}
            {contactMatches?.map(({ contact, contactPhoneNumber }) => (
              <MenuItem
                key={contact.id + contactPhoneNumber.internationalNumber}
                onClick={() => {
                  clearSearch();
                  outgoingCall({
                    phoneNumber: contactPhoneNumber.internationalNumber!,
                    numberType: formatPhoneLabelCallerScreen(t, contactPhoneNumber.preferred, contactPhoneNumber.type),
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    contactId: contact.id,
                    shopId: shop.id,
                    mailAddress: getPreferredEmail(contact) ?? '',
                    suggestedRecipients: contact.emailAddresses?.map(({ address }) => address) ?? [],
                  });
                }}
              >
                <CallMenuItem
                  avatar={<EVAvatar person={contact} />}
                  primaryLabel={fullName(contact)}
                  secondaryLabel={contactPhoneNumber.internationalNumber ?? ''}
                />
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Popper>
    </Box>
  );
}
