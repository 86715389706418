import { BuyerToRentSpecification } from 'api/graphql/generated/graphql';

export function getLegacyLocation(buyerToRentSpecification: BuyerToRentSpecification | undefined | null) {
  if (!buyerToRentSpecification || buyerToRentSpecification.placeIds?.length) {
    return null;
  }

  const country = buyerToRentSpecification?.country;
  const city = buyerToRentSpecification?.city;

  if (!!buyerToRentSpecification.legacyAreaNames?.length || !!buyerToRentSpecification.legacyDistrictNames?.length) {
    return {
      country,
      city,
      area: buyerToRentSpecification.legacyAreaNames?.join(', '),
      district: buyerToRentSpecification.legacyDistrictNames?.join(', '),
    };
  }

  return { country, city, area: buyerToRentSpecification.area, district: buyerToRentSpecification.district };
}
