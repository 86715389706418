import { SxProps } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';
import {
  OptionsList,
  OptionsListPropsOptions,
  OptionsListPropsWithoutOptions,
} from 'components/general/OptionsList/OptionsList';
import * as React from 'react';
import { ReactNode, useRef, useState } from 'react';

export type OptionsButtonProps = {
  color?: ButtonProps['color'];
  button?: ReactNode;
  buttonProps?: ButtonProps;
  icon?: ReactNode;
  iconButtonProps?: IconButtonProps;
  isOpen?: boolean;
  changeIsOpen?: (open: boolean) => void;
  disabled?: boolean;
  disableRipple?: boolean;
  loading?: boolean;
  optionsLoading?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  sx?: SxProps;
  hasStopPropagation?: boolean;
  testId?: string;
} & Omit<OptionsListPropsWithoutOptions, 'open' | 'onClose' | 'anchorRef' | 'loading'> &
  OptionsListPropsOptions;

export function OptionsButton({
  color = 'secondary',
  button,
  icon,
  buttonProps,
  iconButtonProps,
  loading,
  disabled,
  disableRipple,
  optionsLoading,
  onClick,
  isOpen: isOpenProp,
  changeIsOpen: setIsOpenProp,
  sx,
  testId,
  ...optionsListProps
}: OptionsButtonProps) {
  const buttonWrapperRef = useRef<HTMLButtonElement>(null);
  const [isOpenState, setIsOpenState] = useState(false);
  const isOpen = setIsOpenProp ? isOpenProp : isOpenState;

  const handleOnClick = (event: React.MouseEvent) => {
    onClick?.(event);
    setIsOpen(!isOpen);
  };

  const setIsOpen = (open: boolean) => {
    if (setIsOpenProp) {
      setIsOpenProp(open);
    } else {
      setIsOpenState(open);
    }
  };

  function renderButton() {
    if (button) {
      return (
        <Button
          disableRipple={disableRipple}
          sx={sx}
          color={color}
          disabled={disabled}
          onClick={handleOnClick}
          ref={buttonWrapperRef}
          data-testid={testId}
          {...buttonProps}
        >
          {loading ? <CircularProgress size={14} /> : button}
        </Button>
      );
    }
    if (icon && !loading) {
      return (
        <IconButton
          sx={sx}
          color={color}
          disabled={disabled}
          onClick={handleOnClick}
          ref={buttonWrapperRef}
          data-testid={testId}
          {...iconButtonProps}
        >
          {icon}
        </IconButton>
      );
    }
  }

  return (
    <>
      {renderButton()}
      <OptionsList
        {...optionsListProps}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorRef={buttonWrapperRef}
        loading={optionsLoading}
      />
    </>
  );
}
