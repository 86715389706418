export const hasInvalidEmailLinks = (body: string): boolean => {
  const urlRegex = /href=["']([^"']+)["']/gi;

  const matches = body.matchAll(urlRegex);
  const matchesArray = Array.from(matches);
  if (matchesArray.length === 0) {
    return false;
  }

  const validProtocols = ['https://', 'mailto:', 'tel:'];

  const hasInvalidLinks = matchesArray.some((match) => {
    const url = match[1];
    return url && !validProtocols.some((protocol) => url.startsWith(protocol));
  });

  return hasInvalidLinks;
};
