import AgentReportingIcon from 'components/icons/agent_reporting.svg?react';
import DevelopmentIcon from 'components/icons/apartment.svg?react';
import CalendarIcon from 'components/icons/calendar_naked.svg?react';
import CallHistoryIcon from 'components/icons/call_history.svg?react';
import GearIcon from 'components/icons/gear.svg?react';
import GlobeIcon from 'components/icons/globe.svg?react';
import KycIcon from 'components/icons/kyc.svg?react';
import LeadHubLogo from 'components/icons/lead_hub_logo.svg?react';
import ActivitiesIcon from 'components/icons/list_bulleted.svg?react';
import MailIcon from 'components/icons/mail.svg?react';
import LettingsIcon from 'components/icons/property_rent.svg?react';
import SalesIcon from 'components/icons/property_sell.svg?react';
import UserIcon from 'components/icons/user_double.svg?react';
import { CSSProperties, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'util/i18next';
import { isSubPath } from 'util/isSubPath';
import { useDestination } from 'util/navigation/useDestination';
import { useEnabledPages } from 'util/useEnabledPages';

export interface NavItem {
  href: string;
  label: string;
  icon: React.ComponentType<{ style?: CSSProperties }>;
  disabled?: boolean;
  isActive: boolean;
}

export function useMainNavigationItems() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const isPageEnabled = useEnabledPages();
  const {
    toAgentReporting,
    toLeadList,
    toContactList,
    toEmailInbox,
    toEmailTemplates,
    toCalls,
    toNetwork,
    toAdmin,
    toContactDetails,
    toActivities,
    toCommunications,
    toDashboard,
    toSales,
    toLettings,
    toDevelopments,
    toKyc,
  } = useDestination();

  // KEEP IN SYNC with https://git.engelvoelkers.com/ev/go3/go3-toolbox-web/-/blob/develop/src/hooks/useSidebarItems.ts
  const items = useMemo(
    () =>
      [
        ...(isPageEnabled('Dashboard')
          ? ([
              {
                label: t('mainNavigation.dashboard'),
                href: toDashboard(),
                icon: CalendarIcon,
                isActive: isSubPath(toDashboard(), pathname),
              },
            ] as const)
          : []),

        ...(isPageEnabled('AgentReporting')
          ? ([
              {
                label: t('mainNavigation.agentReporting'),
                href: toAgentReporting(),
                icon: AgentReportingIcon,
                isActive: isSubPath(toAgentReporting(), pathname),
              },
            ] as const)
          : []),

        ...(isPageEnabled('LeadList')
          ? ([
              {
                label: t('mainNavigation.leadList'),
                href: toLeadList(),
                icon: LeadHubLogo,
                isActive:
                  (!searchParams.has('contacts') && isSubPath(toContactDetails({ contactId: '' }), pathname)) ||
                  pathname === toLeadList(),
              },
            ] as const)
          : []),

        ...(isPageEnabled('ActivityList')
          ? ([
              {
                label: t('mainNavigation.activities'),
                href: toActivities(),
                icon: ActivitiesIcon,
                isActive: isSubPath(toActivities(), pathname),
              },
            ] as const)
          : []),

        ...(isPageEnabled('ContactList')
          ? ([
              {
                label: t('mainNavigation.contacts'),
                href: toContactList(),
                icon: UserIcon,
                isActive:
                  (searchParams.has('contacts') && isSubPath(toContactDetails({ contactId: '' }), pathname)) ||
                  isSubPath(toContactList(), pathname),
              },
            ] as const)
          : []),
        ...(isPageEnabled('Properties')
          ? ([
              {
                label: t('mainNavigation.sales'),
                href: toSales(),
                icon: SalesIcon,
                isActive: isSubPath(toSales(), pathname),
              },
              {
                label: t('mainNavigation.lettings'),
                href: toLettings(),
                icon: LettingsIcon,
                isActive: isSubPath(toLettings(), pathname),
              },
              {
                label: t('mainNavigation.developments'),
                href: toDevelopments(),
                icon: DevelopmentIcon,
                isActive: isSubPath(toDevelopments(), pathname),
              },
            ] as const)
          : []),
        ...(isPageEnabled('Mails') || isPageEnabled('Templates')
          ? ([
              {
                label: t('mainNavigation.mails'),
                href: isPageEnabled('Mails') ? toEmailInbox() : toEmailTemplates(),
                icon: MailIcon,
                isActive: isSubPath(toCommunications(), pathname),
              },
            ] as const)
          : []),
        ...(isPageEnabled('Calls')
          ? [
              {
                label: t('mainNavigation.calls'),
                href: toCalls(),
                icon: CallHistoryIcon,
                isActive: isSubPath(toCalls(), pathname),
              },
            ]
          : []),
        ...(isPageEnabled('KYC')
          ? [
              {
                label: t('mainNavigation.kyc'),
                href: toKyc(),
                icon: KycIcon,
                isActive: isSubPath(toKyc(), pathname),
              },
            ]
          : []),
        ...(isPageEnabled('Network')
          ? [
              {
                label: t('mainNavigation.network'),
                href: toNetwork(),
                icon: GlobeIcon,
                isActive: isSubPath(toNetwork(), pathname),
              },
            ]
          : []),
        ...(isPageEnabled('Admin')
          ? ([
              {
                label: t('mainNavigation.admin'),
                href: toAdmin(),
                icon: GearIcon,
                isActive: isSubPath(toAdmin(), pathname),
              },
            ] as const)
          : []),
      ] as const satisfies readonly NavItem[],
    [
      isPageEnabled,
      pathname,
      searchParams,
      t,
      toActivities,
      toAdmin,
      toAgentReporting,
      toCalls,
      toCommunications,
      toContactDetails,
      toContactList,
      toDashboard,
      toDevelopments,
      toEmailInbox,
      toEmailTemplates,
      toKyc,
      toLeadList,
      toLettings,
      toNetwork,
      toSales,
    ],
  );

  return { items };
}

export function usePublicMainNavigationItems() {
  const { t } = useTranslation();

  const { toPublicNetwork } = useDestination();
  const { pathname } = useLocation();

  const items = useMemo(
    () =>
      [
        {
          label: t('mainNavigation.network'),
          href: toPublicNetwork(),
          icon: GlobeIcon,
          isActive: isSubPath(toPublicNetwork(), pathname),
        },
      ] as const satisfies readonly NavItem[],
    [t, toPublicNetwork, pathname],
  );
  return { items };
}
