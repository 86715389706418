import { MODAL_Z_INDEX } from '@ev/eva-container-api';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popover, { PopoverOrigin, PopoverProps } from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CrossIcon from 'components/icons/cross_naked.svg?react';
import { ReactNode } from 'react';
import { theme } from 'theme';

interface MobileDropdownMenuDrawerConfig {
  title: string;
}

const MobileHandle = styled('div')({
  marginTop: theme.spacing(1),
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: theme.palette.shade.grey3,
  borderRadius: '2px',
  width: '37px',
  height: '4px',
});

export function DropdownMenu({
  children,
  anchorEl,
  onClose,
  id,
  anchorOrigin,
  transformOrigin,
  showDrawerOnMobile,
  sxPopover,
}: {
  children: ReactNode;
  anchorEl: PopoverProps['anchorEl'];
  onClose: () => void;
  id?: string;
  anchorOrigin: PopoverOrigin | undefined;
  transformOrigin: PopoverOrigin | undefined;
  showDrawerOnMobile?: MobileDropdownMenuDrawerConfig;
  sxPopover?: SxProps;
}) {
  const isOpen = Boolean(anchorEl);
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  if (!isDesktop && showDrawerOnMobile) {
    return (
      <MuiDrawer
        anchor="bottom"
        open={isOpen}
        onClose={onClose}
        sx={{ zIndex: MODAL_Z_INDEX }}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.backgroundExtension.grey,
            width: '100%',
            padding: '0 env(safe-area-inset-right) 0 env(safe-area-inset-left)',
          },
        }}
      >
        <MobileHandle />
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr', margin: 2, alignItems: 'center' }}>
          <IconButton onClick={onClose} sx={{ justifySelf: 'start' }}>
            <CrossIcon />
          </IconButton>
          <Typography variant="h2">{showDrawerOnMobile.title}</Typography>
        </Box>
        <Paper elevation={0} sx={{ margin: 2, overflow: 'auto' }}>
          {children}
        </Paper>
      </MuiDrawer>
    );
  }

  return (
    <Popover
      anchorEl={anchorEl}
      id={id}
      open={isOpen}
      onClose={onClose}
      sx={{ zIndex: MODAL_Z_INDEX }}
      PaperProps={{
        elevation: 0,
        sx: {
          boxSizing: 'border-box',
          overflow: 'auto',
          filter: `drop-shadow(rgba(0,0,0,0.32) 0px 4px 8px)`,
          minWidth: '220px',
          ...sxPopover,
        },
      }}
      transformOrigin={transformOrigin}
      anchorOrigin={anchorOrigin}
    >
      {children}
    </Popover>
  );
}
