import { CloseActivityStatus } from '@ev/eva-container-api';
import { useDrawerParams } from 'util/drawerUrlParams/useDrawerParams';
import { z } from 'zod';

const taskUrlParamsSchema = z.object({
  contactIds: z.string().array().optional(),
  propertyUtags: z.string().array().optional(),
  editId: z.string().optional(),
  status: z.enum(['COMPLETED', 'CANCELLED'] as const satisfies readonly CloseActivityStatus[]).optional(),
  redirectUrl: z.string().optional(),
});

export type TaskUrlParams = z.infer<typeof taskUrlParamsSchema>;

export function useTaskDrawerUrlParams({ onTaskParams }: { onTaskParams: (params: TaskUrlParams) => void }) {
  useDrawerParams({
    paramName: 'taskDrawer',
    schema: taskUrlParamsSchema,
    onParams: onTaskParams,
  });
}
