import { useActivityDrawer } from '@ev/eva-container-api';
import { Button, Stack, SxProps, Typography } from '@mui/material';
import { ActionsModal } from 'components/general/ActionsModal/ActionsModal';
import { useSelectedLead } from 'components/state/SelectedLead';
import { useContactContext } from 'page-components/contact/LayoutContact/ContactContext';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { EMPTY_DATA_STRING } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';

export const PhoneLink = ({
  number,
  emptyDataString = EMPTY_DATA_STRING,
  disabled,
  isContact,
  sx,
  onClick,
}: {
  number?: number | string | null;
  emptyDataString?: string;
  disabled?: boolean;
  isContact?: boolean;
  sx?: SxProps;
  onClick?: () => void;
}) => {
  const { t } = useTranslation(['communication']);
  const { openTaskDrawer } = useActivityDrawer();
  const { contact } = useContactContext();
  const leadId = useSelectedLead();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  if (!number || number === EMPTY_DATA_STRING) {
    return <Typography sx={{ pl: 1.5 }}>{emptyDataString}</Typography>;
  }

  return (
    <Stack direction="row" alignItems="center">
      <Button
        component={Link}
        to={`tel:${number}`}
        variant="text"
        color="secondary"
        title={String(number)}
        sx={{
          textDecoration: 'underline',
          color: theme.palette.text.primary,
          p: 0.5,
          ml: 0.5,
          '&:hover': {
            textDecoration: 'underline',
          },
          ...sx,
        }}
        onClick={(evt) => {
          if (onClick && !disabled) {
            evt.preventDefault();
            onClick();
            return;
          }
          if (isContact) {
            setIsConfirmationModalOpen(true);
          }
        }}
      >
        <Typography variant="body3" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {number}
        </Typography>
      </Button>
      <ActionsModal
        open={isConfirmationModalOpen}
        handleClose={() => setIsConfirmationModalOpen(false)}
        title={t('communication:phone.callContactModal.title')}
        actions={[
          {
            message: t('no'),
            variant: 'outlined',
            color: 'secondary',
            handleClick: () => {
              setIsConfirmationModalOpen(false);
            },
          },
          {
            message: t('yes'),
            variant: 'contained',
            color: 'primary',
            handleClick: () => {
              openTaskDrawer({
                contactIds: contact?.id ? [contact.id] : undefined,
                leadIds: leadId ? [leadId] : undefined,
              });
              setIsConfirmationModalOpen(false);
            },
          },
        ]}
      />
    </Stack>
  );
};
