import { styled } from '@mui/material/styles';
import { theme } from 'theme';

export const PreformattedText = styled('pre', { shouldForwardProp: (prop: string) => !['variant'].includes(prop) })<{
  variant?: 'body1' | 'body2' | 'body3' | 'body4' | 'subtitle1' | 'subtitle2';
}>(({ variant }) => ({
  color: 'inherit',
  font: 'inherit',
  fontSize: variant ? theme.typography[variant]?.fontSize : theme.typography.body3.fontSize,
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  margin: 0,
}));
