import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

export const emailSignaturesQuery = graphql(/* GraphQL */ `
  query EmailSignaturesQuery(
    $limit: Int
    $offset: Int
    $order_by: [DbEmailSignatureOrderBy!]
    $where: DbEmailSignatureBoolExp
    $agent_id: String
    $shop_id: String
  ) {
    dbEmailSignature(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {
      ...EmailSignature
    }
  }
`);

export const useEmailSignatures = (
  variables: VariablesOf<typeof emailSignaturesQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const {
    data: emailSignatures,
    error,
    ...rest
  } = useQuery({
    queryKey: ['email', 'useEmailSignatures', variables],
    queryFn: async () => (await graphqlClient.request(emailSignaturesQuery, variables)).dbEmailSignature,
    ...options,
  });
  return { emailSignatures, error, ...rest };
};
