import { Box, ButtonBase, Typography } from '@mui/material';
import { EVAlert } from 'components/general/EVAlert/EVAlert';
import CrossIcon from 'components/icons/cross_naked.svg?react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'util/i18next';
export function PropertiesExposeWarning({
  onClose,
  onAction,
  description,
}: {
  onClose?: () => void;
  onAction: () => void;
  description: string;
}) {
  const { t } = useTranslation(['communication']);
  return (
    <EVAlert
      severity="info"
      sx={{
        marginTop: 1,
        '& > :nth-of-type(2)': {
          width: '100%',
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            justifyContent: 'space-between',
            display: 'flex',
            width: '100%',
            marginRight: 1,
            alignItems: 'center',
          }}
        >
          <Typography variant="body3">{description}</Typography>
          <Typography variant="body3">
            <Trans
              t={t}
              i18nKey="communication:email.sendExposePopoverWarningLink"
              components={{
                Link: <ButtonBase sx={{ textDecoration: 'underline' }} onClick={onAction} />,
              }}
            />
          </Typography>
        </Box>
        {onClose && <CrossIcon style={{ cursor: 'pointer' }} onClick={onClose} />}
      </Box>
    </EVAlert>
  );
}
