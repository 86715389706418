import * as React from "react";
const SvgEvLogoCompact = (props) => /* @__PURE__ */ React.createElement("svg", { width: 44, height: 44, viewBox: "-5 10 110 80", xmlns: "http://www.w3.org/2000/svg", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M2.63,30.12l27.57,0l0,5.41l-21.58,0l0,11.13l18.4,0l-0,5.41l-18.4,-0l0,12.41l22.06,-0l-0,5.4l-28.05,-0l0,-39.76Z", style: {
  fill: "#303030",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M55.01,51.91c0.74,-1.8 1.11,-3.98 1.11,-6.52l4.35,0c0,3.85 -0.79,7.09 -2.39,9.7c0.71,0.67 1.4,1.13 2.07,1.38c0.67,0.25 1.47,0.37 2.39,0.37l1.06,0l-0,4.03l-0.85,0c-0.88,0 -1.66,-0.03 -2.33,-0.11c-0.67,-0.07 -1.29,-0.21 -1.86,-0.42c-0.57,-0.21 -1.1,-0.49 -1.59,-0.85c-0.49,-0.35 -1.03,-0.8 -1.59,-1.33c-2.47,2.05 -5.67,3.08 -9.6,3.08c-3.46,0 -6.15,-0.79 -8.06,-2.36c-1.91,-1.57 -2.86,-3.79 -2.86,-6.65c0,-2.01 0.52,-3.75 1.56,-5.2c1.04,-1.45 2.75,-2.79 5.12,-4.03c-1.06,-1.24 -1.83,-2.4 -2.31,-3.5c-0.48,-1.1 -0.72,-2.21 -0.72,-3.34c0,-2.12 0.72,-3.83 2.17,-5.14c1.45,-1.31 3.36,-1.96 5.73,-1.96c2.37,0 4.28,0.64 5.73,1.91c1.45,1.27 2.17,2.97 2.17,5.09c0,1.59 -0.48,3.01 -1.43,4.24c-0.95,1.24 -2.53,2.47 -4.72,3.71l6.84,7.9l0.01,0Zm-10.92,-5.99c-1.52,0.78 -2.62,1.63 -3.31,2.55c-0.69,0.92 -1.03,2 -1.03,3.23c0,1.77 0.64,3.15 1.91,4.16c1.27,1.01 2.97,1.51 5.09,1.51c2.26,0 4.15,-0.62 5.67,-1.86l-8.32,-9.6l-0.01,0.01Zm1.64,-4.72c1.52,-0.81 2.58,-1.59 3.18,-2.33c0.6,-0.74 0.9,-1.63 0.9,-2.65c0,-1.13 -0.29,-1.99 -0.87,-2.57c-0.58,-0.58 -1.41,-0.87 -2.47,-0.87c-1.06,0 -1.9,0.27 -2.52,0.82c-0.62,0.55 -0.93,1.32 -0.93,2.31c0,0.74 0.2,1.52 0.61,2.33c0.41,0.81 1.1,1.8 2.09,2.97l0.01,-0.01Z", style: {
  fill: "#e60000",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M62.44,30.12l6.46,0l11.19,33.14l11.19,-33.14l6.1,0l-13.84,39.76l-7.21,0l-13.89,-39.76Z", style: {
  fill: "#303030",
  fillRule: "nonzero"
} }));
export default SvgEvLogoCompact;
