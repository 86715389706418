import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { NylasIntegratedEmails } from 'components/emails/utils';
import { NoGoogleIntegrationWarning } from 'components/general/NoGoogleIntegrationWarning';
import { OptionsButton } from 'components/general/OptionsButton/OptionsButton';
import ArrowDownIcon from 'components/icons/triangle_down_small.svg?react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

export function SendBulkEmailDialogHeader({
  from,
  onChangeFrom,
  noEmailIntegration,
  possibleFromEmails,
}: {
  noEmailIntegration: boolean;
  from: string;
  onChangeFrom: (from: string) => void;
  possibleFromEmails: NylasIntegratedEmails[];
}) {
  const { t } = useTranslation(['communication']);

  if (noEmailIntegration) {
    return <NoGoogleIntegrationWarning />;
  }

  return (
    <Box
      sx={{
        minHeight: 48,
        flex: 'none',
        [theme.breakpoints.up('desktop')]: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
      }}
    >
      {possibleFromEmails.length === 1 ? (
        <Typography variant="body3" sx={{ marginX: 2, marginY: 1 }}>
          {from}
        </Typography>
      ) : (
        <OptionsButton
          button={
            <Stack direction="row" alignItems="center" gap={1} sx={{ color: theme.palette.text.secondary }}>
              {from}
              <ArrowDownIcon />
            </Stack>
          }
          onChange={onChangeFrom}
          options={possibleFromEmails.map((email) => ({ value: email.email, label: email.email }))}
          selectedOption={from}
        />
      )}
      <Typography variant="body3" sx={{ marginX: 2, marginY: 1 }} component="p">
        {t('communication:sendBulkEmailDialog.note')}
      </Typography>
    </Box>
  );
}
