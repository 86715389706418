import { useSnackBars } from '@ev/eva-container-api';

import { toErrorDetails } from 'components/general/ErrorInfoModal/ErrorInfoModal';
import { useCallback } from 'react';

export function useErrorSnackBar() {
  const { openSnackBar } = useSnackBars();

  const openErrorSnackBar = useCallback(
    (generalErrorMessage: string, error: unknown) => {
      openSnackBar(generalErrorMessage, 'error', {
        errorDetailsInfo: toErrorDetails(error),
      });
    },
    [openSnackBar],
  );

  return { openErrorSnackBar };
}
