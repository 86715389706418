import { SearchShopResponse } from 'api/rest/hooks/useSearchShopByLocation';
import { useCallback } from 'react';
import { useGetCoordinatesForAddress } from 'util/places/coordinates';
import { AddressLike } from 'util/summaries/addressSummary';

export type LatLng = {
  /**
   * Latitude in degrees. Values will be clamped to the range [-90, 90]. This
   * means that if the value specified is less than -90, it will be set to
   * -90. And if the value is greater than 90, it will be set to 90.
   */
  lat: number;
  /**
   * Longitude in degrees. Values outside the range [-180, 180] will be
   * wrapped so that they fall within the range. For example, a value of -190
   * will be converted to 170. A value of 190 will be converted to -170. This
   * reflects the fact that longitudes wrap around the globe.
   */
  lng: number;
};

export type MarkerShopData = {
  shopName?: string;
  websiteUrl?: string;
  licensePartner?: string;
  email?: string;
  phone?: string;
  address?: AddressLike;
};

export type Marker = {
  id?: string;
  icon: string;
  position: LatLng;
  address: AddressLike;
  infoShownInitially?: boolean;
  draggable?: boolean;
} & (
  | ({ type: 'SHOP' } & MarkerShopData)
  | {
      type: 'ADDRESS';
      plannedShop?: MarkerShopData;
    }
);

function getPlannedShop(shops: SearchShopResponse[]) {
  const hasNoOpenShop = !shops.find((shop) => shop.status === 'OPENED');
  // If there is more than one it is enough to show the first
  const plannedShop = shops?.find((shop) => !shop.address && shop.status === 'PLANNED');

  return hasNoOpenShop && plannedShop
    ? ({
        shopName: plannedShop.name,
        licensePartner: plannedShop.licensePartner?.name,
        email: plannedShop.emailAddress,
        websiteUrl: plannedShop.websiteUrl,
        phone: plannedShop.phoneNumber,
      } as const)
    : undefined;
}

export const getLatLngAddressMarker = ({
  position,
  address,
  shops,
}: {
  position: LatLng;
  address: AddressLike;
  shops: SearchShopResponse[];
  isPublic: boolean;
}): Marker[] => {
  return [
    {
      id: 'ADDRESS',
      type: 'ADDRESS',
      icon: '/img/pin-ev-grey.svg',
      address,
      draggable: true,
      position,
      plannedShop: getPlannedShop(shops),
    },
  ];
};

export const useGetAddressMarker = () => {
  const getCoordinatesForAddress = useGetCoordinatesForAddress();

  return useCallback(
    async ({
      address,
      shops,
    }: {
      address: AddressLike;
      shops: SearchShopResponse[];
    }): Promise<(Marker & { type: 'ADDRESS' })[]> => {
      const coordinates = await getCoordinatesForAddress(address);
      return coordinates
        ? [
            {
              id: 'ADDRESS',
              type: 'ADDRESS',
              icon: '/img/pin-ev-grey.svg',
              address,
              draggable: true,
              position: coordinates,
              plannedShop: getPlannedShop(shops),
            } as const,
          ]
        : [];
    },
    [getCoordinatesForAddress],
  );
};

export const useGetShopMarkers = () => {
  return useCallback(async (shops: SearchShopResponse[]): Promise<Marker[]> => {
    const shopsWithFullAddress = shops.filter(
      (shop) => shop.address?.city && shop.address?.countryCode && shop.address?.streetName,
    );
    const openedShops = shopsWithFullAddress.filter((shop) => shop.status === 'OPENED');
    const plannedShops = shopsWithFullAddress.filter((shop) => shop.status === 'PLANNED');

    const displayedShops = openedShops.length ? openedShops : plannedShops;

    return (
      await Promise.all(
        displayedShops.map((shop) => {
          const { shopId, name, emailAddress, phoneNumber, address, licensePartner, websiteUrl } = shop;
          const lat = shop.address?.latitude;
          const lng = shop.address?.longitude;
          const coordinates = { lat, lng };
          if (coordinates) {
            return {
              id: shopId,
              type: 'SHOP',
              icon: '/img/pin-ev-red.svg',
              shopName: name,
              licensePartner: licensePartner?.name,
              email: emailAddress,
              websiteUrl: websiteUrl,
              phone: phoneNumber,
              address: address!,
              position: coordinates,
            } as const;
          }
        }),
      )
    ).filter(Boolean) as Marker[];
  }, []);
};
