import { SxProps } from '@mui/material/styles';
import { useCallQueueSize } from 'api/graphql/hooks/useCall';
import { PopoverFrame } from 'components/general/PopoverFrame/PopoverFrame';
import { createContext, PropsWithChildren, ReactNode, useContext } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

type CallUiVariant = 'dark' | 'light';

interface CallPopoverProps {
  title: string;
  onClose: () => void;
  variant: CallUiVariant;
  actions?: ReactNode;
  headerColor?: string;
  isLoading?: boolean;
  sx?: SxProps;
}

const CallUiVariantContext = createContext<CallUiVariant>('light');

export const useCallUiVariant = function () {
  return useContext(CallUiVariantContext);
};

export function CallFrame({
  children,
  onClose,
  title,
  actions,
  variant,
  headerColor,
  isLoading,
  sx,
}: PropsWithChildren<CallPopoverProps>) {
  const { t } = useTranslation(['communication']);
  const { queueSize } = useCallQueueSize();

  const backgroundColor = variant === 'dark' ? theme.palette.shade.grey1 : theme.palette.backgroundExtension.white;

  const queueSizeTitle =
    queueSize === undefined ? '' : t('communication:call.callerPopover.waitingQueue', { size: queueSize });

  return (
    <CallUiVariantContext.Provider value={variant}>
      <PopoverFrame
        popoverId="callPopover"
        title={title}
        secondaryTitle={queueSizeTitle}
        onClose={onClose}
        actions={actions}
        headerColor={headerColor}
        isLoading={isLoading}
        height={700}
        sx={{ ...sx, backgroundColor, padding: theme.spacing(4, 6, 8, 6) }}
      >
        {children}
      </PopoverFrame>
    </CallUiVariantContext.Provider>
  );
}
