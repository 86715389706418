import { Button, SxProps, TextField } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { Listing } from 'api/graphql/generated/graphql';
import { useGetGeoByAddress } from 'api/rest/hooks/useGetGeoByAddres';
import { useSearchShopByLocation } from 'api/rest/hooks/useSearchShopByLocation';
import { components } from 'api/searchBff/generated-rest';
import { useBFFPlaceDetailsQuery } from 'api/searchBff/hooks/usePlaceDetails';
import { EVAlert } from 'components/general/EVAlert/EVAlert';
import { FormStack } from 'components/general/Form/FormStack';
import { MapPlaceholder } from 'components/general/MapPlaceholder';
import EditMapButton from 'components/leads/PropertyMap/EditMarkerButton';
import { StaticLocationMap } from 'components/leads/StaticLocationMap';
import { LeafletMap } from 'components/map/LeafletMap';
import { LeafletRectangle } from 'components/map/LeafletMap/LeafletRectangle';
import { env } from 'env';
import { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useISO2LanguageCode, useTranslation } from 'util/i18next';
import { mapPropertyEngineAddress } from 'util/mappers/propertyEngineMapper';
import { LatLng, Marker, getLatLngAddressMarker, useGetShopMarkers } from 'util/places/mapMarkers';
import { AddressLike, addressToSingleLineString } from 'util/summaries/addressSummary';
import { useFeatureToggles } from 'util/useFeatureToggles';
import { useShowError } from 'util/useShowError';

type Address = Listing['address'];

const PropertyMapComponent = ({
  propertyAddress,
  placeId,
  showMarkers = true,
  editable = false,
  sx,
  containerStyle,
  showMap,
  showStaticMap = false,
}: {
  placeId?: string | null;
  propertyAddress?: Address;
  showMarkers?: boolean;
  editable?: boolean;
  sx?: SxProps;
  containerStyle?: CSSProperties;
  showMap?: boolean;
  showStaticMap?: boolean;
}): JSX.Element | null => {
  const { setValue, watch } = useFormContext() || { setValue: () => null, watch: () => null };
  const { t } = useTranslation(['admin', 'lead']);
  const getShopMarkers = useGetShopMarkers();
  const [features] = useFeatureToggles();
  const [isMapShown, setMapShown] = useState(
    showMap ? true : !['local', 'local-dev', 'feature', 'dev'].includes(env.VITE_LEADHUB_ENV) || features.debugMaps,
  );

  const [isInteractive, setIsInteractive] = useState(false);
  const [markers, setMarkers] = useState<Marker[]>();
  const { getGeoByAddress, isError: isGetGeoByAddress } = useGetGeoByAddress();
  const { searchShopByLocationAsync, isError: isSearchShopByLocationError } = useSearchShopByLocation();

  const { details, isError: isPlaceDetailsError } = useBFFPlaceDetailsQuery(placeId, {
    enabled: !!placeId && isMapShown,
  });
  const [coordinates, setCoordinates] = useState(details?.location);

  const [isPinMovable, setIsPinMovable] = useState(false);
  const isError = isGetGeoByAddress || isPlaceDetailsError || isSearchShopByLocationError;
  const isEditPinVisible = !isPinMovable && editable;
  const watchLat = watch('property._internals.latitude');
  const watchLng = watch('property._internals.longitude');

  const uiLanguage = (useISO2LanguageCode() || 'en') as components['schemas']['Language'];
  const hasCoordinates = !!(propertyAddress?.latitude && propertyAddress?.longitude);

  useEffect(() => {
    /** Handle address removed case */
    if (details?.location?.lat || watchLat) {
      setCoordinates({ lat: watchLat ?? details!.location!.lat, lng: watchLng ?? details!.location!.lng });
    }
  }, [watchLat, watchLng, details]);

  useEffect(() => {
    async function fetchPlaceDetailsMarker(lat: number, lng: number, placeDetails: AddressLike) {
      const shops = await searchShopByLocationAsync({
        location: {
          latitude: lat,
          longitude: lng,
        },
        businessUnitType: 'RESIDENTIAL',
      });
      const [propertyMarker, shopMarkers] = await Promise.all([
        getLatLngAddressMarker({
          position: {
            lat,
            lng,
          },
          address: placeDetails,
          shops,
          isPublic: false,
        }),
        getShopMarkers(shops),
      ]);

      setMarkers([...propertyMarker, ...shopMarkers]);
    }
    if (isMapShown && showMarkers) {
      // When there are coordinates
      if (propertyAddress && hasCoordinates) {
        fetchPlaceDetailsMarker(propertyAddress.latitude!, propertyAddress.longitude!, propertyAddress);
      }
      // When there is a placeId
      else if (details) {
        const { location } = details;
        if (location) {
          fetchPlaceDetailsMarker(location.lat, location.lng, details);
        }
      }
      // When there isn't a placeId
      else if (!placeId && propertyAddress) {
        const address = mapPropertyEngineAddress(propertyAddress);
        getGeoByAddress(
          { address: addressToSingleLineString(t)(address), language: uiLanguage },
          {
            onSuccess: (address) => {
              const lat = address[0]?.location?.lat;
              const lng = address[0]?.location?.lng;
              if (lat && lng) {
                fetchPlaceDetailsMarker(lat, lng, propertyAddress);
              }
            },
          },
        );
      }
    }
  }, [
    details,
    placeId,
    isMapShown,
    showMarkers,
    getGeoByAddress,
    getShopMarkers,
    hasCoordinates,
    propertyAddress,
    searchShopByLocationAsync,
    uiLanguage,
    t,
  ]);

  const handleDrag = (latLng: LatLng) => {
    const { lat, lng } = latLng;

    setValue('property._internals.latitude', lat);
    setValue('property._internals.longitude', lng);
  };

  useShowError(t('lead:loadingMapError'), isError);

  const location = details?.location;
  if (!isMapShown) {
    return (
      <MapPlaceholder sx={sx}>
        <FormStack sx={{ m: 0 }}>
          <Button variant="outlined" color="secondary" onClick={() => setMapShown(true)}>
            {t('lead:showMap')}
          </Button>
        </FormStack>
      </MapPlaceholder>
    );
  }

  if (!isInteractive && showStaticMap && location) {
    return (
      <StaticLocationMap
        location={location}
        onClick={() => {
          setIsInteractive(true);
        }}
        zoom={15}
      />
    );
  }

  return (
    <>
      <FormStack sx={{ ml: 0, ...sx }}>
        <LeafletMap
          markers={markers}
          boundsWithoutMarkers={details?.boundingBox}
          handleDrag={handleDrag}
          editable={isPinMovable}
          gestureHandling
          containerStyle={containerStyle}
        >
          {!!details?.boundingBox && !isPinMovable && (
            <LeafletRectangle key={details.placeId} bounds={details.boundingBox} />
          )}
          {isEditPinVisible && <EditMapButton setIsPinMovable={setIsPinMovable} />}
        </LeafletMap>
      </FormStack>
      {isPinMovable && (
        <>
          <FormStack>
            <EVAlert fullWidth severity="info">
              {t('lead:map.infoMessage')}
            </EVAlert>
          </FormStack>
          <FormStack>
            <TextField label={t('lead:map.latitude')} value={coordinates?.lat} disabled />
            <TextField label={t('lead:map.longitude')} value={coordinates?.lng} disabled />
          </FormStack>
        </>
      )}
    </>
  );
};

export const PropertyMap = memo(PropertyMapComponent);
