import { Box, Popover, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { TContactOption } from 'components/forms/ContactPicker/ContactOption';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import LinkIcon from 'components/icons/link_external.svg?react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { theme } from 'theme';
import { useDestination } from 'util/navigation/useDestination';

export const ContactPopover = ({
  anchorEl,
  selectedOption,
  handleClose,
}: {
  anchorEl: Maybe<HTMLDivElement | HTMLButtonElement>;
  selectedOption?: TContactOption;
  handleClose: () => void;
}) => {
  const open = Boolean(anchorEl);
  const { toContactDetails } = useDestination();
  const id = open ? 'simple-popover' : undefined;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
        <EVAvatar person={{ firstName: selectedOption?.firstName, lastName: selectedOption?.lastName }} size="s" />
        <Box sx={{ display: 'block', lineHeight: 2 }}>
          <Typography variant="body3" sx={{ pl: 1, py: 0 }} component="div">
            {selectedOption?.firstName} {selectedOption?.lastName}
          </Typography>
          <Typography variant="caption" sx={{ pl: 1, py: 0, color: theme.palette.text.secondary }}>
            {selectedOption?.email}
          </Typography>
        </Box>
        <Box sx={{ pl: 3 }}>
          {selectedOption?.id && (
            <IconButton target="_blank" href={toContactDetails({ contactId: selectedOption.id })}>
              <LinkIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </Popover>
  );
};
