import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

export const licensePartnerQuery = graphql(/* GraphQL */ `
  query licensePartners(
    $limit: Int
    $offset: Int
    $order_by: [DbLicensePartnerOrderBy!]
    $where: DbLicensePartnerBoolExp
  ) {
    dbLicensePartner(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {
      ...LicensePartner
    }
  }
`);
export const useLicensePartners = (
  variables: VariablesOf<typeof licensePartnerQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'keepPreviousData'>,
) => {
  const { data: licensePartners, ...rest } = useQuery({
    queryKey: ['shop', 'useLicensePartners', variables],
    queryFn: async () => (await graphqlClient.request(licensePartnerQuery, variables)).dbLicensePartner,
    ...options,
  });
  return { licensePartners, ...rest };
};
