import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { IntentTypeEnum } from 'api/graphql/generated/graphql';
import { useGetAllEnums } from 'api/graphql/hooks/useGetAllEnums';
import { KeyValue } from 'api/rest/models/Common';
import { DropDown } from 'components/general/DropDown/DropDown';
import { FormSection, FormSectionHeader } from 'components/general/Form/FormSection';
import { FormStack } from 'components/general/Form/FormStack';
import { Option } from 'components/general/OptionsList/OptionsList';
import ClockIcon from 'components/icons/clock.svg?react';
import { PropertyTypeSection } from 'components/leads/forms/LeadForm/SellIntent/PropertyTypeSection';
import { timeFrameTranslationKeys } from 'const/enumTranslations';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { includeEmptyOption } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';
import { enumToLokalisedOptions } from 'util/mappers/enumToOption';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';

export const BasicDetails = () => {
  const { t } = useTranslation(['lead', 'enums']);
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<PropertyDetailsFormData>();
  const { allEnums } = useGetAllEnums();

  const IntentArray: KeyValue<IntentTypeEnum, string>[] = [
    {
      key: 'VALUATION',
      value: t('lead:intent.valuation'),
    },
    {
      key: 'SELL',
      value: t('lead:intent.selling'),
    },
  ];

  const timeFrameSelectOptions: Option[] = useMemo(() => {
    return includeEmptyOption(allEnums?.enumsTimeFrame?.map(enumToLokalisedOptions(t, timeFrameTranslationKeys)) ?? []);
  }, [allEnums?.enumsTimeFrame, t]);

  const watchIntentType = watch('intentType');

  return (
    <FormSection>
      <FormSectionHeader>{t('lead:form.section.basicDetails.header')}</FormSectionHeader>
      <FormControl error={!!errors?.intentType}>
        <FormStack>
          <Controller
            name="intentType"
            control={control}
            render={({ field }) => (
              <RadioGroup row name="property.intent-radio-group">
                {IntentArray.map((intent) => (
                  <FormControlLabel
                    {...field}
                    key={intent.key}
                    control={
                      <Radio
                        color="secondary"
                        value={intent.key}
                        checked={field.value === intent.key}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                          if (event.target.value !== 'SELL') {
                            setValue('property.timeFrame', undefined);
                          }
                        }}
                      />
                    }
                    label={intent.value}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormStack>
        <FormStack>
          <FormHelperText>{errors.intentType?.message}</FormHelperText>
        </FormStack>
      </FormControl>
      {watchIntentType === 'SELL' && (
        <FormStack icon={<ClockIcon />}>
          <Controller
            name="property.timeFrame"
            control={control}
            render={({ field }) => (
              <DropDown {...field} label={t('lead:propertyDetails.timeFrameToSell')} options={timeFrameSelectOptions} />
            )}
          />
        </FormStack>
      )}
      <PropertyTypeSection />
    </FormSection>
  );
};
