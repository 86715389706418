import { KeycloakContext, ParsedJwtToken, useJwtToken } from '@ev/eva-container-api';
import { ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { LicensePartner, Shop, ShopSettings } from 'api/graphql/generated/graphql';
import { queryClient } from 'api/queryClient';
import { ShopContext, useActiveShop } from 'components/state/ActiveShopProvider';

import { ExposeWidgetProps } from 'components/forms/RichTextEditor/blots/expose/useSyncExposeFormStateToEditorState';
import { PropsWithChildren, useEffect, useSyncExternalStore } from 'react';
import { theme } from 'theme';

interface GlobalEditorStateState {
  context?: {
    jwtToken: ParsedJwtToken;
    activeShop: Shop;
    activeShopSettings?: ShopSettings;
    allShops: Shop[];
    activeLicensePartner: LicensePartner;
    defaultActivityFilter: string[];
  };

  props: {
    expose?: Record<string, ExposeWidgetProps>;
  };
}

type PropsStateType = Required<GlobalEditorStateState['props']>;

export class GlobalEditorState {
  state: GlobalEditorStateState = {
    props: {},
  };

  listeners: (() => void)[] = [];

  setProps<Type extends keyof PropsStateType>(
    type: Type,
    id: string,
    value: PropsStateType[Type][keyof PropsStateType[Type]],
  ) {
    this.state = {
      ...this.state,
      props: {
        ...this.state.props,
        [type]: {
          ...this.state.props[type],
          [id]: value,
        },
      },
    };
    this.notifyListeners();
  }

  clearProps(type: keyof PropsStateType, id: string) {
    const propStateCopy = { ...this.state.props[type] };
    delete propStateCopy[id];

    this.state = {
      ...this.state,
      props: {
        ...this.state.props,
        [type]: propStateCopy,
      },
    };
    this.notifyListeners();
  }

  getState = () => {
    return this.state;
  };

  setContext(context: GlobalEditorStateState['context']) {
    this.state = {
      ...this.state,
      context,
    };
    this.notifyListeners();
  }

  notifyListeners() {
    for (const listener of this.listeners) {
      listener();
    }
  }

  resetListeners() {
    this.listeners = [];
  }

  subscribe = (listener: () => void) => {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  };
}

export const globalEditorState = new GlobalEditorState();
export function useGlobalEditorState() {
  return useSyncExternalStore(globalEditorState.subscribe, globalEditorState.getState);
}

let instanceCount = 0;
export function useSetupGlobalEditorState() {
  const { jwtToken } = useJwtToken();
  const { activeShop, activeShopSettings, allShops, activeLicensePartner, defaultActivityFilter } = useActiveShop();

  useEffect(() => {
    instanceCount += 1;
    return () => {
      instanceCount -= 1;
      if (instanceCount === 0) {
        // Remove all listeners when the last editor instance in unmounted. This cleans up listeners that are leaked
        // when the separate React mount point for the blots is removed from the DOM.
        globalEditorState.resetListeners();
      }
    };
  }, []);

  // Create a copy of the outer context so that it can be injected into an editor blot
  useEffect(() => {
    globalEditorState.setContext({
      jwtToken,
      activeShop,
      activeShopSettings,
      allShops,
      activeLicensePartner,
      defaultActivityFilter,
    });
  }, [jwtToken, activeShop, activeShopSettings, allShops, activeLicensePartner, defaultActivityFilter]);
}

export function GlobalEditorStateContextProvider({ children }: PropsWithChildren) {
  const { context } = useGlobalEditorState();

  if (!context) {
    return null;
  }

  // Replicate the outer context inside an editor blot
  return (
    <ThemeProvider theme={theme}>
      <KeycloakContext.Provider value={context.jwtToken}>
        <QueryClientProvider client={queryClient}>
          <ShopContext.Provider
            value={{
              activeShop: context.activeShop,
              activeShopSettings: context.activeShopSettings,
              allShops: context.allShops,
              activeLicensePartner: context.activeLicensePartner,
              defaultActivityFilter: context.defaultActivityFilter,
            }}
          >
            {children}
          </ShopContext.Provider>
        </QueryClientProvider>
      </KeycloakContext.Provider>
    </ThemeProvider>
  );
}
