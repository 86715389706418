import { useDrawer, useEmailSending } from '@ev/eva-container-api';
import { ButtonBase, IconButton, ListItemIcon, ListItemText, SxProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useDisplayActivity } from 'components/activity/useDisplayActivity';
import { DropdownMenu } from 'components/general/DropDown/DropdownMenu';
import { EVDrawer } from 'components/general/EVDrawer/EVDrawer';
import DevelopmentIcon from 'components/icons/apartment.svg?react';
import TodayIcon from 'components/icons/calendar_today.svg?react';
import CheckIcon from 'components/icons/check_circle.svg?react';
import DocumentShareIcon from 'components/icons/document_share.svg?react';
import HomeIcon from 'components/icons/home.svg?react';
import MailIcon from 'components/icons/mail.svg?react';
import PlusIcon from 'components/icons/plus_naked.svg?react';
import TinyPlusIcon from 'components/icons/tiny_plus.svg?react';
import ContactIcon from 'components/icons/user_single.svg?react';
import { CreateNewLead } from 'components/leads/forms/CreateNewLead';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useState } from 'react';
import { theme } from 'theme';
import { useCreateContactUrlParams } from 'util/drawerUrlParams/useCreateContactUrlParams';
import { useTranslation } from 'util/i18next';
import { useDestination } from 'util/navigation/useDestination';
import { useNavigation } from 'util/navigation/useNavigation';
import { usePermissions } from 'util/usePermissions';

export function ActionMenuButton({
  variant,
  sx,
  showLabel,
}: {
  variant: 'solid' | 'slim';
  showLabel?: boolean;
  sx?: SxProps;
}) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const { t } = useTranslation();
  const { toSales, toDevelopments } = useDestination();
  const { openSendEmail, openSendBulkEmail } = useEmailSending();
  const { addActivity } = useDisplayActivity();
  const { canCreateLead, canCreateContact } = usePermissions();
  const { activeShop, isFullyOnboardedShop } = useActiveShop();

  const createContactButtonDisabled = !canCreateLead(activeShop) && !canCreateContact(activeShop);

  const [isCreateContactDrawerOpen, setCreateContactDrawerOpen] = useDrawer('contact');

  const { push } = useNavigation();

  useCreateContactUrlParams({ onContactParams: () => setCreateContactDrawerOpen(true) });

  const items = [
    {
      label: t('actionMenu.createAppointment'),
      onClick: () => {
        addActivity('APPOINTMENT');
        setAnchorEl(null);
      },
      disabled: !isFullyOnboardedShop,
      icon: <TodayIcon />,
    },
    {
      label: t('actionMenu.createTask'),
      onClick: () => {
        addActivity('TASK');
        setAnchorEl(null);
      },
      icon: <CheckIcon />,
    },
    {
      label: t('actionMenu.createContactAndLead'),
      disabled: createContactButtonDisabled,
      onClick: () => {
        setCreateContactDrawerOpen(true);
        setAnchorEl(null);
      },
      icon: <ContactIcon />,
    },
    {
      label: t('actionMenu.sendMail'),
      onClick: () => {
        openSendEmail({ to: [] });
        setAnchorEl(null);
      },
      icon: <MailIcon />,
    },
    {
      label: t('actionMenu.proposeExpose'),
      onClick: () => {
        openSendBulkEmail({ template: 'EXPOSE', to: [] });
        setAnchorEl(null);
      },
      icon: <DocumentShareIcon />,
    },
    {
      label: t('actionMenu.proposePremiumExpose'),
      onClick: () => {
        openSendBulkEmail({ template: 'PREMIUM_EXPOSE_MAIL', to: [] });
        setAnchorEl(null);
      },
      icon: <DocumentShareIcon />,
    },
    {
      label: t('actionMenu.createProperty'),
      onClick: () => {
        setAnchorEl(null);
        push(toSales({ create: true }));
      },
      icon: <HomeIcon />,
    },
    {
      label: t('actionMenu.createDevelopment'),
      onClick: () => {
        setAnchorEl(null);
        push(toDevelopments({ create: true }));
      },
      icon: <DevelopmentIcon />,
    },
  ];

  const openShopMenu = (event: React.MouseEvent) => setAnchorEl(event.currentTarget);

  return (
    <>
      {variant === 'solid' ? (
        <Tooltip title={showLabel ? undefined : t('actionMenu.button')} placement="right">
          <ButtonBase
            sx={{
              paddingX: 3,
              paddingY: 2,
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
              '&:hover': {
                backgroundColor: theme.palette.shade.grey4,
              },
              ...sx,
            }}
            onClick={openShopMenu}
          >
            <TinyPlusIcon
              style={{
                color: theme.palette.textExtension.white,
                backgroundColor: theme.palette.secondary.main,
                borderRadius: 2,
              }}
            />
            {showLabel && (
              <Typography variant="body3" color={theme.palette.text.secondary} sx={{ marginLeft: 1 }}>
                {t('actionMenu.button')}
              </Typography>
            )}
          </ButtonBase>
        </Tooltip>
      ) : (
        <IconButton onClick={openShopMenu} sx={sx}>
          <PlusIcon />
        </IconButton>
      )}
      <DropdownMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        showDrawerOnMobile={{ title: t('actionMenu.button') }}
      >
        <MenuList disablePadding>
          {items.map(({ label, icon, onClick, disabled }) => (
            <MenuItem key={label} onClick={onClick} disabled={disabled}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </DropdownMenu>
      <EVDrawer isOpen={isCreateContactDrawerOpen} onClose={() => setCreateContactDrawerOpen(false)}>
        <CreateNewLead onClose={() => setCreateContactDrawerOpen(false)} />
      </EVDrawer>
    </>
  );
}
