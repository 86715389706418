import { styled } from '@mui/material/styles';
import { theme } from 'theme';

export const ModalBox = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
  padding: '56px 64px 56px 64px',
});

export const ButtonsBox = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  paddingTop: theme.spacing(4),
  gap: theme.spacing(2),
});
