import sentry from 'util/sentry';

export function getSessionStorageKey(emailIntegration: string, pageSize: number) {
  return `NYLAS_PAGE_TOKEN-${pageSize}-${emailIntegration}`;
}

function getPageTokenMap(emailIntegration: string, pageSize: number) {
  const key = getSessionStorageKey(emailIntegration, pageSize);
  const sessionStorageValue = sessionStorage.getItem(key);

  if (sessionStorageValue) {
    try {
      return JSON.parse(sessionStorageValue);
    } catch {
      sentry.logError('Unable to read Nylas page token cache from session storage');
    }
  }
}

export function updatePageToken({
  emailIntegration,
  page,
  pageSize,
  token,
}: {
  emailIntegration: string;
  page: number;
  pageSize: number;
  token: string;
}) {
  const updatedMap = {
    ...getPageTokenMap(emailIntegration, pageSize),
    [page]: token,
  };
  sessionStorage.setItem(getSessionStorageKey(emailIntegration, pageSize), JSON.stringify(updatedMap));
}

export function getPageToken({
  emailIntegration,
  page,
  pageSize,
}: {
  emailIntegration: string;
  page: number;
  pageSize: number;
}) {
  return getPageTokenMap(emailIntegration, pageSize)?.[page];
}
