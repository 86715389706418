import { useCustomDemandFields } from 'api/graphql/hooks/useCustomDemandFields';
import { DropDown } from 'components/general/DropDown/DropDown';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';

interface CdfPickerProps {
  value: string | string[] | undefined;
  onChange: (value: string | string[]) => void;
  onBlur?: () => void;
  onClickOption?: (value: string) => void;
}

export function CdfPicker({ value, onChange, onBlur, onClickOption }: CdfPickerProps) {
  const { t } = useTranslation(['lead']);
  const { shopIdsInActiveGroup } = useActiveShop();
  const { customDemandFields } = useCustomDemandFields({ where: { shopId: { _in: shopIdsInActiveGroup } } });
  const [cdfSearchString, setCdfSearchString] = useState<string>('');
  const { watch } = useFormContext<PropertyDetailsFormData>();
  const leadShopId = watch('_internals.leadShopId');
  const deduplicatedCdfs = useMemo(() => {
    const cdfMap = new Map();
    customDemandFields?.forEach((cdf) => {
      if (!cdfMap.has(cdf.label) || leadShopId === cdf.shopId) {
        cdfMap.set(cdf.label, cdf);
      }
    });
    return [...cdfMap.values()];
  }, [customDemandFields, leadShopId]);

  const cdfsFiltered = useMemo(() => {
    return deduplicatedCdfs.filter(({ label }) => label.toLowerCase().includes(cdfSearchString.toLowerCase()) || []);
  }, [deduplicatedCdfs, cdfSearchString]);

  return (
    <DropDown
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onClickOption={onClickOption}
      multiple
      search={{
        searchString: cdfSearchString,
        onSearchStringChange: (searchString: string) => {
          setCdfSearchString(searchString);
        },
        placeholder: t('lead:propertyEvaluateForm.details.demandFieldSearchPlaceholder'),
      }}
      label={t('lead:propertyEvaluateForm.details.demandField')}
      options={
        cdfsFiltered?.map(({ id, label }) => ({
          value: id,
          label,
        })) || []
      }
    />
  );
}
