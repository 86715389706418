import { useDefaultSignature } from 'components/emails/useDefaultSignature';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { emailBodyWithSignature } from 'util/email';
import { EmailFormData } from 'util/schemas/sendEmailSchema';

export function useEmailEditor() {
  const defaultSignature = useDefaultSignature();
  const { getValues, setValue } = useFormContext<EmailFormData>();
  const [selectedSignature, setSelectedSignature] = useState(defaultSignature || '');

  const insertSignature = useCallback(
    (signature: string) => {
      setSelectedSignature(signature);
      setValue('body', emailBodyWithSignature(getValues('body'), signature));
    },
    [getValues, setValue],
  );

  const insertTemplateBody = useCallback(
    (template: string) => {
      const signatureStartMarker = document.querySelector('signature');
      if (signatureStartMarker !== null) {
        const index = getValues('body').indexOf('<signature>');
        setValue(
          'body',

          template + '<p><br></p>' + getValues('body').slice(index).replace('</p><p>', '</p></br><p>'),
        );
      } else {
        setValue('body', template + '<p><br></p>');
      }
    },
    [getValues, setValue],
  );

  return { insertSignature, signature: selectedSignature, insertTemplateBody };
}
