import Typography from '@mui/material/Typography';
import { theme } from 'theme';

export function RequiredLabel({ text, suffix }: { text: string; suffix?: boolean }) {
  return (
    <>
      {!suffix && (
        <Typography variant="inherit" component="span" color={theme.palette.error.main}>
          *&nbsp;
        </Typography>
      )}
      {text}
      {suffix && (
        <Typography variant="inherit" component="span" color={theme.palette.error.main}>
          &nbsp;*
        </Typography>
      )}
    </>
  );
}
