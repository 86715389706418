import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

import { UncheckedIcon } from 'components/general/EVCheckbox/styles';
import React from 'react';

interface EVCheckboxComponentProps extends Omit<CheckboxProps, 'onChange'> {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  'data-testid'?: string;
}

export function EVCheckbox({
  onChange,
  value,
  checked,
  indeterminate,
  readOnly,
  'data-testid': testId,
  onClick,
  ...rest
}: EVCheckboxComponentProps) {
  return (
    <Checkbox
      color="secondary"
      icon={<UncheckedIcon />}
      onChange={onChange}
      checkedIcon={<CheckBoxIcon />}
      indeterminateIcon={<IndeterminateCheckBoxIcon />}
      value={value}
      checked={checked}
      indeterminate={indeterminate}
      disabled={readOnly}
      data-testid={testId}
      onClick={onClick}
      {...rest}
    />
  );
}
