import { IconButton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useCallUiVariant } from 'components/calls/CallFrame';
import { PropsWithChildren, ReactNode } from 'react';
import { theme } from 'theme';

export function CallActions({ children }: PropsWithChildren) {
  return (
    <Stack sx={{ paddingY: 6 }} direction="row" gap={3} justifyContent="center">
      {children}
    </Stack>
  );
}

export function CallActionButton({
  onClick,
  icon,
  disabled,
  color,
  children,
}: PropsWithChildren<{
  onClick?: () => void;
  icon: ReactNode;
  disabled?: boolean;
  color: string;
}>) {
  const variant = useCallUiVariant();

  return (
    <Stack alignItems="center" gap={1} sx={{ minWidth: '20%', maxWidth: '30%' }}>
      <IconButton
        size="large"
        onClick={onClick}
        disabled={disabled}
        sx={{
          backgroundColor: color,
          color: theme.palette.textExtension.white,
          '&:hover': { backgroundColor: color, filter: 'brightness(0.85)', color: theme.palette.textExtension.white },
          '&:disabled': { backgroundColor: theme.palette.shade.grey4, color: theme.palette.shade.grey3 },
        }}
      >
        {icon}
      </IconButton>
      <Typography
        variant="body3"
        sx={{ textAlign: 'center' }}
        color={variant === 'dark' ? theme.palette.textExtension.white : theme.palette.text.secondary}
      >
        {children}
      </Typography>
    </Stack>
  );
}
