import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ImageIcon from 'components/icons/image.svg?react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

export function NoPropertyPlaceholder({
  sx,
  allowsMultipleProperties = true,
}: {
  sx?: SxProps;
  allowsMultipleProperties?: boolean;
}) {
  const { t } = useTranslation(['communication']);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        border: `1px solid ${theme.palette.shade.grey3}`,
        color: theme.palette.shade.grey3,
        backgroundColor: theme.palette.backgroundExtension.white,
        ...sx,
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.shade.grey4,
          borderRight: `1px solid ${theme.palette.shade.grey3}`,
        }}
      >
        <ImageIcon style={{ margin: '20px', color: theme.palette.shade.grey3 }} />
      </Box>
      <Typography variant="body3" sx={{ paddingX: 2 }}>
        {allowsMultipleProperties
          ? t('communication:sendBulkEmailDialog.noPropertyPlaceholder')
          : t('communication:sendBulkEmailDialog.noPropertyPlaceholder.premiumExpose')}
      </Typography>
    </Stack>
  );
}
