import { useMutation } from '@tanstack/react-query';
import { fetchClient, PostRequestBody } from 'api/rest/fetch-client';
import { components } from 'api/rest/generated-rest';

export type SearchShopByLocationRequest = PostRequestBody<'/api/v1/masterdata/shops/search-by-location'>;
export type SearchShopResponse = components['schemas']['ShopFeatures'];

export const useSearchShopByLocation = () => {
  const {
    mutate: searchShopByLocation,
    mutateAsync: searchShopByLocationAsync,
    ...rest
  } = useMutation((requestBody: SearchShopByLocationRequest) =>
    fetchClient
      .POST('/api/v1/masterdata/shops/search-by-location', {
        body: requestBody,
      })
      .then((resp) => resp.data!),
  );
  return { searchShopByLocation, searchShopByLocationAsync, ...rest };
};
