import { VariablesOf } from '@graphql-typed-document-node/core';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { graphql } from 'api/graphql/generated';
import { secondaryGraphqlClient } from 'api/graphql/graphql-client';

export const getLastInteractionQuery = graphql(/* GraphQL */ `
  query getLastInteractionActivities($limit: Int, $orderBy: [DbBaseActivityOrderBy!], $where: DbBaseActivityBoolExp) {
    dbBaseActivity(limit: $limit, orderBy: $orderBy, where: $where) {
      ...LastInteraction
    }
  }
`);

export const useLastInteractionActivities = (
  variables: VariablesOf<typeof getLastInteractionQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: lastInteractions, ...rest } = useQuery({
    queryKey: ['activity', 'useLastInteractionActivities', variables],
    queryFn: async () => (await secondaryGraphqlClient.request(getLastInteractionQuery, variables)).dbBaseActivity,
    enabled: options?.enabled,
    ...options,
  });
  return { lastInteractions, ...rest };
};

export const getBaseInfoActivitiesQuery = graphql(/* GraphQL */ `
  query getActivities($limit: Int, $orderBy: [DbBaseActivityOrderBy!], $where: DbBaseActivityBoolExp) {
    dbBaseActivity(limit: $limit, orderBy: $orderBy, where: $where) {
      ...ActionActivity
    }
  }
`);

export const useBaseInfoActivities = (
  variables: VariablesOf<typeof getBaseInfoActivitiesQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: activities, ...rest } = useQuery({
    queryKey: ['activity', 'useBaseInfoActivities', variables],
    queryFn: async () => (await secondaryGraphqlClient.request(getBaseInfoActivitiesQuery, variables)).dbBaseActivity,
    enabled: options?.enabled,
    ...options,
  });
  return { activities, ...rest };
};

export const getActivitiesListQuery = graphql(/* GraphQL */ `
  query getActivitiesList(
    $limit: Int
    $offset: Int
    $orderBy: [DbBaseActivityOrderBy!]
    $where: DbBaseActivityBoolExp
  ) {
    dbBaseActivity(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
      ...ActivityListItem
    }
  }
`);

export const useActivitiesList = (
  variables: VariablesOf<typeof getActivitiesListQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'staleTime'>,
) => {
  const { data: activities, ...rest } = useQuery({
    queryKey: ['activity', 'useBaseInfoActivities', variables],
    queryFn: async () => (await secondaryGraphqlClient.request(getActivitiesListQuery, variables)).dbBaseActivity,
    ...options,
  });

  return { activities, ...rest };
};

export const getExpandedActivityQuery = graphql(/* GraphQL */ `
  query getExpandedActivity(
    $limit: Int
    $offset: Int
    $orderBy: [DbBaseActivityOrderBy!]
    $where: DbBaseActivityBoolExp
  ) {
    dbBaseActivity(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
      ...ExpandedActivityListItem
    }
  }
`);

export const useExpandedActivity = (
  variables: VariablesOf<typeof getExpandedActivityQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: activities, ...rest } = useQuery({
    queryKey: ['activity', 'useExpandedActivity', variables],
    queryFn: async () => (await secondaryGraphqlClient.request(getExpandedActivityQuery, variables)).dbBaseActivity,
    ...options,
  });

  return { activities, ...rest };
};

const totalActivitiesCountQuery = graphql(/* GraphQL */ `
  query totalActivitiesCountQuery($where: DbBaseActivityBoolExp, $limit: Int) {
    dbBaseActivityAggregate(where: $where, limit: $limit) {
      aggregate {
        totalActivityCount: count
      }
    }
  }
`);

export const useTotalActivitiesCount = (
  variables: VariablesOf<typeof totalActivitiesCountQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'staleTime'>,
) => {
  const { data: totalActivityCount, ...rest } = useQuery({
    ...options,
    queryKey: ['activity', 'useTotalActivitiesCount', variables],
    queryFn: async () =>
      (await secondaryGraphqlClient.request(totalActivitiesCountQuery, variables)).dbBaseActivityAggregate.aggregate
        ?.totalActivityCount,
  });
  return { totalActivityCount, ...rest };
};

const activityTypeGroupingQuery = graphql(/* GraphQL */ `
  query activityTypeGrouping {
    dbActivityTypeGrouping {
      ...ActivityTypeGrouping
    }
  }
`);

export const useActivityWithCategory = () => {
  const { data: activityWithCategory, ...rest } = useQuery({
    queryKey: ['activity', 'useActivityTypeGrouping'],
    queryFn: async () => (await secondaryGraphqlClient.request(activityTypeGroupingQuery))?.dbActivityTypeGrouping,
  });
  return { activityWithCategory, ...rest };
};
