import { Agent, ContactInfo, ContactRelationTypeEnum } from 'api/graphql/generated/graphql';
import { useMemo } from 'react';
import { IterableElement } from 'type-fest';
import { useTranslation } from 'util/i18next';
import { useSchemaPrimitives } from 'util/schemas/schemaPrimitives';
import { z } from 'zod';

export function useCcAndBccSchema() {
  const { t } = useTranslation(['communication']);
  const { requiredEmail } = useSchemaPrimitives();
  return useMemo(() => {
    return z
      .union([
        z.custom<Agent & { type: 'AGENT' }>(),
        z.custom<ContactInfo & { type: 'RELATED_CONTACT'; relationship: ContactRelationTypeEnum }>(),
        z.custom<ContactInfo & { type: 'CONTACT' }>(),
        z.custom<{ type: 'EMAIL'; email: string }>(),
      ])
      .array()
      .refine((data) => !data.find((r) => r.type === 'EMAIL' && !requiredEmail.safeParse(r.email).success), {
        message: t('formValidation.invalidEmail'),
      });
  }, [requiredEmail, t]);
}

export type CcRecipient = IterableElement<z.infer<ReturnType<typeof useCcAndBccSchema>>>;
