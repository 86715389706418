import IconButton from '@mui/material/IconButton';
import { EmailTemplate } from 'api/graphql/generated/graphql';
import { ExposeTemplatesList } from 'components/emails/TemplatesSelector/ExposeTemplatesList';
import { ManualTemplatesList } from 'components/emails/TemplatesSelector/ManualTemplatesList';
import TemplateIcon from 'components/icons/file_text.svg?react';
import { useRef, useState } from 'react';
import { useTranslation } from 'util/i18next';

export function TemplateSelector({
  onTemplateSelected,
  forExposeSending,
  selectedTemplateId,
}: {
  selectedTemplateId?: string;
  forExposeSending?: boolean;
  onTemplateSelected: (template: EmailTemplate) => void;
}) {
  const { t } = useTranslation(['communication']);

  const templateMenuAnchor = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleTemplateSelected = (template: EmailTemplate) => {
    setIsOpen(false);
    onTemplateSelected(template);
  };

  const listProps = {
    isOpen,
    anchorRef: templateMenuAnchor,
    onClose: () => setIsOpen(false),
    onTemplateSelected: handleTemplateSelected,
  };

  return (
    <>
      <IconButton
        data-testid="templateSwitch"
        aria-controls={isOpen ? 'template-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        aria-label={t('communication:emailForm.insertTemplateButton')}
        ref={templateMenuAnchor}
        onClick={() => setIsOpen(true)}
        edge="end"
      >
        <TemplateIcon />
      </IconButton>
      {forExposeSending ? (
        <ExposeTemplatesList selectedTemplateId={selectedTemplateId} {...listProps} />
      ) : (
        <ManualTemplatesList {...listProps} />
      )}
    </>
  );
}
