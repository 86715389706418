import { AxiosRequestConfig, AxiosResponse } from 'axios';
import sentry from 'util/sentry';
import { apiClient } from './axios-client';
import { components } from './generated-rest';

const API_COMMAND_EXECUTION_PATH = '/v1/exec';
const API_MIGRATION_PATH = '/go3-migration/run-all';
const API_COMMAND_EXECUTION_DOWNLOAD_PATH = '/v1/exec/download';

type RestCommandClass = Extract<keyof components['schemas'], `${string}Command`>;

type RestCommand<T extends RestCommandClass = RestCommandClass> = components['schemas'][T];

type LeadEngineCommandOptions<T extends RestCommandClass> = {
  requestBody: RestCommand<T>;
  className: T;
  requestConfig?: AxiosRequestConfig;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isIgnoredError(error: unknown) {
  return true;
  // For now command errors are only logged in the backend
  // return (
  //   error instanceof AxiosError &&
  //   ((error.code && ['ERR_NETWORK', 'ECONNABORTED'].includes(error.code)) ||
  //     (error.response && [undefined, 0, 409, EXPECTED_ERROR_STATUS_CODE, 502].includes(error.response.status)))
  // );
}

export class HttpService {
  async executeLeadEngineCommand<T extends RestCommandClass>({
    requestBody,
    className,
    requestConfig,
  }: LeadEngineCommandOptions<T>): Promise<AxiosResponse> {
    sentry.addBreadcrumb({ message: `Execute command: ${className}` });

    const command = { ...requestBody, class: className };
    try {
      return await apiClient.post(API_COMMAND_EXECUTION_PATH, command, requestConfig);
    } catch (error) {
      if (!isIgnoredError(error)) {
        sentry.logError(error as Error, { extra: { className } });
      }
      const infoError = { response: error, request: command };
      return await Promise.reject(infoError);
    }
  }

  async executeMigration(shopId: string): Promise<AxiosResponse> {
    sentry.addBreadcrumb({ message: `Execute migration for: ${shopId}` });

    try {
      return await apiClient.post(`${API_MIGRATION_PATH}/${shopId}`);
    } catch (error) {
      if (!isIgnoredError(error)) {
        sentry.logError(error as Error, { extra: { shopId } });
      }
      return await Promise.reject(error);
    }
  }

  public executeLeadEngineDownloadCommand<T extends RestCommandClass>({
    requestBody,
    className,
  }: LeadEngineCommandOptions<T>): Promise<AxiosResponse> {
    sentry.addBreadcrumb({ message: `Execute download command: ${className}` });

    const command = { ...requestBody, class: className };
    return apiClient
      .post(API_COMMAND_EXECUTION_DOWNLOAD_PATH, command, {
        responseType: 'arraybuffer',
      })
      .catch((error) => {
        if (!isIgnoredError(error)) {
          sentry.logError(error as Error, { extra: { className } });
        }
        const infoError = { response: error, request: command };
        return Promise.reject(infoError);
      });
  }

  async executeLeadEngineCommandWithFileUpload<T extends RestCommandClass>({
    requestBody,
    className,
    requestConfig,
    files,
  }: LeadEngineCommandOptions<T> & { files: File[] }): Promise<AxiosResponse> {
    const command = { ...requestBody, class: className };
    try {
      const formData = new FormData();
      for (const file of files) {
        formData.append('files', file);
      }
      formData.append('command', new Blob([JSON.stringify(command)], { type: 'application/json' }));

      sentry.addBreadcrumb({ message: `Execute upload command: ${className}` });
      return await apiClient.post(API_COMMAND_EXECUTION_PATH, formData, requestConfig);
    } catch (error) {
      if (!isIgnoredError(error)) {
        sentry.logError(error as Error, { extra: { className } });
      }
      const infoError = { response: error, request: command };
      return await Promise.reject(infoError);
    }
  }
}
