import { IconButton, Stack } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { AddressTypeEnum } from 'api/graphql/generated/graphql';
import { useGetAllEnums } from 'api/graphql/hooks/useGetAllEnums';
import AddButton from 'components/contact/ContactDetailsForm/AddButton';
import { AddressSearch } from 'components/general/AddressSearch/AddressSearch';
import { DropDown } from 'components/general/DropDown/DropDown';
import { FormStack } from 'components/general/Form/FormStack';
import MinusIcon from 'components/icons/minus_circle.svg?react';
import { addressTypeTranslation } from 'const/enumTranslations';
import { useEffect, useMemo } from 'react';
import { Controller, ControllerRenderProps, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { enumToLokalisedOptions } from 'util/mappers/enumToOption';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';

// This somewhat negates the advantages from having the enums in the DB.
// If we need to do this more often consider a more generic solution.
const ADDRESS_TYPE_ORDER: AddressTypeEnum[] = [
  'UNDEFINED',
  'PRIVATE',
  'BUSINESS',
  'SECOND_IN_HOME_COUNTRY',
  'SECOND_IN_FOREIGN_COUNTRY',
  'HOLIDAY',
  'SECRET',
  'WEEKEND',
];

export const AddressDetails = () => {
  const {
    control,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext<ContactDetailsFormData>();

  const { t } = useTranslation(['contact', 'enums']);

  const watchedContactQualified = watch('_internals.contact.contactQualified');

  const {
    fields: addressFields,
    append,
    remove,
  } = useFieldArray({
    name: 'addresses',
  });

  const watchedPreferredAddress = useWatch({
    control,
    name: '_internals.contact.preferredAddress',
  });

  useEffect(() => {
    addressFields.forEach((field, index) => {
      if (watchedPreferredAddress == index) {
        setValue(`addresses.${index}.preferred`, true);
      } else {
        setValue(`addresses.${index}.preferred`, false);
      }
    });
  }, [setValue, addressFields, watchedPreferredAddress]);

  const { allEnums } = useGetAllEnums();

  function removeAddress(
    index: number,
    fieldValue: ControllerRenderProps<ContactDetailsFormData, '_internals.contact.preferredAddress'>,
  ) {
    if (addressFields.length === 1) {
      return;
    }
    remove(index);
    if (fieldValue.value !== undefined && fieldValue.value >= index && fieldValue.value > 0) {
      fieldValue.onChange(fieldValue.value - 1);
    }
  }

  const addressTypes = useMemo(
    () =>
      [...(allEnums?.enumsAddressType ?? [])].sort(
        (a, b) =>
          ADDRESS_TYPE_ORDER.indexOf(a.name as AddressTypeEnum) - ADDRESS_TYPE_ORDER.indexOf(b.name as AddressTypeEnum),
      ),
    [allEnums?.enumsAddressType],
  );
  return (
    <Controller
      name="_internals.contact.preferredAddress"
      control={control}
      render={({ field: outerField }) => (
        <RadioGroup
          defaultValue
          {...outerField}
          onChange={(e) => {
            outerField.onChange(e.target.value);
          }}
        >
          {addressFields.map((field, index) => {
            return (
              <Stack key={field.id} gap={3} mb={3}>
                <FormStack
                  icon={
                    <IconButton
                      sx={{ m: -1 }}
                      disabled={!watchedContactQualified}
                      onClick={() => removeAddress(index, outerField)}
                    >
                      <MinusIcon />
                    </IconButton>
                  }
                >
                  <Controller
                    name={`addresses.${index}.address`}
                    control={control}
                    render={({ field }) => (
                      <AddressSearch
                        disabled={!watchedContactQualified}
                        label={t('contact:contactForm.fullAddress')}
                        sx={{ flex: 1 }}
                        suggestionOptions={{
                          types: ['geocode'],
                        }}
                        textFieldProps={{
                          'data-loggingid': 'addressSelect',
                        }}
                        onChange={(address) => field.onChange(address)}
                        defaultAddress={field.value}
                        hasError={!!errors.addresses?.[index]?.address}
                        errorMessage={errors.addresses?.[index]?.address?.message}
                      />
                    )}
                  />
                </FormStack>
                <FormStack>
                  <Controller
                    name={`addresses.${index}.addressType`}
                    control={control}
                    render={({ field }) => (
                      <DropDown
                        label={t('contact:address.type')}
                        options={addressTypes.map(enumToLokalisedOptions(t, addressTypeTranslation))}
                        disabled={!watchedContactQualified}
                        sx={{ maxWidth: '220px' }}
                        {...field}
                      />
                    )}
                  />
                  <FormControlLabel
                    value={index}
                    control={<Radio sx={{ paddingTop: 0, paddingBottom: '4px' }} />}
                    disabled={!watchedContactQualified}
                    label={t('preferred.abbreviation')}
                  />
                </FormStack>
              </Stack>
            );
          })}
          <AddButton
            onClick={() => {
              if (addressFields.length === 0) {
                setValue('_internals.contact.preferredAddress', 0);
              }
              append({
                addressType: '',
                address: undefined,
              });
            }}
            disabled={!watchedContactQualified || addressFields.length === 3}
            text={t('contact:addAddress')}
          />
        </RadioGroup>
      )}
    />
  );
};
