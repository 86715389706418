import {
  ActivityLeadFragment,
  Contact,
  ContactListEntry,
  Lead,
  LeadInfo,
  Listing,
  PhoneNumberTypeEnum,
} from 'api/graphql/generated/graphql';
import { Person } from 'api/rest/models/Common';
import { assetTypeTranslation, intentTypeTranslation, phoneNumberTypeTranslation } from 'const/enumTranslations';
import { Maybe } from 'graphql/jsutils/Maybe';
import { EMPTY_DATA_STRING } from 'util/emptyDataUtils';
import { TFunction, useTranslation } from 'util/i18next';
import { mapPropertyEngineAssetType } from 'util/mappers/propertyEngineMapper';
import { useFormatNumber } from 'util/useFormatNumber';

export const stringFormatToUpperCaseStart = (str: string | null | undefined): string => {
  const specialChar = /_/;
  let formattedString = '';

  if (!str) {
    return '';
  }

  if (specialChar.test(str)) {
    formattedString = str.replaceAll('_', ' ');
    return `${formattedString[0]?.toUpperCase()}${formattedString.slice(1).toLowerCase()}`;
  }

  return `${str[0]?.toUpperCase()}${str.slice(1).toLowerCase()}`;
};

export const useLeadChipString = (lead: LeadInfo | Lead | ActivityLeadFragment) => {
  const { t } = useTranslation(['enums']);

  const { sellerRentOutSpecification, buyerToRentSpecification, go3PropertyId, intentType } = lead || {};
  const propertyType = sellerRentOutSpecification?.type || buyerToRentSpecification?.type;
  const livingAreaMax = buyerToRentSpecification?.livingAreaMax || sellerRentOutSpecification?.livingArea;
  const livingAreaMin = buyerToRentSpecification?.livingAreaMin;
  const totalSurfaceMax = buyerToRentSpecification?.totalSurfaceMax || sellerRentOutSpecification?.plotArea;
  const totalSurfaceMin = buyerToRentSpecification?.totalSurfaceMin;
  const plotSurfaceMax = buyerToRentSpecification?.plotSurfaceMax;
  const plotSurfaceMin = buyerToRentSpecification?.plotSurfaceMin;

  const { formatAreaRange } = useFormatNumber();

  if (go3PropertyId) {
    return go3PropertyId;
  }

  return propertyType
    ? `${t(assetTypeTranslation[propertyType])} ` +
        ((livingAreaMax && livingAreaMin) || (totalSurfaceMax && totalSurfaceMin) || (plotSurfaceMax && plotSurfaceMin)
          ? formatAreaRange(livingAreaMin, livingAreaMax, { emptyDataString: '' }) ||
            formatAreaRange(totalSurfaceMin, totalSurfaceMax, { emptyDataString: '' }) ||
            formatAreaRange(plotSurfaceMin, plotSurfaceMax)
          : livingAreaMax ||
            (totalSurfaceMax && !livingAreaMin) ||
            (plotSurfaceMax && !totalSurfaceMin && !livingAreaMin)
          ? formatAreaRange(null, livingAreaMax || totalSurfaceMax || plotSurfaceMax)
          : livingAreaMin || totalSurfaceMin || plotSurfaceMin
          ? formatAreaRange(livingAreaMin || totalSurfaceMin || plotSurfaceMin, null)
          : '')
    : t(intentTypeTranslation[!intentType ? 'UNDEFINED' : intentType]);
};

export const leadString = (lead: LeadInfo | Lead | ActivityLeadFragment, t: TFunction<['enums']>) => {
  const { intentType, sellerRentOutSpecification, buyerToRentSpecification, go3PropertyId } = lead || {};
  const city = sellerRentOutSpecification?.address?.city || buyerToRentSpecification?.city;
  const postalCode = sellerRentOutSpecification?.address?.postalCode;

  return intentType || city || postalCode || go3PropertyId
    ? [t(intentTypeTranslation[!intentType ? 'UNDEFINED' : intentType]), city, postalCode, go3PropertyId]
        .filter(Boolean)
        .join(' ')
        .trim()
    : t('newLead.string');
};

export const listingString = (listing: Listing, t: TFunction<['enums']>) => {
  const { asset, address, utag } = listing;

  return asset?.type || address?.city || address?.postalCode || utag
    ? [
        asset?.type && t(assetTypeTranslation[mapPropertyEngineAssetType(asset?.type)]),
        asset?.type && t('util.string.in'),
        address?.city,
        address?.postalCode,
      ]
        .filter(Boolean)
        .join(' ')
        .trim()
    : utag ?? t('enums:listing.untitled');
};

export function formatPhoneLabelCallerScreen(
  t: TFunction<['communication', 'enums']>,
  preferred?: Maybe<boolean>,
  type?: Maybe<PhoneNumberTypeEnum>,
): string {
  if (!type) {
    return '';
  }

  const result = t(phoneNumberTypeTranslation[type]);

  if (!preferred) {
    return result;
  }

  return result + (preferred ? ` (${t('preferred.abbreviation')})` : '');
}

export function removeAllWhiteSpaces(value: string) {
  return value.replace(/\s+/g, '');
}

export function removeExtraPrefixes(value: string) {
  return removeAllWhiteSpaces(value).replaceAll('+', '').replace(/^0+/, '');
}

export function fullName({ firstName, lastName }: Person = {}, fallback = EMPTY_DATA_STRING) {
  return [firstName, lastName].filter(Boolean).join(' ') || fallback;
}

export function fullNameFromContact(
  contact?: Pick<Contact | ContactListEntry, 'firstName' | 'lastName'>,
  fallback = EMPTY_DATA_STRING,
) {
  return [contact?.firstName, contact?.lastName].filter(Boolean).join(' ') || fallback;
}

export function appendEmail(entity: string, email?: string | null) {
  return email ? entity + ' (' + email + ')' : entity;
}

export const joinWithComma = <T>(fields?: T[]): string => {
  if (!fields) {
    return '';
  }
  return fields.filter(Boolean).join(', ');
};

export const titleWithValue = (title: string, value: Maybe<string>, options?: { isTitleAtEnd?: boolean }) => {
  if (!value) return null;
  if (options?.isTitleAtEnd) return `${value} ${title}`;
  return `${title}: ${value}`;
};

export function hasMatchingWords(text: string, search: string) {
  const searchTerms = search.toLocaleLowerCase().split(/\s/);
  const words = text.toLocaleLowerCase().split(/\s/);
  return searchTerms.every((searchTerm) => words.some((word) => word.startsWith(searchTerm)));
}
export function formatDuration(seconds: number) {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;

  const minsStr = mins > 0 ? `${mins} mins` : '';
  const secsStr = secs > 0 ? ` ${secs} sec` : '';

  return minsStr + secsStr;
}

export const pluralize = (count: number, singular: string, plural: string) => {
  return count === 1 ? singular : plural;
};

// Note: These are not covered by `normalize('NFD')`:
// noinspection NonAsciiCharacters
const normalizationTable: Record<string, string> = {
  æ: 'ae',
  ø: 'o',
  œ: 'oe',
  ß: 'ss',
};

export function removeDiacriticsAndSymbols(text: string): string {
  text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  Object.entries(normalizationTable).forEach(([key, value]) => {
    text = text.replace(new RegExp(key, 'g'), value);
  });

  return text.replace(/[^a-zA-Z0-9α-ωΑ-Ω\s]/g, '').toLocaleLowerCase();
}
