export function captureCurrentUrl() {
  // Note: It is safe to call btoa here because the string is URL encoded
  return btoa(location.pathname + location.search);
}

export function restoreUrl({ param, fallback }: { param: string | string[] | undefined | null; fallback: string }) {
  if (!param) {
    return fallback;
  }
  const restoredUrl = atob(param as string);
  return restoredUrl;
}
