import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ErrorDisplay } from 'components/error/ErrorDisplay';
import Logo from 'components/icons/ev_logo_compact.svg?react';
import { ReactNode } from 'react';
import { theme } from 'theme';

export const ErrorPage = ({
  title,
  message,
  buttonText,
  onClick,
  extraTopBarItems,
}: {
  title: string;
  message: string;
  buttonText?: string;
  onClick?: () => void;
  extraTopBarItems?: ReactNode;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.special.noon,
        minHeight: '100vh',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ paddingX: 2, paddingY: 1, background: theme.palette.background.default }}
      >
        {/* We can't render a Link here because the Error Page can be rendered outside the router context */}
        <a style={{ display: 'block' }} href="/">
          <Logo />
        </a>
        {extraTopBarItems}
      </Stack>
      <ErrorDisplay title={title} message={message} buttonText={buttonText} onClick={onClick} />
    </Box>
  );
};
