import { usePlaceDetails } from 'api/searchBff/hooks/usePlaceDetails';
import { usePlaceSuggestions } from 'api/searchBff/hooks/usePlaceSuggestions';
import { useCallback } from 'react';
import { AddressLike, addressToSingleLineString } from 'util/summaries/addressSummary';

export const useGetCoordinatesForAddress = () => {
  const placeDetails = usePlaceDetails();
  const placeSuggestions = usePlaceSuggestions();

  return useCallback(
    async (address: AddressLike) => {
      if (address?.placeId) {
        return (await placeDetails({ placeId: address.placeId }))?.location || undefined;
      }
      const suggestions = await placeSuggestions({ input: addressToSingleLineString()(address), limit: 1 });
      const placeId = suggestions?.[0]?.placeId;
      if (!placeId) {
        return undefined;
      }

      return (await placeDetails({ placeId }))?.location || undefined;
    },
    [placeDetails, placeSuggestions],
  );
};
