import { DbEmailSignatureBoolExp } from 'api/graphql/generated/graphql';

export function createSignatureQuery(agentId: string, teamsIds: string[], shopId: string) {
  return {
    _or: [
      {
        _and: [{ scope: { _eq: 'PERSONAL' } }, { agentId: { _eq: agentId } }, { shopId: { _isNull: true } }],
      }, // to be removed once all personal signatures and templates contain shopId
      {
        _and: [{ scope: { _eq: 'PERSONAL' } }, { agentId: { _eq: agentId } }, { shopId: { _eq: shopId } }],
      },
      {
        _and: [{ scope: { _eq: 'TEAM' } }, { teamId: { _in: teamsIds } }, { shopId: { _eq: shopId } }],
      },
      {
        _and: [{ scope: { _eq: 'SHOP' } }, { shopId: { _eq: shopId } }],
      },
    ],
  } as DbEmailSignatureBoolExp;
}
