import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { CustomDemandFieldsQuery } from 'api/graphql/generated/graphql';
import { graphqlClient } from 'api/graphql/graphql-client';
import { IterableElement } from 'type-fest';

export type CustomDemandField = IterableElement<CustomDemandFieldsQuery['dbCdf']>;

const customDemandFieldQuery = graphql(/* GraphQL */ `
  query CustomDemandFields($where: DbCdfBoolExp) {
    dbCdf(where: $where, orderBy: { createdAt: DESC }) {
      id
      label
      shopId
    }
  }
`);

export const useCustomDemandFields = (
  variables: VariablesOf<typeof customDemandFieldQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: customDemandFields, ...rest } = useQuery({
    queryKey: ['cdf', 'useCustomDemandFields', variables],
    queryFn: async () => (await graphqlClient.request(customDemandFieldQuery, variables)).dbCdf,
    ...options,
  });

  function mapCustomDemandLabels(cdfs: string[] | undefined): string[] {
    return cdfs?.map((cdf) => customDemandFields?.find(({ id }) => id === cdf)?.label ?? '') ?? [];
  }

  return { customDemandFields, mapCustomDemandLabels, ...rest };
};
