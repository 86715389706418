import { Team } from 'api/graphql/generated/graphql';
import { DropDown } from 'components/general/DropDown/DropDown';
import { FormSection, FormSectionHeader } from 'components/general/Form/FormSection';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { NewLeadTeamAssignmentFormData } from 'util/schemas/newLeadAssignmentSchema';

export function NewLeadAssignmentForm({ teams }: { teams: Team[] }) {
  const { t } = useTranslation(['lead']);
  const {
    control,
    formState: { errors },
  } = useFormContext<NewLeadTeamAssignmentFormData>();

  return (
    <FormSection>
      <FormSectionHeader>{t('lead:addNewLead.assignment.title')}</FormSectionHeader>
      <Controller
        name="teamId"
        control={control}
        render={({ field }) => (
          <DropDown
            {...field}
            required
            label={t('lead:addNewLead.assignment.teamLabel')}
            errorMessage={errors.teamId?.message}
            hasError={!!errors.teamId}
            options={teams.map((team) => ({
              value: team.id,
              label: team.name,
            }))}
          />
        )}
      />
    </FormSection>
  );
}
