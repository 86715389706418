import { ErrorBoundaryInner } from 'components/error/ErrorBoundary/ErrorBoundaryInner';
import { ErrorPage } from 'eva-frame/ErrorPage';
import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'util/i18next';

export function RootErrorBoundary({ children }: PropsWithChildren) {
  const { t } = useTranslation(['user']);
  const [hasError, setHasError] = useState(false);

  return (
    <ErrorBoundaryInner
      hasError={hasError}
      setHasError={setHasError}
      fallback={
        <ErrorPage
          title={t('user:error.unknownError.title')}
          message={t('user:error.unknownError.message')}
          buttonText={t('user:error.unknownError.button')}
          onClick={() => location.reload()}
        />
      }
    >
      {children}
    </ErrorBoundaryInner>
  );
}
