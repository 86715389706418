import { ActionsModal } from 'components/general/ActionsModal/ActionsModal';
import { useTranslation } from 'util/i18next';

export function EmailUnresolvedPlaceholdersModal({
  onConfirm,
  onCancel,
  sendingDisabled,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  sendingDisabled: boolean;
}) {
  const { t } = useTranslation(['communication']);

  return (
    <ActionsModal
      open={true}
      handleClose={onCancel}
      title={t('communication:emailForm.unresolved.error.title')}
      description={t('communication:emailForm.unresolved.error.description')}
      actions={[
        {
          message: t('communication:newEmail.drawer.send'),
          variant: 'outlined',
          disabled: sendingDisabled,
          color: 'secondary',
          handleClick: onConfirm,
        },
        {
          message: t('communication:emailForm.bulk.unresolvedModal.cancel'),
          variant: 'contained',
          color: 'primary',
          handleClick: onCancel,
        },
      ]}
    />
  );
}
