import { useLocale } from '@ev/eva-container-api';
import { de, el, enGB, es, fr, it, pt } from 'date-fns/locale';

const locales = [
  {
    baseLocale: 'en',
    dateFnsLocale: enGB,
  },
  {
    baseLocale: 'de',
    dateFnsLocale: de,
  },
  {
    baseLocale: 'el',
    dateFnsLocale: el,
  },
  {
    baseLocale: 'es',
    dateFnsLocale: es,
  },
  {
    baseLocale: 'fr',
    dateFnsLocale: fr,
  },
  {
    baseLocale: 'it',
    dateFnsLocale: it,
  },
  {
    baseLocale: 'pt',
    dateFnsLocale: pt,
  },
] as const;

export function useDateFnsLocale() {
  const locale = useLocale();

  return {
    locale: locales.find((loc) => loc.baseLocale === locale)?.dateFnsLocale || enGB,
  };
}
