import { useMemo } from 'react';
import { ListingWithLegacyFields } from 'util/go3';
import { useTranslation } from 'util/i18next';
import { useLeadAndContactSearchOptionSchema } from 'util/schemas/contactAndLeadSchema';
import { useSchemaPrimitives } from 'util/schemas/schemaPrimitives';
import { z } from 'zod';

export function useShareExposeSchema() {
  const { optionalString, requiredString } = useSchemaPrimitives();
  const { t } = useTranslation(['user']);
  const contactAndLead = useLeadAndContactSearchOptionSchema();

  return useMemo(() => {
    return z.object({
      contactsAndLeads: contactAndLead.array().min(1, t('user:formValidation.activity.atLeastOneContact')),
      properties: z
        .custom<ListingWithLegacyFields>()
        .array()
        .min(1, t('user:formValidation.activity.atLeastOneProperty'))
        .max(1),
      title: requiredString,
      comment: optionalString,
    });
  }, [optionalString, requiredString, contactAndLead, t]);
}

export type ShareExposeFormData = z.infer<ReturnType<typeof useShareExposeSchema>>;
