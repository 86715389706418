import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

export function NoActiveCallsPlaceholder({ sx }: { sx?: SxProps }) {
  const { t } = useTranslation(['communication']);

  return (
    <Stack alignItems="center" sx={sx} gap={1}>
      <svg width="108" height="101" viewBox="0 0 108 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="49.9998" cy="50.9685" r="43.9998" fill={theme.palette.shade.grey4} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94 19.9688C95.1046 19.9688 96 20.8642 96 21.9688C96 23.0733 95.1046 23.9688 94 23.9688C92.8954 23.9688 92 23.0733 92 21.9688C92 20.8642 92.8954 19.9688 94 19.9688ZM95 21.9688C95 21.4165 94.5523 20.9688 94 20.9688C93.4477 20.9688 93 21.4165 93 21.9688C93 22.521 93.4477 22.9688 94 22.9688C94.5523 22.9688 95 22.521 95 21.9688Z"
          fill={theme.palette.shade.grey1}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76 21.9688C76 20.8642 75.1046 19.9688 74 19.9688C72.8954 19.9688 72 20.8642 72 21.9688C72 23.0733 72.8954 23.9688 74 23.9688C75.1046 23.9688 76 23.0733 76 21.9688ZM74 20.9688C74.5523 20.9688 75 21.4165 75 21.9688C75 22.521 74.5523 22.9688 74 22.9688C73.4477 22.9688 73 22.521 73 21.9688C73 21.4165 73.4477 20.9688 74 20.9688Z"
          fill={theme.palette.shade.grey1}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84 19.9688C85.1046 19.9688 86 20.8642 86 21.9688C86 23.0733 85.1046 23.9688 84 23.9688C82.8954 23.9688 82 23.0733 82 21.9688C82 20.8642 82.8954 19.9688 84 19.9688ZM85 21.9688C85 21.4165 84.5523 20.9688 84 20.9688C83.4477 20.9688 83 21.4165 83 21.9688C83 22.521 83.4477 22.9688 84 22.9688C84.5523 22.9688 85 22.521 85 21.9688Z"
          fill={theme.palette.shade.grey1}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64 4.96875H104V44.9688L95.8135 37.9688H64V4.96875ZM103 42.8738V5.96875H65V36.9688H96.1865L103 42.8738Z"
          fill={theme.palette.shade.grey1}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 71.9688C12.8954 71.9688 12 72.8642 12 73.9688C12 75.0733 12.8954 75.9688 14 75.9688C15.1046 75.9688 16 75.0733 16 73.9688C16 72.8642 15.1046 71.9688 14 71.9688ZM13 73.9688C13 73.4165 13.4477 72.9688 14 72.9688C14.5523 72.9688 15 73.4165 15 73.9688C15 74.521 14.5523 74.9688 14 74.9688C13.4477 74.9688 13 74.521 13 73.9688Z"
          fill={theme.palette.shade.grey1}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32 73.9688C32 72.8642 32.8954 71.9688 34 71.9688C35.1046 71.9688 36 72.8642 36 73.9688C36 75.0733 35.1046 75.9688 34 75.9688C32.8954 75.9688 32 75.0733 32 73.9688ZM34 72.9688C33.4477 72.9688 33 73.4165 33 73.9688C33 74.521 33.4477 74.9688 34 74.9688C34.5523 74.9688 35 74.521 35 73.9688C35 73.4165 34.5523 72.9688 34 72.9688Z"
          fill={theme.palette.shade.grey1}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 71.9688C22.8954 71.9688 22 72.8642 22 73.9688C22 75.0733 22.8954 75.9688 24 75.9688C25.1046 75.9688 26 75.0733 26 73.9688C26 72.8642 25.1046 71.9688 24 71.9688ZM23 73.9688C23 73.4165 23.4477 72.9688 24 72.9688C24.5523 72.9688 25 73.4165 25 73.9688C25 74.521 24.5523 74.9688 24 74.9688C23.4477 74.9688 23 74.521 23 73.9688Z"
          fill={theme.palette.shade.grey1}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44 56.9688H4V96.9688L12.1865 89.9688H44V56.9688ZM5 94.8738V57.9688H43V88.9688H11.8135L5 94.8738Z"
          fill={theme.palette.shade.grey1}
        />
      </svg>
      <Typography variant="h2" sx={{ color: theme.palette.text.primary }}>
        {t('communication:call.noActiveCalls')}
      </Typography>
      <Typography variant="body3" sx={{ color: theme.palette.text.secondary }}>
        {t('communication:call.noActiveCallsExplanation')}
      </Typography>
    </Stack>
  );
}
