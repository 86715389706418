import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useGlobalEditorState } from 'components/forms/RichTextEditor/blots/GlobalEditorState';
import { NoPropertyPlaceholder } from 'components/forms/RichTextEditor/blots/expose/NoPropertyPlaceholder';
import AlreadyProposedIcon from 'components/icons/already_proposed.svg?react';
import MinusCircleIcon from 'components/icons/minus_circle.svg?react';
import { PropertyItem } from 'components/property/PropertySearch/PropertyItem';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

export function ExposeListWidget() {
  const { t } = useTranslation(['communication']);
  const editorState = useGlobalEditorState();
  const { properties, alreadyProposedProperties, enableRemove, setProperties, language, hidePrice } =
    editorState.props.expose?.['global'] || {};

  return (
    <Stack gap={1} sx={{ maxWidth: 336, marginY: 2 }}>
      {!properties?.length && <NoPropertyPlaceholder />}
      {properties?.map((property) => (
        <Tooltip
          key={property.utag}
          title={t('communication:sendBulkEmailDialog.property.tooltip')}
          arrow
          placement="top"
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              border: `1px solid ${theme.palette.shade.grey4}`,
              backgroundColor: theme.palette.backgroundExtension.white,
            }}
          >
            <PropertyItem
              property={property}
              hideStatus
              sx={{ minWidth: 0, flex: 1 }}
              size={64}
              hidePrice={hidePrice ? 'ALWAYS' : 'PE_SETTINGS'}
              showMarketingTitleFor={language}
            />
            {enableRemove && alreadyProposedProperties?.includes(property.utag) && (
              <>
                <AlreadyProposedIcon style={{ color: theme.palette.information.main }} />
                <IconButton
                  aria-label={t('delete')}
                  onClick={() => setProperties?.(properties.filter(({ utag: u }) => u !== property.utag))}
                >
                  <MinusCircleIcon />
                </IconButton>
              </>
            )}
          </Stack>
        </Tooltip>
      ))}
    </Stack>
  );
}
