import { signOut } from '@ev/eva-container-api';
import { ListItemIcon, ListItemText } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Logout from 'components/icons/logout.svg?react';
import { useTwilio } from 'components/state/Twilio';
import { useTranslation } from 'util/i18next';
import { useErrorSnackBar } from 'util/useErrorSnackBar';

export function LogoutMenuItem() {
  const { destroyTwilioSession, twilioSession } = useTwilio();
  const { t } = useTranslation(['user']);
  const { openErrorSnackBar } = useErrorSnackBar();

  const onLogout = () => {
    if (!twilioSession) {
      signOut();
      return;
    }
    destroyTwilioSession({
      onSuccess: () => {
        signOut();
      },
      onError: (error) => openErrorSnackBar(t('user:profileMenu.logout.error'), error),
    });
  };

  return (
    <MenuItem onClick={onLogout}>
      <ListItemIcon>
        <Logout />
      </ListItemIcon>
      <ListItemText>{t('user:profile.logOut')}</ListItemText>
    </MenuItem>
  );
}
