import { useContactInfos } from 'api/graphql/hooks/useContact';
import { PersonDetails } from 'api/rest/models/Common';
import { UserChip } from 'components/general/Chips/UserChip';
import { OptionsButton } from 'components/general/OptionsButton/OptionsButton';
import { PersonMenuItem } from 'components/general/person/PersonItem';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useCallback, useMemo, useState } from 'react';
import { getPreferredEmail, getPreferredPhoneNumber } from 'util/contactUtils';
import { contactSearchNameFilter } from 'util/hasura/filters';
import { useTranslation } from 'util/i18next';
import { useSearchText } from 'util/useDebounce';

export function DisplayPersonDropdown({
  onChange,
  selectedPerson,
}: {
  onChange: (person?: PersonDetails) => void;
  selectedPerson?: PersonDetails;
}) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const { searchText, rawSearchText, setSearchText } = useSearchText();

  const { shopIdsInActiveGroup } = useActiveShop();

  const { contacts = [], isInitialLoading } = useContactInfos(
    {
      where: {
        shopId: { _in: shopIdsInActiveGroup },
        _or: [
          {
            deleted: { _eq: false },
          },
          { deleted: { _isNull: true } },
        ],
        ...contactSearchNameFilter(searchText),
      },
      limit: 5,
      offset: 0,
    },
    { enabled: open },
  );

  const onSearchStringChange = useCallback(
    (value: string) => {
      setSearchText(value);
    },
    [setSearchText],
  );

  const persons = useMemo(() => {
    return Object.fromEntries(
      contacts.map((contact) => [
        contact.id,
        {
          firstName: contact.firstName,
          id: contact.id,
          lastName: contact.lastName,
          email: getPreferredEmail(contact) || '',
          phoneNumber: getPreferredPhoneNumber(contact) || '',
        },
      ]) ?? [],
    );
  }, [contacts]);

  const handleChange = (id: string) => {
    onChange(persons[id]);
  };

  return (
    <OptionsButton
      isOpen={open}
      loading={isInitialLoading}
      changeIsOpen={setOpen}
      button={selectedPerson?.id ? <UserChip person={selectedPerson} /> : t('selectPerson.unassigned')}
      selectedOption={selectedPerson?.id || undefined}
      onChange={handleChange}
      options={Object.values(persons).map((contact) => ({
        value: contact.id,
        label: <PersonMenuItem person={contact} />,
      }))}
      search={{
        onSearchStringChange,
        searchString: rawSearchText,
        placeholder: t('selectPerson.search'),
      }}
    />
  );
}
