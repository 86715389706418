import { FRAME_Z_INDEX, useBreadcrumb } from '@ev/eva-container-api';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CrossIcon from 'components/icons/cross_naked.svg?react';
import MenuIcon from 'components/icons/menu.svg?react';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useTwilio } from 'components/state/Twilio';
import { MOBILE_TOP_BAR_HEIGHT } from 'components/state/UIStateProvider';
import { ActionMenuButton } from 'eva-frame/EvaLayout/ActionMenuButton';
import { ActiveShopSelector } from 'eva-frame/EvaLayout/ActiveShopSelector';
import { Breadcrumbs } from 'eva-frame/EvaLayout/Breadcrumbs';
import { CallMenuButton } from 'eva-frame/EvaLayout/CallMenuButton';
import { EVLogo, EVLogoCompact } from 'eva-frame/EvaLayout/EvLogo';
import { LanguageSelector } from 'eva-frame/EvaLayout/LanguageSelector';
import { ProfileMenuButton } from 'eva-frame/EvaLayout/ProfileMenuButton';
import { SupportMenuButton } from 'eva-frame/EvaLayout/SupportMenuButton';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { theme } from 'theme';
import { useDestination } from 'util/navigation/useDestination';
import { usePermissions } from 'util/usePermissions';

export const TopBarContainer = styled('header')({
  height: '100%',
  padding: theme.spacing(0, 3),
  backgroundColor: theme.palette.backgroundExtension.white,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  gridColumnStart: 'left',
  gridColumnEnd: 'right',
  gridRowStart: 'top',
  gridRowEnd: 'content',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'center',
  position: 'sticky',
  top: 0,
  zIndex: FRAME_Z_INDEX,
  [theme.breakpoints.down('desktop')]: {
    gridTemplateColumns: '1fr auto 1fr',
    boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.24)',
    height: MOBILE_TOP_BAR_HEIGHT,
    left: 0,
    right: 0,
    padding:
      'env(safe-area-inset-top) calc(env(safe-area-inset-right) + 16px) 0 calc(env(safe-area-inset-left) + 16px)',
  },
});

export function TopBar({
  isMobileMainNavigationOpen,
  setIsMobileMainNavigationOpen,
  isProfileNavigationOpen,
  setIsProfileNavigationOpen,
}: {
  isMobileMainNavigationOpen: boolean;
  setIsMobileMainNavigationOpen: Dispatch<SetStateAction<boolean>>;
  isProfileNavigationOpen: boolean;
  setIsProfileNavigationOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { breadcrumb } = useBreadcrumb();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const { canCallInAnyShop } = useTwilio();
  const { toHomePage } = useDestination();
  const { activeShop } = useActiveShop();
  const { canSeeLeadHubMainPages } = usePermissions();

  const showActionMenu = !isDesktop && activeShop?.onboardingLevel === 2 && canSeeLeadHubMainPages(activeShop);

  useEffect(() => {
    if (isDesktop) {
      setIsMobileMainNavigationOpen(false);
    }
  }, [setIsMobileMainNavigationOpen, isDesktop]);

  const isMobileProfileNavigationOpen = !isDesktop && isProfileNavigationOpen;
  const isAnyMobileNavigationOpen = !isDesktop && (isProfileNavigationOpen || isMobileMainNavigationOpen);

  return (
    <TopBarContainer>
      <Stack direction="row">
        {isDesktop && <EVLogo href={toHomePage()} />}
        {!isDesktop && (
          <IconButton
            onClick={() => {
              setIsProfileNavigationOpen(false);
              setIsMobileMainNavigationOpen((open) => !open);
            }}
          >
            {isMobileMainNavigationOpen ? (
              <CrossIcon style={{ color: theme.palette.shade.grey2 }} />
            ) : (
              <MenuIcon style={{ color: theme.palette.shade.grey2 }} />
            )}
          </IconButton>
        )}
        {showActionMenu && <ActionMenuButton variant="slim" />}
        {!isAnyMobileNavigationOpen && breadcrumb && <Breadcrumbs {...breadcrumb} />}
      </Stack>
      {!isDesktop && <EVLogoCompact href={toHomePage()} />}
      <Stack direction="row" justifySelf="end" alignItems="center" data-testid="header">
        {isDesktop && <ActiveShopSelector sx={{ marginRight: 1 }} />}
        {isDesktop && <LanguageSelector />}
        {isDesktop && <SupportMenuButton />}
        {canCallInAnyShop && <CallMenuButton />}
        {isMobileProfileNavigationOpen ? (
          <IconButton onClick={() => setIsProfileNavigationOpen(false)}>
            <CrossIcon />
          </IconButton>
        ) : (
          <ProfileMenuButton
            isProfileNavigationOpen={isProfileNavigationOpen}
            setIsProfileNavigationOpen={(isOpen) => {
              setIsMobileMainNavigationOpen(false);
              setIsProfileNavigationOpen(isOpen);
            }}
          />
        )}
      </Stack>
    </TopBarContainer>
  );
}

export function PublicTopBar({
  isMobileNavigationOpen,
  setIsMobileMainNavigationOpen,
}: {
  isMobileNavigationOpen: boolean;
  setIsMobileMainNavigationOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const { toPublicNetwork } = useDestination();

  useEffect(() => {
    if (isDesktop) {
      setIsMobileMainNavigationOpen(false);
    }
  }, [setIsMobileMainNavigationOpen, isDesktop]);

  return (
    <TopBarContainer>
      <Stack direction="row">
        {!isDesktop && (
          <IconButton edge="start" onClick={() => setIsMobileMainNavigationOpen((open) => !open)}>
            {isMobileNavigationOpen ? <CrossIcon /> : <MenuIcon />}
          </IconButton>
        )}
      </Stack>
      {!isDesktop && <EVLogo href={toPublicNetwork()} />}
      <Stack direction="row" justifySelf="end" alignItems="center">
        {isDesktop && <LanguageSelector />}
        {isDesktop && <SupportMenuButton />}
      </Stack>
    </TopBarContainer>
  );
}
