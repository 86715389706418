import { useSnackBars } from '@ev/eva-container-api';
import { zodResolver } from '@hookform/resolvers/zod';
import { UseMutationOptions } from '@tanstack/react-query';
import { Contact } from 'api/graphql/generated/graphql';
import { useCurrentAgentId } from 'api/graphql/hooks/useCurrentAgent';
import { useAddNewBuyerRentalLead, useAddNewSellerRenterLead } from 'api/rest';
import { LeadResponse } from 'api/rest/models/LeadResponse';
import { AddBuyerRentalLeadRequest, AddSellerRenterLeadRequest } from 'api/rest/models/rest/Command';
import { EVDrawerContent } from 'components/general/EVDrawer/EVDrawerContent';
import { useUpdatePropertyAddress } from 'components/leads/forms/AddNewLead/updatePropertyAddress';
import { LeadForm } from 'components/leads/forms/LeadForm';
import { NewLeadAssignmentForm } from 'components/leads/forms/NewLeadAssignmentForm';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useConfirmation } from 'components/state/ConfirmationContext';
import { useNavigateToLead } from 'components/state/SelectedLead';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { newPropertyDefaultValues } from 'util/defaultValues/newPropertyDefaultValues';
import { useTranslation } from 'util/i18next';
import { isIntentTypeWithProperty } from 'util/intentType';
import { addNewBuyerRentalLeadMapper, addNewSellerRenterLeadMapper } from 'util/mappers/addLeadMapper';
import { useNewLeadTeamAssignmentSchema } from 'util/schemas/newLeadAssignmentSchema';
import { PropertyDetailsFormData, usePropertyDetailsSchema } from 'util/schemas/propertyDetailsSchema';
import { useErrorSnackBar } from 'util/useErrorSnackBar';
import { usePermissions } from 'util/usePermissions';

export interface AddNewLeadProps {
  onClose: () => void;
  contact: Contact;
  redirectToLeadOnSuccess?: boolean;
  onSuccess?: (leadId: string) => void;
}

export type AddLeadFormData = PropertyDetailsFormData & { teamId?: string };

export const AddNewLead = ({ onClose, contact, redirectToLeadOnSuccess = true, onSuccess }: AddNewLeadProps) => {
  const { t } = useTranslation(['lead', 'contact']);
  const currentAgentId = useCurrentAgentId();
  const { openErrorSnackBar } = useErrorSnackBar();
  const { activeShop: shop, teamsWhereUserIsMember, shopCurrency } = useActiveShop();
  const navigateToLead = useNavigateToLead();
  const updatePropertyAddress = useUpdatePropertyAddress();

  const { addNewBuyerRentalLead, isLoading: isLoadingAddNewBuyerRentalLead } = useAddNewBuyerRentalLead();
  const { addNewSellerRenterLead, isLoading: isLoadingAddNewSellerRenterLead } = useAddNewSellerRenterLead();

  const { canCreateLeadWithoutTeamAssignment, canCreateLeadWithoutAgentAssignment } = usePermissions();
  const createWithoutAssignment = !!shop && canCreateLeadWithoutTeamAssignment(shop);

  const isLoading = isLoadingAddNewBuyerRentalLead || isLoadingAddNewSellerRenterLead;

  const propertyDetailsSchema = usePropertyDetailsSchema();
  const newLeadTeamAssignmentSchema = useNewLeadTeamAssignmentSchema();
  const formSchema =
    createWithoutAssignment || !teamsWhereUserIsMember.length
      ? propertyDetailsSchema
      : propertyDetailsSchema.and(newLeadTeamAssignmentSchema);

  const defaultValues = newPropertyDefaultValues(shopCurrency);
  defaultValues.property._internals.contactAddress = contact.addresses ? contact.addresses[0]?.address : undefined;

  const methods = useForm({
    mode: 'onTouched',
    resolver: zodResolver(formSchema),
    defaultValues,
  });
  const { formState, setValue, handleSubmit } = methods;

  useConfirmation({
    title: t('contact:newLead.add'),
    active: formState.isDirty,
  });

  useEffect(() => {
    if (!createWithoutAssignment && teamsWhereUserIsMember?.length) {
      const teamId = teamsWhereUserIsMember[0]!.id;
      setValue('teamId', teamId);
    } else {
      setValue('teamId', '');
    }
  }, [setValue, createWithoutAssignment, teamsWhereUserIsMember]);

  const { openSnackBar } = useSnackBars();

  async function handleAddNewLead(submittedData: AddLeadFormData) {
    if (!shop) {
      return;
    }

    const formData = await updatePropertyAddress(submittedData);

    const data = {
      ...formData,
      shopId: shop.id,
      ...(canCreateLeadWithoutAgentAssignment(shop, submittedData.teamId) ? {} : { agentId: currentAgentId }),
    };

    const mutationOptions: UseMutationOptions<
      LeadResponse,
      unknown,
      AddSellerRenterLeadRequest | AddBuyerRentalLeadRequest
    > = {
      onSuccess: (leadResponse) => {
        if (redirectToLeadOnSuccess) {
          openSnackBar(t('lead:message.addNewLead.success'), 'success');
          navigateToLead(leadResponse.id, leadResponse.contact.id, submittedData?.property?.isAddProperty);
        }

        if (onSuccess) {
          onSuccess(leadResponse.id);
        }
        onClose();
      },
      onError: (error) => openErrorSnackBar(t('lead:message.addNewLead.error'), error),
    };

    if (isIntentTypeWithProperty(submittedData.intentType)) {
      addNewSellerRenterLead(addNewSellerRenterLeadMapper(data, contact.id), mutationOptions);
    } else {
      addNewBuyerRentalLead(addNewBuyerRentalLeadMapper(data, contact.id), mutationOptions);
    }
  }

  return (
    <EVDrawerContent
      title={t('contact:newLead.add')}
      primaryAction={{
        primaryButtonLabel: t('lead:newLead.create'),
        callback: handleSubmit(handleAddNewLead),
      }}
      isLoading={isLoading}
      onClose={onClose}
      sx={{ padding: 3 }}
    >
      <FormProvider {...methods}>
        <form>
          {teamsWhereUserIsMember.length > 1 && !createWithoutAssignment && (
            <NewLeadAssignmentForm teams={teamsWhereUserIsMember} />
          )}
          <LeadForm />
        </form>
      </FormProvider>
    </EVDrawerContent>
  );
};
