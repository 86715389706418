import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

const leadListIdsQuery = graphql(/* GraphQL */ `
  query LeadListIdsQuery($limit: Int, $offset: Int, $order_by: [DbLeadOrderBy!], $where: DbLeadBoolExp) {
    dbLead(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {
      id
    }
  }
`);

export const useLeadListIds = (
  variables: VariablesOf<typeof leadListIdsQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'staleTime'>,
) => {
  const { data: leadIds, ...rest } = useQuery({
    queryKey: ['lead', 'useLeadListIds', variables],
    queryFn: async () => {
      const leads = (await graphqlClient.request(leadListIdsQuery, variables)).dbLead;
      return leads?.map(({ id }) => id) || [];
    },
    ...options,
  });

  return { leadIds, ...rest };
};

const leadListQuery = graphql(/* GraphQL */ `
  query LeadListQuery($order_by: [DbLeadOrderBy!], $where: DbLeadBoolExp) {
    dbLead(orderBy: $order_by, where: $where) {
      ...LeadListEntry
      lastInteractionActivity {
        ...LastInteraction
      }
    }
  }
`);

export const useLeadList = (
  variables: VariablesOf<typeof leadListQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'staleTime'>,
) => {
  const { data: leads, ...rest } = useQuery({
    queryKey: ['lead', 'useLeadList', variables],
    queryFn: async () => {
      const leads = (await graphqlClient.request(leadListQuery, variables)).dbLead;
      return leads?.filter((l) => !!l.contact);
    },
    ...options,
  });

  return { leads, ...rest };
};

export type LeadlistEntryWithLastInteraction = NonNullable<ReturnType<typeof useLeadList>['leads']>[0];

//used in defaultValueLoader
export const leadInfoListQuery = graphql(/* GraphQL */ `
  query LeadInfoListQuery($limit: Int, $offset: Int, $order_by: [DbLeadOrderBy!], $where: DbLeadBoolExp) {
    dbLead(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {
      ...LeadInfo
    }
  }
`);
