import { LatLngBounds, LatLngTuple, LatLng as LeafletLatLng } from 'leaflet';
import { LatLng } from 'util/places/mapMarkers';
import { BoundingBox } from 'util/places/types';

export const latLngToPosition = (latLng: LatLng) => new LeafletLatLng(latLng.lat, latLng.lng);

export const latLngToLatLngTuple = (latLng: LatLng): LatLngTuple => [latLng.lat, latLng.lng];

export const boundingBoxToLatLngBounds = (boundingBox: BoundingBox): LatLngBounds =>
  new LatLngBounds(boundingBox.southWest, boundingBox.northEast);
