import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';
import { env } from 'env';

export const googleMapsLoaderConf: LoaderOptions = {
  id: 'leadhub_google_maps_loader',
  apiKey: env.VITE_GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places'],
};

export const createMapsApiLoader = () => new Loader(googleMapsLoaderConf);
