import { useJwtToken } from '@ev/eva-container-api';
import { Box, Button, ListItemIcon, ListItemText } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { useShops } from 'api/graphql/hooks/useShops';
import { DropdownMenu } from 'components/general/DropDown/DropdownMenu';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { SearchTextField } from 'components/general/SearchTextField/SearchTextField';
import CaretIcon from 'components/icons/arrow_1_down.svg?react';
import ShopIcon from 'components/icons/shop_naked.svg?react';
import { useActiveShop, useActiveShopId } from 'components/state/ActiveShopProvider';
import { EMAIL_POPOVER_ID } from 'components/state/EmailSendingProvider';
import { usePopover } from 'components/state/UIStateProvider';
import React, { useState } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { parseRolesFromJwtToken } from 'util/keycloak/roles';
import { removeDiacriticsAndSymbols } from 'util/stringUtils';
import { useSearchText } from 'util/useDebounce';
import { usePermissions } from 'util/usePermissions';

export const ActiveShopSelector = function ActiveShopSelector({
  asMenuItem,
  sx,
}: {
  asMenuItem?: boolean;
  sx?: SxProps;
}) {
  const { setActiveShopId } = useActiveShopId();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { activeShop, allShops } = useActiveShop();
  const { jwtToken } = useJwtToken();
  const { shopIds } = parseRolesFromJwtToken(jwtToken);
  const { canSeeAllShops, canAccessBlockedShops } = usePermissions();
  const { searchText, rawSearchText, setSearchText } = useSearchText();
  const normalizedSearchString = removeDiacriticsAndSymbols(searchText);
  const { t } = useTranslation(['admin', 'user']);
  const { setOpen: setOpenEmailPopover } = usePopover(EMAIL_POPOVER_ID);

  const allowedShopFilter = !canSeeAllShops() ? { id: { _in: shopIds || [] } } : {};
  const { shops } = useShops(
    {
      where: {
        ...allowedShopFilter,
        normalizedShopName: { _like: `%${normalizedSearchString}%` },
        status: { _nin: canAccessBlockedShops() ? ['CLOSED'] : ['CLOSED', 'BLOCKED'] },
      },
      order_by: { createdAt: 'DESC' },
      limit: 20,
    },
    {
      enabled: !!anchorEl,
      keepPreviousData: true,
    },
  );

  const openModal = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeModal = () => {
    setSearchText('');
    setAnchorEl(null);
  };

  return (
    <>
      {asMenuItem ? (
        <MenuItem onClick={openModal}>
          <ListItemIcon>{<ShopIcon />}</ListItemIcon>
          <ListItemText>{activeShop?.shopName ?? ''}</ListItemText>
          <CaretIcon />
        </MenuItem>
      ) : (
        <Button
          startIcon={<ShopIcon style={{ color: theme.palette.shade.grey2 }} />}
          onClick={openModal}
          color="secondary"
          sx={sx}
        >
          <Typography
            variant="body4"
            sx={{
              width: '100%',
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            component="div"
          >
            {activeShop?.shopName ?? ''}
          </Typography>
        </Button>
      )}
      <DropdownMenu
        anchorEl={anchorEl}
        onClose={closeModal}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        data-testid="shopSelector"
        sxPopover={{ maxHeight: '540px' }}
        showDrawerOnMobile={{ title: t('user:shopSelector.mobileTitle') }}
      >
        {(canSeeAllShops() || allShops.length > 10) && (
          <Box sx={{ padding: 2, paddingBottom: 1 }}>
            <SearchTextField
              role="combox"
              forceFocus={true}
              aria-autocomplete="list"
              onClick={(event) => event.stopPropagation()}
              fullWidth={true}
              onChange={(e) => setSearchText(e.target.value)}
              size="small"
              value={rawSearchText}
              placeholder={t('admin:shopTab.search')}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
          </Box>
        )}

        {shops?.map((shop) => (
          <MenuItem
            key={shop.id}
            onClick={() => {
              setOpenEmailPopover(false);
              setActiveShopId(shop.id);
              closeModal();
            }}
          >
            <ListItemIcon>
              <EVAvatar shop={shop} size="s" />
            </ListItemIcon>
            <ListItemText>{shop.shopName}</ListItemText>
          </MenuItem>
        ))}
      </DropdownMenu>
    </>
  );
};
