import { env } from 'env';
import React, { useCallback } from 'react';
import { StyledImage } from './StaticMap.styles';

// uuids of markers used for the static map
// https://app.uploadcare.com/projects/ac7a6012a4ecbd60b943/files/
export const PIN_IMG_ID = '3fb91a4c-dde2-4e60-a73c-219454cad27e';
export const MAP_ID = 'fb6ce80315cb1781';

function parseImgSizes(sizes: string): { query?: string; size: number }[] {
  return sizes.split(',').map((media) => {
    const [query, size] = media.match(/(\(.*\))?\s?([\d]+)/)?.slice(1, 3) || [];
    if (size === undefined) {
      throw new Error('Size is undefined in parseImgSizes()');
    } else {
      return { query, size: parseInt(size) };
    }
  });
}

interface StaticMapBaseProps {
  alt: string;
  sizes: string;
  aspectRatio?: number;
  zoom?: number;
  latitude?: number;
  longitude?: number;
  address?: string;
  markerSize?: number;
  extraHeight?: number;
  cover?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  mapStyle?: string;
  mapId?: string | null;
  showMarker?: boolean;
  extraParams?: string;
}

type StaticMapPropsLatLong = StaticMapBaseProps & Required<Pick<StaticMapBaseProps, 'latitude' | 'longitude'>>;
type StaticMapPropsAddress = StaticMapBaseProps & Required<Pick<StaticMapBaseProps, 'address'>>;

export type StaticMapProps = StaticMapPropsLatLong | StaticMapPropsAddress;

export const StaticMap: React.FC<StaticMapProps> = ({
  address,
  alt,
  aspectRatio = 1,
  latitude,
  longitude,
  sizes,
  zoom = 14,
  extraHeight,
  markerSize,
  cover,
  onClick,
  mapStyle,
  mapId = MAP_ID,
  showMarker = true,
  extraParams,
}) => {
  const center = address || `${latitude},${longitude}`;

  const markerSrc = markerSize
    ? `https://ucarecdn.com/${PIN_IMG_ID}/-/preview/${markerSize}x${markerSize}/`
    : `https://ucarecdn.com/${PIN_IMG_ID}/-/preview/64x64/`;

  const googleMapsStaticLoader = useCallback(
    ({ src, width }: { src: string; width: number }) => {
      const urlParams = new URLSearchParams({
        center,
        scale: '2',
        key: env.VITE_GOOGLE_MAPS_API_KEY,
        zoom: zoom.toString(),
        size: `${width}x${extraHeight ? width * aspectRatio + extraHeight : width * aspectRatio}`,
        format: 'png',
        ...(mapId ? { map_id: MAP_ID } : {}),
        ...(showMarker ? { markers: `anchor:24,40|icon:${markerSrc}|${center}` } : {}),
        ...(mapStyle ? { style: mapStyle } : {}),
      });
      return `https://maps.googleapis.com/maps/api/${src}?${urlParams.toString()}${extraParams ?? ''}`;
    },
    [extraHeight, aspectRatio, center, showMarker, markerSrc, mapId, zoom, mapStyle, extraParams],
  );

  const imgSizes = React.useMemo(() => parseImgSizes(sizes), [sizes]);
  const defaultSize = imgSizes[imgSizes.length - 1];
  const sourceSizes = imgSizes.slice(0, -1);
  const placeLink = `https://www.google.com/maps/search/?api=1&query=${center}`;

  return (
    <a href={placeLink} target="_blank" rel="noopener noreferrer" onClick={onClick}>
      <picture>
        {sourceSizes.map(({ query, size }) => {
          return (
            <source
              key={`${query}${size}`}
              media={query}
              srcSet={googleMapsStaticLoader({ src: 'staticmap', width: size })}
            />
          );
        })}
        {defaultSize?.size && (
          <StyledImage
            alt={alt}
            src={googleMapsStaticLoader({
              src: 'staticmap',
              width: defaultSize.size,
            })}
            sx={{
              aspectRatio,
              maxWidth: cover ? '100%' : extraHeight ? 'none' : '200px',
              objectFit: cover ? 'cover' : undefined,
              maxHeight: cover ? defaultSize.size : undefined,
            }}
          />
        )}
      </picture>
    </a>
  );
};
