import { Divider, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { useBFFPlaceDetailsQuery } from 'api/searchBff/hooks/usePlaceDetails';
import { PreformattedText } from 'components/general/PreformattedText/PreformattedText';
import { ShopInfo } from 'components/map/MarkerInfoBox/ShopInfo';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { Marker } from 'util/places/mapMarkers';
import { addressToMultilineString, addressToSingleLineString } from 'util/summaries/addressSummary';

export const AddressInfo = ({ addressMarker }: { addressMarker: Marker & { type: 'ADDRESS' } }) => {
  const { t } = useTranslation(['lead']);
  const { details } = useBFFPlaceDetailsQuery(addressMarker.address?.placeId);
  const addressString = encodeURIComponent(
    details?.formattedAddress || addressToSingleLineString(t)(addressMarker.address),
  );

  return (
    <Stack gap={1}>
      <Stack gap={1} direction="row" alignItems="center">
        <img src="/img/pin-ev-grey.svg" width={40} height={48} />
        <Link
          href={`https://www.google.com/maps/search/?api=1&query=${addressString}`}
          target="_blank"
          style={{
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            textDecoration: 'none',
          }}
        >
          <PreformattedText variant="body3">
            {addressToMultilineString(t)(addressMarker.address, { omitCountry: true })}
          </PreformattedText>
        </Link>
      </Stack>
      {addressMarker.plannedShop && (
        <>
          <Divider />
          <Typography
            variant="body4"
            color={theme.palette.information.main}
            sx={{
              borderRadius: '4px',
              padding: 0.5,
              width: 'max-content',
              backgroundColor: theme.palette.transparent.blue16,
            }}
          >
            {t('lead:mapMarker.plannedShopLabel')}
          </Typography>

          <ShopInfo shop={addressMarker.plannedShop} />
        </>
      )}
    </Stack>
  );
};
