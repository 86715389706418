import { Buffer } from 'buffer';

function bufferFromBase64Url(base64UrlString: string) {
  // The Buffer polyfill does not support base64url encoding
  return Buffer.from(base64UrlString.replaceAll('-', '+').replaceAll('_', '/'), 'base64');
}

export function decodeBase64Url(base64UrlString: string) {
  return bufferFromBase64Url(base64UrlString).toString();
}

export function parseBase64Uuid(base64Uuid: string) {
  const undecoratedUuid = bufferFromBase64Url(base64Uuid).toString('hex');
  const part1 = undecoratedUuid.slice(0, 8);
  const part2 = undecoratedUuid.slice(8, 12);
  const part3 = undecoratedUuid.slice(12, 16);
  const part4 = undecoratedUuid.slice(16, 20);
  const part5 = undecoratedUuid.slice(20);
  return `${part1}-${part2}-${part3}-${part4}-${part5}`;
}
