import { SxProps } from '@mui/material/styles';
import { Tag } from 'components/general/Tag/Tag';
import { contactIntentTypeTranslation, leadIntentTypeTranslation } from 'const/enumTranslations';
import { memo } from 'react';
import { EMPTY_DATA_STRING } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';
import { IntentTabEnum } from 'util/intentType';

const getIntentAbbreviation = (intent: IntentTabEnum | undefined) => {
  switch (intent) {
    case 'RENT_OUT':
    case 'TO_RENT':
      return 'R';
    case 'SELLER':
      return 'S';
    case 'BUYER':
      return 'B';
    default:
      return '?';
  }
};

const getIntentColor = (intent: IntentTabEnum | undefined) => {
  switch (intent) {
    case 'RENT_OUT':
      return 'purple';
    case 'TO_RENT':
      return 'yellow';
    case 'SELLER':
      return 'green';
    case 'BUYER':
      return 'blue';
    default:
      return 'red';
  }
};

function IntentTagComponent({
  intent,
  compact,
  showIntentType,
  sx,
}: {
  intent?: IntentTabEnum;
  compact?: boolean;
  showIntentType?: boolean;
  sx?: SxProps;
}) {
  const { t } = useTranslation(['contact', 'enums', 'lead']);
  const getLabel = () => {
    if (!intent) {
      return EMPTY_DATA_STRING;
    }
    if (showIntentType) {
      return t(leadIntentTypeTranslation[intent]);
    }
    return compact ? getIntentAbbreviation(intent) : t(contactIntentTypeTranslation[intent]);
  };

  return (
    <Tag color={getIntentColor(intent)} sx={{ ...sx, paddingX: 1, fontWeight: 'regular' }} data-testid="intentType">
      {getLabel()}
    </Tag>
  );
}

export const IntentTag = memo(IntentTagComponent);
