import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import IconSearch from 'components/icons/search.svg?react';
import { forwardRef, memo, useRef } from 'react';
import { splitRef } from 'util/splitRef';

const SearchTextFieldComponent = forwardRef<HTMLElement, TextFieldProps & { forceFocus?: boolean }>(
  function SearchTextFieldComponent({ forceFocus, ...props }, forwardRef) {
    const focusForced = useRef(false);
    const refCallback = (field: HTMLElement) => {
      if (forceFocus && !focusForced.current) {
        setTimeout(() => {
          const input = field?.querySelector('input');
          if (input) {
            focusForced.current = true;
            input.focus();
          }
        }, 10);
      }
    };

    return (
      <TextField
        ref={splitRef(forwardRef, refCallback)}
        {...props}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconSearch />
            </InputAdornment>
          ),
          ...props.InputProps,
        }}
      />
    );
  },
);

export const SearchTextField = memo(SearchTextFieldComponent);
