import Typography from '@mui/material/Typography';
import { CurrencyEnum, Scalars } from 'api/graphql/generated/graphql';
import { useFormatNumber } from 'util/useFormatNumber';

export function Price({
  price,
  currency,
}: {
  price: number | undefined | null;
  currency: CurrencyEnum | Scalars['PropertyEngineCurrency'] | null | undefined;
}) {
  const { formatPrice } = useFormatNumber();
  return (
    <Typography variant="body3" sx={{ whiteSpace: 'nowrap' }}>
      {formatPrice(price, { currency })}
    </Typography>
  );
}

export function PriceRange({
  from,
  to,
  currency,
}: {
  from: number | undefined | null;
  to: number | undefined | null;
  currency?: CurrencyEnum | null;
}) {
  const { formatPriceRange } = useFormatNumber();
  return (
    <Typography variant="body3" sx={{ whiteSpace: 'nowrap' }}>
      {formatPriceRange(from, to, { currency })}
    </Typography>
  );
}
