const values = {
  mobile: 0, // the lowest supported width is 320px
  tablet: 600,
  desktop: 900,
  'large-desktop': 1200,
  'extra-large-desktop': 1536,
};

const unit = 'px';

export const breakpoints = { values, unit };
