import { EmailTemplate, LanguageEnum } from 'api/graphql/generated/graphql';
import {
  LeadAndContactSearchOption,
  getContact,
} from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import uniqBy from 'lodash.uniqby';
import { nanoid } from 'nanoid';
import { getPreferredEmail } from 'util/contactUtils';
import {
  PreviewCcRecipient as BulkPreviewCcRecipient,
  PreviewBulkEmailFormData,
} from 'util/schemas/sendBulkEmailSchema';
import { Recipient as NormalNodeRecipient } from 'util/schemas/sendEmailSchema';
import { CcRecipient } from 'util/schemas/useCcAndBccSchema';

export const EXPOSE_TEMPLATE_TYPES = ['EXPOSE', 'PRICE_REDUCTION', 'PREMIUM_EXPOSE_MAIL'] as const;

export const SIGNATURE_MARKER = '<signature><br></signature>';
const UNRESOLVED_PLACEHOLDER_REGEX = /data-resolved=["']false["']/g;

export function addSignatureMarker(signature?: string) {
  if (!signature) {
    return '';
  }
  return '<p><br><br></p>' + SIGNATURE_MARKER + signature;
}

export function emailBodyWithSignature(emailBody: string, signature?: string) {
  const signatureIndex = emailBody.indexOf('<signature>');
  if (signatureIndex >= 0) {
    return emailBody.slice(0, signatureIndex) + SIGNATURE_MARKER + signature;
  } else {
    return emailBody + addSignatureMarker(signature);
  }
}

export function highlightUnresolvedPlaceholders(body: string, unresolvedPlaceholders: string[]) {
  let bodyWithHighlights = body.replaceAll('<!-- -->', '').replaceAll('\x3C!-- -->', '');

  for (const unresolvedPlaceholder of unresolvedPlaceholders) {
    const placeholderText = unresolvedPlaceholder.replaceAll(/[{}]/g, '');
    bodyWithHighlights = bodyWithHighlights.replaceAll(
      `{${placeholderText}}`,
      `{<span class='ql-placeholder' data-ql-id='${nanoid()}' data-placeholder='${placeholderText}' data-resolved='false'>${placeholderText}</span>}`,
    );
  }
  return bodyWithHighlights;
}

export function countUnresolvedPlaceholders(bodyText: string) {
  return (bodyText.match(UNRESOLVED_PLACEHOLDER_REGEX) || []).length;
}

export function hasAlreadyProposedProperty(recipientWithData: PreviewBulkEmailFormData['recipients'][number]) {
  const propertyUtags = new Set(recipientWithData.properties.map((p) => p.utag));
  return recipientWithData.alreadyProposedProperties.some((alreadyProposedPropertyUtag) =>
    propertyUtags.has(alreadyProposedPropertyUtag),
  );
}

export function replaceExposePlaceholders(content: string) {
  // The div is important here otherwise the content after the widget is cut off
  return content
    .replaceAll('{{{property_expose_list}}}', '<div><expose-list/></div>')
    .replaceAll('{{{premium_expose}}}', '<div><premium-expose/></div>');
}

export function getEmailForCcRecipient(ccRecipient: BulkPreviewCcRecipient) {
  return ccRecipient.type === 'AGENT' || ccRecipient.type === 'EMAIL'
    ? ccRecipient.email
    : getPreferredEmail(ccRecipient);
}

export function getTemplateContent(
  template: EmailTemplate | undefined,
  preferredShopLanguage: LanguageEnum | undefined,
) {
  return (
    template?.contents.find((content) => content.language === preferredShopLanguage) ||
    template?.contents.find((content) => content.language === 'EN')
  );
}

export function getPreferredLanguage(recipients: NormalNodeRecipient[] | undefined) {
  const firstRecipient = recipients?.[0];

  if (firstRecipient?.type === 'CONTACT' || firstRecipient?.type === 'RELATED_CONTACT') {
    return firstRecipient.preferredLanguage;
  }
  if (firstRecipient?.type === 'LEAD') {
    return firstRecipient.contact.preferredLanguage;
  }
}

export function getEmail(recipient: NormalNodeRecipient) {
  switch (recipient.type) {
    case 'AGENT':
    case 'CONTACT':
    case 'EMAIL':
      return recipient.email;
    case 'RELATED_CONTACT':
      return getPreferredEmail(recipient);
    case 'LEAD':
      return recipient.contact.email;
  }
}

export function getId(recipient?: NormalNodeRecipient | CcRecipient) {
  if (!recipient) {
    return;
  }
  return recipient.type === 'EMAIL' ? recipient.email : recipient.id;
}

export function getAutoCcRecipients(
  recipients: NormalNodeRecipient[],
  excludedRecipients: NormalNodeRecipient[],
): CcRecipient[] {
  const leadOrContactRecipients = recipients.filter(
    (r) => r.type === 'CONTACT' || r.type === 'LEAD',
  ) as LeadAndContactSearchOption[];
  if (!leadOrContactRecipients.length) {
    return [];
  }

  const excludedEmails = excludedRecipients?.map(getEmail) ?? [];

  return uniqBy(
    leadOrContactRecipients.flatMap((leadOrContact) => {
      const contact = getContact(leadOrContact);

      const ccOtherEmailsResult = contact.autoCc
        ? contact.emailAddresses
            .filter((email) => !excludedEmails.includes(email.address))
            .map((email) => ({
              type: 'EMAIL' as const,
              email: email.address,
            }))
        : [];

      const ccRelationshipsContactsResult = contact.contactRelationships
        .filter((r) => r.relatedContact && r.autoCc)
        .flatMap((contactRelationship) => ({ type: 'CONTACT' as const, ...contactRelationship.relatedContact }));

      return [...ccOtherEmailsResult, ...ccRelationshipsContactsResult];
    }),
    getId,
  );
}
