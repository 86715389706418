import { MODAL_Z_INDEX } from '@ev/eva-container-api';
import { Box } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { ContactAndLeads, IntentTypeEnum } from 'api/graphql/generated/graphql';
import { useContactsAndLeads } from 'api/graphql/hooks/useContact';
import { ContactItem, LeadItem } from 'components/contact/LeadAndContactPicker/LeadAndContactOptionItems';
import {
  LeadAndContactSearchOption,
  getContactId,
  mapContactToLeadAndContactOptions,
} from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import { SearchTextField } from 'components/general/SearchTextField/SearchTextField';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useMemo, useRef } from 'react';
import { contactSearchNameFilter } from 'util/hasura/filters';
import { useTranslation } from 'util/i18next';
import { useSearchText } from 'util/useDebounce';

export const SEND_EXPOSE_INTENT_TYPES: IntentTypeEnum[] = ['BUY', 'TO_RENT'];

function filterLeadsByIntentType(contacts: ContactAndLeads[]) {
  return contacts?.map((contact) => ({
    ...contact,
    leads: contact.leads.filter((lead) => SEND_EXPOSE_INTENT_TYPES.includes(lead.intentType)),
  }));
}

function hasEmail(recipient: LeadAndContactSearchOption) {
  return recipient.type === 'CONTACT' ? !!recipient.email : !!recipient.contact.email;
}

export function RecipientPicker({
  recipients,
  isForExposeSending,
  onRecipientsChange,
  disabled,
  errorMessage,
}: {
  recipients: LeadAndContactSearchOption[];
  isForExposeSending: boolean;
  onRecipientsChange: (recipients: LeadAndContactSearchOption[]) => void;
  disabled?: boolean;
  errorMessage?: string;
}) {
  const { shopIdsInActiveGroup } = useActiveShop();
  const { t } = useTranslation(['communication']);
  const { searchText, rawSearchText, setSearchText } = useSearchText();
  const searchFieldRef = useRef<HTMLElement>(null);

  const { contacts = [] } = useContactsAndLeads(
    {
      where: {
        shopId: { _in: shopIdsInActiveGroup },
        contactStatus: { _neq: 'FLAGGED' },
        deleted: { _neq: true },
        ...contactSearchNameFilter(searchText),
      },
      limit: 10,
      offset: 0,
    },
    { enabled: !!searchText },
  );

  const contactsWithLeads = useMemo(() => {
    const contactsWithFilteredLeads = isForExposeSending ? filterLeadsByIntentType(contacts) : contacts;
    return mapContactToLeadAndContactOptions(contactsWithFilteredLeads);
  }, [contacts, isForExposeSending]);

  function addRecipient(recipient: LeadAndContactSearchOption) {
    setSearchText('');
    onRecipientsChange([
      ...recipients.filter((oldRecipient) => getContactId(oldRecipient) !== getContactId(recipient)),
      recipient,
    ]);
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <SearchTextField
        fullWidth
        disabled={disabled}
        ref={searchFieldRef}
        value={rawSearchText}
        placeholder={t('communication:sendBulkEmailDialog.selectRecipientsPlaceholder')}
        onChange={(event) => setSearchText(event.target.value)}
        error={!!errorMessage}
        helperText={errorMessage}
      />
      <Popper
        anchorEl={() => searchFieldRef.current!}
        open={!!searchText.length}
        disablePortal
        sx={{ transformOrigin: 'left top', zIndex: MODAL_Z_INDEX, width: '100%' }}
      >
        <Paper elevation={3} sx={{ maxHeight: '300px', overflow: 'auto' }}>
          <ClickAwayListener onClickAway={() => setSearchText('')}>
            <MenuList>
              {contactsWithLeads.map((contactOrLead) => (
                <MenuItem
                  key={contactOrLead.id}
                  onClick={() => addRecipient(contactOrLead)}
                  sx={{ whiteSpace: 'wrap' }}
                  disabled={!hasEmail(contactOrLead)}
                >
                  {contactOrLead.type === 'LEAD' ? (
                    <Box sx={{ marginLeft: 4 }}>
                      <LeadItem lead={contactOrLead} />
                    </Box>
                  ) : (
                    <ContactItem option={contactOrLead} sx={{ minWidth: 0 }} />
                  )}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Box>
  );
}
