import { DRAWER_Z_INDEX } from '@ev/eva-container-api';
import MuiDrawer from '@mui/material/Drawer';
import { PaperProps } from '@mui/material/Paper';
import { Action, ActionsModal } from 'components/general/ActionsModal/ActionsModal';
import { Confirmation, ConfirmationContextProvider } from 'components/state/ConfirmationContext';
import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'util/i18next';

export interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  zIndex?: number;
  hideBackdrop?: boolean;
}

export const EVDrawer = ({ isOpen, onClose, children, zIndex, hideBackdrop }: PropsWithChildren<DrawerProps>) => {
  const [showBackdropClickModal, setShowBackdropClickModal] = useState(false);
  const [confirmation, setConfirmation] = useState<Confirmation>();

  return (
    <>
      <MuiDrawer
        anchor={'right'}
        open={isOpen}
        hideBackdrop={hideBackdrop}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' && confirmation?.active) {
            setShowBackdropClickModal(true);
          } else {
            onClose();
          }
        }}
        disableEscapeKeyDown={!hideBackdrop}
        disableScrollLock={hideBackdrop}
        disableEnforceFocus
        sx={{
          zIndex: zIndex === undefined ? DRAWER_Z_INDEX : zIndex,
          /** Make items outside drawer clickable when the backdrop is hidden */
          ...(hideBackdrop
            ? {
                position: 'unset',
              }
            : {}),
        }}
        PaperProps={
          {
            component: 'aside',
            sx: { width: { mobile: '100%', tablet: 480 } },
          } as PaperProps<'div'>
        }
      >
        <ConfirmationContextProvider
          setConfirmation={(c) => {
            setConfirmation(c);
            if (!c) {
              setShowBackdropClickModal(false);
            }
          }}
        >
          {children}
        </ConfirmationContextProvider>
      </MuiDrawer>
      {confirmation && (
        <ShowBackdropClickModal
          open={showBackdropClickModal}
          onSubmit={() => {
            setShowBackdropClickModal(false);
            onClose();
          }}
          onClose={() => {
            setShowBackdropClickModal(false);
          }}
          title={confirmation.title}
        />
      )}
    </>
  );
};

const ShowBackdropClickModal = ({
  open,
  onSubmit,
  onClose,
  title,
}: {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  title: string;
}) => {
  const { t } = useTranslation();
  const discardAction = {
    message: t('drawer.discardModal'),
    variant: 'contained',
    color: 'primary',
    handleClick: onSubmit,
  } as Action;
  const cancelAction = {
    message: t('cancel'),
    variant: 'outlined',
    color: 'secondary',
    handleClick: onClose,
  } as Action;
  return (
    <ActionsModal
      open={open}
      handleClose={onClose}
      title={t('drawer.discardModalTitle', { drawer: title })}
      actions={[cancelAction, discardAction]}
    />
  );
};
