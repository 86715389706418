import { ShareExposeDrawerData } from '@ev/eva-container-api';
import { mapLeadInfosToLeadAndContactOptions } from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import { loadContactsAndLeads, loadLeads } from 'util/defaultValues/defaultValueLoaders';
import { ListingWithLegacyFields } from 'util/go3';
import { ShareExposeFormData } from 'util/schemas/shareExposeSchema';

export async function getShareExposeDefaultValues({
  initialShareExposeData,
  defaultTitle,
}: {
  initialShareExposeData: ShareExposeDrawerData;
  defaultTitle: string;
}): Promise<ShareExposeFormData> {
  const [contacts, leads] = await Promise.all([
    loadContactsAndLeads(initialShareExposeData?.contactIds),
    loadLeads(initialShareExposeData?.leadIds),
  ]);

  //Find the first lead that has a valid property and prefill that property
  const leadWithProperty = leads?.find((lead) => lead.go3PropertyId || lead.peLinkedPropertyId);
  const propertyUtag =
    initialShareExposeData?.propertyUtag ??
    leadWithProperty?.go3PropertyId ??
    leadWithProperty?.peLinkedPropertyId ??
    '';

  return {
    contactsAndLeads: mapLeadInfosToLeadAndContactOptions(leads || [], contacts || []),
    properties: propertyUtag ? [{ utag: propertyUtag } as ListingWithLegacyFields] : [],
    title: defaultTitle,
  };
}
