import Box, { BoxProps } from '@mui/material/Box';
import { theme } from 'theme';

export type TagColor = 'yellow' | 'green' | 'blue' | 'red' | 'purple';

function setColor(color: TagColor) {
  switch (color) {
    case 'yellow':
      return {
        backgroundColor: theme.palette.transparent.yellow24,
        color: theme.palette.warning.main,
      };
    case 'green':
      return {
        backgroundColor: theme.palette.transparent.green16,
        color: theme.palette.special.green1,
      };
    case 'blue':
      return {
        backgroundColor: theme.palette.transparent.blue16,
        color: theme.palette.information.main,
      };
    case 'red':
      return {
        backgroundColor: theme.palette.transparent.red8,
        color: theme.palette.error.main,
      };
    case 'purple':
      return {
        backgroundColor: theme.palette.additional.purple.light,
        color: theme.palette.additional.purple.main,
      };
  }
}

export function Tag({ sx, color, ...otherProps }: BoxProps & { color: TagColor }) {
  return (
    <Box
      sx={{
        ...setColor(color),
        alignItems: 'center',
        borderRadius: '4px',
        display: 'inline-flex',
        fontSize: theme.typography.body4.fontSize,
        height: '24px',
        justifyContent: 'center',
        padding: theme.spacing(0, 0.5),
        whiteSpace: 'nowrap',
        ...sx,
      }}
      component="span"
      {...otherProps}
    />
  );
}
