import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PostRequestBody, fetchClient, toMultiPartFormData } from 'api/rest/fetch-client';
import { nanoid } from 'nanoid';

type RawSendEmailRequest = PostRequestBody<'/api/v1/email'>;
type SendEmailRequestData = RawSendEmailRequest['data'];

export type SendEmailRequest = {
  data: SendEmailRequestData;
  files: File[];
};

export const useSendEmail = () => {
  const queryClient = useQueryClient();
  const { mutate: sendEmail, ...rest } = useMutation(
    (request: SendEmailRequest) => {
      const requestId = nanoid();

      datadogRum.addAction('send normal email', {
        recipients: request.data.email.to,
        subject: request.data.email.subject,
        templateId: request.data.emailTemplateId,
        requestId,
      });

      return fetchClient
        .POST('/api/v1/email', {
          body: toMultiPartFormData({ ...request, data: { ...request.data, requestId } }),
        })
        .then((resp) => resp.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { sendEmail, ...rest };
};
