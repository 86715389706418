import { useMutation } from '@tanstack/react-query';

import { components, operations } from 'api/searchBff/generated-rest';
import { getSessionToken, searchBffFetchClient } from 'api/searchBff/searchBffFetchClient';
import { useCallback } from 'react';
import { useISO2LanguageCode } from 'util/i18next';

export type PlaceSuggestionsOptions = Pick<operations['commonSuggest']['parameters']['query'], 'input'> &
  Partial<operations['commonSuggest']['parameters']['query']>;

export const usePlaceSuggestions = () => {
  const language = (useISO2LanguageCode() || 'en') as components['schemas']['Language'];
  return useCallback(
    async (input: PlaceSuggestionsOptions) => {
      if (!input.input) {
        return [];
      }
      return searchBffFetchClient
        .GET('/v1/autosuggestion/suggest', {
          params: {
            query: {
              supportedCountryFiltering: false,
              sessionToken: getSessionToken(),
              language,
              limit: 10,
              types: [
                'address',
                // Used by Search
                'natural_feature ',
                'neighborhood',
                'sublocality',
                'locality',
                'postal_code',
                'administrative_area_level_4',
                'administrative_area_level_1',
                'country',
                // Relevant for CRM
                'sublocality_level_7',
                'sublocality_level_6',
                'sublocality_level_5',
                'sublocality_level_4',
                'sublocality_level_3',
                'sublocality_level_2',
                'sublocality_level_1',
                'administrative_area_level_3',
                'administrative_area_level_2',
              ],
              filterCustomPlaceTypes: false,
              ...input,
            },
            header: {},
          },
        })
        .then(({ data }) => data);
    },
    [language],
  );
};

export function useBFFPlaceSuggestions() {
  const placeSuggestions = usePlaceSuggestions();
  const {
    mutate: getPlaceSuggestions,
    mutateAsync: getPlaceSuggestionsAsync,
    data: suggestions,
    ...rest
  } = useMutation(placeSuggestions);

  return {
    getPlaceSuggestions,
    getPlaceSuggestionsAsync,
    suggestions: suggestions ?? [],
    ...rest,
  };
}
