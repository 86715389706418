import { styled } from '@mui/material/styles';

export const listItemHoverReset = {
  ':hover': { background: 'initial' },
};

export const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingRight: '5px',
  button: {
    borderRadius: 50,
  },
});
