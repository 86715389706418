import { Box, SxProps, useMediaQuery } from '@mui/material';
import { ReactNode } from 'react';
import { theme } from 'theme';

export const ModalContainer = ({
  singleColumn,
  sx,
  children,
}: {
  singleColumn?: boolean;
  sx?: SxProps;
  children: ReactNode;
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  return (
    <Box
      sx={{
        display: isMobile || singleColumn ? 'block' : 'flex',
        padding: 3,
        gap: 3,
        flexGrow: 1,
        overflowY: isMobile ? undefined : 'auto',
        backgroundColor: theme.palette.backgroundExtension.grey1,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
