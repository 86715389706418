import { useShops } from 'api/graphql/hooks/useShops';
import { CallActionButton, CallActions } from 'components/calls/CallActions';
import { CallFrame } from 'components/calls/CallFrame';
import { CallInfo } from 'components/calls/CallInfo';
import EndCallIcon from 'components/icons/end_call.svg?react';
import Phone from 'components/icons/phone_naked.svg?react';
import { CallerInfo } from 'components/state/Twilio';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

interface IncomingCallScreenProps {
  callerInfo: CallerInfo;
  onDeclineCall: () => void;
  onAcceptCall: () => void;
  onClose: () => void;
}

export function IncomingCallPopover({ onClose, callerInfo, onDeclineCall, onAcceptCall }: IncomingCallScreenProps) {
  const { t } = useTranslation(['contact', 'communication']);
  const { shops } = useShops({ where: { id: { _eq: callerInfo.call.shopId } } }, { enabled: !!callerInfo.call.shopId });

  return (
    <CallFrame
      onClose={onClose}
      title={t('communication:call.incomingCallTitle')}
      headerColor={theme.palette.shade.grey1}
      variant="dark"
      actions={
        <CallActions>
          <CallActionButton color={theme.palette.success.main} icon={<Phone />} onClick={onAcceptCall}>
            {t('communication:call.incoming.actionButton.accept')}
          </CallActionButton>
          <CallActionButton color={theme.palette.primary.main} icon={<EndCallIcon />} onClick={onDeclineCall}>
            {t('communication:call.incoming.actionButton.decline')}
          </CallActionButton>
        </CallActions>
      }
    >
      <CallInfo
        title={t('communication:call.incoming.title', {
          shopName: shops?.[0]?.shopName ?? '',
        })}
        sx={{ marginY: 'auto' }}
        callerInfo={callerInfo}
        isActive={true}
      />
    </CallFrame>
  );
}
