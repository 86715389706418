import { Ownership } from 'components/leads/forms/LeadForm/Ownership/Ownership';
import { BasicDetails } from 'components/leads/forms/LeadForm/SellIntent/SellIntent';
import { OptionalSections } from 'components/leads/forms/LeadForm/SpecificAddressAndOptionalDetails/OptionalSections';
import { SpecificAddressSection } from 'components/leads/forms/LeadForm/SpecificAddressAndOptionalDetails/SpecificAddressSection';

export const SellDetails = ({ isCreateNewLead }: { isCreateNewLead: boolean }) => {
  return (
    <>
      <Ownership />
      <BasicDetails />
      <SpecificAddressSection isCreateNewLead={isCreateNewLead} />
      <OptionalSections />
    </>
  );
};
