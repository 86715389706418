import { VariablesOf } from '@graphql-typed-document-node/core';
import { useInfiniteQuery, UseInfiniteQueryOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { BaseActivity } from 'api/graphql/generated/graphql';
import { secondaryGraphqlClient } from 'api/graphql/graphql-client';
const leadActivitiesQuery = graphql(/* GraphQL */ `
  query LeadBaseActivity($where: DbBaseActivityBoolExp, $orderBy: [DbBaseActivityOrderBy!], $limit: Int, $offset: Int) {
    dbBaseActivity(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
      ...BaseActivity
    }
  }
`);
export const useLeadActivities = (
  variables: VariablesOf<typeof leadActivitiesQuery> = {},
  options?: Pick<UseQueryOptions<BaseActivity[]>, 'enabled' | 'onError' | 'refetchInterval'>,
) => {
  const { data: activities, ...rest } = useQuery<BaseActivity[]>({
    queryKey: ['lead', 'useLeadActivities', variables],
    queryFn: async () => (await secondaryGraphqlClient.request(leadActivitiesQuery, variables)).dbBaseActivity,
    ...options,
  });
  return { activities: activities || [], ...rest };
};

const ACTIVITY_PER_PAGE = 10;

export const useLeadInfiniteActivities = (
  variables: VariablesOf<typeof leadActivitiesQuery> = {},
  options?: Pick<UseInfiniteQueryOptions<BaseActivity[]>, 'enabled' | 'onError' | 'refetchInterval'>,
) => {
  const { data, ...rest } = useInfiniteQuery<BaseActivity[]>({
    queryKey: ['lead', 'useLeadInfiniteActivities', variables],
    queryFn: async ({ pageParam = 1 }) => {
      const result = await secondaryGraphqlClient.request(leadActivitiesQuery, {
        ...variables,
        limit: ACTIVITY_PER_PAGE,
        offset: (pageParam - 1) * ACTIVITY_PER_PAGE,
      });
      return result.dbBaseActivity;
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < ACTIVITY_PER_PAGE) return undefined;
      return allPages.length + 1;
    },
    ...options,
  });

  return { activities: data, ...rest };
};
