import { useMemo } from 'react';
import { z } from 'zod';
import { useContactDetailsSchema } from './contactDetailsSchema';
import { usePropertyDetailsSchema } from './propertyDetailsSchema';

export function useNewLeadWithContactSchema() {
  const contactDetailsSchema = useContactDetailsSchema();
  const propertyDetailsSchema = usePropertyDetailsSchema();

  return useMemo(() => contactDetailsSchema.and(propertyDetailsSchema), [contactDetailsSchema, propertyDetailsSchema]);
}

export type NewLeadWithContactFormData = z.infer<ReturnType<typeof useNewLeadWithContactSchema>>;
