import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { PersonDetails } from 'api/rest/models/Common';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { ReactNode } from 'react';
import { theme } from 'theme';
import { fullName } from 'util/stringUtils';

interface PersonMenuItemProps extends React.HTMLAttributes<HTMLElement> {
  person: PersonDetails;
  avatar?: ReactNode;
}

export function PersonMenuItem({ person, avatar, ...props }: PersonMenuItemProps) {
  return (
    <Stack direction={'row'} gap={1} sx={{ minWidth: 0 }} {...props}>
      {avatar || <EVAvatar person={person} size="s" sx={{ alignSelf: 'flex-start' }} />}
      <Stack flex={1} sx={{ minWidth: 0 }}>
        <Typography variant="body2" sx={{ whiteSpace: 'noWrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {fullName(person)}
        </Typography>
        {person.email && (
          <Tooltip title={person.email} arrow>
            <Typography
              variant="subtitle1"
              sx={{
                color: theme.palette.text.secondary,
              }}
            >
              <Box sx={{ whiteSpace: 'noWrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{person.email}</Box>
            </Typography>
          </Tooltip>
        )}
        {person.phoneNumber && (
          <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary }}>
            {person.phoneNumber}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
