import TextField from '@mui/material/TextField';
import { IntentTypeEnum } from 'api/graphql/generated/graphql';
import { useCustomDemandFields } from 'api/graphql/hooks/useCustomDemandFields';
import { EVDrawerCollapsible } from 'components/general/EVDrawer/EVDrawerCollapsible';
import { FormSection, FormSectionHeader } from 'components/general/Form/FormSection';
import { FormStack } from 'components/general/Form/FormStack';
import CommentIcon from 'components/icons/chat_1_naked.svg?react';
import StarIcon from 'components/icons/star_outlined.svg?react';
import AddressSection from 'components/leads/forms/LeadForm/SearchDemand/AddressSection';
import PriceSection from 'components/leads/forms/LeadForm/SearchDemand/PriceSection';
import PropertySection from 'components/leads/forms/LeadForm/SearchDemand/PropertySection';
import SizeSection from 'components/leads/forms/LeadForm/SearchDemand/SizeSection';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { Controller, useFormContext } from 'react-hook-form';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';
import { CdfPicker } from '../CdfPicker/CdfPicker';

export function SearchDemand({ intentType }: { intentType?: IntentTypeEnum }) {
  const { t } = useTranslation(['lead', 'enums']);
  const { activeShop, shopIdsInActiveGroup } = useActiveShop();
  const { customDemandFields } = useCustomDemandFields({ where: { shopId: { _in: shopIdsInActiveGroup } } });
  const {
    control,
    watch,
    formState: { errors },
    register,
  } = useFormContext<PropertyDetailsFormData>();

  const commentCollapsedValue = watch('search.personalNote');
  const cdfs = watch('search.cdfs');
  const cdfCollapsedValues = cdfs
    ?.map((cdf) => customDemandFields?.find(({ id }) => id === cdf)?.label)
    .filter(Boolean)
    .join(', ');

  return (
    <>
      <PropertySection intentType={intentType} sx={{ mt: -3 }} />
      <AddressSection />
      <FormSection>
        <FormSectionHeader>{t('lead:form.optionalSection.header')}</FormSectionHeader>
        <PriceSection intentType={intentType} />
        <SizeSection />
        <EVDrawerCollapsible
          title={t('lead:form.searchDemand.comment.header')}
          icon={<CommentIcon />}
          collapsedValues={commentCollapsedValue}
        >
          <FormStack>
            <TextField
              label={t('lead:personalNote')}
              multiline
              error={!!errors.search?.personalNote}
              helperText={errors.search?.personalNote?.message}
              sx={{
                '.MuiInput-input': {
                  fontSize: theme.typography.body3,
                },
              }}
              {...register('search.personalNote')}
            />
          </FormStack>
        </EVDrawerCollapsible>

        {activeShop?.onboardingLevel === 2 && (
          <EVDrawerCollapsible
            title={t('lead:form.searchDemand.features.header')}
            icon={<StarIcon />}
            collapsedValues={cdfCollapsedValues}
          >
            <FormStack>
              <Controller
                name={'search.cdfs'}
                control={control}
                render={({ field: { ref, ...field } }) => <CdfPicker {...field} />}
              />
            </FormStack>
          </EVDrawerCollapsible>
        )}
      </FormSection>
    </>
  );
}
