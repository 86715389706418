import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import { ReactNode } from 'react';
import { CollapsibleSectionContextProvider } from './SectionContext';

export function Section({
  noSpacing,
  collapsible = true,
  children,
  sx,
}: {
  noSpacing?: boolean;
  collapsible?: boolean;
  children: ReactNode;
  sx?: SxProps;
}) {
  return (
    <Box sx={noSpacing ? { ...sx } : { marginY: 4, ...sx }}>
      <CollapsibleSectionContextProvider collapsible={collapsible}>{children}</CollapsibleSectionContextProvider>
    </Box>
  );
}
