import { Box, SxProps, Typography } from '@mui/material';
import Stack, { StackProps } from '@mui/material/Stack';
import { KeyboardEvent, ReactNode, useState } from 'react';
import { theme } from 'theme';

interface FormStackProps extends StackProps {
  children: ReactNode;
  sx?: SxProps;
  descriptionText?: string;
  isOpen?: boolean;
  icon?: ReactNode;
  hidden?: boolean;
}

export const FormStack = ({
  children,
  sx,
  descriptionText,
  isOpen,
  icon,
  hidden,
  direction = 'row',
  ...stackProps
}: FormStackProps) => {
  if (hidden) {
    return null;
  }
  if (descriptionText) {
    return (
      <FormStackWithDescription sx={sx} descriptionText={descriptionText} isOpen={isOpen} icon={icon}>
        {children}
      </FormStackWithDescription>
    );
  }
  return (
    <Stack direction={direction} gap={2} ml={icon ? 0 : '40px'} sx={sx} {...stackProps}>
      {icon && (
        <Box
          sx={{
            flexShrink: 0,
            color: theme.palette.text.secondary,
            mt: stackProps.alignItems !== 'center' ? 1 : 'none',
          }}
        >
          {icon}
        </Box>
      )}
      {children}
    </Stack>
  );
};

const FormStackWithDescription = ({
  children,
  sx,
  descriptionText,
  isOpen: isForceOpened,
  icon,
}: {
  children: ReactNode;
  sx?: SxProps;
  descriptionText: string;
  isOpen?: boolean;
  icon?: ReactNode;
}) => {
  const [isManuallyOpened, setIsManuallyOpened] = useState(false);
  const isInputVisible = isForceOpened || isManuallyOpened;

  if (!isInputVisible) {
    return (
      <Stack
        onClick={() => setIsManuallyOpened(true)}
        onKeyDown={(event: KeyboardEvent) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            setIsManuallyOpened(true);
          }
        }}
        direction={'row'}
        gap={2}
        alignItems={'center'}
        ml={icon ? 0 : '40px'}
        sx={{ cursor: 'pointer', ...sx }}
        tabIndex={0}
      >
        {icon && <Box sx={{ flexShrink: 0, color: theme.palette.text.secondary }}>{icon}</Box>}
        <Typography variant="body2" py={1.5} lineHeight={'28px'} letterSpacing={0.03}>
          {descriptionText}
        </Typography>
      </Stack>
    );
  }

  return (
    <FormStack sx={sx} icon={icon}>
      {children}
    </FormStack>
  );
};
