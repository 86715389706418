import { Option } from 'components/general/OptionsList/OptionsList';
import { ReactNode } from 'react';

export const EMPTY_DATA_STRING = '-';

export const UNDEFINED_OPTION_VALUE = 'UNDEFINED';
export const UNASSIGNED = 'UNASSIGNED';

const EMPTY_OPTION = {
  value: '',
  label: '',
};

export function renderEmptyableString(data: string | null | undefined): string {
  return data || EMPTY_DATA_STRING;
}

export function renderEmptyable<T>(data: T | null | undefined, map: (param: T) => string = (d) => `${d}`): string {
  return data ? map(data) : EMPTY_DATA_STRING;
}

export function renderEmptyableArray<T>(data: T[] | null | undefined, map: (param: T) => string): string {
  const str = data?.map(map).join(', ');
  return str || EMPTY_DATA_STRING;
}

export function renderEmptyableEnum(param?: string | null): string {
  return param === undefined || param === null || param === UNDEFINED_OPTION_VALUE ? '' : param;
}

export function includeEmptyOption(options: Array<Option> | undefined) {
  if (options) {
    return [EMPTY_OPTION, ...options];
  }
  return [];
}

export function replaceUndefinedOption(param?: string) {
  return param === UNDEFINED_OPTION_VALUE ? undefined : param;
}

export function renderEmptyableComponents<T>(data: T[] | null | undefined, map: (param: T) => ReactNode): ReactNode {
  if (!data || !data.length) {
    return EMPTY_DATA_STRING;
  }
  return data.map(map);
}
