import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

export const leadQuery = graphql(/* GraphQL */ `
  query LeadById($id: String) {
    dbLead(where: { id: { _eq: $id } }) {
      ...Lead
      lastInteractionActivity {
        ...LastInteraction
      }
    }
  }
`);

export const useLead = (
  variables: VariablesOf<typeof leadQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: lead, ...rest } = useQuery({
    queryKey: ['lead', 'useLead', variables],
    queryFn: async () => (await graphqlClient.request(leadQuery, variables)).dbLead?.[0] || null,
    ...options,
  });
  return { lead, ...rest };
};

export type LeadWithLastInteraction = NonNullable<ReturnType<typeof useLead>['lead']>;

export const leadAndContactQuery = graphql(/* GraphQL */ `
  query leadAndContactById($id: String) {
    dbLead(where: { id: { _eq: $id } }) {
      ...LeadInfo
      contact {
        ...ContactInfo
      }
    }
  }
`);

export const useLeadAndContact = (
  variables: VariablesOf<typeof leadAndContactQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: lead, ...rest } = useQuery({
    queryKey: ['lead', 'useLeadAndContact', variables],
    queryFn: async () => (await graphqlClient.request(leadAndContactQuery, variables)).dbLead?.[0],
    ...options,
  });
  return { lead, ...rest };
};

export type LeadAndContact = NonNullable<ReturnType<typeof useLeadAndContact>['lead']>;

export const leadBaseInfoQuery = graphql(/* GraphQL */ `
  query LeadBaseInfoById($id: String) {
    dbLead(where: { id: { _eq: $id } }) {
      ...LeadInfo
    }
  }
`);

export const useLeadInfo = (
  variables: VariablesOf<typeof leadBaseInfoQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'staleTime'>,
) => {
  const { data: lead, ...rest } = useQuery({
    queryKey: ['lead', 'useLeadInfo', variables],
    queryFn: async () => (await graphqlClient.request(leadBaseInfoQuery, variables)).dbLead?.[0],
    ...options,
  });
  return { lead, ...rest };
};

export const leadForAddingPropertyQuery = graphql(/* GraphQL */ `
  query LeadInfoByIdForAddingProperty($id: String) {
    dbLead(where: { id: { _eq: $id } }) {
      ...LeadForAddingProperty
    }
  }
`);

export const useLeadForAddingProperty = (
  variables: VariablesOf<typeof leadForAddingPropertyQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'staleTime'>,
) => {
  const { data: lead, ...rest } = useQuery({
    queryKey: ['lead', 'useLeadForAddingProperty', variables],
    queryFn: async () => (await graphqlClient.request(leadForAddingPropertyQuery, variables)).dbLead?.[0],
    ...options,
  });
  return { lead, ...rest };
};

const leadsQuery = graphql(/* GraphQL */ `
  query LeadsQuery($limit: Int, $offset: Int, $where: DbLeadBoolExp, $lastInteractionWhere: DbBaseActivityBoolExp) {
    dbLead(limit: $limit, offset: $offset, where: $where) {
      ...LeadInfo
    }
  }
`);

export const useLeads = (
  variables: VariablesOf<typeof leadsQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: leads, ...rest } = useQuery({
    queryKey: ['lead', 'useLeads', variables],
    queryFn: async () => {
      const leads = (await graphqlClient.request(leadsQuery, variables)).dbLead;
      return leads;
    },
    ...options,
  });

  return { leads, ...rest };
};

const leadsOfContactQuery = graphql(/* GraphQL */ `
  query LeadsOfContactQuery($limit: Int, $where: DbLeadBoolExp) {
    dbLead(limit: $limit, where: $where) {
      ...Lead
    }
  }
`);

export const useLeadsOfContact = (
  variables: VariablesOf<typeof leadsOfContactQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: leads, ...rest } = useQuery({
    queryKey: ['lead', 'useLeadsOfContact', variables],
    queryFn: async () => {
      const leads = (await graphqlClient.request(leadsOfContactQuery, variables)).dbLead;
      return leads;
    },
    ...options,
  });

  return { leads, ...rest };
};
