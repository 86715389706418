import Tab, { TabProps } from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { theme } from 'theme';

interface LinkTabProps {
  showLabel?: boolean;
  label?: string;
  href?: string;
  icon?: TabProps['icon'];
  value?: string;
}

export function SidePanelLinkTab({ href, showLabel, label, ...restProps }: LinkTabProps) {
  return (
    <Tooltip title={showLabel ? undefined : label} placement="right">
      <Tab
        to={href}
        component={href ? Link : 'div'}
        iconPosition="start"
        aria-label={label}
        label={showLabel ? label : undefined}
        sx={{
          textTransform: 'none',
          padding: theme.spacing(2, 3),
          minWidth: 'initial',
          minHeight: 'initial',
          width: '100%',
          justifyContent: 'flex-start',
          fontSize: theme.typography.body3,
          '&:hover': {
            backgroundColor: theme.palette.shade.grey4,
          },
        }}
        {...restProps}
      />
    </Tooltip>
  );
}
