import { Box, FormHelperText, Popover, SxProps } from '@mui/material';
import Autocomplete, { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useContactsAndLeads } from 'api/graphql/hooks/useContact';
import {
  ContactItem,
  LeadItem,
  LeadOptionChip,
} from 'components/contact/LeadAndContactPicker/LeadAndContactOptionItems';
import {
  LeadAndContactSearchOption,
  mapContactToLeadAndContactOptions,
  mapContactsToContactOptions,
} from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import { ContactTooltipChip } from 'components/general/Chips/TooltipChip/ContactTooltipChip';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import React, { HTMLAttributes, SyntheticEvent, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { contactSearchNameFilter } from 'util/hasura/filters';
import { useTranslation } from 'util/i18next';
import { useSearchText } from 'util/useDebounce';

export const ContactsAndLeadsPicker = ({
  name,
  label,
  children,
  errorMessage,
  disabled,
  required,
  limitTags,
  allowLeads = false,
  sx,
}: {
  name: string;
  label: React.ReactNode;
  children?: React.ReactNode;
  errorMessage?: string;
  disabled?: boolean;
  required?: boolean;
  limitTags?: number;
  allowLeads?: boolean;
  sx?: SxProps;
}) => {
  const { t } = useTranslation(['user']);

  const anchorEl = useRef<HTMLDivElement | HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { searchText, setSearchText } = useSearchText();
  const [selectedOption, setOption] = useState<LeadAndContactSearchOption>();
  const { control } = useFormContext();
  const { shopIdsInActiveGroup } = useActiveShop();

  const { contacts = [], isLoading: isContactLoading } = useContactsAndLeads(
    {
      where: {
        shopId: { _in: shopIdsInActiveGroup },
        _and: [
          {
            _or: [
              {
                emailAddresses: { address: { _isNull: false } },
              },
              {
                phoneNumbers: { number: { _isNull: false } },
              },
            ],
          },
          {
            _or: [
              {
                deleted: { _eq: false },
              },
              { deleted: { _isNull: true } },
            ],
          },
          contactSearchNameFilter(searchText),
          {
            contactStatus: { _neq: 'FLAGGED' },
          },
        ],
      },
      limit: 10,
      offset: 0,
    },
    { enabled: !!searchText.length },
  );

  const options = allowLeads ? mapContactToLeadAndContactOptions(contacts) : mapContactsToContactOptions(contacts);

  const onSearchStringChanged = (_: SyntheticEvent<Element, Event>, value: string) => {
    setSearchText(value);
  };

  const handleClose = () => {
    setOption(undefined);
    anchorEl.current = null;
  };

  const renderTags = (value: LeadAndContactSearchOption[], getTagProps: AutocompleteRenderGetTagProps) => {
    return value.map((option, index: number) => {
      if (option.type === 'LEAD') {
        return <LeadOptionChip key={option.id} lead={option} index={index} getTagProps={getTagProps} />;
      }
      if (
        value.some((item) => item.id === option.id) &&
        value.some((item) => {
          if (item.type === 'LEAD') {
            return item.contactId === option.id;
          }
        })
      ) {
        return;
      }
      return (
        <ContactTooltipChip
          key={option.id}
          handleDelete={() => getTagProps({ index }).onDelete(index)}
          contactId={option.id}
          asLink={false}
        />
      );
    });
  };

  const renderOptions = (props: HTMLAttributes<HTMLLIElement>, option: LeadAndContactSearchOption) => {
    if (option.type === 'LEAD') {
      return (
        <Box component="li" {...props} key={option.id}>
          <Stack gap={1} py={0.5} ml={4} direction={'row'}>
            <LeadItem lead={option} />
          </Stack>
        </Box>
      );
    }

    return (
      <Box component="li" {...props} key={option.id}>
        <ContactItem option={option} />
      </Box>
    );
  };

  const getOptionLabel = (option: LeadAndContactSearchOption) => {
    const contact = option.type === 'LEAD' ? option.contact : option;
    return contact.email + contact?.phoneNumber + contact?.firstName + contact?.lastName;
  };

  const open = Boolean(anchorEl.current);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box sx={sx}>
      <Controller
        control={control}
        name={name ?? ''}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            open={isOpen || !!searchText.length}
            onFocus={() => {
              if (value.length === 0) {
                setIsOpen(true);
              }
            }}
            onBlur={() => setIsOpen(false)}
            noOptionsText={
              searchText.length
                ? t('user:dashboard.contactSearch.noMatches')
                : t('user:dashboard.contactSearch.startTyping')
            }
            loading={!!searchText.length && isContactLoading}
            loadingText={t('user:dashboard.contactSearch.loading')}
            value={value}
            limitTags={limitTags}
            disableClearable
            multiple
            disabled={disabled}
            getOptionLabel={getOptionLabel}
            filterOptions={(options) => options} // Needed to disable the internal filtering
            onChange={(_, items) => {
              const payload = items.map((item) => {
                if (typeof item === 'string') {
                  return {
                    displayValue: item,
                  };
                }
                if (item.type === 'LEAD') {
                  return item;
                }
                return {
                  displayValue: item.email,
                  contactId: item.id,
                  email: item.email,
                  phoneNumber: item.phoneNumber,
                  firstName: item.firstName,
                  lastName: item.lastName,
                  id: item.id,
                  type: 'CONTACT',
                };
              });
              if (items.length) {
                setIsOpen(false);
              } else {
                setIsOpen(true);
              }
              onChange(payload);
            }}
            isOptionEqualToValue={(option: string | LeadAndContactSearchOption, value: LeadAndContactSearchOption) => {
              if (typeof option === 'string') return option === value.id;
              return option.id === value.id;
            }}
            onInputChange={(event, newInputValue) => onSearchStringChanged(event, newInputValue)}
            options={options}
            getOptionDisabled={(option) => {
              if (option.type === 'LEAD') {
                return value.some((item: LeadAndContactSearchOption) => item.id === option.id);
              }
              return value.some(
                (item: LeadAndContactSearchOption) =>
                  item.id === option.id || (item.type === 'LEAD' && item.contactId === option.id),
              );
            }}
            renderOption={renderOptions}
            renderTags={renderTags}
            renderInput={(params) => (
              <TextField
                required={required}
                error={!!errorMessage}
                {...params}
                label={label}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        )}
      />
      <Stack direction="row" sx={{ marginBottom: children ? -3 : 0 }}>
        <FormHelperText>{errorMessage}</FormHelperText>
        <Box sx={{ marginLeft: 'auto' }}>{children}</Box>
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {selectedOption && selectedOption.type === 'LEAD' ? (
          <LeadItem lead={selectedOption} />
        ) : (
          <ContactItem option={selectedOption} sx={{ p: 1 }} />
        )}
      </Popover>
    </Box>
  );
};
