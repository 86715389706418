import { Button, ButtonProps, DialogActions, SxProps } from '@mui/material';
import { ReactNode } from 'react';
import { theme } from 'theme';

export const ModalFooter = ({ children, sx }: { children: ReactNode; sx?: SxProps }) => {
  return (
    <DialogActions
      sx={{
        margin: 0,
        paddingX: 3,
        paddingY: 2,
        width: '100%',
        display: 'flex',
        borderTop: `1px solid ${theme.palette.grey[400]}`,
        justifyContent: 'flex-end',
        background: theme.palette.shade.grey5,
        [theme.breakpoints.down('tablet')]: {
          padding: 2,
          position: 'sticky',
          bottom: 0,
        },
        ...sx,
      }}
    >
      {children}
    </DialogActions>
  );
};

export const ModalFooterButton = ({ onClick, sx, variant = 'outlined', children, ...props }: ButtonProps) => (
  <Button color="secondary" variant={variant} onClick={onClick} sx={sx} {...props}>
    {children}
  </Button>
);
