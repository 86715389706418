import { Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { IntentCategoryEnum, IntentTypeEnum, LeadSourceEnum } from 'api/graphql/generated/graphql';
import { useGetAllEnums } from 'api/graphql/hooks/useGetAllEnums';
import { KeyValue } from 'api/rest/models/Common';
import { FormSection, FormSectionHeader } from 'components/general/Form/FormSection';
import { FormStack } from 'components/general/Form/FormStack';
import { RequiredLabel } from 'components/general/RequiredLabel/RequiredLabel';
import { SingleAutocomplete } from 'components/general/SingleAutocomplete/SingleAutocomplete';
import TargetIcon from 'components/icons/target.svg?react';
import { leadSourceTranslationKeys } from 'const/enumTranslations';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { intentTypeToIntentCategory } from 'util/intentType';
import { NewLeadWithContactFormData } from 'util/schemas/newLeadSchema';
import { BuyDetails } from './BuyDetails';
import { RentDetails } from './RentDetails';
import { SellDetails } from './SellDetails';

export function LeadForm({ isCreateNewLead = false, isEdit = false }: { isCreateNewLead?: boolean; isEdit?: boolean }) {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<NewLeadWithContactFormData>();

  const { t } = useTranslation(['lead', 'enums']);
  const { allEnums } = useGetAllEnums();
  const translatedLeadSources =
    allEnums?.enumsLeadSource
      .filter((leadSource) => leadSource.name !== 'UNDEFINED')
      .map(({ name }) => ({
        value: name,
        label: t(leadSourceTranslationKeys[name as LeadSourceEnum]),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const contactIntentType = watch('_internals.contactIntentType');
  const intentType = watch('intentType');

  useEffect(() => {
    const inferredContactIntentType = intentTypeToIntentCategory(intentType);
    if (inferredContactIntentType === contactIntentType) {
      return;
    }

    switch (contactIntentType) {
      case 'SELLER':
        setValue('intentType', 'SELL', { shouldValidate: true });
        break;
      case 'BUYER':
        setValue('intentType', 'BUY', { shouldValidate: true });
        break;
      case 'RENTAL':
        setValue('intentType', intentType as 'RENT_OUT' | 'TO_RENT', { shouldValidate: true });
        break;
    }
  }, [contactIntentType, intentType, setValue]);

  const intentArray: KeyValue<IntentCategoryEnum | IntentTypeEnum, string>[] = [
    {
      key: 'SELLER',
      value: t('lead:intent.sell'),
    },
    {
      key: 'BUYER',
      value: t('lead:intent.buy'),
    },
    {
      key: 'TO_RENT',
      value: t('lead:intent.toRent'),
    },
    {
      key: 'RENT_OUT',
      value: t('lead:intent.rentOut'),
    },
  ];

  function getComponent(key: IntentCategoryEnum | IntentTypeEnum) {
    switch (key) {
      case 'SELLER':
        return <SellDetails isCreateNewLead={isCreateNewLead} />;
      case 'BUYER':
        return <BuyDetails />;
      case 'RENTAL':
        return <RentDetails isCreateNewLead={isCreateNewLead} />;
      default:
        return null;
    }
  }

  // TODO: Fixing the contact intent type causes errors during the lead creation. This needs to be handled
  const intentTypeFormComponent = getComponent(contactIntentType as IntentCategoryEnum);

  return (
    <Stack>
      <FormSection>
        <FormSectionHeader>{t('lead:form.section.contactIntention.header')}</FormSectionHeader>
        <FormStack sx={{ ml: 0 }}>
          <FormControl error={!!errors?._internals?.contactIntentType}>
            <FormGroup row>
              <Controller
                name="_internals.contactIntentType"
                control={control}
                render={({ field }) => (
                  <RadioGroup row name="contact-intent-radio-group">
                    {intentArray.map((intent) => (
                      <FormControlLabel
                        {...field}
                        key={intent.key}
                        control={
                          <Radio
                            disabled={isEdit}
                            color="secondary"
                            value={intent.key}
                            checked={
                              (field.value === 'RENTAL' && intent.key === intentType) || field.value === intent.key
                            }
                            onChange={(event) => {
                              const value = event.target.value;
                              if (['TO_RENT', 'RENT_OUT'].includes(value)) {
                                field.onChange('RENTAL');
                                setValue('intentType', value as 'TO_RENT' | 'RENT_OUT');
                              } else {
                                field.onChange(event.target.value);
                              }
                            }}
                          />
                        }
                        label={intent.value}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </FormGroup>
            <FormHelperText>{errors?._internals?.contactIntentType?.message}</FormHelperText>
          </FormControl>
        </FormStack>
      </FormSection>
      {contactIntentType && (
        <FormSection>
          <FormSectionHeader>{t('lead:form.section.leadSource.header')}</FormSectionHeader>
          <FormControl error={!!errors?.leadSource}>
            <FormGroup>
              <FormStack icon={<TargetIcon />}>
                <Controller
                  name="leadSource"
                  control={control}
                  render={({ field }) => (
                    <SingleAutocomplete
                      hasError={!!errors?.leadSource}
                      errorMessage={errors?.leadSource?.message}
                      noOptionsPlaceholder=""
                      label={<RequiredLabel text={t('lead:propertyEvaluateForm.leadSourceTitle')} />}
                      options={translatedLeadSources || []}
                      sx={{ flex: 1 }}
                      {...field}
                    />
                  )}
                />
              </FormStack>
            </FormGroup>
          </FormControl>
        </FormSection>
      )}
      {intentTypeFormComponent}
    </Stack>
  );
}
