import Box from '@mui/material/Box';

export function SendBulkEmailDesktopGrid({ children }: React.PropsWithChildren) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '320px minMax(0, 1fr)',
        gridTemplateRows: 'max-content 1fr',
        columnGap: 3,
        rowGap: 2,
        width: '100%',
        height: '100%',
      }}
    >
      {children}
    </Box>
  );
}
