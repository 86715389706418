import { useListing } from 'api/graphql/hooks/useListing';
import { EVChip, EVChipProps } from 'components/general/Chips/EVChip/EVChip';
import { EntityTooltip } from 'components/general/Chips/EntityTooltip/EntityTooltip';
import { EntityTooltipHeader } from 'components/general/Chips/EntityTooltip/EntityTooltipHeader';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { Price } from 'components/general/Price';
import { GoEVIdLink } from 'page-components/contact/EVIdWithLink/GoEVIdLink';
import React from 'react';
import { useTranslation } from 'util/i18next';
import { addressToSingleLineString, addressToStreetString } from 'util/summaries/addressSummary';

type PropertyTooltipChipProps = {
  chipProps?: EVChipProps;
  handleDelete?: () => void;
  propertyId: string;
};

export const PropertyTooltipChip: React.FC<PropertyTooltipChipProps> = ({
  chipProps,
  handleDelete,
  propertyId: utag,
}) => {
  const { t } = useTranslation(['enums']);

  const { listing, isLoading } = useListing({ utag });
  const { address, previewImage, askingPrice, monthlyTotalRent } = listing || {};

  return (
    <EntityTooltip
      isLoading={isLoading}
      placement={'top-start'}
      header={
        <EntityTooltipHeader
          label={<GoEVIdLink goPath={`${listing?.type === 'SALE' ? 'sales' : 'lettings'}/${utag}`} label={utag} />}
          avatar={previewImage ? <EVAvatar isSquare size="l" pictureSrc={previewImage} /> : undefined}
          subLabel={addressToSingleLineString(t)(address)}
        />
      }
      content={<Price price={listing?.type === 'SALE' ? askingPrice : monthlyTotalRent} currency={listing?.currency} />}
    >
      <EVChip
        onDelete={handleDelete}
        label={addressToStreetString(address, listing?.fileAs)}
        avatar={previewImage ? <EVAvatar size="s" pictureSrc={previewImage} /> : undefined}
        {...chipProps}
      />
    </EntityTooltip>
  );
};
