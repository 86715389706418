import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import { PropsWithChildren, ReactNode } from 'react';
import { theme } from 'theme';

export function SearchResultCard({
  avatar,
  title,
  children,
  onClick,
  isSelected,
  disabled,
}: PropsWithChildren<{
  avatar: ReactNode;
  title: ReactNode;
  onClick: () => void;
  isSelected?: boolean;
  disabled?: boolean;
}>) {
  return (
    <Paper
      variant="outlined"
      sx={{
        cursor: disabled ? 'default' : 'pointer',
        margin: 1.5,
        padding: 2,
        opacity: disabled ? 0.5 : 1,
        borderColor: isSelected ? theme.palette.shade.grey2 : theme.palette.shade.grey4,
      }}
      onClick={disabled ? undefined : onClick}
    >
      <Box
        sx={{ display: 'grid', gridTemplateColumns: 'max-content minmax(0,1fr)', columnGap: 1, alignItems: 'center' }}
      >
        {avatar}
        {title}
        {children}
      </Box>
    </Paper>
  );
}
