import { Stack, Typography } from '@mui/material';
import { ContactAndLeads } from 'api/graphql/generated/graphql';
import { EVAlert } from 'components/general/EVAlert/EVAlert';
import { useTranslation } from 'util/i18next';
import { fullName } from 'util/stringUtils';

interface ContactsRemovedAlertProps {
  toContactsWithoutEmail: ContactAndLeads[];
  toContactsFlagged: ContactAndLeads[];
}

export const ContactsRemovedAlert = ({ toContactsWithoutEmail, toContactsFlagged }: ContactsRemovedAlertProps) => {
  const { t } = useTranslation(['communication']);

  if (toContactsWithoutEmail.length === 0 && toContactsFlagged.length === 0) return null;

  const toContactsWithoutEmailValues = toContactsWithoutEmail
    .map((contact) => fullName({ firstName: contact.firstName, lastName: contact.lastName }))
    .join(', ');
  const toContactsFlaggedValues = toContactsFlagged
    .map((contact) => fullName({ firstName: contact.firstName, lastName: contact.lastName }))
    .join(', ');

  return (
    <EVAlert
      sx={{
        marginBottom: 1,
      }}
      severity="warning"
    >
      <Stack direction="row" spacing={1}>
        <Stack direction="column" spacing={1}>
          {toContactsWithoutEmail.length > 0 && (
            <Typography variant="body3">
              {t('communication:emailForm.contactsRemoved', {
                contactsRemoved: toContactsWithoutEmailValues,
              })}
            </Typography>
          )}
          {toContactsFlagged.length > 0 && (
            <Typography variant="body3">
              {t('communication:emailForm.contactsFlagged', { contactsFlagged: toContactsFlaggedValues })}
            </Typography>
          )}
        </Stack>
      </Stack>
    </EVAlert>
  );
};
