import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

export const emailTemplatesQuery = graphql(/* GraphQL */ `
  query EmailTemplatesQuery($order_by: [DbEmailTemplateOrderBy!], $where: DbEmailTemplateBoolExp) {
    dbEmailTemplate(orderBy: $order_by, where: $where) {
      ...EmailTemplate
    }
  }
`);

export const useEmailTemplates = (
  variables: VariablesOf<typeof emailTemplatesQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: emailTemplates, ...rest } = useQuery({
    queryKey: ['email', 'useEmailTemplates', variables],
    queryFn: async () => (await graphqlClient.request(emailTemplatesQuery, variables)).dbEmailTemplate,
    ...options,
  });
  return { emailTemplates, ...rest };
};
