import Typography from '@mui/material/Typography';
import { PropsWithChildren } from 'react';

export function SectionHeaderText({ children }: PropsWithChildren) {
  return (
    <Typography variant="subtitle1" fontWeight="bold" sx={{ textTransform: 'uppercase', letterSpacing: '0.04em' }}>
      {children}
    </Typography>
  );
}
