import { Box, SxProps, Typography } from '@mui/material';
import { useAgentsWithAllMemberships } from 'api/graphql/hooks/useAgentsWithAllMemberships';
import { EVChip, EVChipProps } from 'components/general/Chips/EVChip/EVChip';
import { EntityTooltip } from 'components/general/Chips/EntityTooltip/EntityTooltip';
import { EntityTooltipHeader } from 'components/general/Chips/EntityTooltip/EntityTooltipHeader';
import { EmailTooltipButton } from 'components/general/Chips/TooltipButton/EmailTooltipButton';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { LinkButton } from 'components/general/LinkButton';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { ROLE_TRANSLATION } from 'const/enumTranslations';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { MouseEventHandler, ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { EMPTY_DATA_STRING } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';
import { useDestination } from 'util/navigation/useDestination';
import { fullName } from 'util/stringUtils';
import { sxTruncate } from 'util/styleUtils';
import { usePermissions } from 'util/usePermissions';

type AgentTooltipChipProps = {
  agentId?: Maybe<string>;
  handleDelete?: () => void;
  isDefaultSystemUser?: boolean;
  children?: ReactElement;
  emptyDataString?: string;
  sx?: SxProps;
  compact?: boolean;
  chipProps?: EVChipProps;
};

export const AgentTooltipChip: React.FC<AgentTooltipChipProps> = ({
  agentId,
  handleDelete,
  isDefaultSystemUser,
  children,
  emptyDataString = EMPTY_DATA_STRING,
  sx,
  compact,
  chipProps,
}) => {
  const { ref, inView } = useInView({ triggerOnce: true });
  const { activeShop } = useActiveShop();
  const { t } = useTranslation(['enums']);
  const { canPreviewAgentProfile } = usePermissions();

  const { agentsWithMembership: agents } = useAgentsWithAllMemberships(
    { where: { id: { _eq: agentId } } },
    { enabled: !!agentId && inView },
  );

  const { toAgentProfile } = useDestination();

  function getContent() {
    const agent = agents?.[0];
    if (!(agentId && agent) && !isDefaultSystemUser) {
      return (
        <Typography variant="body3" color={(theme) => theme.palette.text.secondary}>
          {emptyDataString}
        </Typography>
      );
    }

    const agentPerson = isDefaultSystemUser
      ? { firstName: 'System User' }
      : { firstName: agent?.firstName, lastName: agent?.lastName };
    const label = fullName(agentPerson);

    return (
      <EntityTooltip
        header={
          <EntityTooltipHeader
            label={
              canPreviewAgentProfile(activeShop) && agentId ? (
                <LinkButton to={toAgentProfile({ agentId })}>{label}</LinkButton>
              ) : (
                label
              )
            }
            avatar={<EVAvatar size="l" person={agent} />}
            subLabel={
              <>
                <Box sx={sxTruncate}>{agent?.email}</Box>
                <Box sx={sxTruncate}>{agent?.mobilePhoneNumber}</Box>
                <Box sx={sxTruncate}>{agent?.officePhoneNumber}</Box>
              </>
            }
          />
        }
        content={
          <>
            {agent?.userShopAssignments.map((assignment) => (
              <Box key={assignment.shop.id}>{assignment.shop.shopName}</Box>
            ))}
            {agent?.userTeamAssignments.map((assignment) => (
              <Box key={assignment.team.id}>
                {assignment.team.name} | {t(ROLE_TRANSLATION[assignment.role])}
              </Box>
            ))}
          </>
        }
        buttons={!!agentId && <EmailTooltipButton to={[{ agentId }]} />}
      >
        {children ??
          (!!agentId && (
            <Link to={toAgentProfile({ agentId })}>
              {compact ? (
                <EVAvatar person={agent} />
              ) : (
                <EVChip
                  avatar={<EVAvatar size="s" person={agent} />}
                  onDelete={handleDelete}
                  label={label}
                  {...chipProps}
                />
              )}
            </Link>
          ))}
      </EntityTooltip>
    );
  }

  const handleClick: MouseEventHandler = (event) => {
    event.stopPropagation();
  };

  return (
    <Box ref={ref} sx={sx} onClick={handleClick}>
      {getContent()}
    </Box>
  );
};
