import Dialog, { DialogProps } from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import RejectedIcon from 'components/icons/cross_naked.svg?react';
import { PropsWithChildren } from 'react';
import { theme } from 'theme';

export function EVDialog({
  children,
  onClose,
  maxWidth = 'tablet',
  ...props
}: Omit<DialogProps, 'onClose'> & { onClose: () => void }) {
  return (
    <Dialog
      onClose={onClose}
      {...props}
      maxWidth={maxWidth}
      fullWidth={true}
      PaperProps={{
        sx: {
          [theme.breakpoints.up('tablet')]: {
            padding: '40px 64px 56px',
          },
          [theme.breakpoints.down('tablet')]: {
            padding: '20px 32px 28px',
          },
        },
      }}
    >
      <RejectedIcon
        onClick={onClose}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: '18px',
          right: '18px',
        }}
      />
      {children}
    </Dialog>
  );
}

export function DialogSubtitle({ children }: PropsWithChildren) {
  return (
    <Typography variant="body3" component="div" color={theme.palette.text.secondary} sx={{ marginTop: 4 }}>
      {children}
    </Typography>
  );
}
