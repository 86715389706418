import { Call } from '@twilio/voice-sdk';
import { CallOutcomePage } from 'components/calls/ActiveCallPopover/CallOutcomePage/CallOutcomePage';
import { CallPopoverOverviewPage } from 'components/calls/ActiveCallPopover/CallPopoverOverviewPage';
import { LinkContactToCallPage } from 'components/calls/ActiveCallPopover/LinkContactToCallPage';
import { LinkLeadToCallPage } from 'components/calls/ActiveCallPopover/LinkLeadToCallPage';
import { CallerInfo } from 'components/state/Twilio';
import { useEffect, useRef, useState } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { useInterval } from 'util/useInterval';

export interface ActiveCallPopoverProps {
  callerInfo: CallerInfo;
  onEndCall: () => void;
  onMuteCall: () => void;
  onClose: () => void;
}

export type ActiveCallPage = 'overview' | 'outcome' | 'contact' | 'lead';

export function ActiveCallPopover({ onClose, callerInfo, onEndCall, onMuteCall }: ActiveCallPopoverProps) {
  const [page, setPage] = useState<ActiveCallPage>('overview');

  const { t } = useTranslation(['communication']);
  const isActive = callerInfo.call.status === Call.State.Open;
  const callDuration = useTimer(callerInfo.call.status === Call.State.Open);
  const headerTitle = isActive ? t('communication:call.activeCallTitle') : t('communication:call.endedCallTitle');
  const headerColor = isActive ? theme.palette.special.green1 : theme.palette.shade.grey1;

  switch (page) {
    case 'overview':
      return (
        <CallPopoverOverviewPage
          onClose={onClose}
          callerInfo={callerInfo}
          onEndCall={onEndCall}
          onMuteCall={onMuteCall}
          onPageChange={setPage}
          callDuration={callDuration}
          headerTitle={headerTitle}
          headerColor={headerColor}
        />
      );
    case 'outcome':
      return callerInfo.activity ? (
        <CallOutcomePage
          onClose={onClose}
          callStatus={callerInfo.call.status}
          headerTitle={headerTitle}
          headerColor={headerColor}
          onPageChange={setPage}
          callDuration={callDuration}
          activity={callerInfo.activity}
        />
      ) : null;
    case 'contact':
      return (
        <LinkContactToCallPage
          onClose={onClose}
          callerInfo={callerInfo}
          headerTitle={headerTitle}
          headerColor={headerColor}
          onPageChange={setPage}
          callDuration={callDuration}
        />
      );
    case 'lead':
      return callerInfo.activity ? (
        <LinkLeadToCallPage
          onClose={onClose}
          activity={callerInfo.activity}
          headerTitle={headerTitle}
          headerColor={headerColor}
          onPageChange={setPage}
          callDuration={callDuration}
        />
      ) : null;
  }
}

function useTimer(isActive: boolean) {
  const [duration, setDuration] = useState(0);
  const startTime = useRef(Date.now());

  useEffect(() => {
    if (isActive) {
      setDuration(0);
      startTime.current = Date.now();
    }
  }, [isActive]);

  useInterval(
    () => {
      const seconds = Math.floor((Date.now() - startTime.current) / 1000);
      setDuration(seconds);
    },
    isActive ? 1000 : null,
  );
  return duration;
}
