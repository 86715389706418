import { RouteObject } from 'react-router-dom';

export function getPathPrefixes(routes: RouteObject[], parentPrefixes: string[] = []) {
  return routes.reduce((prefixes, route) => {
    if (route.path) {
      const prefix = route.path.split('/')[0]!;
      if (!prefixes.includes(prefix)) {
        prefixes.push(prefix);
      }
    } else if (route.children) {
      getPathPrefixes(route.children, prefixes);
    }
    return prefixes;
  }, parentPrefixes);
}

function pathOverlaps(path: string, pathPrefix: string) {
  return path === pathPrefix || path.startsWith(pathPrefix + '/');
}

export function filterOverlappingRoutes(routes: RouteObject[], excludedRoutePrefixes: string[], currentPath = '') {
  return routes.reduce((nonOverlappingRoutes, route) => {
    // skips root level index route
    if (currentPath || route.path !== undefined) {
      const routePath = route.path || '';
      const path = currentPath ? currentPath + '/' + routePath : routePath;
      if (!excludedRoutePrefixes.some((prefix) => pathOverlaps(path, prefix))) {
        const newRoute = route.children
          ? { ...route, children: filterOverlappingRoutes(route.children, excludedRoutePrefixes, path) }
          : route;

        if (path || newRoute.children?.length) {
          nonOverlappingRoutes.push(newRoute);
        }
      }
    }
    return nonOverlappingRoutes;
  }, [] as RouteObject[]);
}
