import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

export const fullLeadsQuery = graphql(/* GraphQL */ `
  query FullLeadsQuery($limit: Int, $offset: Int, $order_by: [DbLeadOrderBy!], $where: DbLeadBoolExp) {
    dbLead(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {
      ...Lead
    }
  }
`);

export const useFullLeads = (
  variables: VariablesOf<typeof fullLeadsQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: leads, ...rest } = useQuery({
    queryKey: ['lead', 'useFullLeads', variables],
    queryFn: async () => (await graphqlClient.request(fullLeadsQuery, variables)).dbLead,
    ...options,
  });

  return { leads, ...rest };
};
