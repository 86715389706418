import { Person } from 'api/rest/models/Common';
import { EVChip, EVChipProps } from 'components/general/Chips/EVChip/EVChip';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { memo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { EMPTY_DATA_STRING } from 'util/emptyDataUtils';
import { useDestination } from 'util/navigation/useDestination';
import { fullName } from 'util/stringUtils';

interface UserChipProps extends Omit<EVChipProps, 'label' | 'avatar'> {
  person?: Person;
}

export function UserChip({ person, ...chipProps }: UserChipProps) {
  if (!person) {
    return EMPTY_DATA_STRING;
  }
  return <EVChip {...chipProps} avatar={<EVAvatar person={person} size="s" />} label={fullName(person)} />;
}

export const ClickableUserChipComponent = ({
  person,
  contactId,
  linkProps,
}: {
  person: Person;
  contactId: string;
  linkProps?: Pick<Partial<LinkProps>, 'to'> & Omit<LinkProps, 'to'>;
}) => {
  const { toContactDetails } = useDestination();

  return (
    <Link
      to={toContactDetails({ contactId })}
      onClick={(event) => event.stopPropagation()}
      target="_blank"
      {...linkProps}
    >
      <UserChip person={person} />
    </Link>
  );
};

export const ClickableUserChip = memo(ClickableUserChipComponent);
