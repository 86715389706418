import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { InternalPreviewBulkEmailStepProps } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/PreviewBulkEmailStep';
import { PreviewBulkEmailTemplateForm } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/PreviewBulkEmailTemplateForm';
import { PreviewRecipients } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/PreviewRecipents';
import { SendBulkEmailDialogHeader } from 'components/emails/SendBulkEmailDialog/SendBulkEmailDialogHeader';
import { SendBulkEmailDesktopGrid } from 'components/emails/SendBulkEmailDialog/SendBulkEmailDialogLayout';
import {
  ModalContainer,
  ModalFooter,
  ModalFooterButton,
  ModalHeader,
  ModalTitle,
  ModalTitleAction,
} from 'components/general/Modal';
import ArrowLeftIcon from 'components/icons/arrow_2_left.svg?react';
import CrossIcon from 'components/icons/cross_naked.svg?react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { PreviewBulkEmailFormData } from 'util/schemas/sendBulkEmailSchema';

export function PreviewBulkEmailStepDesktop({
  possibleFromEmails,
  onClose,
  onSend,
  onBackToCompose,
  noEmailIntegration,
  selectedRecipient,
  onSelectRecipient,
  onRemoveRecipient,
  isSending,
  from,
  hidePrice,
  onChangeFrom,
}: InternalPreviewBulkEmailStepProps) {
  const { t } = useTranslation(['communication']);
  const { watch } = useFormContext<PreviewBulkEmailFormData>();

  const title = watch('isExposeSending')
    ? t('communication:sendBulkEmailDialog.titleExpose')
    : t('communication:sendBulkEmailDialog.title');

  return (
    <>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <ModalTitleAction onClick={onClose}>
          <CrossIcon />
        </ModalTitleAction>
      </ModalHeader>
      <SendBulkEmailDialogHeader
        from={from}
        onChangeFrom={onChangeFrom}
        noEmailIntegration={noEmailIntegration}
        possibleFromEmails={possibleFromEmails}
      />

      <ModalContainer singleColumn sx={{ opacity: noEmailIntegration ? 0.2 : undefined }}>
        <SendBulkEmailDesktopGrid>
          <PreviewRecipients
            disabled={noEmailIntegration}
            selectedRecipient={selectedRecipient}
            onSelectRecipient={onSelectRecipient}
            onRemoveRecipient={onRemoveRecipient}
          />
          <PreviewBulkEmailTemplateForm
            selectedRecipient={selectedRecipient}
            disabled={noEmailIntegration}
            hidePrice={hidePrice}
          />
        </SendBulkEmailDesktopGrid>
      </ModalContainer>
      <ModalFooter>
        <Button
          sx={{ marginRight: 'auto' }}
          startIcon={<ArrowLeftIcon />}
          onClick={onBackToCompose}
          color="secondary"
          disabled={isSending}
        >
          {t('communication:sendBulkEmailDialog.backToComposeButton')}
        </Button>
        <ModalFooterButton
          variant="contained"
          onClick={onSend}
          disabled={noEmailIntegration || isSending}
          color="primary"
        >
          {t('communication:sendBulkEmailDialog.sendButton', { number: watch('recipients').length })}
          {isSending && <CircularProgress size={14} sx={{ marginLeft: 1, color: 'inherit' }} />}
        </ModalFooterButton>
      </ModalFooter>
    </>
  );
}
