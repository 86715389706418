import { SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Person } from 'api/rest/models/Common';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { theme } from 'theme';
import { fullName } from 'util/stringUtils';

export function PersonWithAvatar({ size = 's', person, sx }: { size?: 's' | 'm'; person: Person; sx?: SxProps }) {
  return (
    <Stack direction="row" gap={1.5} alignItems="center" sx={{ overflow: 'hidden' }}>
      <EVAvatar person={person} size={size} />
      <Typography
        variant="body3"
        color={theme.palette.text.primary}
        component="div"
        data-testid="personName"
        sx={size === 's' ? { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', ...sx } : sx}
      >
        {fullName(person)}
      </Typography>
    </Stack>
  );
}
