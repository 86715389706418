export interface AssignmentProps {
  shopId: string;
  agentId?: string | undefined;
  teamId?: string | undefined;
}

export function newLeadAssignmentMapper(data: AssignmentProps) {
  return {
    shopId: data.shopId,
    agentId: data.agentId || undefined,
    teamId: data.teamId || undefined,
  };
}
