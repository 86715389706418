declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}

const fontWeightRegular = 400;
const fontWeightMedium = 500;
const fontWeightBold = 700;

const fontFamilyHead = ['EngelVoelkersHead', 'sans-serif'].join(', ');
const fontFamilyText = ['EngelVoelkersText', 'sans-serif'].join(', ');

export const typography = {
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,
  fontFamily: fontFamilyText,
  h1: {
    fontFamily: fontFamilyHead,
    fontSize: '24px',
    lineHeight: '1.5em',
  },
  h2: {
    fontSize: '20px',
    lineHeight: '1.5em',
  },
  h3: {
    fontSize: '16px',
    lineHeight: '1.5em',
  },
  subtitle1: {
    fontSize: '11px',
  },
  subtitle2: {
    fontSize: '11px',
  },
  body1: {
    fontSize: '20px',
  },
  body2: {
    fontSize: '16px',
  },
  body3: {
    fontSize: '14px',
  },
  body4: {
    fontSize: '12px',
  },
};
