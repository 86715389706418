import { useCallback, useEffect, useRef, useState } from 'react';

type TimerId = ReturnType<typeof setTimeout>;

const DEFAULT_DEBOUNCE_TIME = 500;

export function useDebounce(timeoutMS: number = DEFAULT_DEBOUNCE_TIME) {
  const timeoutId = useRef<TimerId | null>(null);

  return useCallback(
    (thisFN: () => void) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        thisFN();
      }, timeoutMS);
    },
    [timeoutId, timeoutMS],
  );
}

export function useDebouncedValue<T>(value: T, timeoutMS: number = DEFAULT_DEBOUNCE_TIME): T | undefined {
  const debounce = useDebounce(timeoutMS);
  const [debouncedValue, setDebouncedValue] = useState<T | undefined>();

  useEffect(() => {
    debounce(() => setDebouncedValue(value));
  }, [value, debounce]);

  return debouncedValue;
}

export function useSearchText(minSearchTermLength = 2) {
  const [rawSearchText, setSearchText] = useState('');
  const debouncedSearchText = useDebouncedValue(rawSearchText)?.trim();
  // Don't start searching with a single character
  const searchText =
    debouncedSearchText && debouncedSearchText.length >= minSearchTermLength ? debouncedSearchText : '';

  return { searchText, rawSearchText, setSearchText };
}
