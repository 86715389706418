import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { decodeBase64Url } from 'util/queryParams/encoding';
import sentry from 'util/sentry';
import { ZodType, z } from 'zod';

function parseParams<T extends ZodType>(data: string, schema: T) {
  try {
    const params = JSON.parse(decodeBase64Url(data));
    return schema.parse(params);
  } catch (error) {
    sentry.logError(error as Error);
    return;
  }
}

export function useDrawerParams<T extends ZodType>({
  paramName,
  schema,
  onParams,
  enabled = true,
}: {
  paramName: string;
  schema: T;
  onParams: (params: z.infer<T>) => void;
  enabled?: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const data = searchParams.get(paramName);

  useEffect(() => {
    if (data && enabled) {
      const parsedParams = parseParams(data, schema);
      searchParams.delete(paramName);
      setSearchParams(searchParams, { replace: true });

      if (parsedParams) {
        onParams(parsedParams);
      }
    }
  }, [enabled, data, onParams, searchParams, setSearchParams, paramName, schema]);
}
