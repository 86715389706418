import { htmlToText } from 'html-to-text';
import { useMemo } from 'react';
import { useTranslation } from 'util/i18next';
import type { RefinementCtx } from 'zod';
import { z } from 'zod';

type ErrMessage =
  | string
  | {
      message?: string;
    };

function preprocessOptional(arg: unknown) {
  if (typeof arg !== 'string') {
    return arg;
  }
  const trimmedVal = arg.trim();
  return trimmedVal === '' ? undefined : trimmedVal;
}

function preprocessRichText(arg: unknown) {
  if (typeof arg !== 'string' || !arg) {
    return arg;
  }
  const plainText = htmlToText(arg).trim();
  return plainText === '' ? '' : arg;
}

export function useSchemaPrimitives() {
  const { t } = useTranslation();
  return useMemo(() => {
    const uniqueInArray =
      <T, D>({ fieldName, arrayName, selector }: { fieldName: string; arrayName: string; selector: (item: T) => D }) =>
      (items: Array<T>, ctx: RefinementCtx) => {
        const uniqueValues = new Map<D, number>();

        items.forEach((item, idx) => {
          const firstAppearanceIndex = uniqueValues.get(selector(item));
          if (firstAppearanceIndex !== undefined) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('common:formValidation.uniqueInArray', { fieldName, arrayName }),
              path: [idx, 'name'],
            });

            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('common:formValidation.uniqueInArray', { fieldName, arrayName }),
              path: [firstAppearanceIndex, 'name'],
            });

            return;
          }

          uniqueValues.set(selector(item), idx);
        });
      };

    const requiredString = z
      .string({ required_error: t('formValidation.requiredField') })
      .trim()
      .min(1, { message: t('formValidation.requiredField') });

    const requiredHtmlString = z.string().refine((val) => htmlToText(val).trim().length, {
      message: t('formValidation.requiredField'),
    });

    return {
      requiredString,
      requiredEmail: requiredString.email({
        message: t('formValidation.invalidEmail'),
      }),
      uniqueInArray,
      optionalString: z.string().trim().optional(),
      optionalRichText: z.preprocess(preprocessRichText, z.string().trim().optional()),
      optionalRichTextMax: (maxLength: number, message?: ErrMessage) =>
        z.preprocess(preprocessRichText, z.string().trim().max(maxLength, message).optional()),
      optionalStringEmptyAsUndefined: z.preprocess(preprocessOptional, z.string().optional()),
      requiredNumber: z.preprocess(
        preprocessOptional,
        z.coerce.number({
          required_error: t('formValidation.requiredField'),
          invalid_type_error: t('formValidation.requiredNumber'),
        }),
      ),
      optionalNumber: z.preprocess(
        preprocessOptional,
        z.coerce.number({ invalid_type_error: t('formValidation.requiredNumber') }).optional(),
      ),
      optionalBoolean: z.boolean().optional(),
      requiredBoolean: z.boolean({
        required_error: t('formValidation.requiredField'),
      }),
      optionalUrl: z.preprocess(
        preprocessOptional,
        z
          .string()
          .regex(/^(https?:\/\/(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(\.[a-zA-Z]{2,})*(\/[^\s]*)?$/, {
            message: t('formValidation.invalidUrl'),
          })
          .url()
          .optional(),
      ),
      optionalHTTPSUrl: z.preprocess(
        preprocessOptional,
        z
          .string()
          .regex(/^(https:\/\/(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(\.[a-zA-Z]{2,})*(\/[^\s]*)?$/, {
            message: t('formValidation.invalidUrl'),
          })
          .url()
          .optional(),
      ),
      requiredHtmlString,
    };
  }, [t]);
}
