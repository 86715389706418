import { Box, Stack, SxProps, Typography } from '@mui/material';
import { EvaImage } from 'components/general/EvaImage/EvaImage';
import { Tag } from 'components/general/Tag/Tag';
import { propertyEngineListingStatusTranslation } from 'const/enumTranslations';
import { useTranslation } from 'util/i18next';

import { LanguageEnum } from 'api/graphql/generated/graphql';
import { ListingWithLegacyFields } from 'util/go3';
import { getMarketingDetails } from 'util/propertyUtils';
import { useFormatNumber } from 'util/useFormatNumber';

export type HidePrice = 'NEVER' | 'ALWAYS' | 'PE_SETTINGS';

export function usePropertyDetails(property: ListingWithLegacyFields, hidePrice?: HidePrice) {
  const { t } = useTranslation(['communication']);
  const { formatPrice } = useFormatNumber();

  const details = [property.utag];
  const asset = property.asset;

  const showPrice =
    hidePrice === 'NEVER' ||
    (hidePrice === 'PE_SETTINGS' &&
      !property.internetProfile.priceOnRequest &&
      property.internetProfile.portals?.find((portal) => portal.includeInExport));

  if (asset && 'livingArea' in asset && asset.livingArea) {
    details.push(`${asset.livingArea as string} ${t('communication:emailForm.properties.livingarea')}`);
  }
  if (asset && 'numberBedrooms' in asset && asset.numberBedrooms) {
    details.push(`${asset.numberBedrooms as string} ${t('communication:emailForm.properties.room')}`);
  }
  if (showPrice && (property.askingPrice || property.monthlyNetRent)) {
    details.push(formatPrice(property.askingPrice || property.monthlyNetRent, { currency: property.currency }));
  }
  return details.join('; ');
}

export function PropertyItem({
  property,
  hideStatus,
  sx,
  showMarketingTitleFor,
  hidePrice = 'NEVER',
  size = 56,
}: {
  property: ListingWithLegacyFields;
  hideStatus?: boolean;
  showMarketingTitleFor?: LanguageEnum; // Only supported for lvl 2 shops
  size?: number;
  sx?: SxProps;
  hidePrice?: HidePrice;
}) {
  const { t } = useTranslation(['enums']);

  const propertyDetails = usePropertyDetails(property, hidePrice);
  const title = showMarketingTitleFor ? getMarketingDetails(property, showMarketingTitleFor)?.title : property.fileAs;

  return (
    <Stack direction="row" alignItems="center" gap={1} sx={sx}>
      <EvaImage
        src={property.previewImage}
        alt="property_photo"
        width={size}
        height={size}
        style={{ borderRadius: '2px', objectFit: 'cover' }}
      />
      <Box sx={{ minWidth: 0, flex: 1 }}>
        <Typography
          variant="body3"
          component="div"
          sx={{ overflow: 'hidden', whiteSpace: 'noWrap', textOverflow: 'ellipsis' }}
        >
          {title}
        </Typography>
        {propertyDetails && (
          <Typography variant="body4" color="text.secondary">
            {propertyDetails}
          </Typography>
        )}
      </Box>
      {property.status && !hideStatus && (
        <Tag color={property.status === 'ACTIVE' ? 'green' : 'red'}>
          {t(propertyEngineListingStatusTranslation[property.status])}
        </Tag>
      )}
    </Stack>
  );
}
