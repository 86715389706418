import { ActionsModal } from 'components/general/ActionsModal/ActionsModal';
import { useTranslation } from 'util/i18next';

export const ModalConfirmation = ({
  onConfirm,
  title,
  onCancel,
  isOpen,
}: {
  onConfirm: () => void;
  title: string;
  onCancel: () => void;
  isOpen: boolean;
}) => {
  const { t } = useTranslation();
  const confirmAction = {
    message: t('ok'),
    variant: 'contained',
    color: 'primary',
    handleClick: onConfirm,
  } as const;
  const cancelAction = {
    message: t('cancel'),
    variant: 'outlined',
    color: 'secondary',
    handleClick: onCancel,
  } as const;
  return <ActionsModal open={isOpen} handleClose={onCancel} title={title} actions={[cancelAction, confirmAction]} />;
};
