import { CircularProgress, Stack, SxProps } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PrimaryAction } from 'components/general/EVDrawer/EVDrawerContent';
import ArrowLeftIcon from 'components/icons/arrow_1_left.svg?react';
import CrossNaked from 'components/icons/cross_naked.svg?react';
import { ReactNode } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

interface DrawerHeaderProps {
  sx?: SxProps;
  onClose: () => void;
  title: ReactNode | string;
  primaryAction?: PrimaryAction;
  isLoading: boolean;
  hasBackButton?: boolean;
}

const Header = styled('header')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'minMax(max-content, 1fr) auto minMax(max-content, 1fr)',
  width: '100%',
  backgroundColor: theme.palette.backgroundExtension.white,
  borderBottom: `1px solid ${theme.palette.shade.grey4}`,
  padding: theme.spacing(1, 3),
  alignItems: 'center',
  [theme.breakpoints.up('tablet')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

export const EVDrawerHeader = ({ sx, onClose, title, primaryAction, isLoading, hasBackButton }: DrawerHeaderProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const { t } = useTranslation();

  return (
    <Header sx={sx}>
      {isMobile &&
        (isLoading ? (
          <CircularProgress size={20} sx={{ margin: '10px 0' }} />
        ) : (
          <IconButton onClick={onClose} edge="start" sx={{ justifySelf: 'start' }}>
            {hasBackButton ? <ArrowLeftIcon aria-label={t('close')} /> : <CrossNaked aria-label={t('close')} />}
          </IconButton>
        ))}
      <Stack direction="row" gap={1} alignItems={'center'}>
        {hasBackButton && !isMobile ? (
          <IconButton onClick={onClose} edge="start" sx={{ justifySelf: 'start' }}>
            <ArrowLeftIcon />
          </IconButton>
        ) : null}
        <Typography
          component={'div'}
          sx={{
            typography: { mobile: 'body3', tablet: 'body2' },
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            justifySelf: 'start',
          }}
        >
          {title}
        </Typography>
      </Stack>
      {isMobile
        ? primaryAction && (
            <Button
              disabled={primaryAction.disabled || isLoading}
              color="primary"
              variant="contained"
              type="submit"
              sx={{ whiteSpace: 'nowrap', justifySelf: 'end', marginLeft: 1 }}
              onClick={primaryAction.callback}
            >
              {primaryAction.primaryButtonLabel}
            </Button>
          )
        : !hasBackButton && (
            <IconButton onClick={onClose} sx={{ justifySelf: 'end' }} edge="end">
              <CrossNaked aria-label={t('close')} />
            </IconButton>
          )}
    </Header>
  );
};
