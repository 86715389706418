import { CallActionButton, CallActions } from 'components/calls/CallActions';
import { CallFrame } from 'components/calls/CallFrame';
import { CallInfo } from 'components/calls/CallInfo';
import EndCallIcon from 'components/icons/end_call.svg?react';
import MicOffIcon from 'components/icons/microphone_crossed.svg?react';
import PauseIcon from 'components/icons/pause.svg?react';
import { CallerInfo } from 'components/state/Twilio';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

interface IncomingCallScreenProps {
  callerInfo: CallerInfo;
  onEndCall: () => void;
  onClose: () => void;
}

export function OutgoingCallPopover({ onClose, callerInfo, onEndCall }: IncomingCallScreenProps) {
  const { t } = useTranslation(['contact', 'communication']);
  return (
    <CallFrame
      onClose={onClose}
      title={t('communication:call.outgoingCallTitle')}
      headerColor={theme.palette.shade.grey1}
      variant="dark"
      actions={
        <CallActions>
          <CallActionButton disabled color={theme.palette.primary.main} icon={<PauseIcon />}>
            {t('communication:call.actionButton.pause')}
          </CallActionButton>
          <CallActionButton color={theme.palette.primary.main} icon={<EndCallIcon />} onClick={onEndCall}>
            {t('communication:callActions.cancelCall')}
          </CallActionButton>
          <CallActionButton color={theme.palette.primary.main} icon={<MicOffIcon />} disabled>
            {t('contact:callActions.mute')}
          </CallActionButton>
        </CallActions>
      }
    >
      <CallInfo
        isActive={true}
        title={t('communication:call.outgoing.title')}
        sx={{ marginY: 'auto' }}
        callerInfo={callerInfo}
      />
    </CallFrame>
  );
}
