import { Address, CountryEnum } from 'api/graphql/generated/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { EMPTY_DATA_STRING } from 'util/emptyDataUtils';
import { translateCountryEnum } from 'util/i18n/translateCountryEnum';
import { TFunction } from 'util/i18next';

export interface AddressLike {
  city?: string | null;
  postalCode?: string | null;
  streetNumber?: string | null;
  streetName?: string | null;
  secondAddressLine?: string | null;
  countryCode?: string | null;
  placeId?: string | null;
  latitude?: number | null;
  longitude?: number | null;
}

export const addressToMultilineString =
  (t: TFunction<['enums']>) =>
  (address: Maybe<AddressLike>, options?: { omitCountry?: boolean }): string => {
    if (!address) {
      return EMPTY_DATA_STRING;
    }

    let addressString = '';

    if (address?.streetName || address?.streetNumber) {
      addressString += `${address?.streetName || ''} ${address?.streetNumber || ''}`.trim() + '\n';
    }

    if (address?.secondAddressLine) {
      addressString += address?.secondAddressLine;
      addressString += '\n';
    }

    if (address?.city || address?.postalCode) {
      addressString += `${address?.postalCode || ''} ${address?.city || ''}`.trim() + '\n';
    }

    if (!options?.omitCountry && address?.countryCode && address?.countryCode !== 'UNDEFINED') {
      const country = translateCountryEnum(t, address?.countryCode as CountryEnum);
      addressString += `${country}\n`;
    }

    return addressString || EMPTY_DATA_STRING;
  };

export const addressToSingleLineString =
  (t?: TFunction<['enums']>) =>
  (address: Maybe<AddressLike>, emptyDataString: string = EMPTY_DATA_STRING): string => {
    if (!address) {
      return emptyDataString ?? EMPTY_DATA_STRING;
    }

    const countryCode = address?.countryCode !== 'UNDEFINED' ? address?.countryCode : undefined;
    return (
      [
        [address?.streetName, address?.streetNumber].filter(Boolean).join(' '),
        [address?.postalCode, address?.city].filter(Boolean).join(' '),
        countryCode && t ? translateCountryEnum(t, countryCode as CountryEnum) : countryCode,
      ]
        .filter(Boolean)
        .join(', ') || emptyDataString
    );
  };

export const addressToCountryString =
  (t?: TFunction<['enums']>) => (address?: Maybe<Address>, emptyDataString?: string) => {
    const countryCode = address?.countryCode !== 'UNDEFINED' ? address?.countryCode : undefined;
    return countryCode && t ? translateCountryEnum(t, countryCode as CountryEnum) : countryCode || emptyDataString;
  };

export const addressToCityCountryString = (t?: TFunction<['enums']>) => (address?: Maybe<Address>) => {
  return [address?.city, addressToCountryString(t)(address)].filter(Boolean).join(', ') || EMPTY_DATA_STRING;
};

export const addressToStreetString = (address?: AddressLike | null, fallback?: Maybe<string>) => {
  return [address?.streetName, address?.streetNumber].filter(Boolean).join(' ') || fallback || EMPTY_DATA_STRING;
};
