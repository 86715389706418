import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PostRequestBody, PostResponseData, fetchClient } from 'api/rest/fetch-client';

export type CreateSellerRenterLeadRequest = PostRequestBody<'/api/v1/leads/seller-renter-leads'>;
export type CreateSellerRenterLeadResponse = PostResponseData<'/api/v1/leads/seller-renter-leads'>;

export const useCreateNewSellerLead = () => {
  const queryClient = useQueryClient();
  const { mutate: createNewSellerLead, ...rest } = useMutation(
    (requestBody: CreateSellerRenterLeadRequest) =>
      fetchClient
        .POST('/api/v1/leads/seller-renter-leads', {
          body: requestBody,
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { createNewSellerLead, ...rest };
};
