import { ShareExposeDrawerData, useSnackBars } from '@ev/eva-container-api';
import { zodResolver } from '@hookform/resolvers/zod';
import { useShareExpose } from 'api/rest';
import { ShareExposeForm } from 'components/dashboard/ShareExposeForm';
import { EVDrawerContent } from 'components/general/EVDrawer/EVDrawerContent';
import { useActiveShopId } from 'components/state/ActiveShopProvider';
import { separateContactsAndLeads } from 'page-components/dashboard/separateContactsAndLeads';
import { FormProvider, useForm } from 'react-hook-form';
import { getShareExposeDefaultValues } from 'util/defaultValues/shareExposeDefaultValues';
import { useTranslation } from 'util/i18next';
import { useShareExposeSchema } from 'util/schemas/shareExposeSchema';
import { useErrorSnackBar } from 'util/useErrorSnackBar';
import { z } from 'zod';

export interface ShareExposeProps {
  onClose: () => void;
  initialShareExposeData: ShareExposeDrawerData;
}

export function ShareExpose({ onClose, initialShareExposeData }: ShareExposeProps) {
  const { t } = useTranslation(['user']);
  const { shareExpose, isLoading } = useShareExpose();
  const { openSnackBar } = useSnackBars();
  const { openErrorSnackBar } = useErrorSnackBar();
  const { activeShopId } = useActiveShopId();

  const shareExposeSchema = useShareExposeSchema();
  const methods = useForm<z.infer<typeof shareExposeSchema>>({
    mode: 'onTouched',
    resolver: zodResolver(shareExposeSchema),
    defaultValues: () =>
      getShareExposeDefaultValues({
        initialShareExposeData,
        defaultTitle: t('user:dashboard.shareExpose.title.default'),
      }),
  });

  const drawerTitle = (() => {
    return t('user:dashboard.shareExpose.title');
  })();

  const onSave = (data: z.infer<typeof shareExposeSchema>): void => {
    const payload = mapShareExposeData(data);
    shareExpose(
      { ...payload, shopId: activeShopId! },
      {
        onSuccess: () => {
          openSnackBar(t('user:dashboard.shareExpose.success'), 'success');
          onClose();
        },
        onError: (error) => openErrorSnackBar(t('user:dashboard.shareExpose.error'), error),
      },
    );
  };

  if (methods.formState.isLoading) {
    return null;
  }

  return (
    <EVDrawerContent
      title={drawerTitle}
      onClose={onClose}
      primaryAction={{
        primaryButtonLabel: t('user:dashboard.drawer.addActivity.saveButton'),
        callback: methods.handleSubmit(onSave),
      }}
      isLoading={isLoading}
    >
      <FormProvider {...methods}>
        <ShareExposeForm />
      </FormProvider>
    </EVDrawerContent>
  );
}

export const mapShareExposeData = (data: z.infer<ReturnType<typeof useShareExposeSchema>>) => {
  const contactsAndLeads = separateContactsAndLeads(data.contactsAndLeads);

  return {
    title: data.title,
    propertyId: data.properties?.[0]?.utag ?? '',
    contactIds: contactsAndLeads.contactIds,
    leadIds: contactsAndLeads.leadIds,
    comment: data.comment,
  };
};
