import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';
import { IterableElement } from 'type-fest';

export const contactAndLeadsQuery = graphql(/* GraphQL */ `
  query ContactAndLeads($id: String) {
    dbContact(where: { id: { _eq: $id } }) {
      ...ContactAndLeads
      lastInteractionActivity {
        ...LastInteraction
      }
      ...ContactLastActivity
    }
  }
`);

export const useContactAndLeads = (
  variables: VariablesOf<typeof contactAndLeadsQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: contact, ...rest } = useQuery({
    queryKey: ['contact', 'useContactAndLeads', variables],
    queryFn: async () => {
      const result = await graphqlClient.request(contactAndLeadsQuery, variables);
      const dbContact = result.dbContact?.[0];
      return dbContact
        ? {
            ...dbContact,
            emailAddresses: dbContact.emailAddresses
              ?.slice()
              .sort((a, b) => (a.preferred === b.preferred ? 0 : a.preferred ? -1 : 1)),
          }
        : null;
    },
    ...options,
  });

  return { contact, ...rest };
};

export type ContactWithLastInteraction = NonNullable<ReturnType<typeof useContactAndLeads>['contact']>;

export const contactQuery = graphql(/* GraphQL */ `
  query Contact($id: String) {
    dbContact(where: { id: { _eq: $id } }) {
      ...Contact
    }
  }
`);

export const useContact = (
  variables: VariablesOf<typeof contactQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: contact, ...rest } = useQuery({
    queryKey: ['contact', 'useContact', variables],
    queryFn: async () => (await graphqlClient.request(contactQuery, variables)).dbContact?.[0],
    ...options,
  });

  return { contact, ...rest };
};

export const contactsAndLeadsQuery = graphql(/* GraphQL */ `
  query ContactsAndLeads($limit: Int, $offset: Int, $order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {
    dbContact(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {
      ...ContactAndLeads
    }
  }
`);

export const useContactsAndLeads = (
  variables: VariablesOf<typeof contactsAndLeadsQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'keepPreviousData'>,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['contact', 'useContactsAndLeads', variables],
    queryFn: async () => graphqlClient.request(contactsAndLeadsQuery, variables),
    ...options,
  });

  return {
    contacts: data?.dbContact,
    ...rest,
  };
};

export const contactsQuery = graphql(/* GraphQL */ `
  query Contacts($limit: Int, $offset: Int, $order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {
    dbContact(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {
      ...Contact
    }
  }
`);

export const useContacts = (
  variables: VariablesOf<typeof contactsQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['contact', 'useContacts', variables],
    queryFn: async () => graphqlClient.request(contactsQuery, variables),
    ...options,
  });

  return {
    contacts: data?.dbContact,
    ...rest,
  };
};

export const contactsListIdsQuery = graphql(/* GraphQL */ `
  query ContactIds($limit: Int, $offset: Int, $order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {
    dbContact(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {
      id
    }
  }
`);

export const useContactsListIds = (
  variables: VariablesOf<typeof contactsListIdsQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'staleTime'>,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['contact', 'useContactIds', variables],
    queryFn: async () => graphqlClient.request(contactsListIdsQuery, variables),
    ...options,
  });

  return {
    contactIds: data?.dbContact?.map(({ id }) => id),
    ...rest,
  };
};

export const contactsListQuery = graphql(/* GraphQL */ `
  query ContactsList($order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {
    dbContact(orderBy: $order_by, where: $where) {
      ...ContactListEntry
      lastInteractionActivity {
        ...LastInteraction
      }
    }
  }
`);

export const useContactsList = (
  variables: VariablesOf<typeof contactsListQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'staleTime'>,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['contact', 'useContactsList', variables],
    queryFn: async () => graphqlClient.request(contactsListQuery, variables),
    ...options,
  });

  return {
    contacts: data?.dbContact,
    ...rest,
  };
};

export type ContactListItemWithLastInteraction = NonNullable<ReturnType<typeof useContactsList>['contacts']>[0];

export const contactsListCountQuery = graphql(/* GraphQL */ `
  query ContactsListCount($where: DbContactBoolExp, $limit: Int) {
    dbContactAggregate(where: $where, limit: $limit) {
      aggregate {
        totalCount: count
      }
    }
  }
`);

export const useContactsListCount = (
  variables: VariablesOf<typeof contactsListCountQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'staleTime'>,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['contact', 'useContactsListCount', variables],
    queryFn: async () => graphqlClient.request(contactsListCountQuery, variables),
    ...options,
  });

  return {
    totalCount: data?.dbContactAggregate?.aggregate?.totalCount,
    ...rest,
  };
};

export const publicContactQuery = graphql(/* GraphQL */ `
  query publicContactQuery(
    $limit: Int
    $offset: Int
    $order_by: [DbContactDetailsOrderBy!]
    $where: DbContactDetailsBoolExp
  ) {
    dbContactDetails(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {
      firstName
      lastName
      id
      contact {
        ...ContactInfo
      }
    }
  }
`);

export type PublicContact = NonNullable<ReturnType<typeof usePublicContacts>['publicContact']>;

export const usePublicContacts = (
  variables: VariablesOf<typeof publicContactQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'staleTime'>,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['contact', 'usePublicContacts', variables],
    queryFn: async () => graphqlClient.request(publicContactQuery, variables),
    ...options,
  });

  return {
    publicContact: data?.dbContactDetails?.[0],
    ...rest,
  };
};

export const contactInfosQuery = graphql(/* GraphQL */ `
  query contactInfosQuery($limit: Int, $offset: Int, $order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {
    dbContact(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {
      ...ContactInfo
    }
  }
`);

export const useContactInfos = (
  variables: VariablesOf<typeof contactInfosQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['contact', 'useContactInfos', variables],
    queryFn: async () => graphqlClient.request(contactInfosQuery, variables),
    ...options,
  });

  return {
    contacts: data?.dbContact,
    ...rest,
  };
};

export const contactRelationshipsQuery = graphql(/* GraphQL */ `
  query contactInfoWithRelationshipsQuery($contactId: String, $where: DbContactBoolExp) {
    dbContact(where: { id: { _eq: $contactId } }) {
      contactRelationships(where: { related_contact: $where }) {
        type
        relatedContact: related_contact {
          ...ContactInfo
        }
      }
    }
  }
`);

export const useContactRelationships = (
  variables: VariablesOf<typeof contactRelationshipsQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['contact', 'useContactInfoWithRelationships', variables],
    queryFn: async () => graphqlClient.request(contactRelationshipsQuery, variables),
    ...options,
  });

  return {
    contactRelationships: data?.dbContact?.[0]?.contactRelationships,
    ...rest,
  };
};

export type Relationship = IterableElement<ReturnType<typeof useContactRelationships>['contactRelationships']>;
