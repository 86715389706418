import { Box, Button, useMediaQuery } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import DrawIcon from 'components/icons/signature.svg?react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

const PolygonEditToggle = ({ enableEditMode }: { enableEditMode: () => void }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        bottom: theme.spacing(isMobile ? 5 : 2),
        zIndex: zIndex.drawer - 1,
      }}
    >
      <Button
        variant="outlined"
        color="secondary"
        sx={{
          backgroundColor: theme.palette.backgroundExtension.white,
          marginLeft: 1,
          marginBottom: 2,
          padding: 1,
          pointerEvents: 'all',
          ':hover': {
            backgroundColor: theme.palette.backgroundExtension.white,
          },
        }}
        startIcon={<DrawIcon />}
        onClick={() => {
          enableEditMode();
        }}
      >
        {t('map.control.draw')}
      </Button>
    </Box>
  );
};

export default PolygonEditToggle;
