import { PlaceholderImage } from 'components/general/EvaImage/PlaceholderImage';
import { CSSProperties } from 'react';
import sentry from 'util/sentry';

const UPLOAD_CARE_PROXY_URL = 'https://ac7a6012a4ecbd60b943.ucr.io/';
const MAX_SIZE = 3000;
const FILL_SIZES = [16, 32, 48, 64, 96, 128, 256, 384, 640, 750, 828, 1080, 1200, 1920, 2048];

interface PropertyEngineImage {
  imageUrl: string;
}

export interface EvaImageProps extends React.HTMLAttributes<HTMLElement> {
  src: string | PropertyEngineImage | null | undefined;
  alt?: string;
  sizes?: string;
  width?: number;
  height?: number;
  style?: CSSProperties;
  fill?: boolean | undefined;
  doNotUseUploadCare?: boolean;
}

function getUploadCareSrc(src: string | PropertyEngineImage, width: number, height?: number): string {
  const cappedWidth = Math.min(width, MAX_SIZE);
  const cappedHeight = height && Math.min(height, MAX_SIZE);

  const options = ['-/quality/smart/', '-/format/auto/'];
  if (cappedHeight) {
    options.push(`-/scale_crop/${cappedWidth}x${cappedHeight}/center/`);
  } else {
    options.push(`-/resize/${cappedWidth}x/`);
  }
  const optionPath = options.join('');

  if (typeof src === 'string') {
    return `${UPLOAD_CARE_PROXY_URL}${optionPath}${encodeURI(src)}`;
  } else {
    const imageUrl = new URL(src.imageUrl);
    imageUrl.pathname += `/${optionPath}`;
    return imageUrl.toString();
  }
}

function getUploadCareSrcSetForFill(src: string | PropertyEngineImage): string {
  return FILL_SIZES.map((width) => `${getUploadCareSrc(src, width)} ${width}w`).join(', ');
}

function getUploadCareSrcSetForFixedSize(src: string | PropertyEngineImage, width: number, height?: number): string {
  return `${getUploadCareSrc(src, width, height)} 1x, ${getUploadCareSrc(
    src,
    width * 2,
    height ? height * 2 : undefined,
  )} 2x`;
}

/**
 * More or less equivalent to https://nextjs.org/docs/app/building-your-application/optimizing/images
 * but uses upload care proxy for image transformations https://uploadcare.com/docs/delivery/proxy/.
 */
export function EvaImage({
  src,
  fill,
  style: styleOverride,
  width,
  height,
  alt,
  doNotUseUploadCare,
  ...passthroughProps
}: EvaImageProps) {
  const style = fill
    ? {
        position: 'absolute' as const,
        height: '100%',
        width: '100%',
        inset: '0px',
        ...styleOverride,
      }
    : styleOverride;

  if (!src) {
    return <PlaceholderImage {...passthroughProps} style={style} width={width} height={height} fill={fill} />;
  }

  if (typeof src !== 'string' && doNotUseUploadCare) {
    sentry.logError('ProxyImage used should not be used together with doNotUseUploadCare');
  }

  const isLocal = typeof src === 'string' && !src.startsWith('http');
  const shouldNotUseUploadCare = doNotUseUploadCare || isLocal;

  let imgSrc: string | undefined;
  let srcSet: string | undefined;

  if (shouldNotUseUploadCare) {
    imgSrc = typeof src === 'string' ? src : src.imageUrl;
  } else {
    srcSet = fill ? getUploadCareSrcSetForFill(src) : getUploadCareSrcSetForFixedSize(src, width || 750, height);
  }

  return (
    <img {...passthroughProps} src={imgSrc} srcSet={srcSet} alt={alt} style={style} width={width} height={height} />
  );
}
