import {
  AssetConditionEnum,
  AssetSubTypeEnum,
  AssetTypeEnum,
  AssetVariationEnum,
  CountryEnum,
  LeadForAddingProperty,
  PropertyEngineListingType,
  TimeFrameEnum,
} from 'api/graphql/generated/graphql';
import { CreateSellerRenterLeadRequest } from 'api/rest/hooks/useCreateLead';
import { Maybe } from 'graphql/jsutils/Maybe';
import { AddressWithTypeFormData } from 'util/schemas/addressSchema';
import { PropertyFormData } from 'util/schemas/propertyDetailsSchema';

export function propertyMapper(
  property: PropertyFormData,
  clientAddress?: AddressWithTypeFormData,
): NonNullable<CreateSellerRenterLeadRequest['property']> {
  return {
    address:
      property?.isClientAddress && clientAddress
        ? (clientAddress as NonNullable<CreateSellerRenterLeadRequest['property']>['address'])
        : property?.propertyAddress
        ? {
            ...property?.propertyAddress,
            countryCode: property?.propertyAddress?.countryCode as CountryEnum,
            addressFormat: 'FULL',
          }
        : undefined,
    assetSubType: property?.propertySubtype ? (property?.propertySubtype as AssetSubTypeEnum) : undefined,
    assetType: property?.propertyType ? (property?.propertyType as AssetTypeEnum) : undefined,
    assetVariation: property?.propertyVariation ? (property?.propertyVariation as AssetVariationEnum) : undefined,
    condition: property?.condition as AssetConditionEnum,
    constructionYear: property?.constructionDate as unknown as NonNullable<
      CreateSellerRenterLeadRequest['property']
    >['constructionYear'],
    livingArea: property?.livingArea ?? undefined,
    note: property?.additionalNoteProperty || undefined,
    occupationStatus: property?.occupationStatus || undefined,
    ownershipStatus: property?.ownershipStatus || undefined,
    ownerDescription: property?.ownershipDescription ?? undefined,
    ownerId: property?.ownershipStatus !== 'OTHER' ? property?.owner?.id ?? undefined : undefined,
    plotSurface: property?.plotSurface ?? undefined,
    rooms: property?.rooms ?? undefined,
    bathrooms: property?.bathrooms ?? undefined,
    floor: property?.floor ?? undefined,
    totalFloors: property?.totalFloors ?? undefined,
    priceRangeFrom: property?.priceRangeFrom ?? undefined,
    priceRangeTo: property?.priceRangeTo ?? undefined,
    priceUnit: property?.priceRangeUnit,
    rentRangeFrom: property?.rentRangeFrom ?? undefined,
    rentRangeTo: property?.rentRangeTo ?? undefined,
    rentPriceUnit: property?.rentRangeUnit,
    timeFrame: property?.timeFrame ? (property?.timeFrame as TimeFrameEnum) : undefined,
    sameAddressAsContact: property?.isClientAddress,
    balcony: property?.balcony ?? false,
    garden: property?.garden ?? false,
    parking: property?.parking ?? false,
    swimmingPool: property?.swimmingPool ?? false,
    guestToilet: property?.guestToilet ?? false,
    elevator: property?.elevator ?? false,
    garage: property?.garage ?? false,
    cdfs: property?.cdfs ?? undefined,
  };
}

export interface InitialValuesForAddProperty {
  leadId: string;
  type: PropertyEngineListingType;
  listingOwnerContactId: Maybe<number>;
  propertyType: Maybe<AssetTypeEnum>;
  propertySubType: Maybe<AssetSubTypeEnum>;
  surfaceLivingArea: Maybe<number>;
  plotSurface: Maybe<number>;
  totalSurface: Maybe<number>;
  numberOfRooms: Maybe<number>;
  numberOfBathrooms: Maybe<number>;
  address?: {
    streetName: Maybe<string>;
    streetNumber: Maybe<string>;
    city: Maybe<string>;
    postalCode: Maybe<string>;
  };
}

export function getInitialValuesForAddProperty(lead: LeadForAddingProperty): InitialValuesForAddProperty {
  return {
    leadId: lead?.id,
    type: lead?.intentType === 'RENT_OUT' ? 'RENTAL' : 'SALE',
    listingOwnerContactId: lead?.contact?.go3ContactKey,
    propertyType: lead?.sellerRentOutSpecification?.type,
    propertySubType: lead?.sellerRentOutSpecification?.subType,
    surfaceLivingArea: lead?.sellerRentOutSpecification?.livingArea,
    plotSurface: lead?.sellerRentOutSpecification?.plotArea,
    totalSurface: lead?.sellerRentOutSpecification?.surfaceTotal,
    numberOfRooms: lead?.sellerRentOutSpecification?.numberOfRooms,
    numberOfBathrooms: lead?.sellerRentOutSpecification?.numberOfBathrooms,
    address: {
      streetName: lead?.sellerRentOutSpecification?.address?.streetName,
      streetNumber: lead?.sellerRentOutSpecification?.address?.streetNumber,
      city: lead?.sellerRentOutSpecification?.address?.city,
      postalCode: lead?.sellerRentOutSpecification?.address?.postalCode,
    },
  };
}
