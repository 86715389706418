import { SxProps } from '@mui/material';
import MuiLink from '@mui/material/Link';
import LinkIcon from 'components/icons/link_external.svg?react';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';

export function LinkButton({
  to,
  children,
  target,
  hideIcon,
  sx,
}: { to: string; target?: string; hideIcon?: boolean; sx?: SxProps } & PropsWithChildren) {
  return (
    <MuiLink
      to={to}
      component={Link}
      target={target}
      sx={{
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        gap: 0.5,
        color: theme.palette.text.primary,
        textDecoration: 'none',
        '&:hover': {
          color: theme.palette.primary.main,
        },
        ...sx,
      }}
    >
      {children}
      {hideIcon ? undefined : <LinkIcon />}
    </MuiLink>
  );
}
