import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { theme } from 'theme';

interface StyledQuillProps {
  hideNativeToolbar?: boolean;
}

export const QuillEditorStyles = styled('div')<StyledQuillProps>(({ hideNativeToolbar }) => ({
  transition: 'all 200ms',
  flex: 'auto',
  '& .quill': {
    color: '#444444',
    border: 'none',
    '& .ql-container': {
      padding: '24px',
      border: 'none',
    },
  },

  '.ql-placeholder[data-resolved="false"]': {
    color: theme.palette.error.main,
  },

  '.ql-editor': {
    padding: 0,
    minHeight: '250px',
  },
  '.ql-snow.ql-toolbar button:hover .ql-stroke ': {
    stroke: theme.palette.shade.grey1,
  },
  '.ql-snow.ql-toolbar button:hover .ql-fill ': {
    fill: theme.palette.shade.grey1,
  },
  '.ql-snow.ql-toolbar .ql-picker-label:hover': {
    color: theme.palette.shade.grey1,
    '.ql-stroke ': {
      stroke: theme.palette.shade.grey1,
    },
  },
  '.ql-snow.ql-toolbar button.ql-active .ql-stroke': {
    color: theme.palette.shade.grey1,
    stroke: theme.palette.shade.grey1,
  },
  '.ql-snow.ql-toolbar button.ql-active .ql-fill': {
    fill: theme.palette.shade.grey1,
  },
  '& .ql-toolbar.ql-snow': {
    display: hideNativeToolbar ? 'none' : 'undefined',
    border: 'none',
    marginRight: 'auto',

    button: {
      width: '28px',
      height: '28px',
      padding: '5px',
    },

    'button:hover': {
      backgroundColor: theme.palette.shade.grey4,
      borderRadius: '50%',
    },
    'button.ql-active': {
      background: 'rgba(0, 0, 0, 0.16)',
      borderRadius: '50%',
    },
  },
  '& .ql-snow .ql-picker.ql-header': {
    height: '28px',
    width: '60px',
  },
  '& .ql-snow .ql-picker-label::before': {
    lineHeight: '28px',
  },

  '& .ql-snow .ql-picker.ql-header .ql-picker-label::before, .ql-snow .ql-picker.ql-header .ql-picker-item::before': {
    content: '"Text"',
  },
  '& .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before':
    {
      content: '"H1"',
    },
  '& .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before':
    {
      content: '"H2"',
    },
  '& .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before':
    {
      content: '"H3"',
    },
  '& .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before':
    {
      content: '"H4"',
    },
  '& .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before':
    {
      content: '"H5"',
    },
  '& .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before':
    {
      content: '"H6"',
    },
}));

export const CustomInputLabel = styled('label')(
  () => `
  color: ${theme.palette.shade.grey2};
  position: absolute;
  pointer-events: none;
  transition: 0.2s ease all;
  font-size: 14px;
  left: 12px;
  top: 8px;
  background: ${theme.palette.backgroundExtension.white};
  z-index: 1;
  &.focused{
    top: -8px;
    font-size: 12px;
  }
  
  &.error{
    color: ${theme.palette.error.main};
  }
  `,
);

interface StyledIconButtonProps extends IconButtonProps {
  customcolor?: string;
}

export const CustomIconButton = styled(IconButton)<StyledIconButtonProps>(({ customcolor }) => ({
  backgroundColor: customcolor,
  transition: 'all 200ms',
  borderRadius: '4px',
  '&:hover': { opacity: 0.6, backgroundColor: customcolor },
}));

export const RichTextContainer = styled('div')({
  width: '100%',
});
