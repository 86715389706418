import { LanguageEnum, SpokenLanguageEnum } from 'api/graphql/generated/graphql';
import { Option } from 'components/general/OptionsList/OptionsList';
import { TFunction } from 'util/i18next';

export function translateLanguageEnum(
  t: TFunction<['enums']>,
  languageEnum?: LanguageEnum | SpokenLanguageEnum,
): string {
  return languageEnum ? t(`enums:language.${languageEnum}`) : '';
}

export function translateLanguageOption(t: TFunction<['enums']>, { name }: { name: string }): Option {
  return {
    value: name,
    label: translateLanguageEnum(t, name as LanguageEnum),
  };
}
