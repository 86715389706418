import { IconButton, IconButtonProps, Stack, SxProps, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { ReactNode, isValidElement } from 'react';
import { theme } from 'theme';
import { sxTruncate } from 'util/styleUtils';

export const ModalHeader = ({ children, sx }: { children: ReactNode; sx?: SxProps }) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      sx={{
        width: '100%',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.backgroundExtension.white,
        py: 1.5,
        px: 3,
        [theme.breakpoints.down('tablet')]: {
          position: 'sticky',
          top: 0,
          zIndex: 1,
        },
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};

export const ModalHeaderMobile = ({ children, sx }: { children: ReactNode; sx?: SxProps }) => {
  const childNodes = React.Children.toArray(children).filter(isValidElement);

  const title = childNodes.find((child) => child.type === ModalTitle);
  const action = childNodes.find((child) => child.type === ModalTitleAction);
  const otherChildren = childNodes.filter((child) => child.type !== ModalTitle && child.type !== ModalTitleAction);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        top: 0,
        position: 'sticky',
        padding: 2,
        alignItems: 'center',
        zIndex: 1,
        width: '100%',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.backgroundExtension.white,
        ...sx,
      }}
    >
      <Box sx={{ justifySelf: 'start' }}>{action}</Box>
      {title}
      <Box sx={{ justifySelf: 'end' }}>{otherChildren}</Box>
    </Box>
  );
};

export const ModalTitle = ({ children }: { children: ReactNode }) => (
  <Typography variant="body1" sx={sxTruncate}>
    {children}
  </Typography>
);

export const ModalTitleAction = ({ children, ...iconButtonProps }: IconButtonProps) => (
  <IconButton {...iconButtonProps}>{children}</IconButton>
);
