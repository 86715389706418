import { Tab, Tabs } from '@mui/material';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { ContactsRemovedAlert } from 'components/emails/ContactsRemovedAlert/ContactsRemovedAlert';
import { CcBccPickerList } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/CcBccPickerList';
import { RecipientPicker } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/RecipientPicker';
import { RecipientsList } from 'components/emails/SendBulkEmailDialog/RecipientsList';
import { TabPanel } from 'components/general/TabPanel';
import { Controller, useFormContext } from 'react-hook-form';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { ComposeBulkEmailFormData } from 'util/schemas/sendBulkEmailSchema';

export type RecipientTab = 'to' | 'cc' | 'bcc';

export function BulkEmailRecipientTabs({
  activeTab,
  setActiveTab,
  disabled,
}: {
  activeTab: RecipientTab;
  setActiveTab: (tab: RecipientTab) => void;
  disabled?: boolean;
}) {
  const { t } = useTranslation(['communication']);

  return (
    <Tabs
      value={activeTab}
      onChange={(_, value) => setActiveTab(value)}
      sx={{ borderBottom: `1px solid ${theme.palette.transparent.black8}` }}
    >
      <Tab
        value="to"
        disabled={disabled}
        label={t('communication:sendBulkEmailDialog.recipientTab.to')}
        sx={{ textTransform: 'none' }}
      />
      <Tab
        value="cc"
        disabled={disabled}
        label={
          <Tooltip title={t('communication:sendBulkEmailDialog.recipientTab.ccBccTooltip')}>
            <span>{t('communication:sendBulkEmailDialog.recipientTab.cc')}</span>
          </Tooltip>
        }
        sx={{ textTransform: 'none' }}
      />
      <Tab
        value="bcc"
        disabled={disabled}
        label={
          <Tooltip title={t('communication:sendBulkEmailDialog.recipientTab.ccBccTooltip')}>
            <span>{t('communication:sendBulkEmailDialog.recipientTab.bcc')}</span>
          </Tooltip>
        }
        sx={{ textTransform: 'none' }}
      />
    </Tabs>
  );
}

export function BulkEmailRecipientTabPanels({ activeTab, disabled }: { activeTab: RecipientTab; disabled?: boolean }) {
  const { control, watch } = useFormContext<ComposeBulkEmailFormData>();

  const tabPanelSx = { height: '100%', overflow: 'hidden' };

  const toContactsWithoutEmail = watch('toContactsWithoutEmail');
  const toContactsFlagged = watch('toContactsFlagged');

  return (
    <>
      <TabPanel activeTab={activeTab} value="to" sx={tabPanelSx}>
        <Controller
          name="to"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Stack gap={2} sx={{ height: '100%' }}>
              <RecipientPicker
                isForExposeSending={watch('isExposeSending')}
                disabled={disabled}
                recipients={field.value}
                onRecipientsChange={field.onChange}
                errorMessage={error?.message}
              />
              <ContactsRemovedAlert
                toContactsFlagged={toContactsFlagged}
                toContactsWithoutEmail={toContactsWithoutEmail}
              />
              <RecipientsList
                disabled={disabled}
                recipients={field.value}
                onRemoveRecipient={(recipientId) => field.onChange(field.value.filter((r) => r.id !== recipientId))}
              />
            </Stack>
          )}
        />
      </TabPanel>

      <TabPanel activeTab={activeTab} value="cc" sx={tabPanelSx}>
        <Controller
          name="cc"
          control={control}
          render={({ field }) => (
            <CcBccPickerList disabled={disabled} recipients={field.value} onChange={field.onChange} />
          )}
        />
      </TabPanel>

      <TabPanel activeTab={activeTab} value="bcc" sx={tabPanelSx}>
        <Controller
          name="bcc"
          control={control}
          render={({ field }) => (
            <CcBccPickerList disabled={disabled} recipients={field.value} onChange={field.onChange} />
          )}
        />
      </TabPanel>
    </>
  );
}
