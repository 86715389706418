import { useJwtToken } from '@ev/eva-container-api';
import { ListItem, ListItemAvatar } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCurrentAgent } from 'api/graphql/hooks/useCurrentAgent';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { theme } from 'theme';

export function AgentMenuItem() {
  const { jwtToken } = useJwtToken();
  const { currentAgent } = useCurrentAgent();

  return (
    <ListItem component="div">
      <ListItemAvatar sx={{ minWidth: 'auto', marginInlineEnd: theme.spacing(1.5) }}>
        <EVAvatar person={currentAgent} size="m" />
      </ListItemAvatar>
      <Stack>
        <Typography variant="body3" fontWeight="bold">
          {jwtToken?.given_name} {jwtToken?.family_name}
        </Typography>
        <Typography variant="body4">{jwtToken?.email}</Typography>
      </Stack>
    </ListItem>
  );
}
