import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import Typography from '@mui/material/Typography';
import { LeadAndContactSearchOption } from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import ArrowLeftIcon from 'components/icons/arrow_2_left.svg?react';
import ArrowRightIcon from 'components/icons/arrow_2_right.svg?react';
import { EMPTY_DATA_STRING } from 'util/emptyDataUtils';

export function RecipientPagination({
  narrow,
  recipients,
  selectedRecipient,
  onSelectRecipient,
  disabled,
}: {
  narrow?: boolean;
  disabled?: boolean;
  recipients: LeadAndContactSearchOption[];
  selectedRecipient: string | undefined;
  onSelectRecipient: (recipient: string) => void;
}) {
  if (!recipients.length) {
    return null;
  }

  const currentIndex = recipients.findIndex((r) => r.id === selectedRecipient);
  const currentPage = currentIndex + 1 || EMPTY_DATA_STRING;
  const lastPage = recipients.length;

  const onPrev = () => onSelectRecipient(recipients[currentIndex - 1]!.id);
  const onNext = () => onSelectRecipient(recipients[currentIndex + 1]!.id);

  return (
    <Stack direction="row" gap={narrow ? 0.5 : 2} alignItems="center">
      <IconButton disabled={disabled || currentIndex < 1} onClick={onPrev}>
        <ArrowLeftIcon />
      </IconButton>
      <Typography variant="body3">{`${currentPage}/${lastPage}`}</Typography>
      <IconButton disabled={disabled || currentIndex + 1 === lastPage || currentIndex < 0} onClick={onNext}>
        <ArrowRightIcon />
      </IconButton>
    </Stack>
  );
}
