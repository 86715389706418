import { useActivityDrawer } from '@ev/eva-container-api';
import { DashboardActivity } from 'api/graphql/generated/graphql';
import { ActivityCategory } from 'components/activity/CreateActivityButton';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { assertUnreachable } from 'util/typescript';

export function useDisplayActivity() {
  const { contactId, leadId } = useParams();
  const { openTaskDrawer, openAppointmentDrawer, openShareExposeDrawer } = useActivityDrawer();

  const displayActivity = useCallback(
    (activity: DashboardActivity) => {
      if (activity.category === 'TASK') {
        openTaskDrawer({ editId: activity?.taskActivity?.task.id });
      }
      if (activity.category === 'APPOINTMENT') {
        openAppointmentDrawer({ editId: activity?.appointmentActivity?.appointment.id });
      }
    },
    [openTaskDrawer, openAppointmentDrawer],
  );

  const addActivity = useCallback(
    (activityType: ActivityCategory, day?: Date) => {
      const contactIds = contactId ? [contactId] : undefined;
      const leadIds = leadId ? [leadId] : undefined;
      switch (activityType) {
        case 'APPOINTMENT':
          openAppointmentDrawer({ day, contactIds, leadIds });
          break;
        case 'TASK':
          openTaskDrawer({ day, contactIds, leadIds });
          break;
        case 'SHARED':
          openShareExposeDrawer({ contactIds, leadIds });
          break;
        case 'ALL':
          return;

        default:
          assertUnreachable(activityType);
      }
    },
    [openAppointmentDrawer, openTaskDrawer, openShareExposeDrawer, contactId, leadId],
  );

  return {
    displayActivity,
    addActivity,
  };
}
