import { ErrorBoundaryInner } from 'components/error/ErrorBoundary/ErrorBoundaryInner';
import { ErrorDisplay } from 'components/error/ErrorDisplay';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'util/i18next';

export interface ErrorBoundaryProps {
  children?: ReactNode;
  iconSize?: 'medium' | 'small';
}

export function ErrorBoundary({ children, iconSize }: ErrorBoundaryProps) {
  const { t } = useTranslation(['user']);
  const [hasError, setHasError] = useState(false);
  const location = useLocation();

  //Gets rid of the error state when we navigate to a new page
  useEffect(() => {
    setHasError(false);
  }, [location.key]);

  return (
    <ErrorBoundaryInner
      hasError={hasError}
      setHasError={setHasError}
      fallback={
        <ErrorDisplay
          title={t('user:error.unknownError.title')}
          message={t('user:error.unknownError.message')}
          buttonText={t('user:error.unknownError.button')}
          iconSize={iconSize}
          onClick={() => window.location.reload()}
        />
      }
    >
      {children}
    </ErrorBoundaryInner>
  );
}
