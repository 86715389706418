import { useSnackBars } from '@ev/eva-container-api';
import { useEffect } from 'react';

export function useShowError(errorText: string, hasError: boolean) {
  const { openSnackBar } = useSnackBars();

  useEffect(() => {
    if (hasError) {
      openSnackBar(errorText, 'error');
    }
  }, [openSnackBar, hasError, errorText]);
}
