import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { theme } from 'theme';

export const StyledChip = styled(Chip)(() => ({
  background: theme.palette.background.paper,
  color: theme.palette.text.primary,
  textTransform: 'none',
  '&&:hover': {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
}));

export const RowWrapper = styled('div')(({ markDuplicate }: { markDuplicate?: boolean }) => ({
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '4fr 6fr',
  gap: theme.spacing(1),
  padding: '4px 4px 4px 20px',
  marginBottom: '6px',
  backgroundColor: markDuplicate ? theme.palette.warning.light : 'inherit',
}));

export const Footer = styled('footer')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: theme.spacing(2),
  position: 'sticky',
  bottom: 0,
  borderTop: 1,
  borderTopColor: theme.palette.shade.grey4,
  borderTopStyle: 'solid',
}));

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  width: '100%',
  flex: 1,
  overflow: 'auto',
}));
