import { Box, SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { LastInteraction } from 'api/graphql/generated/graphql';
import { useAgents } from 'api/graphql/hooks/useAgents';
import { DateLabel } from 'components/general/DateLabel/DateLabel';
import { activityTypeTranslationKeys, appointmentTypeTranslation, taskTypeTranslation } from 'const/enumTranslations';
import { Container } from 'page-components/LeadListPage/LeadRow/ActivityRowItem/styles';
import { theme } from 'theme';
import { EMPTY_DATA_STRING, renderEmptyable } from 'util/emptyDataUtils';
import { TFunction, useTranslation } from 'util/i18next';
import { fullName } from 'util/stringUtils';

export function formatActivityType(t: TFunction<['enums']>, lastInteraction?: LastInteraction | null) {
  switch (lastInteraction?.category) {
    case 'TASK':
      return renderEmptyable(
        lastInteraction?.taskActivity?.task?.type,
        (taskTypeEnum) => t(taskTypeTranslation[taskTypeEnum]) || taskTypeEnum,
      );
    case 'APPOINTMENT':
      return renderEmptyable(
        lastInteraction?.appointmentActivity?.appointment?.type,
        (appointmentTypeEnum) => t(appointmentTypeTranslation[appointmentTypeEnum]) || appointmentTypeEnum,
      );
    default:
      return renderEmptyable(
        lastInteraction?.type,
        (activityTypeEnum) => t(activityTypeTranslationKeys[activityTypeEnum]) || activityTypeEnum,
      );
  }
}
export function LastInteractionItem({
  lastInteraction,
  emptyDataString = EMPTY_DATA_STRING,
  showAgentName,
  sx,
}: {
  lastInteraction?: LastInteraction | null;
  emptyDataString?: string;
  showAgentName?: boolean;
  sx?: SxProps;
}) {
  const { t } = useTranslation(['enums']);
  const type = formatActivityType(t, lastInteraction);

  const { agents } = useAgents(
    { where: { id: { _eq: lastInteraction?.createdBy } }, limit: 1 },
    { enabled: !!lastInteraction?.createdBy && showAgentName },
  );

  const agentName = showAgentName && fullName(agents?.[0]);

  if (lastInteraction) {
    return (
      <Container sx={sx}>
        <Typography
          variant="body3"
          sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {type}
        </Typography>
        <DateLabel type="fullDate" dateString={lastInteraction.orderDate} />
        {agentName && (
          <Typography variant="body3" color={theme.palette.text.secondary}>
            {agentName}
          </Typography>
        )}
      </Container>
    );
  }
  return (
    <Box>
      <Typography variant="body3" color={theme.palette.text.secondary} sx={{ whiteSpace: 'nowrap', ...sx }}>
        {emptyDataString}
      </Typography>
    </Box>
  );
}
