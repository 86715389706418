import { DialogActions, DialogTitle, IconButton, SxProps, Typography } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import { EVDialog } from 'components/general/EVDialog';
import UploadIcon from 'components/icons/upload.svg?react';
import { PropsWithChildren, useRef, useState } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

interface ImageUploadButtonProps {
  onUpload: (files: File[]) => void;
  allowMultipleSelection?: boolean;
  accept?: string;
  sx?: SxProps;
  disabled?: boolean;
  icon?: React.ReactNode;
  wrapInForm?: boolean;
  byteSumOfAllUploads?: number;
  variant?: ButtonProps['variant'];
}

export const FileUploadButton = ({
  onUpload,
  allowMultipleSelection = false,
  accept,
  children,
  byteSumOfAllUploads = 0,
  icon = <UploadIcon />,
  wrapInForm = true,
  variant,
  ...buttonProps
}: PropsWithChildren<ImageUploadButtonProps>) => {
  const { t } = useTranslation(['communication']);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const selectedFiles = Array.from(e.target.files);
    const totalSize = selectedFiles.reduce((acc, file) => acc + file.size, byteSumOfAllUploads);

    if (totalSize > 25000000) {
      setIsDialogOpen(true);
    } else {
      onUpload(selectedFiles);
    }
    e.target.value = '';
  };

  return (
    <>
      {children ? (
        <>
          <Button
            color="secondary"
            startIcon={icon}
            aria-label="upload picture"
            onClick={() => ref.current?.click()}
            variant={variant}
            {...buttonProps}
          >
            {children}
          </Button>
        </>
      ) : (
        <IconButton
          {...buttonProps}
          aria-label="upload picture"
          component="label"
          onClick={() => ref.current?.click()}
          {...buttonProps}
        >
          {icon}
        </IconButton>
      )}
      {wrapInForm ? (
        <form>
          <input
            hidden
            type="file"
            accept={accept}
            onChange={handleUpload}
            ref={ref}
            data-testid="fileUpload"
            multiple={allowMultipleSelection}
          />
        </form>
      ) : (
        <input
          hidden
          type="file"
          accept={accept}
          onChange={handleUpload}
          ref={ref}
          data-testid="fileUpload"
          multiple={allowMultipleSelection}
        />
      )}
      <EVDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>{t('communication:emailForm.fileTooLarge')}</DialogTitle>
        <DialogContent sx={{ backgroundColor: theme.palette.shade.grey5 }}>
          <Typography variant="body2" color="text.secondary" textAlign="center">
            {t('communication:emailForm.sizeLimitExceeded')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} variant="contained" sx={{ width: '50%' }}>
            {t('communication:emailForm.fileTooLarge.confirmation')}
          </Button>
        </DialogActions>
      </EVDialog>
    </>
  );
};
