import { Stack, Tooltip } from '@mui/material';
import { LeadForContactList } from 'api/graphql/generated/graphql';
import { EVChip } from 'components/general/Chips/EVChip/EVChip';
import { AgentTooltipChip } from 'components/general/Chips/TooltipChip/AgentTooltipChip';
import { fullName } from 'util/stringUtils';

type AgentSetData = {
  agentId?: string;
  agentFirstName?: string;
  agentLastName?: string;
};

//Can take in a list of leads, or a list of agent data that has id, first, last
export function AgentSet({ leads, agents }: { leads?: LeadForContactList[]; agents?: AgentSetData[] }) {
  const agentIds = leads?.map((lead) => lead.agentId!).filter(Boolean) ?? agents?.map((data) => data.agentId);

  if (!agentIds) {
    return null;
  }

  const uniqueAgentIds = [...new Set(agentIds)];

  //If there is more than one agent, additional agents are shown as a +{number} chip
  let countChip;

  //If there is one additional agent, hovering over the +1 chip opens the agent tooltip
  if (uniqueAgentIds.length == 2) {
    countChip = (
      <AgentTooltipChip agentId={uniqueAgentIds[1]}>
        <EVChip label="+1" />
      </AgentTooltipChip>
    );
  }

  //If there are many additional agents, hovering over the +{number} chip shows their names
  if (uniqueAgentIds.length > 2) {
    const agentNamesFromLeads = leads
      ?.map((lead) => {
        //get names of all agents besides the first agent
        if (lead.agentId && uniqueAgentIds.indexOf(lead.agentId)) {
          return fullName({ firstName: lead.agent?.firstName, lastName: lead.agent?.lastName }, '');
        }
      })
      .filter(Boolean);

    const agentNamesFromAgents = agents
      ?.map((agent) => {
        //get names of all agents besides the first agent
        if (uniqueAgentIds.indexOf(agent.agentId)) {
          return fullName({ firstName: agent.agentFirstName, lastName: agent.agentLastName }, '');
        }
      })
      .filter(Boolean);

    const agentNames = agentNamesFromLeads ?? agentNamesFromAgents;
    const uniqueAgentNames = [...new Set(agentNames)];

    countChip = (
      <Tooltip title={uniqueAgentNames.join(', ')}>
        <EVChip label={`+${uniqueAgentNames.length}`} />
      </Tooltip>
    );
  }

  return (
    <Stack gap={1} direction="row">
      <AgentTooltipChip agentId={uniqueAgentIds[0]} />
      {countChip}
    </Stack>
  );
}
