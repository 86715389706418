import { Box, Tab, Tabs } from '@mui/material';
import Typography from '@mui/material/Typography';
import { AssetSubTypeEnum, AssetVariationEnum, Lead } from 'api/graphql/generated/graphql';
import { SearchTextField } from 'components/general/SearchTextField/SearchTextField';
import { assetSubTypeTranslation, assetVariationTranslation, intentCategoryTabs } from 'const/enumTranslations';
import { LinkingLeadCard } from 'page-components/mails/ThreadPage/LinkingLeadCard';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { IntentTabEnum, intentTypeToIntentCategory, intentTypeToIntentTabs } from 'util/intentType';

export const filterLeads = ({ leads, searchText }: { leads: Lead[]; searchText: string }) => {
  if (!searchText) {
    return leads;
  }

  const lowerCaseSearchText = searchText.toLowerCase();
  return leads.filter((lead) => {
    const sellerRentOutSpecification = lead?.sellerRentOutSpecification;
    const buyerToRentSpecification = lead?.buyerToRentSpecification;
    return (
      sellerRentOutSpecification?.address?.fullAddress?.toLowerCase().includes(lowerCaseSearchText) ||
      sellerRentOutSpecification?.type?.toLowerCase().includes(lowerCaseSearchText) ||
      sellerRentOutSpecification?.subType?.toLowerCase().includes(lowerCaseSearchText) ||
      sellerRentOutSpecification?.condition?.toLowerCase().includes(lowerCaseSearchText) ||
      lead?.personalNote?.toLowerCase().includes(lowerCaseSearchText) ||
      lead?.personalNote?.toLowerCase().includes(lowerCaseSearchText) ||
      buyerToRentSpecification?.city?.toLowerCase().includes(lowerCaseSearchText) ||
      buyerToRentSpecification?.country?.toLowerCase().includes(lowerCaseSearchText) ||
      buyerToRentSpecification?.type?.toLowerCase().includes(lowerCaseSearchText) ||
      buyerToRentSpecification?.subTypes?.some((subType) =>
        assetSubTypeTranslation[subType as AssetSubTypeEnum].toLowerCase().includes(lowerCaseSearchText),
      ) ||
      buyerToRentSpecification?.variations?.some((variation) =>
        assetVariationTranslation[variation as AssetVariationEnum].toLowerCase().includes(lowerCaseSearchText),
      )
    );
  });
};

export function LeadsSearch({ leads, messageId, threadId }: { leads: Lead[]; messageId: string; threadId: string }) {
  const { t } = useTranslation(['communication', 'contact']);
  const [searchText, setSearchText] = useState<string>('');
  const [showResults, setShowResults] = useState(false);
  const [selectedContactIntent, setSelectedContactIntent] = useState<IntentTabEnum>('SELLER');

  const filteredLeads = filterLeads({ leads, searchText });

  const ref = useOutsideClick(() => {
    setShowResults(false);
  });

  const getLabel = (intentType: IntentTabEnum) => {
    return (
      <Box>
        <Typography variant="body3">{t(intentCategoryTabs[intentType])}</Typography>
        <Typography
          variant="body3"
          sx={{
            color: theme.palette.shade.grey3,
            paddingLeft: theme.spacing(0.8),
          }}
        >
          {filteredLeads.filter((lead) => intentTypeToIntentTabs(lead?.intentType) === intentType).length}
        </Typography>
      </Box>
    );
  };

  return (
    <Box ref={ref}>
      <SearchTextField
        onClick={(event) => {
          event.stopPropagation();

          setShowResults(true);
        }}
        fullWidth={true}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setSearchText(event.target.value);
        }}
        size="medium"
        value={searchText}
        placeholder={t('communication:mails.searchForALead')}
        onFocus={() => {
          setShowResults(true);
        }}
      />

      {showResults && (
        <Box sx={{ paddingTop: theme.spacing(1) }}>
          <Box
            sx={{
              boxShadow: '0px 2px 4px rgb(0 0 0 / 40%)',
              borderRadius: '4px',
              backgroundColor: theme.palette.backgroundExtension.grey,
            }}
          >
            <Box sx={{ backgroundColor: theme.palette.backgroundExtension.white }}>
              <Tabs
                value={selectedContactIntent}
                onChange={(_: unknown, value: IntentTabEnum) => {
                  setSelectedContactIntent(value);
                }}
              >
                <Tab value="SELLER" label={getLabel('SELLER')} sx={{ textTransform: 'none' }} />
                <Tab value="BUYER" label={getLabel('BUYER')} sx={{ textTransform: 'none' }} />
                <Tab value="RENT_OUT" label={getLabel('RENT_OUT')} sx={{ textTransform: 'none' }} />
                <Tab value="TO_RENT" label={getLabel('TO_RENT')} sx={{ textTransform: 'none' }} />
              </Tabs>
            </Box>
            <Box
              sx={{
                borderTop: 1,
                borderTopColor: theme.palette.shade.grey3,
                padding: theme.spacing(1),
              }}
            >
              {filteredLeads
                .filter((lead) => intentTypeToIntentCategory(lead?.intentType) === selectedContactIntent)
                .map((lead) => (
                  <LinkingLeadCard key={lead.id} lead={lead} messageId={messageId} threadId={threadId} />
                ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

const useOutsideClick = (callback: () => void) => {
  const ref = useRef<HTMLElement>();
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [callback]);
  return ref;
};
