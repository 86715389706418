import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';
import { httpService } from 'api/rest';
import { Go3RetrievePropertyInfoResponse } from 'api/rest/models/go3/Go3RetrievePropertyInfoResponse';
import { RetrievePropertyInfoCommmandRequest } from 'api/rest/models/rest/Command';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { ListingWithLegacyFields, mapGo3PropertyToListing } from 'util/go3';

export const listingQuery = graphql(/* GraphQL */ `
  query getPropertyDetailQuery($utag: String!) {
    propertyEngine {
      getListingByUtag(utag: $utag) {
        ...Listing
      }
    }
  }
`);

const useListingByUtagFromPropertyEngine = (
  variables: { utag: string },
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) =>
  useQuery({
    queryKey: ['listing', 'useListing', variables],
    queryFn: async () => (await graphqlClient.request(listingQuery, variables)).propertyEngine?.getListingByUtag,
    ...options,
  });

const useRetrievePropertyInfoFromGo3 = (
  requestBody: RetrievePropertyInfoCommmandRequest,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) =>
  useQuery({
    queryKey: ['retrievePropertyInfo', 'useRetrievePropertyInfo', requestBody],
    enabled: !!requestBody.go3PropertyId,
    queryFn: () => {
      return httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'RetrievePropertyInfoCommand',
        })
        .then((resp) => resp.data.data as Go3RetrievePropertyInfoResponse);
    },
    ...options,
  });

export function useListing(variables: { utag: string }, options?: Pick<UseQueryOptions, 'enabled' | 'onError'>) {
  const { isFullyOnboardedShop } = useActiveShop();

  const { data: propertyEngineListingResult, ...restPropertyEngine } = useListingByUtagFromPropertyEngine(variables, {
    ...options,
    enabled: isFullyOnboardedShop && options?.enabled,
  });

  const { data: go3ListingResult, ...restGo3 } = useRetrievePropertyInfoFromGo3(
    { go3PropertyId: variables.utag },
    { ...options, enabled: !isFullyOnboardedShop && options?.enabled },
  );

  if (isFullyOnboardedShop) {
    return { listing: propertyEngineListingResult as ListingWithLegacyFields | undefined, ...restPropertyEngine };
  } else {
    return { listing: go3ListingResult && mapGo3PropertyToListing(go3ListingResult), ...restGo3 };
  }
}
