import {
  AddressTypeEnum,
  ContactTimeEnum,
  CountryEnum,
  EmailAddressTypeEnum,
  LanguageEnum,
  PhoneNumberTypeEnum,
  SalutationEnum,
} from 'api/graphql/generated/graphql';
import { CreateSellerRenterLeadRequest } from 'api/rest/hooks/useCreateLead';
import { CreateBuyerRentalLeadRequest } from 'api/rest/models/rest/Command';
import { formatISO } from 'date-fns';
import { contactRelationshipMapper } from 'util/mappers/contactRelationshipMapper';
import { AssignmentProps, newLeadAssignmentMapper } from 'util/mappers/leadAssigmentMapper';
import { propertyMapper } from 'util/mappers/propertyMapper';
import { requirementsMapper } from 'util/mappers/requirementsMapper';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';

function newContactMapper(data: ContactDetailsFormData) {
  return {
    company: data.isCompany ? { name: data.company as string, department: data.division } : undefined,
    contactNote: data.additionalNote,
    tags: data.tags?.map((tag) => tag.value),
    preferredLanguage: data.preferredLanguage as LanguageEnum,
    preferredTimes: data.preferredTimes as ContactTimeEnum[],
    autoCc: data.autoCc,
    person: {
      firstName: data.firstName || undefined,
      lastName: data.lastName || undefined,
      salutation: (data.salutation as SalutationEnum) || undefined,
      title: data.title,
      birthDate: data.birthDate ? formatISO(data.birthDate, { representation: 'date' }) : undefined,
      placeOfBirth: data.placeOfBirth || undefined,
      nationality: data.nationality || undefined,
      idNumber: data.idNumber || undefined,
    },
    addresses: data.addresses
      .filter(
        ({ address }) =>
          address?.streetName || address?.streetNumber || address?.postalCode || address?.city || address?.countryCode,
      )
      .map(
        ({ address, preferred, addressType }) =>
          ({
            ...address,
            countryCode: (address?.countryCode || 'UNDEFINED') as CountryEnum,
            addressType: (addressType || 'UNDEFINED') as AddressTypeEnum,
            addressFormat: 'FULL',
            preferred: preferred,
          } as const),
      ),
    phoneNumbers: data.phoneNumbers
      .map((phone) => ({
        ...phone,
        phoneType: (phone.phoneType || 'UNDEFINED') as PhoneNumberTypeEnum,
        preferred: phone.preferred,
      }))
      .filter(({ number }) => !!number),
    contactRelationships: contactRelationshipMapper(data),
    renterDetails: {
      householdType: data?.renterDetails?.householdType || undefined,
      professionType: data?.renterDetails?.professionType || undefined,
      petOwner: data?.renterDetails?.petOwner ?? undefined,
      incomeRange: data.renterDetails?.incomeRange ?? undefined,
    },
    emailAddresses: data.emailAddresses
      .map((email) => ({
        ...email,
        emailType: (email.emailType || 'UNDEFINED') as EmailAddressTypeEnum,
        preferred: email.preferred,
      }))
      .filter(({ email }) => email && email.length > 0),
  };
}

export function createSellerRenterLeadMapper(
  data: ContactDetailsFormData & PropertyDetailsFormData & AssignmentProps,
): CreateSellerRenterLeadRequest {
  return {
    ...newLeadAssignmentMapper(data),
    ...newContactMapper(data),
    leadStatus: 'ACTIVE',
    leadSource: data.leadSource,
    property: {
      intentType: data.intentType,
      ...propertyMapper(data.property, data.addresses[0]),
    },
  };
}

export function createBuyerRentalLeadMapper(
  data: ContactDetailsFormData & PropertyDetailsFormData & AssignmentProps,
): CreateBuyerRentalLeadRequest {
  const { search } = data;
  return {
    ...newLeadAssignmentMapper(data),
    ...newContactMapper(data),
    intentType: data.intentType,
    leadSource: data.leadSource,
    propertyId: data._internals.isKnownProperty ? data.knownProperty.linkedPropertyArray?.[0]?.utag : undefined,
    personalOrLeadNote:
      (data._internals.isKnownProperty ? data.knownProperty.personalNote : search.personalNote) || undefined,
    requirements: search && !data._internals.isKnownProperty ? requirementsMapper(search) : undefined,
  };
}
