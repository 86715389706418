import { useDrawer } from '@ev/eva-container-api';
import { DuplicateContactInfo } from 'api/rest/models/ContactDuplicates';
import { DuplicateAlertDrawer } from 'components/contact/ContactDetailsForm/CheckForDuplicates/DuplicateAlertDrawer';
import { EVAlert } from 'components/general/EVAlert/EVAlert';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';

export const DuplicateText = ({
  duplicateChecked,
  duplicates,
}: {
  duplicateChecked: boolean;
  duplicates: Array<DuplicateContactInfo>;
}) => {
  const [isDuplicateAlertDrawerOpen, setDuplicateAlertDrawerOpen] = useDrawer('duplicateAlert');
  const { setValue } = useFormContext<ContactDetailsFormData>();
  const { t } = useTranslation(['contact']);

  if (!duplicateChecked) {
    return t('contact:duplicateCheck.message');
  }

  if (duplicateChecked && !duplicates?.length) {
    return (
      <EVAlert severity="success" fullWidth testId="noDuplicates">
        {t('contact:duplicateCheck.duplicatesAbsent.message')}
      </EVAlert>
    );
  }

  return (
    <>
      <EVAlert
        severity="warning"
        buttonText={t('contact:duplicateCheck.duplicatesPresent.CTA')}
        onButtonClick={() => setDuplicateAlertDrawerOpen(true)}
        fullWidth
      >
        {t('contact:duplicateCheck.duplicatesPresent.message')}
      </EVAlert>
      <DuplicateAlertDrawer
        isOpen={isDuplicateAlertDrawerOpen}
        duplicates={duplicates || []}
        onClose={() => setDuplicateAlertDrawerOpen(false)}
        hideBackdrop
        onContinue={() => {
          setDuplicateAlertDrawerOpen(false);
          setValue('_internals.contact.contactQualified', true);
        }}
      />
    </>
  );
};
