import { DEFAULT_LANG, useLocale } from '@ev/eva-container-api';
import { ListItemIcon, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { DropdownMenu } from 'components/general/DropDown/DropdownMenu';
import CaretIcon from 'components/icons/arrow_1_down.svg?react';
import GlobeIcon from 'components/icons/globe.svg?react';
import React, { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { buildUrl } from 'util/navigation/useNavigation';
import { useCookies } from 'util/useCookies';

export const LanguageSelector = function LanguageSelector({ asMenuItem }: { asMenuItem?: boolean }) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { t } = useTranslation(['user']);
  const { pathname } = useLocation();
  const locale = useLocale();
  const [searchParams] = useSearchParams();

  const { setCookie } = useCookies();

  const languages = [
    {
      value: 'en',
      label: t('user:languageSelector.en'),
    },
    {
      value: 'de',
      label: t('user:languageSelector.de'),
    },
    {
      value: 'de-CH',
      label: t('user:languageSelector.de-CH'),
    },
    {
      value: 'de-AT',
      label: t('user:languageSelector.de-AT'),
    },
    {
      value: 'el',
      label: t('user:languageSelector.el'),
    },
    {
      value: 'es',
      label: t('user:languageSelector.es'),
    },
    {
      value: 'fr',
      label: t('user:languageSelector.fr'),
    },
    {
      value: 'it',
      label: t('user:languageSelector.it'),
    },
    {
      value: 'pt',
      label: t('user:languageSelector.pt'),
    },
  ] as const;

  const openModal = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeModal = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {asMenuItem ? (
        <MenuItem onClick={openModal}>
          <ListItemIcon>{<GlobeIcon />}</ListItemIcon>
          <ListItemText>{t('mainNavigation.language')}</ListItemText>
          <CaretIcon />
        </MenuItem>
      ) : (
        <IconButton onClick={openModal} color="secondary">
          <GlobeIcon style={{ color: theme.palette.shade.grey2 }} />
        </IconButton>
      )}

      <DropdownMenu
        anchorEl={anchorEl}
        onClose={closeModal}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        showDrawerOnMobile={{ title: t('mainNavigation.language') }}
      >
        <MenuList>
          {languages.map((language) => (
            <MenuItem
              key={language.value}
              onClick={() => {
                if (language.value === locale) {
                  return;
                }

                const pathnameWithOutLocale = pathname.replace(`/${locale}`, '');

                setCookie('NEXT_LOCALE', language.value, 24 * 365);

                const newPath =
                  language.value === DEFAULT_LANG
                    ? pathnameWithOutLocale
                    : `/${language.value}${pathnameWithOutLocale}`;

                location.assign(buildUrl(newPath, searchParams));
                closeModal();
              }}
              selected={language.value === locale}
            >
              <ListItemText>{language.label}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </DropdownMenu>
    </>
  );
};
