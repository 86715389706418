import { AddLeadFormData } from 'components/leads/forms/AddNewLead';
import { CreateLeadFormData } from 'components/leads/forms/CreateNewLead';
import { useCallback } from 'react';
import { useGetAddressFromCoordinates } from 'util/places/geocoder';

export const useUpdatePropertyAddress = () => {
  const getAddressFromCoordinates = useGetAddressFromCoordinates();

  return useCallback(
    async <T extends AddLeadFormData | CreateLeadFormData>(submittedData: T): Promise<T> => {
      const updatedFormData = { ...submittedData };

      //Seller leads can use a select on map function to set the address
      //Lat & lng are stored internally, and we call the google api to get the place id
      if (submittedData.intentType == 'SELL') {
        const latitude = submittedData.property._internals.latitude;
        const longitude = submittedData.property._internals.longitude;

        if (latitude && longitude) {
          const addressDetails = await getAddressFromCoordinates(latitude, longitude);

          if (addressDetails) {
            updatedFormData.property.propertyAddress = addressDetails;
          }
        }
      }
      return updatedFormData;
    },
    [getAddressFromCoordinates],
  );
};
