import * as Sentry from '@sentry/browser';
import { Breadcrumb, CaptureContext } from '@sentry/types';
import { env } from 'env';

const sentryEnabled = ['dev', 'stage', 'prod'].includes(env.VITE_LEADHUB_ENV);

if (sentryEnabled) {
  Sentry.init({
    dsn: env.VITE_SENTRY_DSN,
    environment: env.VITE_SENTRY_ENVIRONMENT,

    tracesSampleRate: 0.0,
    autoSessionTracking: false,
    beforeBreadcrumb(event) {
      if (event.category === 'navigation') {
        // Prevent navigation tracking
        return null;
      }

      return event;
    },
  });
}

const sentry = {
  logError(message: string | Error, captureContext?: CaptureContext) {
    // eslint-disable-next-line no-console
    console.error('Sentry error:', message, captureContext && { captureContext });
    if (sentryEnabled) {
      Sentry.captureException(typeof message === 'string' ? new Error(message) : message, captureContext);
    }
  },
  addBreadcrumb(breadcrumb: Breadcrumb) {
    if (sentryEnabled) {
      Sentry.addBreadcrumb(breadcrumb);
    }
  },
};

export default sentry;
