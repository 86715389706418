import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

export const agentWithShopMembershipQuery = graphql(/* GraphQL */ `
  query AgentsWithAllMemberships($where: DbAgentBoolExp!, $limit: Int, $offset: Int, $orderBy: [DbAgentOrderBy!]) {
    dbAgent(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
      ...AgentWithShopMembership
    }
    dbAgentAggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
  }
`);

export const useAgentsWithAllMemberships = (
  variables: VariablesOf<typeof agentWithShopMembershipQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['agent', 'useAgentWithShopMembershipQuery', variables],
    queryFn: async () => await graphqlClient.request(agentWithShopMembershipQuery, variables),
    ...options,
  });
  return {
    agentsWithMembership: data?.dbAgent,
    totalAgentCount: data?.dbAgentAggregate.aggregate?.totalCount,
    ...rest,
  };
};
