import { EmailData } from '@ev/eva-container-api';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { SendEmailForm } from 'components/emails/SendEmailPopover/SendEmailForm';
import { NylasIntegratedEmails } from 'components/emails/utils';
import { ErrorDisplay } from 'components/error/ErrorDisplay';
import { ModalConfirmation } from 'components/general/Modal';
import { PopoverFrame } from 'components/general/PopoverFrame/PopoverFrame';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { theme } from 'theme';
import { useCreateEmailDefaultValues } from 'util/defaultValues/createEmailDefaultValues';
import { useTranslation } from 'util/i18next';
import { useEmailSchema } from 'util/schemas/sendEmailSchema';

interface SendEmailPopoverProps {
  initialData: EmailData;
  onClose: () => void;
  from: string | null;
  possibleFromEmails: NylasIntegratedEmails[];
}

const INITIAL_EMAIL_POPOVER_HEIGHT = 678;

export function SendEmailPopover({ onClose, initialData, from, possibleFromEmails }: SendEmailPopoverProps) {
  const schema = useEmailSchema();
  const { activeShopSettings, activeShop } = useActiveShop();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const { t } = useTranslation(['communication']);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const { createEmailDefaultValues, error: loadDefaultValuesError } = useCreateEmailDefaultValues();

  const methods = useForm({
    mode: 'onTouched',
    resolver: zodResolver(schema),
    defaultValues: () =>
      createEmailDefaultValues({
        from: from || '',
        data: initialData,
        activeShopId: activeShop.id,
        preferredShopLanguage: activeShopSettings?.defaultPreferredLanguage,
      }),
  });
  const isLoadingDefaultValues = methods.formState.isLoading;
  const isDirty = methods.formState.isDirty;

  const onCloseWithConfirmation = () => {
    if (isDirty) {
      setIsConfirmationOpen(true);
    } else {
      onClose();
    }
  };

  const { watch } = methods;
  const subject = watch('subject');

  return (
    <>
      <FormProvider {...methods}>
        <PopoverFrame
          sx={{
            padding: 0,
            backgroundColor: theme.palette.backgroundExtension.grey1,
          }}
          popoverId="emailPopover"
          title={subject && !isMobile ? subject : t('communication:emailPopoverTitle')}
          onClose={onCloseWithConfirmation}
        >
          <>
            {isLoadingDefaultValues && !loadDefaultValuesError && (
              <Box sx={{ height: INITIAL_EMAIL_POPOVER_HEIGHT }}>
                <LinearProgress />
              </Box>
            )}
            {loadDefaultValuesError && (
              <ErrorDisplay
                sx={{ height: INITIAL_EMAIL_POPOVER_HEIGHT, justifyContent: 'center' }}
                title={t('communication:sendBulkEmailDialog.loadingErrorTitle')}
                message={t('communication:sendBulkEmailDialog.loadingError')}
                showDetailsForError={loadDefaultValuesError}
              />
            )}

            {!isLoadingDefaultValues && !loadDefaultValuesError && (
              <SendEmailForm possibleFromEmails={possibleFromEmails} onClose={onClose} />
            )}
          </>
        </PopoverFrame>
      </FormProvider>
      <ModalConfirmation
        isOpen={isConfirmationOpen}
        title={t('communication:emailPopover.abortConfirmation')}
        onConfirm={onClose}
        onCancel={() => setIsConfirmationOpen(false)}
      />
    </>
  );
}
