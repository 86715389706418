import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

// Requires Paper to have position relative
export const LinearProgressPaperTop = styled(LinearProgress)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  borderTopLeftRadius: '3px',
  borderTopRightRadius: '3px',
});
