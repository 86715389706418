// ___Extension means that these are properties which conflict with Material UI
export const palette = {
  brand: {
    red: '#E60000', // Only used for logo, use primary.main instead for UI
    black: '#000',
  },
  primary: {
    main: '#C80000', // Accessibility adjusted brand red
    dark: '#b30000',
  },
  secondary: {
    main: '#0D0D0D',
  },
  tertiary: {
    active: '#e5e5e5',
  },
  dark: {
    lighter: '#808080',
    coloured: '#262626',
  },
  backgroundExtension: {
    grey: '#fafafa',
    grey1: '#fcfcfb',
    white: '#fff',
  },
  text: {
    primary: '#444',
    secondary: '#777',
    disabled: '#bcbdbc',
  },
  textExtension: {
    white: '#fff',
  },
  success: {
    main: '#459e31',
    light: '#e7edd4',
  },
  error: {
    main: '#eb0a0a',
    light: '#fbebeb',
  },
  information: {
    main: '#1671c0',
    light: '#e8ecf4',
    light1: '#5b9bd2',
  },
  warning: {
    main: '#be4b0a',
    light: '#fff8e8',
  },
  warningExtension: {
    light1: '#f3a157',
    light2: '#f9c99f',
    light3: '#fdf4eb',
  },
  shade: {
    grey1: '#444',
    grey2: '#777',
    grey3: '#bcbdbc',
    grey4: '#f0f0f0',
    grey5: '#f6f6f6',
    grey6: '#404040',
  },
  transparent: {
    full: 'rgba(0,0,0,0)',
    black72: 'rgba(0,0,0,0.72)',
    black48: 'rgba(0,0,0,0.48)',
    black32: 'rgba(0,0,0,0.32)',
    black24: 'rgba(0,0,0,0.24)',
    black16: 'rgba(0,0,0,0.16)',
    black8: 'rgba(0,0,0,0.08)',
    white88: 'rgba(255,255,255,0.88)',
    white24: 'rgba(255,255,255,0.24)',
    red8: 'rgba(235, 10, 10, 0.08)',
    blue16: 'rgba(91, 155, 210, 0.16)',
    green16: 'rgba(124, 187, 110, 0.16)',
    yellow24: 'rgba(245, 214, 109, 0.24)',
    gray16: 'rgba(124, 62, 102, 0.16);',
    gray10: 'rgba(64, 64, 64, 0.1)',
  },
  special: {
    navy1: '#264582',
    navy2: '#677Ca7',
    navy3: '#A8B5CD',
    green1: '#459E31',
    green2: '#7CBB6E',
    green3: '#B5D8AD',
    yellow1: '#F4C832',
    yellow2: '#F5D66D',
    yellow3: '#FBE9AD',
    noon: '#F6F6F4',
  },
  additional: {
    purple: {
      main: '#7F3C67',
      light: '#EBDCE7',
    },
  },
};

export type EVPalette = typeof palette;
