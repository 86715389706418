import { EmailRecipient, useEmailSending } from '@ev/eva-container-api';
import { TooltipButton } from 'components/general/Chips/LeadChip/TooltipButton';
import EmailIcon from 'components/icons/mail.svg?react';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useTranslation } from 'util/i18next';
import { usePermissions } from 'util/usePermissions';

export function EmailTooltipButton({ to }: { to: EmailRecipient[] }) {
  const { openSendEmail } = useEmailSending();
  const { activeShop } = useActiveShop();
  const { canSendEmail } = usePermissions();
  const { t } = useTranslation(['communication']);

  if (!canSendEmail(activeShop)) {
    return;
  }

  return (
    <TooltipButton
      icon={<EmailIcon />}
      onClick={() => {
        openSendEmail({
          to,
        });
      }}
    >
      {t('communication:emailForm.send')}
    </TooltipButton>
  );
}
