import { FeatureFlagsEnum } from 'api/graphql/generated/graphql';
import { useGetEnableFeatures } from 'api/graphql/hooks/useEnabledFeatures';
import { ErrorPage } from 'eva-frame/ErrorPage';
import { createContext, PropsWithChildren, useContext } from 'react';
import { useTranslation } from 'util/i18next';

type EnabledFeatureState = FeatureFlagsEnum[];

export const FeatureFlagContext = createContext<EnabledFeatureState>([]);

export function FeatureFlagProvider({ children }: PropsWithChildren) {
  const { enabledFeatures, isError } = useGetEnableFeatures();
  const { t } = useTranslation(['user']);

  if (!enabledFeatures && isError) {
    return (
      <ErrorPage
        title={t('user:error.unknownError.title')}
        message={t('user:error.unknownError.message')}
        buttonText={t('user:error.unknownError.button')}
        onClick={() => location.reload()}
      />
    );
  }

  if (!enabledFeatures) {
    // Make sure that we know what features are enabled before we start rendering
    return null;
  }

  return <FeatureFlagContext.Provider value={enabledFeatures}>{children}</FeatureFlagContext.Provider>;
}

export const useIsFeatureEnabled = (feature: FeatureFlagsEnum) => {
  const enabledFeatures = useContext(FeatureFlagContext);
  return enabledFeatures?.includes(feature);
};
