import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/material/styles';
import { SectionHeaderText } from 'components/general/Section/SectionHeaderText';
import { ReactNode } from 'react';
import { theme } from 'theme';

export function SectionHeader({
  label,
  children,
  sx,
  sxContainer,
}: {
  label?: string;
  children?: ReactNode;
  sx?: SxProps;
  sxContainer?: SxProps;
}) {
  return (
    <Stack
      direction="row"
      sx={{
        borderBottom: `1px solid ${theme.palette.shade.grey4}`,
        height: 42,
        gap: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        ...sxContainer,
      }}
    >
      <Grid item sx={{ marginRight: 'auto', ...sx }}>
        {label && <SectionHeaderText>{label}</SectionHeaderText>}
      </Grid>
      {children}
    </Stack>
  );
}
