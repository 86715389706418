import { Action, ActionsModal } from 'components/general/ActionsModal/ActionsModal';
import { useTranslation } from 'util/i18next';

export type ModalType = 'alreadyProposed' | 'serviceContacts' | 'unresolvedPlaceholders' | null;

interface ModalRendererProps {
  currentModal: ModalType;
  isSending: boolean;
  onCancel: () => void;
  onSend: () => void;
}

export const PreviewBulkSendingModals = ({ currentModal, isSending, onCancel, onSend }: ModalRendererProps) => {
  const { t } = useTranslation(['communication']);

  if (!currentModal) return null;

  const modalConfig = {
    alreadyProposed: {
      title: t('communication:emailForm.alreadyProposedProperties.title'),
      description: t('communication:sendBulkEmailDialog.alreadyProposedProperties.confirmation'),
      actions: [
        {
          message: t('communication:newEmail.drawer.send'),
          variant: 'outlined',
          color: 'secondary',
          disabled: isSending,
          handleClick: onSend,
        },
        {
          message: t('communication:emailForm.hasServiceContact.cancel'),
          variant: 'contained',
          color: 'primary',
          handleClick: onCancel,
        },
      ] as Action[],
    },
    serviceContacts: {
      title: t('communication:emailForm.hasServiceContact.title'),
      description: t('communication:emailForm.hasServiceContact.description'),
      actions: [
        {
          message: t('communication:newEmail.drawer.send'),
          variant: 'outlined',
          color: 'secondary',
          disabled: isSending,
          handleClick: onSend,
        },
        {
          message: t('communication:emailForm.hasServiceContact.cancel'),
          variant: 'contained',
          color: 'primary',
          handleClick: onCancel,
        },
      ] as Action[],
    },
    unresolvedPlaceholders: {
      title: t('communication:emailForm.unresolved.error.title'),
      description: t('communication:emailForm.unresolved.error.description'),
      actions: [
        {
          message: t('communication:newEmail.drawer.send'),
          variant: 'outlined',
          color: 'secondary',
          disabled: isSending,
          handleClick: onSend,
        },
        {
          message: t('communication:emailForm.bulk.unresolvedModal.cancel'),
          variant: 'contained',
          color: 'primary',
          handleClick: onCancel,
        },
      ] as Action[],
    },
  };

  const { title, description, actions } = modalConfig[currentModal];

  return <ActionsModal open handleClose={onCancel} title={title} description={description} actions={actions} />;
};
