import { NOTIFICATION_Z_INDEX, SnackBarContext, SnackbarOptions } from '@ev/eva-container-api';
import { Box, Snackbar } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';
import { ErrorBoundaryInner } from 'components/error/ErrorBoundary/ErrorBoundaryInner';
import { SnackbarAlert } from 'components/general/EVAlert/SnackbarAlert';
import { ErrorInfoModal } from 'components/general/ErrorInfoModal/ErrorInfoModal';
import { nanoid } from 'nanoid';
import { ReactNode, useCallback, useState } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

type SnackbarData = {
  id: string;
  message: string | ReactNode;
  type: AlertColor;
  options?: SnackbarOptions;
};

export function SnackBarProvider({ children }: { children: ReactNode }) {
  const { t } = useTranslation(['user']);
  const [snackBars, setSnackBars] = useState<SnackbarData[]>([]);
  const [errorSnackBarData, setErrorSnackBarData] = useState<SnackbarData | undefined>(undefined);
  const [hasError, setHasError] = useState(false);

  const openSnackBar = useCallback((message: string | ReactNode, type: AlertColor, options?: SnackbarOptions) => {
    const id = nanoid();
    setSnackBars((snackBars) => [...snackBars, { id, message, type, options }]);
    return id;
  }, []);

  const closeSnackBar = useCallback((id?: string) => {
    setHasError(false);
    if (id === undefined || id === null) {
      setSnackBars([]);
    } else {
      setSnackBars((snackBars) => snackBars.filter((snackbar) => snackbar.id !== id));
    }
  }, []);

  const onClose = useCallback(
    (onCloseCallback: (() => void) | undefined, snackBar: SnackbarData) => {
      closeSnackBar(snackBar.id);
      onCloseCallback?.();
    },
    [closeSnackBar],
  );

  const notificationsBanners = snackBars.filter((snackBar) => snackBar.options?.asBanner);
  const notifications = snackBars.filter((snackBar) => !snackBar.options?.asBanner);

  return (
    <SnackBarContext.Provider value={{ openSnackBar, closeSnackBar }}>
      <ErrorBoundaryInner hasError={hasError} setHasError={setHasError} fallback={null}>
        <Box>
          {notificationsBanners.map((snackBar, index) => {
            return (
              <SnackbarAlert
                key={`globalNotifications_${index}`}
                message={snackBar.message}
                action={snackBar.options?.action}
                type={snackBar.type}
                sx={{
                  pointerEvents: 'all',
                  backgroundColor: theme.palette.warning.light,
                  borderBottom: `solid 1px ${theme.palette.shade.grey4}`,
                  'div:nth-of-type(2)': {
                    width: '100%',
                  },
                }}
                sxAction={{ marginLeft: 'auto' }}
                onClose={() => onClose(snackBar.options?.onCloseCallback, snackBar)}
              />
            );
          })}
        </Box>
        <Box
          sx={{
            top: 0,
            position: 'fixed',
            width: '100vw',
            pointerEvents: 'none',
            zIndex: NOTIFICATION_Z_INDEX,
          }}
        >
          {notifications.map((snackBar, index) => {
            return (
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={snackBar?.options?.isSticky ? undefined : 4000}
                open={true}
                key={`notifications_${index}`}
                sx={{
                  marginTop: index === 0 ? theme.spacing(8) : theme.spacing(2),
                  marginRight: theme.spacing(2),
                  position: 'unset',
                }}
                onClose={() => onClose(snackBar.options?.onCloseCallback, snackBar)}
              >
                <SnackbarAlert
                  message={snackBar.message}
                  action={
                    snackBar.type === 'error' && !snackBar.options?.action
                      ? { text: t('user:errorInfoModal.button.label'), callback: () => setErrorSnackBarData(snackBar) }
                      : snackBar.options?.action
                  }
                  type={snackBar.type}
                  sx={{
                    pointerEvents: 'all',
                    'div:nth-of-type(2)': {
                      width: '100%',
                    },
                  }}
                  onClose={
                    snackBar.type === 'error' && !snackBar.options?.action
                      ? () => setErrorSnackBarData(undefined)
                      : () => onClose(snackBar.options?.onCloseCallback, snackBar)
                  }
                />
              </Snackbar>
            );
          })}
          {errorSnackBarData && (
            <ErrorInfoModal
              message={errorSnackBarData.message}
              errorInfo={errorSnackBarData.options?.errorDetailsInfo}
              onClose={() => setErrorSnackBarData(undefined)}
            />
          )}
        </Box>
      </ErrorBoundaryInner>
      {children}
    </SnackBarContext.Provider>
  );
}
